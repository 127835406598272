import { call, put, takeLatest } from "redux-saga/effects";
import { updateTagStart, updateTagFailure, updateTagSuccess } from "../reducer";

import { httpPutTag } from "../../../services/tags";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* updateTag({ payload }) {
  const { data, onSuccess, onFailure } = payload;
  const response = yield call(httpPutTag, data);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(updateTagSuccess());
    if (onSuccess) {
      onSuccess();
    }
    successNotifier("tag was successfully updated");
  } else {
    yield put(updateTagFailure(raw));
    networkErrorHandler(statusCode, message[0] || "Unable to update this tag.");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onUpdateTag() {
  yield takeLatest(updateTagStart.type, updateTag);
}
