import { useEffect,  useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectFeaturesData, selectIsLoadingFeatures } from "../../redux/plans/selector";
import { fetchFeatureDataStart } from "../../redux/plans/reducer";




const useFeatureGet = () => {
  const dispatch = useDispatch();
  const features = useSelector(selectFeaturesData);
  const isLoadingFeature = useSelector(selectIsLoadingFeatures);
 



  const fetchFeatures = useCallback(() => {
 
    dispatch(fetchFeatureDataStart());
  }, [dispatch]);

  useEffect(() => {
    fetchFeatures();
  }, [fetchFeatures,]);

  return {
    features,
    isLoadingFeature: isLoadingFeature,

  };
};

export default useFeatureGet;