import {
  Box,
  Grid,
  Spacer,
  Input,
  Select,
  Button,
  Switch,
  TextArea,
} from '@flexisaf/flexibull2';
import { Text } from '@flexisaf/flexibull2/build/typo';
import styled from 'styled-components';
import { useEffect } from 'react';
import Theme from '../../../utils/theme';
import fontSize from '../../../utils/typography';
import { useForm } from 'react-hook-form';

const Swiper = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

function TemplateStep1({
  step,
  setStep,
  data,
  handleClose,
  getDefaultTemplateInformation,
  bulkUpdateTemplateInformation,
  templateInformation,
  fetchIntegrationExtra,
  integrationExtras,
  products,
  setSearchProduct,
  loadingProducts,
  templateData,
  loadingIntegrationExtras,
  errorLoadingIntegrationExtras,
  setIsPublished,
  isPublished
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      ...getDefaultTemplateInformation(),
    },
  });

  useEffect(() => {
    if (watch("product")?.product?.integrationUrl) {
      fetchIntegrationExtra(watch("product")?.product);
    } else if (watch("product")?.product) {
    }
    // eslint-disable-next-line
  }, [watch('product')]);

  useEffect(() => {
    reset(templateInformation);
    // eslint-disable-next-line
  }, [step, templateInformation]);

  useEffect(() => {
    //TODO: Reset form with template data, for edit conditions
  }, [templateData]);

  return (
    <Swiper active={step === 1}>
      <Box pad="32px">
        <Text {...fontSize.h4}>Template Information</Text>
        <Spacer space="24px" />

        <Grid default="1fr 1fr" gap="24px">
          <Input
            spaceTop
            type="text"
            label={'Template Title'}
            required={true}
            placeholder={`Type in a Title`}
            value={watch('templateTitle')}
            {...register('templateTitle', {
              required: `Template Title is required`,
            })}
            error={errors.templateTitle?.message}
            onChange={({ target: { value } }) =>
              setValue('templateTitle', value)
            }
          />
          <Select
            disable={data?.id}
            spaceTop
            label="Product"
            required
            placeholder="Select Product"
            options={products.data.filter(d => d.integrationUrl).map((d) => ({
              label: d.name,
              value: d.id,
              product: d,
            }))}
            block
            isClearable
            value={watch('product')}
            {...register('product', {
              required: 'Product is required',
            })}
            error={errors.product?.message}
            onChange={(value) => {
              if (data?.id) return;
              setValue('product', value, {
                shouldDirty: false,
                shouldValidate: false,
              });
            }}
            onInputChange={(e) => setSearchProduct(e)}
            isLoading={loadingProducts}
          />
        </Grid>
        <Spacer space="24px" />
        <Grid default="1fr" gap="24px">
          <TextArea
            spaceTop
            type="text"
            label={'Description'}
            width="auto"
            required={true}
            placeholder={`Type in a short description for this template`}
            value={watch('templateDescription')}
            {...register('templateDescription', {
              required: `Description is required`,
            })}
            error={errors.templateDescription?.message}
            onChange={({ target: { value } }) =>
              setValue('templateDescription', value)
            }
          />
        </Grid>
        <Spacer space="24px" />
        <Text bold block>
                  Published status
                </Text>
                <Switch
                  label={isPublished ? "Publish" : "UnPublish"}
                  checked={isPublished}
                  onChange={() => setIsPublished(!isPublished)}
                />
      </Box>

      <Grid default="auto max-content" className="bt">
        <Box pad="24px">
          <Button
            onClick={handleClose}
            pale
            color="rgba(0,0,0,0.0)"
            fontColor={Theme.PrimaryGrey}
          >
            Cancel
          </Button>
        </Box>
        <Box pad="24px">
          <Button
            disabled={!watch('product')?.product?.integrationUrl || errorLoadingIntegrationExtras}
            progress={loadingIntegrationExtras}
            onClick={handleSubmit((v) => {
              bulkUpdateTemplateInformation(v);
              if (data?.id) {
                setStep(4);
                return;
              }

              if (integrationExtras?.[watch('product')?.product?.id]) {
                setStep(2);
              } else {
                setStep(3);
              }
            })}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Swiper>
  );
}

export default TemplateStep1;
