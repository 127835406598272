import {
  Box,
  Text,
  Grid,
  Button,
  Input,
  Select,
  Spacer,
  Table,
  StatusIndicator,
  Modal,
  Switch,
  ModalBody,
} from '@flexisaf/flexibull2';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Avatar } from "@flexisaf/flexibull2/build/avatar";
import { DropDown } from "@flexisaf/flexibull2/build/dropdown";
import { PageHeader } from "../../components/custom/header";
import { DashWrapper } from "../../components/custom/wrappers";
import { statusColors } from "../../utils/colors";
import Theme from "../../utils/theme";
import fontSize from "../../utils/typography";
import SubscriptionEditor from "./support/subscribe";
import ChangePlanModal from "./support/ChangePlan";
import DeleteSubscriptionModal from "./support/DeleteSubscription";
import request from "../../utils/request";
import urls from "../../utils/config";
import { errorNotifier, successNotifier } from "../../utils/helpers";
import { Link } from "react-router-dom";
import moment from "moment";
import { FlexiPagination } from "@flexisaf/flexibull2/build/table";
import {
  PAGE_SIZES,
  PROVISIONED_STATUS,
  SUBSCRIPTION_STATUS,
  IS_DEMO_SUB
} from "../../utils/constants";
import useSubscriptionsGet from '../../hooks/subscriptions/useSubscriptionsGet';
import useDebounce from "../../hooks/useDebounce";
import { EmptyState } from "@flexisaf/flexibull2/build/emptystates";
import useProductsGet from '../../hooks/products/useProductsGet';
import SectionLoader from "../../components/custom/sectionLoader";
import CanView, {
  Permissions,
  CanFilter,
} from "../../components/custom/canView";
import useToggleDemo from '../../hooks/subscriptions/useToggleDemo';

const getStatus = (row) => {
  if (moment().isBefore(moment(row.startDate), "D")) return "future";
  if (moment().isAfter(moment(row.endDate), "D")) return "expired";
  return "active";
};
const Subscriptions = (props) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    show: false,
    data: null,
  });
  const [planChangeModal, setPlanChangeModal] = useState({
    show: false,
    data: null,
  });
  const [delModal, setDelModal] = useState({
    show: false,
    data: {},
  });
  const [subscriptionType, setSubscriptionType] = useState(null)
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [status, setStatus] = useState(null);
  const [provisioned, setProvisioned] = useState(null);
  const [searchPlan, setSearchPlan] = useState("");
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [plans, setPlans] = useState([]);
  const debouncedPlanTerm = useDebounce(searchPlan, 1000);
  const [plan, setPlan] = useState(null);
  const [searchProduct, setSearchProduct] = useState("");
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [product, setProduct] = useState(null);
  const debouncedProductTerm = useDebounce(searchProduct, 1000);
  const {products} = useProductsGet()
  const additionalOptions ={
    isDemo:subscriptionType?.value,
    productID:product?.value,
    planID:plan?.value,
    status:status?.value,
    PROVISIONED:provisioned?.value
   
  }

  const {
    subscriptions,
    loadingSubscriptions,
    refetch,
    page,
    pageSize,
    setPage,
    setPageSize,
    searchTerm,
    setSearchTerm,
    setReloadPage,
  } = useSubscriptionsGet(additionalOptions);

 const {toggleSubDemo} = useToggleDemo()
  const column = [
    {
      title: "Subscription No.",
      key: "subscriptionNumber",
      searchable: "searchable",
      custom: (row) => {
        return (
          <Box>
            <Link to={`/subscriptions/${row.id}`}>
              <Text bold color={Theme.PrimaryColor}>
                #SUB-{String(row.subscriptionNumber).padStart(5, "0")}
              </Text>
            </Link>
          </Box>
        );
      },
    },
    {
      title: "Customer",
      key: "customer",
      searchable: "searchable",
      custom: (row) => {
        return (
          <Box>
            <Text bold color={Theme.PrimaryDark}>
              {row.customer?.name}
            </Text>
          </Box>
        );
      },
    },
    {
      title: "Product",
      key: "product",
      searchable: "searchable",
      custom: (row) => {
        return (
          <Box vAlign="start">
            {row?.plan?.product?.name && (
              <Avatar
                name={row.plan.product.name}
                src={row.plan.product.logoUrl ? row.plan.product.logoUrl : ""}
                margin="0 8px 0 0"
                color="none"
                size="18px"
              />
            )}
            <Box>
              <Text bold block>
                {row?.plan?.product?.name}
              </Text>
              <Text {...fontSize.caption}>Plan: {row.plan?.name}</Text>
            </Box>
          </Box>
        );
      },
    },
    {
      title: "Subscription type",
      key: "subscriptiontype",
      custom: (row) => (
          <div
          onClick={() =>
            toggleSubDemo({
              data: { id: row.id, isDemo: !row.isDemo },
              onSuccess: () => {
             refetch()
              },
          
            })
          }
          >
            <Switch checked={!row.isDemo} label= {row.isDemo ? "Demo" : "Live"}/>
          </div>
      ),
    },
    {
      title: "Activated on",
      key: "start",
      searchable: "searchable",
      custom: (row) => {
        return (
          <Box>
            <Text block bold>
              {moment(row.startDate).format("DD MMMM, YYYY")}
            </Text>
            <Text {...fontSize.caption}>
              {row.plan?.billingCycleType
                ? `Billing cycle: ${String(row.plan?.billingCycle)} ${String(
                    row.plan?.billingCycleType
                  ).toLowerCase()}`
                : ""}
            </Text>
          </Box>
        );
      },
    },
    {
      title: "End date",
      key: "end",
      searchable: "searchable",
      custom: (row) => (
        <Text>{moment(row.endDate).format("DD MMMM, YYYY")}</Text>
      ),
    },
    {
      title: "Status",
      key: "status",
      custom: (row) => {
        const rowStatus = getStatus(row);
        return (
          <StatusIndicator
            compound
            label={rowStatus}
            {...statusColors[rowStatus]}
          />
        );
      },
    },
    {
      title: "Provisioned",
      key: "isLive",
      custom: (row) => {
        return (
          <StatusIndicator
            compound
            label={row.isLive ? "Provisioned" : "Not Provisioned"}
            {...statusColors[row.isLive ? "active" : "inactive"]}
          />
        );
      },
    },
    {
      title: "Created",
      key: "createdAt",
      custom: (row) => (
        <Text>{moment(row.createdAt).format("DD MMMM, YYYY")}</Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      custom: (row) => {
        return (
          <Box align="right">
            <DropDown
              trigger="click"
              style={{ textAlign: "left" }}
              menuAlign="bottom right"
              label={
                <Box pad="8px" className="cursor">
                  <Text {...fontSize.h3}>...</Text>
                </Box>
              }
              menuList={CanFilter([
                {
                  onClick: () => navigate(`/subscriptions/${row?.id}`),
                  label: "View Subscription",
                  iconLeft: "icon-popup",
                  can: [Permissions.READ_SUBSCRIPTIONS],
                },
                {
                  onClick: () => setModal({ show: true, data: row }),
                  label: "Edit Subscription",
                  iconLeft: "icon-pencil",
                  can: [Permissions.UPDATE_SUBSCRIPTIONS],
                },
                {
                  onClick: () => setPlanChangeModal({ show: true, data: row }),
                  label: "Change Plan",
                  iconLeft: "icon-cog-outline",
                  can: [Permissions.UPDATE_SUBSCRIPTIONS],
                },
                {
                  onClick: () => setDelModal({ show: true, data: row }),
                  label: "Delete Subscription",
                  color: Theme.PrimaryRed,
                  iconLeft: "icon-trash",
                  can: [Permissions.DELETE_SUBSCRIPTIONS],
                },
              ])}
            />
          </Box>
        );
      },
    },
  ];

  const fetchPlans = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/plans?`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}&${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setPlans(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch customers");
    } finally {
      setLoadingPlans(false);
    }
  };

  const fetchProducts = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/products?`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}&${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
  
    } catch (error) {
      errorNotifier("Unable to fetch products");
    } finally {
      setLoadingProducts(false);
    }
  };

  useEffect(() => {
    setLoadingPlans(true);
    fetchPlans({
      search: debouncedPlanTerm,
      productId: product?.value,
    });
    // eslint-disable-next-line
  }, [debouncedPlanTerm, product]);

  useEffect(() => {
    setLoadingProducts(true);
    fetchProducts({
      search: debouncedProductTerm,
    });
    // eslint-disable-next-line
  }, [debouncedProductTerm]);

  const handleSubmitSubscription = async (submissionData, cb = () => null) => {
    setSaving(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/subscriptions${
          modal.data?.id ? `/${modal.data?.id}` : ""
        }`,
        method: modal.data?.id ? "put" : "post",
        data: submissionData,
      });
      if (!res.success) {
        throw res.message;
      }
      cb();
      refetch();
      successNotifier("The subscription was saved successfully");
    } catch (error) {
      let message = error.message || "Unable to save subscription";
      if (error.errors) {
        message = Object.values(error.errors)[0];
      }
      errorNotifier(message);
    } finally {
      setSaving(false);
    }
  };

  const handleChangePlan = async (submissionData, cb = () => null) => {
    setSaving(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/subscriptions/${planChangeModal.data?.id}/change-plan`,
        method: "put",
        data: submissionData,
      });
      if (!res.success) {
        throw res.message;
      }
      cb();
      refetch();
      successNotifier("The plan has been changed successfully");
    } catch (error) {
      let message = error.message || "Unable to change plan";
      if (error.errors) {
        message = Object.values(error.errors)[0];
      }
      errorNotifier(message);
    } finally {
      setSaving(false);
    }
  };
  const deleteSubscription = async (id) => {
    setDeleting(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/subscriptions/${id}`,
        method: "delete",
      });
      if (!res?.success) {
        throw res?.message;
      }

      setDelModal({
        show: false,
        data: {},
      });
      successNotifier("The Subscription has been deleted successfully!!");
      refetch();
    } catch (error) {
      errorNotifier(error?.message || "Unable to update subscription");
    } finally {
      setDeleting(false);
    }
  };
  if (loadingSubscriptions) return <SectionLoader />;
  return (
    <>
      <PageHeader>
        <Text {...fontSize.h3}>
          <i className="flexibull-clock" /> Subscriptions
        </Text>
      </PageHeader>
      <DashWrapper className="products">
        <Box pad="32px">
          <Grid default="minmax(100px, 350px) minmax(100px, 130px) minmax(100px, 130px) minmax(100px, 130px) minmax(100px, 130px)  minmax(100px, 130px) auto">
            <Box>
              <Input
                block
                type="search"
                placeholder="Search customer name, email or phone number"
                iconLeft="flexibull-spin6"
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
              />
            </Box>
            <Box>
              <Select
                block
                placeholder="Product"
                options={products?.data?.map((d) => ({
                  label: d.name,
                  value: d.id,
                  plan: d,
                }))}
                onInputChange={(e) => setSearchProduct(e)}
                onChange={(e) => {
                  setProduct(e);
                  setPlan(null);
                }}
                value={product}
                isClearable
                isLoading={loadingProducts}
              />
            </Box>
            <Box>
              <Select
                block
                placeholder="Plans"
                options={ plans?.data?.map((d) => ({
                  label: d.name,
                  value: d.id,
                  plan: d,
                }))}
                onInputChange={(e) => setSearchPlan(e)}
                onChange={(e) => setPlan(e)}
                value={plan}
                isClearable
                isLoading={loadingPlans}
              />
            </Box>
            <Box>
              <Select
                block
                placeholder="Status"
                options={SUBSCRIPTION_STATUS}
                onChange={(e) => setStatus(e)}
                value={status}
                isClearable
              />

             
            </Box>
            <Box>
            <Select
                block
                placeholder="Subscription type"
                options={ IS_DEMO_SUB.map((t) => ({ label: t.name, value: t.value }))}
                onChange={(e) => setSubscriptionType(e)}
                value={subscriptionType}
                isClearable
              />
              </Box>
            <Box>
              <Select
                block
                placeholder="Provisioned"
                options={PROVISIONED_STATUS}
                onChange={(e) => setProvisioned(e)}
                value={provisioned}
                isClearable
              />
            </Box>
            <Box align="center">
              <CanView can={[Permissions.CREATE_SUBSCRIPTIONS]}>
                <Button onClick={() => setModal({ show: true, data: null })}>
                  Add Subscription
                </Button>
              </CanView>
            </Box>
          </Grid>
          <Spacer space="24px" />
          {subscriptions?.data?.length === 0 ? (
            <EmptyState
              type="customers"
              title={`No ${
                status?.value.toLowerCase() || ""
              } subscription currently available`}
              action={
                <CanView can={[Permissions.CREATE_SUBSCRIPTIONS]}>
                  <Button onClick={() => setModal({ show: true, data: {} })}>
                    Add Subscription
                  </Button>
                </CanView>
              }
            />
          ) : (
            <>
              <Table responsive={false} report>
                <table>
                  <thead>
                    <tr>
                      {column &&
                        column.map((elem, index) => {
                          return (
                            <th scope="row" key={elem.key ? elem.key : index}>
                              {elem.title}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptions?.data &&
                      subscriptions.data.map((elem, index) => {
                        return (
                          <tr key={index}>
                            {column.map((entry, index) => {
                              return (
                                <td key={index} data-label={entry.key}>
                                  {entry.custom
                                    ? entry.custom(elem)
                                    : elem[entry.key]}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Table>
              <Box pad="20px">
                <FlexiPagination
                  pageCounts={PAGE_SIZES}
                  total={subscriptions?.count}
                  pageSize={pageSize}
                  itemsDisplayed
                  onChange={(s) => {
                    setReloadPage(false);
                    setPage(s);
                  }}
                  changePageSize={(s) => setPageSize(s?.value)}
                  current={page}
                />
              </Box>
            </>
          )}
        </Box>
        <Modal open={modal.show} center={false}>
          <ModalBody width="600px">
            <SubscriptionEditor
              closeModal={() => setModal({ show: false, data: null })}
              data={modal.data}
              onSubmit={handleSubmitSubscription}
              saving={saving}
            />
          </ModalBody>
        </Modal>
        {planChangeModal.show && (
          <Modal open={planChangeModal.show} center={false}>
            <ModalBody width="600px">
              <ChangePlanModal
                closeModal={() =>
                  setPlanChangeModal({ show: false, data: null })
                }
                data={planChangeModal.data}
                onSubmit={handleChangePlan}
                saving={saving}
              />
            </ModalBody>
          </Modal>
        )}
        <DeleteSubscriptionModal
          data={delModal.data}
          open={delModal.show}
          openModal={setDelModal}
          onDelete={deleteSubscription}
          deleting={deleting}
        />
      </DashWrapper>
    </>
  );
};

export default Subscriptions;
