const fontSize = {
  h1: { size: '1.6em', block: true, bold: true },
  h2: { size: '1.5em', block: true },
  h3: { size: '1.2em', bold: true },
  h4: { size: '1.2em' },
  h5: { size: '1em', bold: true },
  caption: { size: '0.8em', color: '#8893A6' },
  subtext: { size: '32px', uppercase: true },
  accordionLabel: { size: '.9em', bold: false },
  comingSoonText: { size: '30px', bold: true, color: '#3A61E91A' },
  templateIconLarge: { size: '40px' },
};

export default fontSize;
