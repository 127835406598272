import { useDispatch } from "react-redux";
import { togglePLanDemo } from "../../redux/plans/reducer";

const useToggleDemo = () => {
  const dispatch = useDispatch();  


  const toggleplanDemo =({data, onSuccess, onFailure }) => {
    dispatch(togglePLanDemo({ data, onSuccess, onFailure }));
  }
   
  return {
 toggleplanDemo,
  };
};

export default useToggleDemo;
