import { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Spacer,
  Input,
  Checkbox,
  Button,
  Modal,
  ModalBody,
  Tabs,
  Text,
} from "@flexisaf/flexibull2";
import styled from "styled-components";
import fontSize from "../../../utils/typography";
import Theme from "../../../utils/theme";
import SectionLoader from "../../../components/custom/sectionLoader";
import useRolesEditor from "../../../hooks/roles/useRolesEditor";
import useRolesGet from "../../../hooks/roles/useRolesGet";
import usePrivilegeModulesGet from "../../../hooks/roles/usePrivilegeModulesGet";

const sortOrder = ["Read", "Create", "Update", "Delete"];

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const PrivilegeBox = styled.div`
  & .privileges {
    margin: 10px 0px;
    border: 1px solid ${({ theme }) => theme.TetiaryColor};
    border-radius: 5px;
    background-color: #ffffff;
  }
  & .privilege_name {
    display: flex;
    align-items: center;
  }
`;

const RoleEditor = ({ open, setModal, data }) => {
  const [roleState, setRoleState] = useState({
    name: "",
    description: "",
    privileges: [],
  });
  const [errors, setErrors] = useState(null);
  const { createRole, isCreating, updateRole, isUpdating } = useRolesEditor();
  const { refetch } = useRolesGet();
  const { loadingModules, privilegeModules } = usePrivilegeModulesGet();

  const closeModal = () => {
    setRoleState({
      name: "",
      description: "",
      privileges: [],
    });
    setErrors(null);
    setModal({ show: false, data: {} });
  };

  const handlePermission = (e, permission, allPermissions) => {
    const { privileges } = allPermissions;
    let privilegeNamesClone = [...roleState.privileges];
    if (e.target.checked) {
      if (permission.name.toLowerCase() === "read") {
        privilegeNamesClone = [...privilegeNamesClone, permission.id];
      } else {
        const findRead = privileges.find(
          (p) => p.name.toLowerCase() === "read"
        );
        if (!findRead) {
          privilegeNamesClone = [...privilegeNamesClone, permission.id];
        } else {
          const readExists = roleState.privileges.find(
            (pId) => pId === findRead.pId
          );
          if (!findRead.id || readExists) {
            privilegeNamesClone = [...privilegeNamesClone, permission.id];
          } else {
            privilegeNamesClone = [
              ...privilegeNamesClone,
              permission.id,
              findRead.id,
            ];
          }
        }
      }
    } else {
      if (permission.name.toLowerCase() === "read") {
        const newPrivilegeNames = [];
        privilegeNamesClone.forEach((pr) => {
          const findPr = privileges.find((p) => p.id === pr);
          if (!findPr) {
            newPrivilegeNames.push(pr);
          }
        });
        privilegeNamesClone = newPrivilegeNames;
      } else {
        privilegeNamesClone = privilegeNamesClone.filter(
          (id) => id !== permission.id
        );
      }
    }
    setRoleState({
      ...roleState,
      privileges: privilegeNamesClone,
    });
  };

  const handleSubmit = () => {
    let errorsObject = {};
    if (!roleState?.name) {
      errorsObject["name"] = "Role name is required";
    }
    if (!roleState?.description) {
      errorsObject["description"] = "Role description is required";
    }
    if (roleState?.privileges?.length === 0) {
      errorsObject["privileges"] =
        "You have to select at least one privilege for this role";
    }

    setErrors(errorsObject);

    if (Object.keys(errorsObject)?.length !== 0) return;

    if (data?.id) {
      updateRole({
        data: {
          id: data.id,
          role: roleState,
        },
        onSuccess: () => {
          refetch();
          closeModal();
        },
      });
    } else {
      createRole({
        role: roleState,
        onSuccess: () => {
          refetch();
          closeModal();
        },
      });
    }
  };

  useEffect(() => {
    if (data?.id) {
      setRoleState({
        name: data?.name || "",
        description: data?.description || "",
        privileges: data?.privileges?.map((p) => p?.id),
      });
    }
  }, [data]);

  if (loadingModules) return <SectionLoader height="100%" />;
  return (
    <Modal open={open} center={false}>
      <ModalBody width="800px">
        <Box>
          <Swiper active={true}>
            <Grid default="auto max-content" responsive={false} className="bb">
              <Box pad="24px 32px">
                <Text {...fontSize.h3} uppercase>
                  {data?.id ? "Edit" : "Add"} Role
                </Text>
              </Box>
            </Grid>
            <Spacer space="24px" />

            <Box pad="24px 32px">
              <Grid default="45% 53.5%">
                <Input
                  type="text"
                  label="Name"
                  placeholder="Enter role name"
                  required
                  value={roleState.name}
                  error={errors?.name}
                  onChange={(e) =>
                    setRoleState({ ...roleState, name: e.target.value })
                  }
                />
                <Input
                  type="text"
                  label="Description"
                  placeholder="Enter short role description"
                  required
                  value={roleState.description}
                  error={errors?.description}
                  onChange={(e) =>
                    setRoleState({
                      ...roleState,
                      description: e.target.value,
                    })
                  }
                />
              </Grid>
              <Spacer space="12px" />

              <Tabs responsive={-1} bgColor={Theme.PrimaryFade}>
                <div label="Permissions">
                  {errors?.privileges && (
                    <Text color={Theme.PrimaryRed}>{errors.privileges}</Text>
                  )}
                  <PrivilegeBox>
                    {Object?.values(privilegeModules)?.map((privilege) => {
                      return (
                        <Box
                          key={privilege.id}
                          pad="10px"
                          className="privileges"
                        >
                          <Grid default="1fr 3fr">
                            <Text className="privilege_name" block size="12px">
                              {privilege.name}
                            </Text>
                            <Box>
                              <Grid default="repeat(4, 1fr)">
                                {[...privilege?.privileges]
                                  ?.sort((a, b) => {
                                    return (
                                      sortOrder.indexOf(a?.name) -
                                      sortOrder.indexOf(b?.name)
                                    );
                                  })
                                  ?.map((permission, i) => (
                                    <Checkbox
                                      key={i}
                                      label={permission.name}
                                      elevate="high"
                                      size="20px"
                                      // disabled={checkIfDisabled(
                                      //   permission.disabled
                                      // )}
                                      checked={roleState.privileges.find(
                                        (pId) => pId === permission.id
                                      )}
                                      onChange={(e) =>
                                        handlePermission(
                                          e,
                                          permission,
                                          privilege
                                        )
                                      }
                                    />
                                  ))}
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      );
                    })}
                  </PrivilegeBox>
                  {errors?.privileges && (
                    <Text color={Theme.PrimaryRed}>{errors.privileges}</Text>
                  )}
                </div>
              </Tabs>
            </Box>

            <Grid default="auto max-content" responsive={false} className="bt">
              <Box pad="24px">
                <Button
                  pale
                  color="rgba(0,0,0,0.0)"
                  fontColor={Theme.PrimaryGrey}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
              </Box>
              <Box pad="24px">
                <Button
                  progress={isCreating || isUpdating}
                  onClick={handleSubmit}
                >
                  {data?.id ? "Edit Role" : "Create Role"}
                </Button>
              </Box>
            </Grid>
          </Swiper>
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default RoleEditor;
