import { call, put, takeLatest } from "redux-saga/effects";
import {
  passwordResetRequestFailure,
  passwordResetRequestSuccess,
  passwordResetRequestStart,
} from "../reducer";
import { httpPostPasswordResetRequest } from "../../../services/auth";
import { networkErrorHandler } from "../../../utils/errorHandlers";

function* sendRequest({ payload }) {
  const { onSuccess, onFailure } = payload;

  const response = yield call(httpPostPasswordResetRequest, payload);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(passwordResetRequestSuccess());

    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield put(passwordResetRequestFailure(raw));
    networkErrorHandler(
      statusCode,
      message?.message ||
        message ||
        "Unable to send password reset request. Please try again later"
    );

    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onPasswordResetRequest() {
  yield takeLatest(passwordResetRequestStart.type, sendRequest);
}
