import { all, call } from "redux-saga/effects";

import onLogin from "./login";
import onLogout from "./logout";
import onPasswordResetRequest from "./sendPasswordResetRequest";
import onPasswordReset from "./passwordReset";
import onConfirmEmail from "./confirmEmail";

export default function* authSagas() {
  yield all([
    call(onLogin),
    call(onLogout),
    call(onPasswordResetRequest),
    call(onPasswordReset),
    call(onConfirmEmail),
  ]);
}
