import { useDispatch } from "react-redux";
import { toggleCustomerDemo } from "../../redux/customers/reducer";

const useToggleDemo = () => {
  const dispatch = useDispatch();  


  const togglecustomerDemo =({data, onSuccess, onFailure }) => {
    dispatch(toggleCustomerDemo({ data, onSuccess, onFailure }));
  }
   
  return {
 togglecustomerDemo,
  };
};

export default useToggleDemo;
