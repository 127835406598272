import { call, put, takeLatest } from "redux-saga/effects";
import {
    toggleCustomerDemoSuccess,
  toggleCustomerDemo,
  toggleCustomerDemoFailure
} from "../reducer";

import { httpToggleIsDemo } from "../../../services/customers";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* toggleIsDemo({ payload }) {
  const { data, onSuccess, onFailure } = payload;
  const response = yield call(httpToggleIsDemo, data);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(toggleCustomerDemoSuccess(raw));
    if (onSuccess) {
      onSuccess();
    }
    successNotifier("The demo status is updated successfully");
  } else {
    yield put(toggleCustomerDemoFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to edit this Plan.");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onToggleDemo() {
  yield takeLatest(toggleCustomerDemo.type, toggleIsDemo);
}
