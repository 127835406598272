import React from "react";
import { Layout, SplitLoginLayout, Text } from "@flexisaf/flexibull2";
import Theme from "../../utils/theme";
import { DashWrapper } from "../custom/wrappers";

import LoginBG from "../../assets/images/loginbg.svg";
import fontSize from "../../utils/typography";

const SplitLayoutContainer = ({ children, modal }) => {
  return (
    <>
      <Layout theme={Theme}>
        <DashWrapper>
          <SplitLoginLayout
            image={LoginBG}
            brand={
              <>
                <Text {...fontSize.h1}>SAFBilling</Text>
                <Text block>Billing and Subscription Management System</Text>
              </>
            }
            leftFoot={
              <>
                <div>SAFBilling version 1.0</div>
                <span>
                  Copyright © 2020 <strong>Flexisaf Edusoft Limited.</strong>{" "}
                  All rights reserved.{" "}
                </span>
              </>
            }
          >
            {children}
          </SplitLoginLayout>
        </DashWrapper>
        {modal}
      </Layout>
    </>
  );
};

export default SplitLayoutContainer;
