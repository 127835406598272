import { call, put, takeLatest } from "redux-saga/effects";
import {
  deleteStaffFailure,
  deleteStaffStart,
  deleteStaffSuccess,
} from "../reducer";

import { httpDeleteStaff } from "../../../services/staff";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* deleteStaff({ payload }) {
  const { id, onSuccess, onFailure } = payload;
  const response = yield call(httpDeleteStaff, id);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(deleteStaffSuccess());

    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield put(deleteStaffFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to delete this staff");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onDeleteStaff() {
  yield takeLatest(deleteStaffStart.type, deleteStaff);
}
