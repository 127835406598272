import { createSelector } from "@reduxjs/toolkit";

const selectAuth = (state) => state.auth;

export const selectIsLoading = createSelector(
  [selectAuth],
  (auth) => auth.isLoading
);

export const selectCurrentUser = createSelector(
  [selectAuth],
  (auth) => auth.currentUser
);

export const selectCurrentUserRole = createSelector(
  [selectCurrentUser],
  (currentUser) => currentUser?.user?.roles[0]?.role?.name || null
);

export const selectIsAuthenticated = createSelector(
  [selectAuth],
  (auth) => auth.isAuthenticated
);

export const selectIsLoadingPasswordResetRequest = createSelector(
  [selectAuth],
  (auth) => auth.isLoadingPasswordResetRequest
);

export const selectIsLoadingPasswordReset = createSelector(
  [selectAuth],
  (auth) => auth.isLoadingPasswordReset
);

export const selectIsConfirmingEmail = createSelector(
  [selectAuth],
  (auth) => auth.isConfirmingEmail
);
