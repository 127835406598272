import { call, put, takeLatest } from "redux-saga/effects";
import { fetchTagsFailure, fetchTagsStart, fetchTagsSuccess } from "../reducer";

import { httpGetTags } from "../../../services/tags";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getTags() {
  const response = yield call(httpGetTags);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchTagsSuccess(raw));
  } else {
    yield put(fetchTagsFailure(raw));
    networkErrorHandler(
      statusCode,
      message || "Unable to fetch tags. Please try again later."
    );
  }
}

export default function* onGetTags() {
  yield takeLatest(fetchTagsStart.type, getTags);
}
