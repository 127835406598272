import { call, put, takeLatest } from "redux-saga/effects";
import { deleteTagFailure, deleteTagStart, deleteTagSuccess } from "../reducer";

import { httpDeleteTag } from "../../../services/tags";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* deleteTag({ payload }) {
  const { id, onSuccess, onFailure } = payload;
  const response = yield call(httpDeleteTag, id);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(deleteTagSuccess());
    if (onSuccess) {
      onSuccess();
    }
    successNotifier("tag was successfully deleted");
  } else {
    yield put(deleteTagFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to delete this tag.");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onDeleteTag() {
  yield takeLatest(deleteTagStart.type, deleteTag);
}
