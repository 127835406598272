import { Box, Text, Table } from "@flexisaf/flexibull2";
import { useEffect, useState } from "react";
import { Avatar } from "@flexisaf/flexibull2/build/avatar";
import { PageHeader } from "../../components/custom/header";
import fontSize from "../../utils/typography";
import Theme from "../../utils/theme";
import { Link } from "react-router-dom";
import moment from "moment";
import { FlexiPagination } from "@flexisaf/flexibull2/build/table";
import { PAGE_SIZES } from "../../utils/constants";
import urls from "../../utils/config";
import request from "../../utils/request";
import { errorNotifier } from "../../utils/helpers";


function filterExpired(subscriptions) {
  const currentDate = moment()
  let expiredSubscriptions = [];
  subscriptions.forEach(subscription => {
    const endDate = moment(subscription?.endDate);
    const daysLeft = endDate.diff(currentDate, "days");
    if (daysLeft >= 0 ) {
      expiredSubscriptions = [...expiredSubscriptions, subscription]
    }
  });
  return expiredSubscriptions;
}
export default function SubscriptionsExpiringSoon() {
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const column = [
    {
      title: "Subscription No.",
      key: "subscriptionNumber",
      searchable: "searchable",
    },
    {
      title: "Customer",
      key: "customer",
      searchable: "searchable",
    },
    {
      title: "Product",
      key: "product",
      searchable: "searchable",
    },
    {
      title: "Sales person",
      key: "salesPerson",
      searchable: "searchable",
    },
    {
      title: "Activated on",
      key: "start",
      searchable: "searchable",
    },
    {
      title: "End date",
      key: "end",
      searchable: "searchable",
    },
  ];

  useEffect(() => {
    const fetchSubscriptionsExpiringSoon = async () => {
      try {
        const res = await request({
          url: `${urls.API_BASE_URL}/api/subscriptions/expiring-soon`,
        });
        if (!res.success) {
          throw res.message;
        }
        const subscriptions = filterExpired(res.raw.data)
        setSubscriptionList(subscriptions);
      } catch (error) {
        errorNotifier("Unable to fetch customers count");
      }
    };
    fetchSubscriptionsExpiringSoon();
  }, []);
  return (
    <><PageHeader>
      <Text {...fontSize.h3}>
        <i className="flexibull-clock" /> Subscriptions Expiring Soon
      </Text>
    </PageHeader><Box pad="32px">
        <Table responsive={false} report>
          <table>
            <thead>
              <tr>
                {column &&
                  column.map((elem, index) => {
                    return (
                      <th scope="row" key={elem.key ? elem.key : index}>
                        {elem.title}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {subscriptionList?.map((subscription, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/subscriptions/${subscription?.id}`}>
                        <Text bold color={Theme.PrimaryColor}>
                          #SUB-
                          {String(subscription?.subscriptionNumber).padStart(
                            5,
                            "0"
                          )}
                        </Text>
                      </Link>
                    </td>
                    <td>
                      <Text bold color={Theme.PrimaryDark}>
                        {subscription?.customer?.name}
                      </Text>
                    </td>
                    <td>
                      <Box vAlign="start">
                        {subscription?.product?.name && (
                          <Avatar
                            name={subscription?.product?.name || "--/--"}
                            margin="0 8px 0 0"
                            color="none"
                            size="18px" />
                        )}
                        <Box>
                          <Text bold block>
                            {subscription?.product?.name || "--/--"}
                          </Text>
                        </Box>
                      </Box>
                    </td>
                    <td>{subscription?.salesPerson?.name || "--/--"}</td>
                    <td>{moment(subscription?.startDate).format("DD MMMM, YYYY")}</td>
                    <td>{moment(subscription?.endDate).format("DD MMMM, YYYY")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Table>
        <Box pad="20px">
          <FlexiPagination
            pageCounts={PAGE_SIZES}
            total={subscriptionList?.length}
            pageSize={pageSize}
            itemsDisplayed
            onChange={(s) => setPage(s)}
            changePageSize={(s) => setPageSize(s?.value)}
            current={page} />
        </Box>
      </Box></>
  );
}
