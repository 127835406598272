import { Box } from '@flexisaf/flexibull2/build/layout';
import styled, {css} from 'styled-components';
import Theme from '../../utils/theme';
import { transparentize, darken} from 'polished';

export const DashWrapper = styled.div`
    & .bordered{
        border: 1px solid ${props=> props.theme.PrimaryBorderColor};
    }
    & .br{
        border-right: 1px solid ${props=> props.theme.PrimaryBorderColor};
    }
    & .bl{
        border-left: 1px solid ${props=> props.theme.PrimaryBorderColor};
    }
    & .bt{
        border-top: 1px solid ${props=> props.theme.PrimaryBorderColor};
    }
    & .bbt{
        border-top: 2px solid ${props=> props.theme.PrimaryBorderColor};
    }
    & .bb{
        border-bottom: 1px solid ${props=> props.theme.PrimaryBorderColor};
    }
    & .ar{
        text-align: right;
    }
    & .cursor{
        cursor: pointer;
    }
    & a{
        color: ${Theme.PrimaryColor};
    }
    & .flexi-table{
        overflow-x: visible;
        & table tbody tr{
            z-index: inherit;
            &:hover{
                z-index: inherit;
            }
            &:nth-child(odd) {
                &:hover{
                z-index: inherit;
            }
            }
        }
        & .flexi-dropdown-list{
            z-index: 90 !important;
        }
    }
    & .spaced{
        & .flexi-status-indicator{
            margin: 4px;
        }
    }
    & .product-wrapper .flexi-tab-header{
        padding-left: 32px;
        & .flexi-tab-header-box{
            min-height: 48px;
        }
    }
`;

export const DashCardee = styled.div`
    border: 1px solid ${props=> props.theme.PrimaryBorderColor};
    border-radius: ${props=>props.theme.SecondaryRadius};
    min-height: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    background: ${props=> props.pale ? 'none' : 'white'};
`;

export const DashCell = styled.div`
    grid-column: ${props=> props.col}/ span ${props=> props.colSpan};
    grid-row: ${props=> props.row}/ span ${props=> props.rowSpan};
    ${props=> !props.notCentered && css`
    display: grid;
    align-items: center;
    justify-content: center;
    `}
`;

export const DashCellEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
`;

export const ChartWrap = styled(Box)`
min-width: 100px;
min-height: 100px;
`;

export const Liner = styled.div`
    height: 0;
    width: 100%;
    margin: ${props=> props.margin};
    border-bottom: ${props=> props.thickness ? props.thickness : '1px'} ${props=> props.borderstyle ? props.borderstyle : 'solid'} ${props=> props.color ? props.color : Theme.PrimaryBorderColor}; 
`;

export const AlertBars = styled.div`
    padding: 8px;
    background: ${props=> transparentize(0.9, props.color)};
    border: 1px solid ${props=> props.color};
    margin: -1px;
    /* border-radius: ${props=> props.theme.PrimaryRadius}; */
    display: grid;
    grid-template-columns: max-content auto max-content;
    color: ${props=> darken(0.05, props.color)};
    grid-gap: 8px;
    align-items:center ;
`;

export const InfoBox = styled.div`
  background-color: ${Theme.PrimarySurface};
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
`;

export const TabBox = styled.div`
  background-color: ${(props) =>
    props.selected ? Theme.PrimarySurface : "white"};
  width: fit-content;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid
    ${(props) => (props.selected ? Theme.PrimaryBorderColor : "white")};
`;