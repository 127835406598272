import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchPrivilegesFailure,
  fetchPrivilegesStart,
  fetchPrivilegesSucess,
} from "../reducer";

import { httpGetPrivileges } from "../../../services/roles";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getPrivilegeModules() {
  const response = yield call(httpGetPrivileges);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchPrivilegesSucess(raw));
  } else {
    yield put(fetchPrivilegesFailure(raw));
    networkErrorHandler(
      statusCode,
      message || "Unabled to fetch privilege modules. Please try again later"
    );
  }
}

export default function* onGetPrivilegeModules() {
  yield takeLatest(fetchPrivilegesStart.type, getPrivilegeModules);
}
