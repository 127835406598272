import {
  Box,
  Text,
  Grid,
  Button,
  Input,
  Select,
  Spacer,
  Table,
  Switch,
  Modal,
  FlexiPagination,
} from '@flexisaf/flexibull2';
import { Avatar } from '@flexisaf/flexibull2/build/avatar';
import { DropDown } from '@flexisaf/flexibull2/build/dropdown';
import { ModalBody } from '@flexisaf/flexibull2/build/modal';
import { useState, useEffect } from "react";
import moment from 'moment';
import { PageHeader } from '../../components/custom/header';
import { DashWrapper } from '../../components/custom/wrappers';
import request from '../../utils/request';
import urls from '../../utils/config';
import Theme from '../../utils/theme';
import fontSize from '../../utils/typography';
import { CUSTOMER_TYPES, PAGE_SIZES,ISDEMO } from '../../utils/constants';
import CustomerEditor from './support/createCustomer';
import CanView, {
  Permissions,
  CanFilter,
} from "../../components/custom/canView";
import {
  errorNotifier,
  successNotifier,
  warningNotifier,
} from "../../utils/helpers";
import { EmptyState } from "@flexisaf/flexibull2/build/emptystates";
import useDebounce from "../../hooks/useDebounce";
import { Link, useNavigate } from "react-router-dom";
import SectionLoader from "../../components/custom/sectionLoader";
import useTagsGet from "../../hooks/tags/useTagsGet";
import styled from "styled-components"
import useToggleDemo from '../../hooks/customers/useToggleCustomerDemo';

const Gridstyle = styled(Grid)`
grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
`

const Header = () => (
  <PageHeader>
    <Text {...fontSize.h3}>
      <i className="icon-private" /> Customers
    </Text>
  </PageHeader>
);


const Customer = () => {
  const { tags, loadingTags } = useTagsGet();

  const navigate = useNavigate();
  const [modal, setModal] = useState({
    show: false,
    data: {},
  });
  const [selectedTags, setSelectedTags] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [saving, setSaving] = useState(false);
  const [customerType, setCustomerType] = useState(null);
  const [accountType, setAccountType] = useState(null)
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedTerm = useDebounce(searchTerm, 1000);

  const [delModal, setDelModal] = useState({
    show: false,
    data: null,
  });
  const [loadingData, setLoadingData] = useState(false);
  const [customerList, setCustomerList] = useState({ count: 0, data: [] });
  const {togglecustomerDemo} = useToggleDemo()

  const fetchCustomers = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/customers?include-contacts=true`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}&${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setCustomerList(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch customers");
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    setLoadingData(true);
    fetchCustomers();
  }, []);

  useEffect(() => {
    const options = {
      customerType: customerType?.value,
      isDemo:accountType?.value,
      search: debouncedTerm,
      offset: (page - 1) * pageSize,
      limit: pageSize,
    };
    selectedTags?.forEach((tag, i) => {
      options[`tags[${i}]`] = tag.value;
    });
    fetchCustomers(options);
  }, [debouncedTerm, customerType, selectedTags, pageSize, page, accountType ]);

  const column = [
    {
      title: "Customer Name",
      key: "name",
      searchable: "searchable",
      custom: (row) => {
        return (
          <Box>
            <Avatar name={row.name} round margin="0 8px 0 0" />
            <Link to={`/customers/${row.id}`}>
              <Text bold color={Theme.PrimaryColor}>
                {row.name}
              </Text>
            </Link>
          </Box>
        );
      },
    },
    {
      title: "Type",
      key: "customerType",
      searchable: "searchable",
      custom: (row) => (
        <Text bold color={Theme.PrimaryDark}>
          {row.customerType === "ORGANIZATION"
            ? "PRIVATE"
            : row.customerType || "NIL"}
        </Text>
      ),
    },
    {
      title: "Organization Phone",
      key: "phone",
      searchable: "searchable",
    },
    {
      title: "Account type",
      key: "accounttype",
      custom: (row) => (
        <div
        onClick={() =>
          togglecustomerDemo({
            data: { id: row.id, isDemo: !row.isDemo },
            onSuccess: () => {
            fetchCustomers()
            },
        
          })
        }
        >
          <Switch checked={!row.isDemo} label= {row.isDemo ? "Demo" : "Live"}/>
         </div>
      ),
    },
    {
      title: "Created",
      key: "createdAt",
      custom: (row) => (
        <Text>{moment(row.createdAt).format("DD MMMM, YYYY")}</Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      custom: (row) => {
        return (
          <Box align="left">
            <DropDown
              trigger="click"
              style={{ textAlign: "left" }}
              menuAlign="bottom right"
              label={
                <Box pad="8px" className="cursor">
                  <Text {...fontSize.h3}>...</Text>
                </Box>
              }
              menuList={CanFilter([
                {
                  onClick: () => navigate(`/customers/${row.id}`),
                  label: "View Details",
                  iconLeft: "icon-popup",
                  can: [Permissions.READ_CUSTOMERS],
                },
                {
                  onClick: () => setModal({ show: true, data: row }),
                  label: "Edit Customer",
                  disbale: true,
                  iconLeft: "icon-pencil",
                  can: [Permissions.UPDATE_CUSTOMERS],
                },
                {
                  onClick: () =>
                    setDelModal({
                      data: row,
                      show: true,
                    }),
                  label: "Delete Customer",
                  color: Theme.PrimaryRed,
                  iconLeft: "icon-trash",
                  can: [Permissions.DELETE_CUSTOMERS],
                },
              ])}
            />
          </Box>
        );
      },
    },
  ];

  const closeDelModal = () =>
    setDelModal({
      data: null,
      show: false,
    });

  const deleteCustomer = async (customerId) => {
    setSaving(true);
    try {
      await request({
        url: `${urls.API_BASE_URL}/api/customers/${customerId}`,
        method: "delete",
      });
      setModal({ show: false, data: {} });
      warningNotifier("The Customer has been deleted");
      closeDelModal();
      fetchCustomers();
    } catch (error) {
    } finally {
      setSaving(false);
    }
  };

  const saveCustomer = async (customerData, cb = () => null) => {
    setSaving(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/customers${
          modal.data.id ? `/${modal.data.id}` : ""
        }?include-contacts=true`,
        method: modal.data.id ? "put" : "post",
        data: {
          ...customerData,
          tags: customerData?.tags?.map((t) => t?.value),
        },
      });

      if (!res.success) {
        throw res.message;
      }
      cb();
      setModal({ show: false, data: {} });
      successNotifier("The Customer was saved successfully");
      setLoadingData(true);
      fetchCustomers();
    } catch (error) {
      errorNotifier( error || error?.error?.message);
    } finally {
      setSaving(false);
    }
  };

  if (loadingData) return <SectionLoader />;

  return (
    <>
      <Header />
      <DashWrapper className="customers">
        <Box pad="32px">
          <Gridstyle>
            <Box>
              <Input
                block
                type="search"
                placeholder="Search customer name, email or phone number"
                iconLeft="flexibull-spin6"
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
              />
            </Box>
            <Box>
              <Select
                block
                placeholder="Customer type"
                options={[
                  { label: "All Customer types", value: null },
                  ...CUSTOMER_TYPES.map((ct) => ({
                    label: ct === "ORGANIZATION" ? "PRIVATE" : ct,
                    value: ct,
                  })),
                ]}
                value={customerType}
                onChange={(value) => setCustomerType(value)}
              />
            </Box>
            <Box>
             
              <Select
                block
                placeholder="Tags"
                isLoading={loadingTags}
                options={tags?.data?.map((t) => ({ label: t.name, value: t.id }))}
                onChange={(e) => setSelectedTags(e)}
                value={selectedTags}
                isClearable
                isMulti
              />
              
            </Box>
            <Box>
                <Select
                block
                placeholder="Account type"
                isLoading={loadingTags}
                options={ ISDEMO.map((t) => ({ label: t.name, value: t.value }))}
                onChange={(e) => setAccountType(e)}
                value={accountType}
                isClearable
              />
              </Box>
            <Box >
              <CanView can={[Permissions.CREATE_CUSTOMERS]}>
                <Button onClick={() => setModal({ show: true, data: {} })}>
                  Create Customer
                </Button>
              </CanView>
            </Box>
          </Gridstyle>
          <Spacer space="24px" />
          {customerList.data.length === 0 ? (
            <EmptyState
              type="customers"
              title="No Customer available"
              action={
                <CanView can={[Permissions.CREATE_CUSTOMERS]}>
                  <Button onClick={() => setModal({ show: true, data: {} })}>
                    Add Customer
                  </Button>
                </CanView>
              }
            />
          ) : (
            <>
              <Table responsive={false} report>
                <table>
                  <thead>
                    <tr>
                      {column &&
                        column.map((elem, index) => {
                          return (
                            <th scope="row" key={elem.key ? elem.key : index}>
                              {elem.title}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {customerList?.data?.map((elem, index) => {
                      return (
                        <tr key={index}>
                          {column.map((entry, index) => {
                            return (
                              <td key={index} data-label={entry.key}>
                                {entry.custom
                                  ? entry.custom(elem)
                                  : elem[entry.key]}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Table>

              <Box pad="20px">
                <FlexiPagination
                  pageCounts={PAGE_SIZES}
                  total={customerList?.count}
                  pageSize={pageSize}
                  itemsDisplayed
                  onChange={(s) => setPage(s)}
                  changePageSize={(s) => setPageSize(s?.value)}
                  current={page}
                />
              </Box>
            </>
          )}
        </Box>
        <Modal open={modal.show} center={false}>
          <ModalBody width="600px">
            <CustomerEditor
              onSaveCustomer={saveCustomer}
              saving={saving}
              data={modal.data}
              closeModal={() => setModal({ show: false, data: {} })}
            />
          </ModalBody>
        </Modal>
        <Modal open={delModal.show} center={false}>
          <ModalBody width="600px">
            <Box pad="32px">
              <Text {...fontSize.h4} block bold>
                Delete the customer “{delModal.data?.name}”
              </Text>
              <Spacer space="16px" />
              <Text>
                Deleting the customer is not reversible and all linked account
                and product setup will be removed from the system.
              </Text>
            </Box>
            <Grid default="auto max-content" responsive={false} className="bt">
              <Box pad="24px">
                <Button
                  onClick={closeDelModal}
                  pale
                  fontColor={Theme.PrimaryGrey}
                  color={Theme.PrimaryGrey}
                >
                  <Text bold>No</Text>, Keep Customer
                </Button>
              </Box>
              <Box pad="24px">
                <Button
                  onClick={() => deleteCustomer(delModal.data.id)}
                  color={Theme.PrimaryRed}
                >
                  <Text bold>Yes</Text>, Delete Customer
                </Button>
              </Box>
            </Grid>
          </ModalBody>
        </Modal>
      </DashWrapper>
    </>
  );
};

export default Customer;
