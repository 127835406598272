import {
  Box,
  Grid,
  Spacer,
  Input,
  Checkbox,
  Button,
  Select,
} from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import { useForm } from "react-hook-form";
import { validEmail } from "../../../utils/regex";
import PasswordValidation from "../../../components/custom/password-validation";
import { validate } from "../../subscriptions/utils";
import useRolesGet from "../../../hooks/roles/useRolesGet";
import useUserEditor from "../../../hooks/users/useUserEditor";
import useUsersGet from "../../../hooks/users/useUsersGet";
const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const getDefaultDetails = () => ({
  firstName: "",
  lastName: "",
  otherName: "",
  email: "",
  password: "",
  roleId: null,
  status: false,
});

const UserEditor = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      ...getDefaultDetails(),
    },
  });
  const {
    isCreating,
    createUser,
    isUpdating,
    updateUser
  } = useUserEditor();
  const {
    refetch
  } = useUsersGet();
  

  const [showPassword, setShowPassword] = useState("");

  const closeModal = () => {
    reset({ ...getDefaultDetails() });
    props.closeModal();
  };
 
  const onSaveUser = (v) => {
    createUser({user: {...v, roleId: v?.roleId?.value},  onSuccess: () => {
      closeModal();
      refetch();
    }})
  };

const onUpdateUser = (v) => {
  const data = {...v, roleId: v?.roleId?.value} 
  updateUser({data: {id:props.data?.id, user:data},  onSuccess: () => {
    closeModal();
    refetch();
  }})
}

  const { roles } = useRolesGet();
  useEffect(() => {
    if (props.data?.id) {
      reset({
        firstName: props.data?.first_name,
        lastName: props.data?.last_name,
        otherName: props.data?.other_name,
        email: props.data?.username,
        status: props.data?.status,
        roleId: props.data?.roles[0]?.role?.id
          ? {
              label: props.data?.roles[0]?.role?.name,
              value: props.data?.roles[0]?.role?.id,
            }
          : null,
      });
    }
    // eslint-disable-next-line
  }, [props.data]);

  return (
    <Box>
      <Swiper active={true}>
        <Grid default="auto max-content" responsive={false} className="bb">
          <Box pad="24px 32px">
            <Text {...fontSize.h3} uppercase>
              {props.data?.id ? "Edit" : "Add"} User
            </Text>
          </Box>
        </Grid>
        <Box pad="32px">
          <Text {...fontSize.h4}>User Details</Text>
          <Spacer space="24px" />
          <Grid default="1fr 1fr" gap="24px">
            <Input
              spaceTop
              label="First Name"
              required
              placeholder="Enter first name"
              value={watch("firstName")}
              {...register("firstName", {
                required: "first name is required",
              })}
              error={errors.firstName?.message}
              onChange={({ target: { value } }) => setValue("firstName", value)}
            />
            <Input
              spaceTop
              label="Last Name"
              required
              placeholder="Enter last name"
              value={watch("lastName")}
              {...register("lastName", {
                required: "last name is required",
              })}
              error={errors.lastName?.message}
              onChange={({ target: { value } }) => setValue("lastName", value)}
            />
            <Input
              spaceTop
              label="Other Name"
              placeholder="Enter other name"
              value={watch("otherName")}
              {...register("otherName", {
                required: "other name is required",
              })}
              error={errors.otherName?.message}
              onChange={({ target: { value } }) => setValue("otherName", value)}
            />
            <Input
              spaceTop
              label="Email Address"
              required
              disabled={props.data?.id}
              placeholder="Enter email address"
              value={watch("email")}
              {...register("email", {
                required: "email address is required",
                pattern: {
                  value: validEmail,
                  message: "invalid email address",
                },
              })}
              error={errors.email?.message}
              onChange={({ target: { value } }) =>
                !props.data?.id && setValue("email", value)
              }
            />
          </Grid>
          <Spacer space="24px" />
          {!props.data?.id ? (
            <>
              <Grid default="1fr 1fr" gap="24px">
                <PasswordValidation
                  watch={watch}
                  register={register}
                  showPassword={showPassword}
                  validate={validate}
                  getValues={getValues}
                  setValue={setValue}
                  fieldErrors={
                    errors.password
                      ? { password: { message: "password is required" } }
                      : ""
                  }
                  error={errors.password?.message}
                  field="password"
                  type="password"
                />
                <Select
                  spaceTop
                  label="Role"
                  required
                  placeholder="Choose Role"
                  options={roles?.entities?.map((role) => ({
                    label: role.name,
                    value: role.id,
                  }))}
                  block
                  value={watch("roleId")}
                  {...register("roleId", {
                    required: "Role is required",
                  })}
                  error={errors.roleId?.message}
                  onChange={(value) => setValue("roleId", value)}
                />
              </Grid>
              <Checkbox
                block
                style={{ marginRight: "0px" }}
                label={<Text size="12px">show passwords</Text>}
                onChange={(e) => setShowPassword(e.target.checked)}
                checked={showPassword}
                size="16px"
              />
            </>
          ) : (
            <Grid default="1fr 1fr" gap="24px">
              <Select
                spaceTop
                label="Role"
                required
                placeholder="Choose Role"
                options={roles?.entities?.map((role) => ({
                  label: role.name,
                  value: role.id,
                }))}
                block
                value={watch("roleId")}
                {...register("roleId", {
                  required: "Required",
                })}
                error={errors.roleId?.message}
                onChange={(value) => setValue("roleId", value)}
              />
            </Grid>
          )}
          <Spacer space="24px" />
        </Box>

        <Grid default="auto max-content" responsive={false} className="bt">
          <Box pad="24px">
            <Button
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Box>
          <Box pad="24px">
           {props.data?.id ? <Button progress={isUpdating} onClick={handleSubmit(onUpdateUser)}>
              Edit
            </Button>: <Button progress={isCreating} onClick={handleSubmit(onSaveUser)}>
              Save
            </Button>}
          </Box>
        </Grid>
      </Swiper>
    </Box>
  );
};
export default UserEditor;
