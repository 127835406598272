import { useDispatch, useSelector } from "react-redux";

import {
  updateTagStart,
  createTagStart,
  deleteTagStart,
} from "../../redux/tags/reducer";
import {
  selectIsCreatingTags,
  selectIsUpdatingTag,
  selectIsDeletingTag,
} from "../../redux/tags/selectors";

const useTagsEditor = () => {
  const dispatch = useDispatch();
  const isCreating = useSelector(selectIsCreatingTags);
  const isEditing = useSelector(selectIsUpdatingTag);
  const isDeleting = useSelector(selectIsDeletingTag);

  const createTag = ({ data, onSuccess, onFailure }) => {
    dispatch(createTagStart({ data, onSuccess, onFailure }));
  };

  const updateTag = ({ data, onSuccess, onFailure }) => {
    dispatch(updateTagStart({ data, onSuccess, onFailure }));
  };

  const deleteTag = ({ id, onSuccess, onFailure }) => {
    dispatch(deleteTagStart({ id, onSuccess, onFailure }));
  };

  return {
    isCreating,
    isDeleting,
    isEditing,
    createTag,
    updateTag,
    deleteTag,
  };
};

export default useTagsEditor;
