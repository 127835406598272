import React from 'react';
import {
  Box,
  Grid,
  StatusIndicator,
  Avatar,
  Spacer,
  Button,
  Tag,
} from '@flexisaf/flexibull2';
import { Text } from '@flexisaf/flexibull2/build/typo';
import styled from 'styled-components';
import Theme from '../../../utils/theme';
import fontSize from '../../../utils/typography';
import { Liner } from '../../../components/custom/wrappers';
import { statusColors } from '../../../utils/colors';
import { Tooltip, Tooltiptext } from '@flexisaf/flexibull2/build/tooltip';
import { ReadMore } from '../../custom/readMoreText';

const Swiper = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

const TemplateStep5 = ({
  step,
  setStep,
  templateInformation,
  adminInformation,
  templateAdditionalInformation,
  handleClose,
  isPublished,
  templateOption,
  saveTemplate,
  saving,
}) => {
  const handleComplete = () => {
    const data = {
      templateData: templateInformation,
      admin: adminInformation,
      extra: templateAdditionalInformation,
      sourceTemplateId: templateOption?.templateOption?.id,
     isPublished:isPublished
    };
    saveTemplate(data, handleClose);
  };

  return (
    <Swiper active={step === 5}>
      <Box pad="32px">
        <Box
          round="high"
          background={Theme.PrimarySurface}
          className="bordered"
          pad="24px"
        >
          <Grid default="4fr 1fr">
            <Box>
              <Text {...fontSize.h4} block bold>
                {templateInformation?.templateTitle}
              </Text>
              <ReadMore text={templateInformation?.templateDescription} />
            </Box>
            <Text
              style={{ marginLeft: "auto" }}
              {...fontSize.templateIconLarge}
            >
              <i
                className={`${templateAdditionalInformation?.templateIcon?.icon}`}
              />
            </Text>
          </Grid>
        </Box>
        <Spacer space="12px" />

        <Box
          round="high"
          background={Theme.PrimarySurface}
          className="bordered"
          pad="24px"
        >
          <Grid default="1fr 1fr" gap="24px">
            <Box>
              <Text {...fontSize.caption} block>
                Product
              </Text>
              <Box vAlign="center">
                <Avatar
                  name={templateInformation?.product?.label}
                  src={templateInformation?.product?.product?.logoUrl}
                  margin="0 8px 0 0"
                  color="none"
                  size="20px"
                />
                <Text bold>{templateInformation?.product?.label}</Text>
              </Box>
            </Box>

            <Box>
              <Text {...fontSize.caption} block>
                Template URL Path
              </Text>
              <Text bold>safapply.com/{adminInformation?.url_path}</Text>
            </Box>
          </Grid>
          <Spacer space="24px" />

          <Box>
            <Text {...fontSize.caption}>Tags</Text>

            <Box>
              {templateAdditionalInformation?.templateTags &&
              templateAdditionalInformation?.templateTags?.length !== 0 ? (
                <>
                  {templateAdditionalInformation?.templateTags?.map(
                    (tag, i) => {
                      return (
                        <Tag
                          key={i}
                          pad="10px 15px"
                          color={Theme.PrimaryColor}
                          font="10px"
                          spaceRight
                        >
                          {tag?.label}
                        </Tag>
                      );
                    }
                  )}
                </>
              ) : (
                <Text {...fontSize.caption}>No tags selected</Text>
              )}
            </Box>
          </Box>
          <Liner margin="24px 0" borderstyle="dashed" />

          <Grid default="1fr 1fr 1fr" gap="24px">
            <Box>
              <Text {...fontSize.caption} block>
                Admin Information
              </Text>
              <Box vAlign>
                <Tooltip>
                  <Avatar
                    name={
                      `${adminInformation?.first_name} ${adminInformation.last_name}` ||
                      ""
                    }
                    margin="0 8px 0 0"
                    round
                    color={Theme.AccentRed}
                    size="32px"
                  />
                  <Tooltiptext top>
                    {adminInformation?.first_name} {adminInformation?.last_name}
                  </Tooltiptext>
                </Tooltip>
                <Text bold block>
                  {adminInformation?.first_name} {adminInformation?.last_name}{" "}
                  <br />
                  <a href={`mailto:${adminInformation?.email}`}>
                    {adminInformation?.email}
                  </a>
                </Text>
              </Box>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Platform Status
              </Text>
              <Box>
                <StatusIndicator
                  compound
                  label="not provisioned"
                  {...statusColors["inactive"]}
                />
              </Box>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Publish Status
              </Text>
              <Box>
                <StatusIndicator
                  compound
                  label={isPublished ?"published" : "not published" }
                  {...statusColors[ isPublished ?"active" : "inactive"]}
                />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>

      <Grid
        default="auto max-content max-content"
        responsive={false}
        className="bt"
        gap="0"
      >
        <Box pad="24px">
          <Button
            pale
            color="rgba(0,0,0,0.0)"
            fontColor={Theme.PrimaryGrey}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
        <Box pad="24px 0">
          <Button
            pale
            color="rgba(0,0,0,0.0)"
            iconLeft={<i className="flexibull-left" />}
            onClick={() => setStep(4)}
          >
            Previous
          </Button>
        </Box>
        <Box pad="24px">
          <Button
            iconRight={<i className="flexibull-right" />}
            progress={saving}
            onClick={handleComplete}
          >
            Start Provisioning
          </Button>
        </Box>
      </Grid>
    </Swiper>
  );
};

export default TemplateStep5;