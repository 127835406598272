import { useDispatch, useSelector } from "react-redux";

import {
  createPlanStart,
} from "../../redux/plans/reducer";
import {
  selectIsCreatePlan,
  selectIsUpdatePlan
} from "../../redux/plans/selector";
import { deletePlanStart, updatePlanStart } from "../../redux/plans/reducer";
import { selectIsDeletePlan } from "../../redux/plans/selector";
const usePlanEditor = () => {
  const dispatch = useDispatch();
  const isCreating = useSelector(selectIsCreatePlan);
  const isUpdating= useSelector(selectIsUpdatePlan)
  const isDeleting = useSelector(selectIsDeletePlan)

  const createPlan = ({ plan, onSuccess, onFailure }) => {
    dispatch(createPlanStart({ plan, onSuccess, onFailure }));
  };
  const updatePlan =({data, onSuccess, onFailure }) =>{
    dispatch(updatePlanStart({ data, onSuccess, onFailure }));
  }
  const deletePlan = ({ id, onSuccess, onFailure }) => {
    dispatch(deletePlanStart({ id, onSuccess, onFailure }));
  };

  return {
    isCreating,
    createPlan,
    deletePlan,
    isDeleting,
    isUpdating,
    updatePlan

  };
};

export default usePlanEditor;
