export const ORGANIZATION_ID = 'flexisaf';
export const PRODUCT_ID = '47af860e-0843-41d8-a8b6-f88748ab4ce0';
    
export const TOP_RIGHT = 'top-right';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export const INFO = 'info';
export const EMAIL = 'email';
export const DOCUMENT = 'document';
export const SMS = 'sms';
export const DEFAULT_ERROR_MESSAGE =
  'An error occurred. Please reload the page to try again.';

export const PAGE_SIZES = [
  { label: '5 Rows', value: 5 },
  { label: '10 Rows', value: 10 },
  { label: '15 Rows', value: 15 },
  { label: '20 Rows', value: 20 },
];
export const CUSTOMER_TYPES = ['ORGANIZATION', 'INDIVIDUAL', 'PUBLIC', 'NGO'];
export const ISDEMO = [{name:"Demo customers", value:true} , {name:"Real customers", value:false}]
export const IS_DEMO_PRODUCTS = [{name:"Demo products", value:true} , {name:"Real products", value:false}]
export const IS_DEMO_SUB = [{name:"Demo subscriptions", value:true} , {name:"Real subscriptions", value:false}]
export const PLAN_TYPES = [
  { label: 'Flat Billing', value: 'FLAT' },
  { label: 'Per Head', value: 'PER_HEAD' },
];

export const PLAN_TYPE_LABELS = {
  PER_HEAD: 'Per Head',
  FLAT: 'Flat Billing',
};

export const SUBSCRIPTION_STATUS = [
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'EXPIRED', value: 'EXPIRED' },
  { label: 'FUTURE', value: 'FUTURE' },
];

export const TEMPLATE_STATUS = [
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'INACTIVE', value: 'ONBOARDING' },
];

export const PROVISIONED_STATUS = [
  { label: 'Provisioned', value: 'PROVISIONED' },
  { label: 'Not Provisioned', value: 'NOT_PROVISIONED' },
];

export const MAX_DESC_LENGTH = 150;

export const REACT_APP_ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development'

export const EMPTY_VALUE = '--/--';

export const statusCodes = {
  INTERNAL_SERVER_ERROR: 500,
  BAD_REQUEST: 400,
  CONFLICT: 409,
  UNAUTHORIZED: 401,
  UNPROCESSABLE: 422,
};
