import {
  Box,
  Text,
  Grid,
  Button,
  Spacer,
  Modal,
  ModalBody,
  Notify,
} from '@flexisaf/flexibull2';
import { useEffect, useState } from 'react';
import { Avatar } from '@flexisaf/flexibull2/build/avatar';
import { PageHeader } from '../../components/custom/header';
import { DashWrapper } from '../../components/custom/wrappers';
import Theme from '../../utils/theme';
import fontSize from '../../utils/typography';
import ProductEditor from '../products/support/createProduct';
import { Tabs } from '@flexisaf/flexibull2/build/tabs';
import ProductDetails from "./support/productDetails";
import ProductPlans from '../plan/plans';
import ProductDiscounts from "./support/productDiscounts";
import urls from "../../utils/config";
import { Link, useParams } from "react-router-dom";
import request from "../../utils/request";
import { errorNotifier, successNotifier } from "../../utils/helpers";
import NotFound from "../../components/custom/notFound";
import SectionLoader from "../../components/custom/sectionLoader";

const Product = () => {
  const params = useParams();
  const [modal, setModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [productDetails, setProductDetails] = useState(null);
  const [saving, setSaving] = useState(false);
  const [productSummary, setProductSummary] = useState({});
  const [subscriptionsExpiringSoon, setSubscriptionsExpiringSoon] = useState(
    []
  );

  const fetchProduct = async (id) => {
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/products/${id}`,
      });
      if (!res.success) {
        throw res.message;
      }
      setProductDetails(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch product");
      setProductDetails(null);
    } finally {
      setLoadingData(false);
    }
  };

  const fetchProductSummary = async (id) => {
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/products/${id}/subscription-summary`,
      });
      if (!res.success) {
        throw res.message;
      }
      setProductSummary(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch product summarry");
    } finally {
      setLoadingData(false);
    }
  };

  const fetchSubscriptionsExpiringSoon = async (id) => {
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/subscriptions/expiring-soon?productId=${id}`,
      });
      if (!res.success) {
        throw res.message;
      }
      setSubscriptionsExpiringSoon(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch product summarry");
    } finally {
      setLoadingData(false);
    }
  };

  const savePlan = async (planData, cb = () => null) => {
    setSaving(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/plans`,
        method: "post",
        data: { ...planData, productId: params.id },
      });
      if (!res.success) {
        throw res.message;
      }
      cb();
      setModal(false);
      successNotifier("The plan was saved successfully");
      setLoadingData(true);
      fetchProduct(params.id);
    } catch (error) {
      errorNotifier(
        Object.values(error?.errors || {})[0] || "Unable to save plan"
      );
    } finally {
      setSaving(false);
    }
  };

  const saveProduct = async (productData, cb = () => null) => {
    setSaving(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/products${
          modal.data?.id ? `/${modal.data?.id}` : ""
        }`,
        method: modal.data?.id ? "put" : "post",
        data: productData,
      });
      if (!res.success) {
        throw res.message;
      }
      cb();
      successNotifier("The Customer was saved successfully");
      fetchProduct(params.id);
    } catch (error) {
      errorNotifier(error.message || "Unable to save product");
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      setLoadingData(true);
      fetchProduct(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      setLoadingData(true);
      fetchProductSummary(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      setLoadingData(true);
      fetchSubscriptionsExpiringSoon(params.id);
    }
  }, [params.id]);

  if (loadingData) return <SectionLoader />;
  if (!loadingData && !productDetails)
    return (
      <NotFound
        title="Product not found"
        info="Seems you are trying to access a product that does not exist"
        link="products"
      />
    );
  return (
    <>
      <PageHeader>
        <Text {...fontSize.h3}>
          <i className="flexibull-clock" /> Product
        </Text>
      </PageHeader>
      <DashWrapper className="products">
        <Box>
          <Box pad="32px" className="bb">
            <Grid default="auto max-content">
              <Box>
                <Text {...fontSize.caption} block>
                  <Link to="/products">
                    <i className="icon-left" /> back to product list
                  </Link>
                </Text>
                <Box vAlign="center" margin="8px 0 0 0">
                  <Avatar
                    name={productDetails?.name || ""}
                    src={productDetails?.logoUrl}
                    margin="0 8px 0 0"
                    color="none"
                    size="32px"
                  />{" "}
                  <Text {...fontSize.h3} color={Theme.PrimaryDark}>
                    {productDetails?.name}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Button
                  pale
                  iconLeft={<i className="icon-pencil" />}
                  onClick={() => setModal(true)}
                >
                  Edit Product
                </Button>
              </Box>
            </Grid>
          </Box>
          <Box className="product-wrapper">
            <Tabs
              bgColor="none"
              labelColor="none"
              padContent="0"
              persist={false}
            >
              <div label="Details">
                <ProductDetails
                  data={productDetails}
                  subscriptionSummary={productSummary}
                  expiringSoon={subscriptionsExpiringSoon?.data}
                />
              </div>
              <div label="Plans">
                <ProductPlans
                  productId={params?.id}
                  onSavePlan={savePlan}
                  saving={saving}
                />
              </div>
              <div label="Discounts">
                <ProductDiscounts />
              </div>
            </Tabs>
          </Box>
        </Box>
        <Modal open={modal} center={false}>
          <ModalBody width="600px">
            <ProductEditor
              data={productDetails}
              saving={saving}
              onSaveProduct={saveProduct}
              closeModal={() => setModal(false)}
            />
          </ModalBody>
        </Modal>
        <Modal open={delModal} center={false}>
          <ModalBody width="600px">
            <Box pad="32px">
              <Text {...fontSize.h4} block bold>
                Retire the product “SAFSIMS”
              </Text>
              <Spacer space="16px" />
              <Text>
                Retiring the product will make it unavailable to all customers
                and linked activities. Also the product will be achieved and it
                can be restored at a later time.
              </Text>
            </Box>
            <Grid default="auto max-content" reponsize={false} className="bt">
              <Box pad="24px">
                <Button
                  onClick={() => setDelModal(false)}
                  pale
                  fontColor={Theme.PrimaryGrey}
                  color={Theme.PrimaryGrey}
                >
                  <Text bold>No</Text>, Keep Product
                </Button>
              </Box>
              <Box pad="24px">
                <Button
                  onClick={() => {
                    setDelModal(false);
                    Notify("The Product has been retired", {
                      status: "warning",
                    });
                  }}
                  color={Theme.PrimaryRed}
                >
                  <Text bold>Yes</Text>, Retire Product
                </Button>
              </Box>
            </Grid>
          </ModalBody>
        </Modal>
      </DashWrapper>
    </>
  );
};

export default Product;
