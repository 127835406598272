import {
  Box,
  Grid,
  Text,
  Spacer,
  DashCard,
  CardSection,
} from "@flexisaf/flexibull2";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import { InfoBox } from "../../../components/custom/wrappers";

const SubscriptionPaymentSettings = () => {
  return (
    <Box className="bb" style={{ height: "70vh" }}>
      <Grid default="minmax(330px, 600px) 2fr" style={{ height: "100%" }}>
        <Box pad="32px">
          <Text {...fontSize.h3}>Payment Information</Text>
          <Spacer space="20px" />
          <Box display="flex">
            <Text {...fontSize.h5} bold={false}>
              Paylog ID - <Text bold>1000121</Text>
            </Text>
            <Text
              style={{ marginLeft: "1rem" }}
              bold
              color={Theme.PrimaryColor}
            >
              Update
            </Text>
            <Text style={{ marginLeft: "1rem" }} bold color={Theme.PrimaryRed}>
              Clear
            </Text>
          </Box>
          <Spacer space="24px" />

          <Grid default="1fr 1fr" gap="16px">
            <Box>
              <Text {...fontSize.caption}>Customer Name</Text>
              <Text bold block>
                University of Abuja
              </Text>
            </Box>
            <Box>
              <Text {...fontSize.caption}>Customer ID</Text>
              <Text bold block>
                Test/Demo/001
              </Text>
            </Box>
            <Box>
              <Text {...fontSize.caption}>Email</Text>
              <Text bold block>
                test@example.com
              </Text>
            </Box>
            <Box>
              <Text {...fontSize.caption}>Phone number</Text>
              <Text bold block>
                08166406459
              </Text>
            </Box>
          </Grid>
          <Spacer space="24px" />
          <InfoBox>
            <p>
              This information contains the subscription payment information
              that has been configured on FlexiSAF Paylog system. You can only
              update or clear these configuration using the buttons above, but
              to edit it, you must use the Paylog interface.
            </p>
            <p>
              <Text bold>Note:</Text> Ensure the information displayed here
              matches the customer information tied to. this subscription
              because this will determine how the customer will pay for any
              raised invoices.
            </p>
          </InfoBox>
        </Box>

        <Box
          className="bl"
          pad="32px"
          style={{ height: "100%" }}
          maxWidth="600px"
        >
          <Grid default="1fr 1fr">
            <DashCard
              elevate={null}
              style={{ border: `1px solid ${Theme.PrimaryInputOutline}` }}
            >
              <CardSection left>
                <Text
                  bold
                  size="12px"
                  style={{ textTransform: "capitalize" }}
                  color={Theme.PrimaryDark}
                >
                  Account Name
                </Text>
                <Text style={{ marginTop: "5px" }} block size="11px">
                  2611781012 . <Text bold>Kuda Bank</Text>
                </Text>
                <Spacer space="20px" />
                <img
                  src="/images/paystack.png"
                  alt="logo"
                  style={{ width: "50px" }}
                />
              </CardSection>
            </DashCard>
            <DashCard
              elevate={null}
              style={{ border: `1px solid ${Theme.PrimaryInputOutline}` }}
            >
              <CardSection left>
                <Text
                  bold
                  size="12px"
                  style={{ textTransform: "capitalize" }}
                  color={Theme.PrimaryDark}
                >
                  Account Name
                </Text>
                <Text style={{ marginTop: "5px" }} block size="11px">
                  2611781012 . <Text bold>Kuda Bank</Text>
                </Text>
                <Spacer space="20px" />
                <img
                  src="/images/remita.png"
                  alt="logo"
                  style={{ width: "50px" }}
                />
              </CardSection>
            </DashCard>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default SubscriptionPaymentSettings;
