import { call, put, takeLatest } from "redux-saga/effects";
import {
  confirmEmailFailure,
  confirmEmailStart,
  confirmEmailSuccess,
} from "../reducer";
import { httpPostConfirmEmail } from "../../../services/auth";

function* confirmEmail({ payload }) {
  const { onSuccess, onFailure } = payload;

  const response = yield call(httpPostConfirmEmail, payload);

  const { raw, success } = response;

  if (success) {
    yield put(confirmEmailSuccess());

    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield put(confirmEmailFailure(raw));

    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onConfirmEmail() {
  yield takeLatest(confirmEmailStart.type, confirmEmail);
}
