import { useState } from "react";
import {
  Box,
  Text,
  Grid,
  Button,
  Input,
  Spacer,
  Table,
  DropDown,
  FlexiPagination,
  EmptyState,
} from "@flexisaf/flexibull2";
import moment from "moment";
import SectionLoader from "../../components/custom/sectionLoader";

import useRolesGet from "../../hooks/roles/useRolesGet";
import { EMPTY_VALUE, PAGE_SIZES } from "../../utils/constants";
import Theme from "../../utils/theme";
import fontSize from "../../utils/typography";

import DeleteRoleModal from "./support/DeleteRole";
import RoleEditor from "./support/rolesEditor";
import useRolesEditor from "../../hooks/roles/useRolesEditor";
import CanView, {
  CanFilter,
  Permissions,
} from "../../components/custom/canView";

const RolesManagement = () => {
  const {
    loadingRoles,
    roles,
    page,
    pageSize,
    setPage,
    setPageSize,
    searchTerm,
    setSearchTerm,
    refetch,
  } = useRolesGet();
  const { deleteRole, isDeleting } = useRolesEditor();
  const [delModal, setDelModal] = useState({
    show: false,
    data: null,
  });
  const [modal, setModal] = useState({
    show: false,
    data: null,
  });

  const column = [
    {
      title: "Name",
      key: "name",
      searchable: "searchable",
      custom: (row) => (
        <Text style={{ textTransform: "capitalize" }}>{row.name}</Text>
      ),
    },
    {
      title: "Description",
      key: "description",
    },
    {
      title: "Date Created",
      key: "date_created",
      custom: (row) => (
        <Text>{moment(row.date_created).format("DD MMMM, YYYY")}</Text>
      ),
    },
    {
      title: "User Count",
      key: "user_count",
      custom: (row) => <Text>{row.user_count || 0}</Text>,
    },
    {
      title: "Action",
      key: "action",
      custom: (row) => {
        return (
          <Box align="left">
            <DropDown
              trigger="click"
              style={{ textAlign: "left" }}
              menuAlign="bottom right"
              label={
                <Box pad="8px" className="cursor">
                  <Text {...fontSize.h3}>...</Text>
                </Box>
              }
              menuList={CanFilter([
                {
                  onClick: () => setModal({ show: true, data: row }),
                  label: "Edit Role",
                  iconLeft: "icon-pencil",
                  can: [Permissions.UPDATE_ROLES],
                },
                {
                  onClick: () =>
                    setDelModal({
                      show: true,
                      data: row,
                    }),
                  label: `Delete Role`,
                  color: Theme.PrimaryRed,
                  iconLeft: "icon-block-outline",
                  can: [Permissions.DELETE_ROLES],
                },
              ])}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {!roles?.entities?.length && searchTerm === "" ? (
        <EmptyState
          type="customers"
          title="You have not created a role"
          action={
            <CanView can={[Permissions.CREATE_ROLES]}>
              <Button onClick={() => setModal({ show: true, data: {} })}>
                Add Role
              </Button>
            </CanView>
          }
        />
      ) : (
        <Box pad="32px">
          <Grid default="minmax(100px, 350px)  auto">
            <Box>
              <Input
                block
                type="search"
                placeholder="Search roles"
                iconLeft="flexibull-spin6"
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
              />
            </Box>

            <Box align="right">
              <CanView can={[Permissions.CREATE_ROLES]}>
                <Button onClick={() => setModal({ show: true, data: {} })}>
                  Add Role
                </Button>
              </CanView>
            </Box>
          </Grid>
          <Spacer space="24px" />
          {!roles?.entities?.length ? (
            <EmptyState
              type="customers"
              title="No roles available"
              action={
                <CanView can={[Permissions.CREATE_ROLES]}>
                  <Button onClick={() => setModal({ show: true, data: {} })}>
                    Add Role
                  </Button>
                </CanView>
              }
            />
          ) : loadingRoles ? (
            <SectionLoader />
          ) : (
            <>
              <Table responsive={false} report>
                <table>
                  <thead>
                    <tr>
                      {column &&
                        column.map((elem, index) => {
                          return (
                            <th scope="row" key={elem.key ? elem.key : index}>
                              {elem.title}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {roles.entities &&
                      roles.entities.map((elem, index) => {
                        return (
                          <tr key={index}>
                            {column.map((entry, index) => {
                              return (
                                <td
                                  key={entry?.id || index}
                                  data-label={entry.key}
                                >
                                  {entry.custom
                                    ? entry.custom(elem)
                                    : elem[entry.key] || EMPTY_VALUE}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Table>
              <Box>
                <FlexiPagination
                  pageCounts={PAGE_SIZES}
                  total={roles?.total}
                  pageSize={pageSize}
                  itemsDisplayed
                  onChange={(s) => setPage(s)}
                  changePageSize={(s) => setPageSize(s?.value)}
                  current={page}
                />
              </Box>
            </>
          )}
        </Box>
      )}

      <DeleteRoleModal
        data={delModal.data}
        open={delModal.show}
        openModal={setDelModal}
        deleting={isDeleting}
        onDelete={() => {
          deleteRole({
            id: delModal?.data?.id,
            onSuccess: () => {
              refetch();
              setDelModal({
                show: false,
                data: null,
              });
            },
          });
        }}
      />

      <RoleEditor
        data={modal.data}
        open={modal.show}
        setModal={setModal}
        onAdd={() => {}}
        onEdit={() => {}}
      />
    </>
  );
};

export default RolesManagement;
