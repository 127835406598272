import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Text, Box, Spacer, Button } from "@flexisaf/flexibull2";
import SplitLayoutContainer from "../../components/auth/splitLayoutContainer";
import UserVerified from "../../assets/images/verified.svg";
import UserNotVerified from "../../assets/images/not-verified.svg";

import { confirmEmailStart } from "../../redux/auth/reducer";

import SectionLoader from "../../components/custom/sectionLoader";

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("user");

  const [isVerified, setIsVerified] = useState(false);
  const [failedVerification, setFailedVerification] = useState(false);

  useEffect(() => {
    if (!token || !userId) {
      setFailedVerification(true);
      return;
    }

    dispatch(
      confirmEmailStart({
        data: {
          userId,
          token,
        },
        onSuccess: () => {
          setIsVerified(true);
          setFailedVerification(false);
        },
        onFailure: () => {
          setIsVerified(false);
          setFailedVerification(true);
        },
      })
    );
  }, [dispatch, token, userId]);

  if (isVerified) {
    return (
      <SplitLayoutContainer>
        <Box width="100%" height="100%">
          <Box width="100%" align="center">
            <img src={UserVerified} alt="verified" />
            <Box margin="30px auto" maxWidth="300px">
              <Text block bold size="18px">
                Verification Successful
              </Text>
              <Spacer space="15px" />
              <Text block>
                {" "}
                Your email account has been verified successfully, Login to your
                account to continue.
              </Text>
              <Spacer space="30px" />
              <Button onClick={() => navigate("/login")} width={150} bold>
                Log in
              </Button>
            </Box>
          </Box>
        </Box>
      </SplitLayoutContainer>
    );
  }

  if (failedVerification) {
    return (
      <SplitLayoutContainer>
        <Box width="100%" height="100%">
          <Box width="100%" align="center">
            <img src={UserNotVerified} alt="Not verified" />
            <Box margin="30px auto" maxWidth="300px">
              <Text block bold size="18px">
                Unable to verify user
              </Text>
              <Spacer space="15px" />
              <Text block>
                {" "}
                Your email account could not be verified, you can contact your
                account admin or support for more enquiries.
              </Text>
              <Spacer space="30px" />
              <Button onClick={() => navigate("/login")} width={150} bold>
                Back to home
              </Button>
            </Box>
          </Box>
        </Box>
      </SplitLayoutContainer>
    );
  }

  return (
    <SplitLayoutContainer>
      <SectionLoader height="100%" />
    </SplitLayoutContainer>
  );
};

export default ConfirmEmail;
