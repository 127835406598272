import { call, put, takeLatest } from "redux-saga/effects";
import { httpGetSafapplyFeatures } from "../../../services/plans";
import {
    fetchFeatureDataFailure,
    fetchFeatureDataStart,
    fetchFeatureDataSuccess,
  } from "../reducer";

  import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getFeatures() {
  const response = yield call(httpGetSafapplyFeatures);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchFeatureDataSuccess(raw));
  } else {
    yield put(fetchFeatureDataFailure(raw));
    networkErrorHandler(
      statusCode,
      message ||
        "Unable to fetch User summary. Please check your internet connection or try again later."
    );
  }
}

export default function* onGetFeatureData() {
  yield takeLatest(fetchFeatureDataStart.type, getFeatures);
}