import {
  Box,
  Text,
  Grid,
  Button,
  Spacer,
  Modal,
  ModalBody,
} from "@flexisaf/flexibull2";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";

const DeleteProductModal = function ({open, openModal, data, onDelete, deleting}) {
  return (
    <Modal open={open} center={false}>
      <ModalBody width="600px">
        <Box pad="32px">
        <Text {...fontSize.h4} block bold>
                Delete Product
              </Text>
          <Spacer space="16px" />
          <Text>
            Deleting <strong>{data?.name}</strong> will make it unavailable to all customers and
            linked activities. Also, this action cannot be undone. Are you sure
            you want to delete this product
          </Text>
        </Box>
        <Grid default="auto max-content" responsive={false} className="bt">
          <Box pad="24px">
            <Button
              onClick={() => openModal({ show: false, data: {} })}
              pale
              fontColor={Theme.PrimaryGrey}
              color={Theme.PrimaryGrey}
            >
              <Text bold>No</Text>, Keep Product
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              progress={deleting}
              onClick={() => {
                onDelete(data?.id);
              }}
              color={Theme.PrimaryRed}
            >
              <Text bold>Yes</Text>, Delete Product
            </Button>
          </Box>
        </Grid>
      </ModalBody>
    </Modal>
  );
};
export default DeleteProductModal;
