import { call, put, takeLatest } from "redux-saga/effects";
import {
  updateUserStart,
  updateUserFailure,
  updateUserSuccess,
} from "../reducer";

import { httpPutUser } from "../../../services/users";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* updateUser({ payload }) {
  const { data, onSuccess, onFailure } = payload;
  const response = yield call(httpPutUser, data);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(updateUserSuccess(raw));
    if (onSuccess) {
      onSuccess();
    }
    successNotifier("User updated successfully");
  } else {
    yield put(updateUserFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to edit this user.");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onUpdateUser() {
  yield takeLatest(updateUserStart.type, updateUser);
}
