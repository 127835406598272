import { call, all } from "redux-saga/effects";
import onGetProductData from "./getProducts";
import onToggleDemo from "./toggleProductsDemo";
export default function* productsSaga() {
  yield all([
    call(onGetProductData),
    call(onToggleDemo)
  
  ]);
}
