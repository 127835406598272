import request from "../utils/request";
import urls from "../utils/config";

const { API_BASE_URL } = urls || {};

export const httpGetDashboardData = () => {
  const url = `${API_BASE_URL}/api/dashboard/summary`;

  return request({
    url,
    method: "get",
  });
};
