import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import dashboardReducer from "./dashboard/reducer";
import subscriptionsReducer from "./subscriptions/reducer";
import tagsReducer from "./tags/reducer";
import staffReducer from "./staff/reducer";
import rolesReducer from "./roles/reducer";
import userReducer from "./users/reducer";
import productsReducer from "./products/reducer";
import planReducer from "./plans/reducer";
import invoicesReducer from './invoice/reducer'
import customersReducer from "./customers/reducer";
export const rootReducer = combineReducers({
  auth: authReducer,
  customer:customersReducer,
  dashboard: dashboardReducer,
  subscriptions: subscriptionsReducer,
  tags: tagsReducer,
  products:productsReducer,
  staff: staffReducer,
  roles: rolesReducer,
  users:userReducer,
  plans:planReducer,
  invoices:invoicesReducer
});

export default rootReducer;
