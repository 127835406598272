import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Box,
  Text,
  Grid,
  Button,
  Spacer,
  Table,
  Switch,
  Checkbox,
  Select,
  Input,
} from "@flexisaf/flexibull2";
import infinity from "../../assets/images/infinity.svg";
import fontSize from "../../utils/typography";
import { useForm } from "react-hook-form";
import Theme from "../../utils/theme";
import useTagsGet from "../../hooks/tags/useTagsGet";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const Boxstyle = styled(Box)`
  border: 1px solid;
  border-radius: 10px;
  padding: 0.7rem;
  width: 253px;
`;

const Gridstyle = styled(Box)`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
`;

const PlanStep3 = ({
  step,
  popular,
  setPopular,
  planInformation,
  featureLogic,
  status,
  isPublished,
  setIsPublished,
  closeModal,
  isDemo,
  setIsDemo,
  setStep,
  isCreating,
  setStatus,
  getDefaultAliases,
  bulkUpdatePlanAliases,
  handleAlias,
  planAliases,
  autoRenew,
  aliasValue,
  productId,
  integrationUrl,
}) => {
  const { tags } = useTagsGet();

  const {
    register,
    handleSubmit: handleSubmitAliases,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      ...getDefaultAliases(),
    },
  });
  useEffect(() => {
    reset(planAliases);

    // eslint-disable-next-line
  }, [step, planAliases]);

  const column = [
    {
      title: "Feature",
      key: "feature",
      custom: (row, index) => <Text>{row?.name.replace(/_/g, ' ')}</Text>,
    },

    isPublished
      ? {
          title: "Aliases",
          key: "feature",
          custom: (row, index) => (
            <Input
              spaceTop
              placeholder="Type feature alias"
              value={
                featureLogic[index].alias
                  ? featureLogic[index].alias
                  : aliasValue[index]
              }
              onChange={({ target: { value } }) => handleAlias(value, index)}
            />
          ),
        }
      : {},
    {
      title: "Value",
      key: "value",
      custom: (row, index) => (
        <>
          {typeof row?.value === "number" && !Number.isNaN(row?.value) ? (
            <>
              {row?.value === -1 ? (
                <img src={infinity} alt="minus" style={{ width: "15px" }} />
              ) : (
                <Text bold style={{ textAlign: "center", fontSize: "16px" }}>
                  {row?.value}
                </Text>
              )}
            </>
          ) : typeof row?.value === "boolean" ? (
            <Checkbox
              block
              style={{ marginRight: "0px" }}
              checked={row?.value}
              size="16px"
            />
          ) : null}
        </>
      ),
    },
  ];
  function renderPlaceholder() {
    if (parseInt(planInformation.unitCost) === 0) {
      return "free trial";
    } else if(parseInt(planInformation.unitCost) > 0 && parseInt(planInformation.freeTrial) < 1 ) {
      return "contact us";
    }else{
      return "start free trial"
    }
  }
  
  
  return (
    <>
      <Swiper active={step === 3}>
        <Spacer space="24px" />

        <Box pad=" 0 32px">
          <Text {...fontSize.h4}>
            Activate or publish to self service portal
          </Text>
          <Spacer space="24px" />
          <Grid default="1fr 1fr" style={{ justifyContent: "space-between" }}>
            <Gridstyle>
              <Box>
                <Text bold block>
                  Plan status
                </Text>
                <Switch
                  label={status ? "Activate" : "Deactivate"}
                  checked={status}
                  onChange={() => setStatus(!status)}
                />
              </Box>

              <Box>
                <Text bold block>
                  Published status
                </Text>
                <Switch
                  label={isPublished ? "Publish" : "Unpublish"}
                  checked={isPublished}
                  onChange={() => setIsPublished(!isPublished)}
                />
              </Box>
              <Box>
                <Spacer space="15px" />

                <Text bold block>
                  Is this a poplular plan ?
                </Text>
                <Switch
                  label={popular ? "Yes" : "No"}
                  checked={popular}
                  onChange={() => setPopular(!popular)}
                />
              </Box>
              <Box>
                <Spacer space="15px" />

                <Text bold block>
                Is this a demo plan ?
                </Text>
                <Switch
                  label={isDemo? "Yes" : "No"}
                  checked={isDemo}
                  onChange={() => setIsDemo(!isDemo)}
                />
              </Box>
            
            </Gridstyle>
            <Boxstyle>
              <Text bold block>
                Plan status:
              </Text>
              <Text>This determines if plan is in use or not</Text>
              <Text bold block>
                Publish status:
              </Text>
              <Text>
                This determines whether this plan will be visible to users on
                the pricing page. When publishing a plan, you can type in the
                alias that will be displayed to users or you can publish it with
                the default name.
              </Text>
            </Boxstyle>
          </Grid>
          <Spacer space="24px" />
       
            <Box>
              <Text bold block>
                Call to action button
              </Text>
              <Spacer space="10px" />
              <Box
                round="high"
                background={Theme.PrimarySurface}
                className="bordered"
                pad="24px"
              >
                {/* <Select
                spaceTop
                label="Select mode of payment"
                style={{width:"100%"}}
                placeholder="Select mode"
                options={[
                {  label:"contact us"},
                {  label:"pay now",}
                ]}
                
                onChange={(value) => setValue("segment", value)}
              />  */}
                <Spacer space="10px" />

                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Text bold>
                    What text do you want displayed on the call to action button
                    for this plan?
                  </Text>
                  <Spacer space="5px" />
                  <Input
                    placeholder={`Default should be ${
                    renderPlaceholder()
                    }`}
                    value={watch("callToActionText")}
                    {...register("callToActionText")}
                    onChange={({ target: { value } }) =>
                      setValue("callToActionText", value)
                    }
                  />
                </Box>
                <Spacer space="10px" />
                {planInformation.unitCost >= 1 ? (
                  <Box>
                    <Text bold>
                      Add an embed code for the user to fill in additional
                      information when the button is clicked.
                    </Text>
                    <Spacer space="5px" />

                    <textarea
                      placeholder="add embed code "
                      style={{
                        width: "100%",
                        padding: "0.9rem",
                        borderRadius: "4px",
                        borderColor: " rgb(203, 213, 228)",
                      }}
                      value={watch("embedCode")}
                      {...register("embedCode")}
                      onChange={({ target: { value } }) =>
                        setValue("embedCode", value)
                      }
                    ></textarea>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
        
          <Spacer space="24px" />
          <Box
            round="high"
            background={Theme.PrimarySurface}
            className="bordered"
            pad="24px"
          >
            <Box>
              <Select
                spaceTop
                label="Customer Segment"
                required
                placeholder="Select a segment"
                options={tags?.data?.map((v) => ({
                  label: v.name,
                  value: v.id,
                }))}
                block
                value={watch("segment")}
                {...register("segment", {
                  required: "segment is required",
                })}
                error={errors.segment?.message}
                onChange={(value) => setValue("segment", value)}
              />
            </Box>
            <Spacer space="24px" />
            <Grid default="1fr 1fr">
              <Box>
                <Text {...fontSize.caption} block>
                  Plan Name
                </Text>
                <Text bold>{planInformation.name}</Text>
              </Box>
              {isPublished ? (
                <Input
                  spaceTop
                  label="Alias (Optional)"
                  placeholder="Plan alias"
                  value={watch("planAlias")}
                  {...register("planAlias")}
                  error={errors.name?.message}
                  onChange={({ target: { value } }) =>
                    setValue("planAlias", value)
                  }
                />
              ) : (
                ""
              )}
            </Grid>
            <Spacer space="24px" />
            <Grid default="1fr 1fr">
              <Box>
                <Text {...fontSize.caption} block>
                  Billing Cycle
                </Text>
                {autoRenew ? (
                  <Text bold block>
                    On Auto Renew
                  </Text>
                ) : (
                  <Box>
                    {planInformation?.billingCycleFrequency?.value ? (
                      <Text
                        bold
                      >{`   every ${planInformation?.billingCycleFrequency?.value}
              
                 ${planInformation?.durationType?.value}
                `}</Text>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
              </Box>
              {isPublished ? (
                <Input
                  spaceTop
                  label="Alias (Optional)"
                  placeholder="Type billing cycle alias"
                  value={watch("billingAlias")}
                  {...register("billingAlias")}
                  onChange={({ target: { value } }) =>
                    setValue("billingAlias", value)
                  }
                />
              ) : (
                ""
              )}
            </Grid>
          </Box>
          <Spacer space="24px" />
        </Box>

        <Spacer space="24px" />
        <Box pad=" 0 32px">
          <Box
            round="high"
            background={Theme.PrimarySurface}
            className="bordered"
            pad="24px"
          >
            <Table style={{ overflowX: "visible" }}>
              <table style={{ backgroundColor: "#F2F4F6" }}>
                <thead pad="20px">
                  <tr>
                    {column &&
                      column.map((elem, index) => {
                        return (
                          <th scope="row" key={elem.key ? elem.key : index}>
                            {elem.title}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {!integrationUrl?.integrationUrl ? (
                    ""
                  ) : (
                    <>
                      {featureLogic &&
                        featureLogic.map((elem, outerindex) => (
                          <tr key={outerindex}>
                            {column.map((entry, innerindex) => {
                              return (
                                <td key={innerindex} data-label={entry.key}>
                                  {entry.custom
                                    ? entry.custom(elem, outerindex)
                                    : elem[entry.key]}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                    </>
                  )}
                </tbody>
              </table>
            </Table>
          </Box>
        </Box>
        <Spacer space="24px" />

        
        <Grid  default="auto max-content max-content"
        responsive={false}
        className="bt">
            <Box pad="24px">
              <Button
                pale
                color="rgba(0,0,0,0.0)"
                fontColor={Theme.PrimaryGrey}
                onClick={() => closeModal()}
              >
                close
              </Button>
            </Box>

            <Box pad="24px">   
            <Button
          pale
          color="rgba(0,0,0,0.0)"
          iconLeft={<i className="flexibull-left" />}
          onClick={() =>
            !integrationUrl?.integrationUrl ? setStep(1) : setStep(2)
          }
        >
          Previous
        </Button>
        </Box>
        <Box pad="24px">   
        <Button
              iconRight={<i className="flexibull-right" />}
              onClick={handleSubmitAliases((v) => {
                bulkUpdatePlanAliases(v);
                setStep(4);
              })}
              progress={isCreating}
            >
              Preview
            </Button>
            </Box>
          </Grid>
      </Swiper>
    </>
  );
};

export default PlanStep3;
