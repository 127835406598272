import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchTagsStart } from "../../redux/tags/reducer";
import {
  selectIsLoadingTags,
  selectTagsData,
} from "../../redux/tags/selectors";

const useTagsGet = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoadingTags);
  const tags = useSelector(selectTagsData);
  const [fetch, setFetch] = useState(0);

  const refetch = () => setFetch((prev) => prev + 1);

  useEffect(() => {
    dispatch(fetchTagsStart());
  }, [dispatch, fetch]);

  return {
    loadingTags: isLoading,
    tags,
    refetch,
  };
};

export default useTagsGet;
