import {
  Box,
  Grid,
  StatusIndicator,
  Avatar,
  Spacer,
  Input,
  Select,
  Button,
  Checkbox,
  Switch,
  TextArea,
} from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import { Liner } from "../../../components/custom/wrappers";
import { statusColors } from "../../../utils/colors";
import { useForm } from "react-hook-form";
import useDebounce from "../../../hooks/useDebounce";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import { errorNotifier } from "../../../utils/helpers";
import moment from "moment";
import { validate } from "../utils";
import PasswordValidation from "../../../components/custom/password-validation";
import { CardSelector } from "@flexisaf/flexibull2/build/cardselectors";
import { EMPTY_VALUE } from "../../../utils/constants";
import useStaffGet from "../../../hooks/staff/useStaffGet";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const adminFields = [
  {
    field: "product_____first_name",
    type: "text",
    required: true,
    title: "First Name",
    placeholder: "Ex: Jane",
  },
  {
    field: "product_____middle_name",
    type: "text",
    title: "Middle Name",
    placeholder: "Ex: Emily",
  },
  {
    field: "product_____last_name",
    type: "text",
    required: true,
    title: "Last Name",
    placeholder: "Ex: Doe",
  },
  {
    field: "product_____phone",
    type: "text",
    required: true,
    title: "Phone",
    placeholder: "Ex: 08100000123",
  },
  {
    field: "product_____email",
    type: "email",
    required: true,
    title: "Email",
    placeholder: "Ex: jane@example.com",
  },
];

const confirm_password = {
  field: "confirm password",
  type: "password",
  required: true,
  title: "Confirm Password",
};

const getDefaultExtraFields = () => {
  const defaultData = {};
  adminFields.forEach((f) => {
    defaultData[f.field] = "";
  });
  return defaultData;
};


const getDefaultSubscription = () => ({
  customer: null,
  plan: null,
  product: null,
  salesPerson: null,
  startDate: "",
  goLive: true,
  recurring: null,
  template: null,
  discount: null,
  isDemo:false
  // trialPeriod:null,
  // trialPeriodUnit:null
});

const SubscriptionEditor = (props) => {
  const [step, setStep] = useState(1);
  // const [defaultExtrafield, setDefaultExtrafield] = useState({ ...getDefaultExtraFields()})


  const [searchCustomer, setSearchCustomer] = useState("");
  const [integrationExtras, setIntegrationExtras] = useState({});
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [customers, setCustomers] = useState({ data: [] });
  const debouncedCustomerTerm = useDebounce(searchCustomer, 1000);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [searchProduct, setSearchProduct] = useState("");
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [products, setProducts] = useState({ data: [] });
  const debouncedProductTerm = useDebounce(searchProduct, 1000);
  const [templates, setTemplates] = useState(null);
  const [searchPlan, setSearchPlan] = useState("");
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [plans, setPlans] = useState([]);
  const [loadingDiscounts, setLoadingDiscounts] = useState(true);
  const [discounts, setDiscounts] = useState({ data: [] });
  const debouncedPlanTerm = useDebounce(searchPlan, 1000);
  const { staff, loadingStaff } = useStaffGet();

  const [showPasswords, setShowPasswords] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
...getDefaultExtraFields()
    },
  });

  const {
    register: registerExtraFields,
    handleSubmit: handleSubmitExtraFields,
    watch: watchExtraFields,
    formState: { errors: extraFieldsErrors },
    reset: resetExtraFields,
    getValues: getExtraFieldValues,
    setValue: setExtraFieldValue,
  } = useForm({
    defaultValues: {
      ...getDefaultExtraFields(),
    },
  });
  // useEffect(() => {
  //   reset(defaultExtrafield);
  //   // eslint-disable-next-line
  // }, [step, defaultExtrafield])
  

  const fetchCustomers = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/customers?`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}&${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setCustomers(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch customers");
    } finally {
      setLoadingCustomers(false);
    }
  };


  const fetchProducts = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/products?`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}&${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setProducts(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch customers");
    } finally {
      setLoadingProducts(false);
    }
  };

  const fetchPlans = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/plans?`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}&${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setPlans(res.raw);
    } catch (error) {
    
    } finally {
      setLoadingPlans(false);
    }
  };

  const fetchDiscounts = async (options = {}) => {
    try {
      setLoadingDiscounts(true);
      let url = `${urls.API_BASE_URL}/api/discounts`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}?${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setDiscounts(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch discounts");
    } finally {
      setLoadingDiscounts(false);
    }
  };

  const fetchTemplates = async () => {
    try {
      const url = `${urls.API_BASE_URL}/api/templates?productId=${
        watch("product")?.value || ""
      }&status=ACTIVE`;
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setTemplates(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch templates");
    } finally {
      if (loadingTemplates) setLoadingTemplates(false);
    }
  };

  const fetchIntegrationExtra = async ({
    id: productId,
    integrationUrl: url,
  } = {}) => {
    try {
      if (!url || integrationExtras[productId]) return;
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setIntegrationExtras((c) => ({
        ...c,
        [productId]: res.raw?.extraSchema || [],
      }));
      resetExtraFields(...getDefaultExtraFields);
    } catch (error) {
  
    }
  };

  useEffect(() => {
    setLoadingCustomers(true);
    fetchCustomers({ search: debouncedCustomerTerm });
  }, [debouncedCustomerTerm]);

  useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("product")]);

  useEffect(() => {
    fetchDiscounts({
      productId: watch("product")?.value,
      planId: watch("plan")?.value,
      status: "ACTIVE",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("plan")]);

  useEffect(() => {
    setLoadingProducts(true);
    fetchProducts({ search: debouncedProductTerm });
  }, [debouncedProductTerm]);

  useEffect(() => {
    setLoadingPlans(true);
    fetchPlans({ 
      search: debouncedPlanTerm,
      productId: watch("product")?.value,
    });
    fetchIntegrationExtra(watch("product")?.product);
    // eslint-disable-next-line
  }, [debouncedPlanTerm, watch("product")]);

  const handleClose = () => {
    setStep(1);
    reset({ ...getDefaultSubscription() });
    resetExtraFields({ ...getDefaultExtraFields });
    props.closeModal();
  };


  const handleSubmitSubscription = async () => {
    const formValues = getValues();
    const extraValues = getExtraFieldValues();
    delete extraValues.confirmPassword;
    if (extraValues.url_path) {
      extraValues.url_path = extraValues.url_path.replace(/\s/g, '').toLowerCase();
    }
    console.log(extraValues)
    const submissionData = {
      customerId: props?.data?.id
        ? props.data?.customer?.id
        : formValues?.customer?.value,
      planId: props?.data?.id ? props.data?.plan?.id : formValues.plan.value,
      discountId: props?.data?.id
        ? props.data?.discount?.id
        : formValues?.discount?.value,
      status: "ACTIVE",
      salesPerson: formValues.salesPerson?.label,
      salesPersonEmail: formValues.salesPerson?.value,
      startDate: formValues.startDate,
      goLive: formValues.goLive,
      isDemo:formValues.isDemo,
      // trialPeriod: formValues.trialPeriod ? formValues.trialPeriod.value : null,
      // trialPeriodUnit:formValues.trialPeriod ?formValues.trialPeriodUnit.value : null,
      recurring: formValues.recurring?.value || false,
      extra: {
        ...extraValues,
      },
    };
   
    if (templates?.data?.length > 0 && formValues.template) {
      submissionData.templateId = formValues.template;
    }
    props.onSubmit(submissionData, handleClose);
  };
  const getEndDate = () => {
    if (!watch("startDate") || !watch("plan")) return null;
    return moment(watch("startDate"))
      .add(
        watch("plan")?.plan?.billingCycle,
        String(watch("plan")?.plan?.billingCycleType).toLowerCase()
      )
      .format("DD MMMM, YYYY");
  };

  useEffect(() => {
    function populate (){
    if (props?.data?.id) {
      reset({
        customer: {
          label: props.data?.customer?.name,
          value: props.data?.customer?.id,
        },
        product: {
          label: props.data?.plan?.product?.name,
          value: props.data?.plan?.product?.id,
          product: props.data?.plan?.product,
        },
        plan: { label: props.data?.plan?.name, value: props.data?.plan?.id },
        discount: {
          label: props.data?.discount?.name,
          value: props.data?.discount?.id,
        },
        recurring: {
          label: props.data?.recurring ? "Recurring" : "One Off",
          value: props.data?.recurring,
        },
        salesPerson: {
          label: props.data?.salesPerson,
          value: props.data?.salesPersonEmail,
        },
        startDate: props.data?.startDate?.split("T")[0],
        goLive: props.data?.goLive,
      });
      resetExtraFields({
        ...getDefaultExtraFields(),
        ...(typeof props?.data?.extra === "string" ? JSON.parse(props?.data?.extra || "")  : props?.data?.extra),
      });
    } else {
      reset(getDefaultSubscription());
      resetExtraFields(getDefaultExtraFields());
    }}
  
    populate()
    if(props?.data?.id){
      populate()
    }
      // eslint-disable-next-line
  }, [props?.data?.id , props?.data]);


  return (
    <Box>
      <Grid default="auto max-content" responsive={false} className="bb">
        <Box pad="24px 32px">
          <Text {...fontSize.h3} uppercase>
            {props?.data?.id ? "Edit" : "Add a new"} Subscription
          </Text>
        </Box>
      </Grid>
      <Swiper active={step === 1}>
        <Box pad="32px">
          <Text {...fontSize.h4}>Product Details</Text>
          <Spacer space="24px" />

          <Grid default="1fr 1fr 1frd" gap="24px">
            <Select
              disable={props?.data?.id}
              spaceTop
              label="Customer"
              required
              placeholder="Select Customer"
              block
              options={customers.data.map((d) => ({
                label: d.name,
                value: d.id,
                customer: d,
              }))}
              value={watch("customer")}
              {...register("customer", {
                required: "customer is required",
              })}
              isClearable
              error={errors.customer?.message}
              onChange={(value) => {
                if (props?.data?.id) return;
                setValue("customer", value);
              }}
              onInputChange={(e) => setSearchCustomer(e)}
              isLoading={loadingCustomers}
            />
            <Select
              disable={props?.data?.id}
              spaceTop
              label="Product"
              required
              placeholder="Select Product"
              options={products.data.map((d) => ({
                label: d.name,
                value: d.id,
                product: d,
              }))}
              block
              isClearable
              value={watch("product")}
              {...register("product", {
                required: "product is required",
              })}
              error={errors.product?.message}
              onChange={(value) => {
                if (props?.data?.id) return;
                setValue("product", value);
                setValue("plan", null);
              }}
              onInputChange={(e) => setSearchProduct(e)}
              isLoading={loadingProducts}
            />
          </Grid>
          <Spacer space="24px" />
          <Liner borderstyle="dashed" />
          <Spacer space="24px" />

          <Grid default="1fr 1fr" gap="24px">
            <Select
              disable={props?.data?.id || !watch("product")}
              spaceTop
              label="Service Plan"
              required
              placeholder="Select a plan"
              block
              isClearable
              options={plans?.data?.map((d) => ({
                label: d.name,
                value: d.id,
                plan: d,
              }))}
              value={watch("plan")}
              {...register("plan", {
                required: "plan is required",
              })}
              error={errors.plan?.message}
              onChange={(value) => {
                if (props?.data?.id) return;
                setValue("plan", value);
              }}
              onInputChange={(e) => setSearchPlan(e)}
              isLoading={loadingPlans}
            />
            <Select
              spaceTop
              label="Sales Person"
              required
              placeholder="Select a sales person"
              block
              isLoading={loadingStaff}
              options={staff.map((s) => ({ label: s.name, value: s.email }))}
              value={watch("salesPerson")}
              {...register("salesPerson", {
                required: "sales person is required",
              })}
              error={errors.salesPerson?.message}
              onChange={(value) => setValue("salesPerson", value)}
            />
          </Grid>
          <Spacer space="24px" />
          <Grid default="1fr 1fr" gap="24px">
            <Input
              spaceTop
              type="date"
              label="Service Start Date"
              placeholder="Pick a date"
              required
              {...register("startDate", {
                required: "startDate is required",
              })}
              error={errors.startDate && "startDate is required"}
              onChange={({ target: { value } }) => setValue("startDate", value)}
              value={watch("startDate")}
            />
            <Select
              spaceTop
              label="Recurrence Type"
              required
              placeholder="Select recurrence type"
              name="recurring"
              block
              options={[
                { label: "One off", value: false },
                { label: "Recurring", value: true },
              ]}
              autocomplete="off"
              value={watch("recurring") || ""}
              {...register("recurring", {
                required: "Recurrence Type is required",
              })}
              error={errors.recurring?.message}
              onChange={(value) => setValue("recurring", value)}
            />
          </Grid>
         
          <Switch
                style={{marginTop:"0.9rem"}}
                  label={`Is this a demo subscription ?`}
                  checked={watch("isDemo")}
                  onChange={({ target: { checked } }) => setValue("isDemo", checked)}
                />
                 <Spacer space="24px" /> 
          {/* <Grid responsive={false} default="1fr 1fr">
              <Select
                spaceTop
                label="Trial Creation period"
                
                placeholder="Duration"
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((v) => ({
                  label: v,
                  value: v,
                }))}
                block
                value={watch("trialPeriod")}
                {...register("trialPeriod")}
                error={errors.billingCycle?.message}
                onChange={(value) => setValue("trialPeriod", value)}
              />
              
              <Select
                spaceTop
                label="Trial Period Unit"
                placeholder="unit"
                options={[
                  { label: "SECONDS", value: "SECONDS" },
                  { label: "DAYS", value: "DAYS" },
                  { label: "WEEKS", value: "WEEKS" },
                  { label: "MONTHS", value: "MONTHS" },
                  { label: "YEARS", value: "YEARS" },
                ]}
                block
                value={watch("trialPeriodUnit")}
                {...register("trialPeriodUnit")}
                error={errors.billingCycleType?.message}
                onChange={(value) => setValue("trialPeriodUnit", value)}
              />
            </Grid> */}
          <Spacer space="24px" />
          <Liner borderstyle="dashed" />
          <Spacer space="24px" />

          <Grid default="1fr 1fr" gap="24px">
            <Select
              spaceTop
              label="Discount"
              placeholder="Select discount"
              value={watch("discount")}
              block
              disable={
                !watch("plan")?.value ||
                !watch("product")?.value ||
                discounts.loading ||
                !discounts.data?.length
              }
              isLoading={loadingDiscounts}
              options={[{ label: "None", value: null }].concat(
                discounts.data?.map((d) => ({
                  label: d.name,
                  value: d.id,
                }))
              )}
              onChange={(value) => setValue("discount", value)}
            />

            <Text
              style={{ marginTop: "auto" }}
              color={Theme.SecondaryFontColor}
            >
              The discount list has been filtered to contain only available
              discounts for the selected plan.
            </Text>
          </Grid>
        </Box>

        <Grid default="auto max-content" responsive={false} className="bt">
          <Box pad="24px">
            <Button
              onClick={handleClose}
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
            >
              Cancel
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              onClick={handleSubmit(() =>
                integrationExtras?.[watch("product")?.product?.id]
                  ? setStep(2)
                  : setStep(4)
              )}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Swiper>
      <Swiper active={step === 2}>
        <Box pad="24px 32px">
          <Text {...fontSize.h4}>Organisation Information</Text>
          <Spacer space="24px" />
          <Grid default="1fr" gap="24px">
            {integrationExtras?.[watch("product")?.product?.id]
              ?.filter(
                (field) =>
                  field.field === "url_path" || field.field === "address"
              )
              .map((field, i) =>
                field.field !== "address" ? (
                  <>
                    {field.field === "url_path" ? (
                      <Grid default="1fr 2fr" gap="0px">
                        <Input
                          style={{ borderRadius: "5px 0px 0px 5px" }}
                          spaceTop
                          label={`${watch("product")?.label} url path`}
                          disabled
                          iconRight={null}
                          value="safapply.com/"
                        />

                        <Input
                          key={i}
                          spaceTop
                          type={field.type}
                          // label={field.field}
                          style={{ borderRadius: "0px 5px 5px 0px" }}
                          required
                          placeholder={`Enter ${field.field}`}
                          value={watchExtraFields(field.field)}
                          {...registerExtraFields(field.field, {
                            required: `${field.field} is required`,
                          })}
                          error={extraFieldsErrors[field.field]?.message}
                          onChange={({ target: { value } }) =>
                            setExtraFieldValue(field.field, value)
                          }
                        />
                      </Grid>
                    ) : (
                      <Input
                        key={i}
                        spaceTop
                        type={field.type}
                        label={field.field}
                        required
                        placeholder={`Enter ${field.field}`}
                        value={watchExtraFields(field.field)}
                        {...registerExtraFields(field.field, {
                          required: `${field.field} is required`,
                        })}
                        error={extraFieldsErrors[field.field]?.message}
                        onChange={({ target: { value } }) =>
                          setExtraFieldValue(field.field, value)
                        }
                      />
                    )}
                  </>
                ) : (
                  <TextArea
                    spaceTop
                    key={i}
                    type={field.type}
                    label={field.field}
                    width="auto"
                    required={true}
                    placeholder={`Type in the address of the organization`}
                    value={watchExtraFields(field.field)}
                    {...registerExtraFields(field.field, {
                      required: `${field.field} is required`,
                    })}
                    error={extraFieldsErrors[field.field]?.message}
                    onChange={({ target: { value } }) =>
                      setExtraFieldValue(field.field, value)
                    }
                  />
                )
              )}
          </Grid>
          <Spacer space="24px" />
        </Box>
        <Box pad="24px 32px">
          <Text {...fontSize.h4}>Admin Information</Text>
          <Spacer space="24px" />
          <Grid default="1fr 1fr 1fr" gap="24px">
            {adminFields?.slice(0, 3).map((field, i) => (
              <Input
                key={i}
                spaceTop
                type={field.type}
                label={field.title}
                required={field.required}
                placeholder={field.placeholder}
                value={watchExtraFields(field.field)}
                {...registerExtraFields(field.field, {
                  ...(field.required
                    ? {
                        required: `${field.title} is required`,
                      }
                    : {}),
                })}
                error={extraFieldsErrors[field.field]?.message}
                onChange={({ target: { value } }) =>
                  setExtraFieldValue(field.field, value)
                }
              />
            ))}
          </Grid>
          <Spacer space="24px" />
          <Grid default="1fr 1fr" gap="24px">
            {adminFields?.slice(3).map((field, i) => (
              <Input
                key={i}
                spaceTop
                type={field.type}
                label={field.title}
                required={field.required}
                placeholder={field.placeholder}
                value={watchExtraFields(field.field)}
                {...registerExtraFields(field.field, {
                  ...(field.required
                    ? {
                        required: `${field.title} is required`,
                      }
                    : {}),
                })}
                error={extraFieldsErrors[field.field]?.message}
                onChange={({ target: { value } }) =>
                  setExtraFieldValue(field.field, value)
                }
              />
            ))}
          </Grid>
          <Spacer space="24px" />

          <Grid default="1fr 1fr" gap="24px">
            {integrationExtras?.[watch("product")?.product?.id]
              ?.filter((field) => field.field === "password")
              .map((field, i) => (
                <>
                  <PasswordValidation
                    watch={watchExtraFields}
                    register={registerExtraFields}
                    showPassword={showPasswords}
                    validate={validate}
                    getValues={getExtraFieldValues}
                    setValue={setExtraFieldValue}
                    fieldErrors={extraFieldsErrors}
                    field={field.field}
                    type={field.type}
                  />
                  <Input
                    key={i}
                    spaceTop
                    type={
                      field.type === "password" && showPasswords
                        ? "text"
                        : field.type
                    }
                    label={confirm_password.title}
                    iconRight={
                      field.type === "password"
                        ? showPasswords
                          ? "icon-lock-open"
                          : "icon-lock"
                        : undefined
                    }
                    required={confirm_password?.required}
                    placeholder={`Enter ${confirm_password?.field}`}
                    {...registerExtraFields("confirmPassword", {
                      validate: (val) => {
                        if (watchExtraFields("password") !== val) {
                          return "Passwords do not match";
                        }
                      },
                    })}
                    error={extraFieldsErrors["confirmPassword"]?.message}
                    onChange={({ target: { value } }) =>
                      setExtraFieldValue("confirmPassword", value)
                    }
                  />
                </>
              ))}
          </Grid>

          {integrationExtras?.[watch("product")?.product?.id]?.some(
            (extra) => extra.type === "password"
          ) && (
            <Checkbox
              block
              style={{ marginRight: "0px" }}
              label={<Text size="12px">show passwords</Text>}
              onChange={(e) => setShowPasswords(e.target.checked)}
              checked={showPasswords}
              size="16px"
            />
          )}
        </Box>

        <Grid
          default="auto max-content max-content"
          responsive={false}
          className="bt"
        >
          <Box pad="24px">
            <Button
              onClick={handleClose}
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
            >
              Cancel
            </Button>
          </Box>
          <Box pad="24px 0">
            <Button
              pale
              color="rgba(0,0,0,0.0)"
              iconLeft={<i className="flexibull-left" />}
              onClick={() => setStep(1)}
              disabled={props.saving}
            >
              Previous
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              onClick={handleSubmitExtraFields(() =>
                setStep(templates?.data?.length > 0 ? 3 : 4)
              )}
            >
              {templates?.data?.length > 0 ? "Next" : "Preview Subscription"}
            </Button>
          </Box>
        </Grid>
      </Swiper>
      <Swiper active={step === 3}>
        <Box pad="32px">
          <Text {...fontSize.h4}>Select Template</Text>

          <Spacer space="24px" />
          <Grid default="1fr 1fr 1fr">
            <CardSelector
              {...register("template")}
              name="blank"
              value={null}
              view="icon"
              space="0"
              checked={watch("template") === null}
              icon={
                <Box>
                  <Text style={{ textAlign: "center" }} block>
                    Blank Template
                  </Text>
                  <Text
                    {...fontSize.caption}
                    style={{ textAlign: "center" }}
                    block
                  >
                    preview{" "}
                    <i
                      className="icon-external-link"
                      style={{ fontSize: "10px" }}
                    />
                  </Text>
                </Box>
              }
              onChange={() => setValue("template", null)}
            />
            {templates?.data?.map((template) => {
              return (
                <CardSelector
                  {...register("template")}
                  key={template.id}
                  name={template.title}
                  value={template.id}
                  view="icon"
                  label={
                    <Box>
                      <Text style={{ textAlign: "center" }} block>
                        {template.title}
                      </Text>
                      <Text
                        {...fontSize.caption}
                        style={{ textAlign: "center" }}
                        block
                      >
                        preview <i className="icon-external-link" />
                      </Text>
                    </Box>
                  }
                  checked={watch("template") === template.id}
                  icon={
                    <i
                      className={`${template?.icon}`}
                      style={{ fontSize: fontSize.templateIconLarge.size }}
                    />
                  }
                  onChange={() => setValue("template", template.id)}
                />
              );
            })}
          </Grid>
        </Box>

        <Grid
          default="auto max-content max-content"
          responsive={false}
          className="bt"
        >
          <Box pad="24px">
            <Button
              onClick={handleClose}
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
            >
              Cancel
            </Button>
          </Box>
          <Box pad="24px 0">
            <Button
              pale
              color="rgba(0,0,0,0.0)"
              iconLeft={<i className="flexibull-left" />}
              onClick={() => {
                setStep(2);
              }}
            >
              Previous
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              onClick={handleSubmit((v) => {
                setStep(4);
              })}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Swiper>
      <Swiper active={step === 4}>
        <Box pad="32px">
          <Box
            round="high"
            background={Theme.PrimarySurface}
            className="bordered"
            pad="24px"
          >
            <Text {...fontSize.h4}>Subscription Details</Text>
            <Spacer space="24px" />
            <Grid default="1fr 1fr" gap="24px">
              <Box>
                <Text {...fontSize.caption} block>
                  Customer Name
                </Text>
                <Text bold>{watch("customer")?.label}</Text>
              </Box>
              <Box></Box>
              <Box>
                <Text {...fontSize.caption} block>
                  Product
                </Text>
                <Box vAlign="center">
                  {watch("product")?.label && (
                    <Avatar
                      name={watch("product")?.label}
                      src={watch("product")?.product?.logoUrl}
                      margin="0 8px 0 0"
                      color="none"
                      size="20px"
                    />
                  )}
                  <Text bold>{watch("product")?.label}</Text>
                </Box>
              </Box>
              <Box>
                <Text {...fontSize.caption} block>
                  Service Plan
                </Text>
                <Text bold>{watch("plan")?.label}</Text>
              </Box>
            </Grid>
            <Liner margin="24px 0" borderstyle="dashed" />
            <Box>
              <Grid default="1fr 1fr 1fr" gap="12px">
                <Box>
                  <Text {...fontSize.caption} block>
                    Admin First Name
                  </Text>
                  <Text bold>
                    {watchExtraFields("product_____first_name") || EMPTY_VALUE}
                  </Text>
                </Box>
                <Box>
                  <Text {...fontSize.caption} block>
                    Admin First Name
                  </Text>
                  <Text bold>
                    {watchExtraFields("product_____middle_name") || EMPTY_VALUE}
                  </Text>
                </Box>
                <Box>
                  <Text {...fontSize.caption} block>
                    Admin First Name
                  </Text>
                  <Text bold>
                    {watchExtraFields("product_____last_name") || EMPTY_VALUE}
                  </Text>
                </Box>
              </Grid>
              <Spacer space="24px" />
              <Grid default="1fr 1fr 1fr" gap="24px">
                <Box>
                  <Text {...fontSize.caption} block>
                    Phone Number
                  </Text>
                  <Text bold>
                    {watchExtraFields("product_____phone") || EMPTY_VALUE}
                  </Text>
                </Box>
                <Box>
                  <Text {...fontSize.caption} block>
                    Email
                  </Text>
                  <Text bold>
                    {watchExtraFields("product_____email") || EMPTY_VALUE}
                  </Text>
                </Box>
              </Grid>
            </Box>
            <Liner margin="24px 0" borderstyle="dashed" />

            <Grid default="1fr 1fr" gap="24px">
              <Box>
                <Text {...fontSize.caption} block>
                  Starts On
                </Text>
                <Text bold>
                  {moment(watch("startDate")).format("DD MMMM, YYYY")}
                </Text>
                <Text block>
                  {watch("plan")?.plan?.billingCycleType
                    ? `Billing cycle: ${String(
                        watch("plan")?.plan?.billingCycle
                      )} ${String(
                        watch("plan")?.plan?.billingCycleType
                      ).toLowerCase()}`
                    : ""}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption} block>
                  End Date
                </Text>
                <Text bold>{getEndDate()}</Text>
              </Box>
              <Box>
                <Text {...fontSize.caption} block>
                  Platform Status
                </Text>
                <Box>
                  <StatusIndicator
                    compound
                    label="Not Provisioned"
                    {...statusColors["onboarding"]}
                  />
                </Box>
              </Box>
              <Box>
              <Box>
                <Text {...fontSize.caption}>Subscription type</Text>
                <Text bold block>
                {watch("recurring")?.isDemo ? "Demo subscription" :"Real subscription"}
                </Text>
              </Box>
                </Box>
              <Box>
                <Text {...fontSize.caption} block>
                  Recurrence Type
                </Text>
                <Text bold>{watch("recurring")?.label}</Text>
              </Box>
             
            </Grid>
            
          </Box>
        </Box>

        <Grid
          default="auto max-content max-content"
          responsive={false}
          className="bt"
          gap="0"
        >
          <Box pad="24px">
            <Button
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
          <Box pad="24px 0">
            <Button
              pale
              color="rgba(0,0,0,0.0)"
              iconLeft={<i className="flexibull-left" />}
              onClick={() =>
                integrationExtras?.[watch("product")?.product?.id]
                  ? setStep(templates?.data?.length > 0 ? 3 : 2)
                  : setStep(1)
              }
              disabled={props.saving}
            >
              Previous
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              iconRight={<i className="flexibull-right" />}
              onClick={handleSubmitSubscription}
              progress={props.saving}
            >
              Complete
            </Button>
          </Box>
        </Grid>
      </Swiper>
    </Box>
  );
};
export default SubscriptionEditor;
