import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Table,
  Text,
  DropDown,
  Select,
  Input,
  Button,
  Modal,
  ModalBody,
  Switch
} from "@flexisaf/flexibull2";
import { Spacer } from "@flexisaf/flexibull2/build/layout";
import { StatusIndicator } from "@flexisaf/flexibull2/build/statusindicator";
import fontSize from "../../utils/typography";
import Theme from "../../utils/theme";
import { statusColors } from "../../utils/colors";
import PlanEditor from "./support/createPlan";
import PlanDetail from "../product/support/plandetail";
import {
  errorNotifier,
  thousandFormatter,
  dollarFormatter
} from "../../utils/helpers";
import urls from "../../utils/config";
import request from "../../utils/request";
import { EmptyState } from "@flexisaf/flexibull2/build/emptystates";
import moment from "moment";
import useDebounce from "../../hooks/useDebounce";
import { FlexiPagination } from "@flexisaf/flexibull2/build/table";
import {
  PAGE_SIZES,
  PLAN_TYPES,
  PLAN_TYPE_LABELS,
} from "../../utils/constants";
import SectionLoader from "../../components/custom/sectionLoader";
import usePlanEditor from "../../hooks/plan/usePlanEditor";
import useToggleDemo from "../../hooks/plan/useToggleDemo";

const ProductPlans = ({ productId }) => {
  const [modal, setModal] = useState({ show: false, data: {} });
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [planType, setPlanType] = useState(null);
  const debouncedTerm = useDebounce(searchTerm, 1000);
  const [planDetailModal, setPlanDetailModal] = useState({
    show: false,
    data: {},
  });
  const {toggleplanDemo} = useToggleDemo()

  const [loadingData, setLoadingData] = useState(false);
  const [planList, setPlanList] = useState({ count: 0, data: [] });

  const fetchPlans = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/plans?productId=${productId}`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}&${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setPlanList(res.raw);
    } catch (error) {
    if(planList.data.length === 0) return 
     errorNotifier("Unable to fetch plans");
    } finally {
      setLoadingData(false);
    }
  };
 

  useEffect(() => {
    fetchPlans({
      planType: planType?.value,
      search: debouncedTerm,
      offset: (page - 1) * pageSize,
      limit: pageSize,
    });
    // eslint-disable-next-line
  }, [debouncedTerm, planType, pageSize, page]);
  const { isDeleting, deletePlan } = usePlanEditor();
  const [delModal, setDelModal] = useState({ show: false, data: null });
  const onDeletePlan = () => {
    deletePlan({
      id: delModal?.data?.id,
      onSuccess: () => {
        fetchPlans();
        setDelModal({
          show: false,
          data: null,
        });
      },
    });
  };



  const column = [
    {
      title: "Name",
      key: "name",
      searchable: "searchable",
      custom: (row) => {
        return (
          <Box>
            <Text bold color={Theme.PrimaryDark}>
              {row.name}
            </Text>
          </Box>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      searchable: "searchable",
      custom: (row) => {
        return (
          <Box>
            <Text bold color={Theme.PrimaryDark}>
              {PLAN_TYPE_LABELS[row.type]}
            </Text>
          </Box>
        );
      },
    },
    {
      title: "Product type",
      key: "producttype",
      custom: (row) => (
        
        <div
        onClick={() =>
          toggleplanDemo({
            data: { id: row.id, isDemo: !row.isDemo },
            onSuccess: () => {
            fetchPlans()
            },
        
          })
        }
        >
          <Switch checked={!row.isDemo} label= {row.isDemo ? "Demo" : "Live"}/>
        </div>
      ),
    },
    {
      title: "Billing Cycle",
      key: "billing_cycle",
      custom: (row) => {
        return (
          <Box>
            <Text bold color={Theme.PrimaryDark} block>
              {row.billingCycle}
            </Text>
            <Text {...fontSize.caption}>{row.billingCycleType}</Text>
          </Box>
        );
      },
    },

    {
      title: "Unit Cost",
      key: "unitCost",
      custom: (row) => <Text> {row?.currency === "NGN" ? `₦${thousandFormatter(row.unitCost)}` : 
      `${dollarFormatter(row.unitCost)}`
      }</Text>,
    },
    {
      title: "Created",
      key: "createdAt",
      custom: (row) => (
        <Text>{moment(row.createdAt).format("DD MMMM, YYYY")}</Text>
      ),
    },
    {
      title: "Status",
      key: "status",
      custom: (row) => {
        return (
          <StatusIndicator
            compound
            label={row.status}
            {...statusColors[String(row.status || "").toUpperCase()]}
          />
        );
      },
    },
    {
      title: "Action",
      key: "action",
      custom: (row) => {
        return (
          <Box align="left">
            <DropDown
              trigger="click"
              style={{ textAlign: "left" }}
              menuAlign="bottom right"
              label={
                <Box pad="8px" className="cursor">
                  <Text {...fontSize.h3}>...</Text>
                </Box>
              }
              menuList={[
                {
                  onClick: () => setPlanDetailModal({ show: true, data: row }),
                  label: "View Plan",
                  iconLeft: "icon-popup",
                },
                {
                  onClick: () => setModal({ show: true, data: row }),
                  label: "Edit Plan",
                  iconLeft: "icon-pencil",
                },
                {
                  onClick: () => setDelModal({ show: true, data: row }),
                  label: "Remove Plan",
                  color: Theme.PrimaryRed,
                  iconLeft: "icon-cancel-outline",
                },
              ]}
            />
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (productId) {
      setLoadingData(true);
      fetchPlans();
    }
    // eslint-disable-next-line
  }, [productId]);

  if (loadingData) return <SectionLoader height="68vh" />;

  return (
    <Box pad="32px">
      {planList?.data.length === 0 && !searchTerm && !planType ? (
        <EmptyState
          type="customers"
          title="No Plan available"
          action={
            <Button onClick={() => setModal({ show: true, data: {} })}>
              Create New Plan
            </Button>
          }
        />
      ) : (
        <>
          <Grid default="minmax(100px, 350px) minmax(100px, 130px) auto">
            <Box>
              <Input
                block
                type="search"
                placeholder="Search plan name"
                iconLeft="flexibull-spin6"
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
              />
            </Box>
            <Box>
              <Select
                block
                placeholder="Type"
                options={PLAN_TYPES}
                isClearable
                value={planType}
                onChange={(v) => setPlanType(v)}
              />
            </Box>
            <Box align="right">
              <Button onClick={() => setModal({ show: true, data: {} })}>
                Add Plan
              </Button>
            </Box>
          </Grid>
          <Spacer space="24px" />
          <Table responsive={false} report>
            <table>
              <thead>
                <tr>
                  {column &&
                    column.map((elem, index) => {
                      return (
                        <th scope="row" key={elem.key ? elem.key : index}>
                          {elem.title}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {planList?.data?.length > 0 &&
                  planList?.data?.map((elem, index) => {
                    return (
                      <tr key={index}>
                        {column.map((entry, idex) => {
                          return (
                            <td key={idex} data-label={entry.key}>
                              {entry.custom
                                ? entry.custom(elem, index)
                                : elem[entry.key]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Table>
          <Box>
            <FlexiPagination
              pageCounts={PAGE_SIZES}
              total={planList?.count}
              pageSize={pageSize}
              itemsDisplayed
              onChange={(s) => setPage(s)}
              changePageSize={(s) => setPageSize(s?.value)}
              current={page}
            />
          </Box>
        </>
      )}
      <Modal open={modal.show} center={false}>
        <ModalBody width="650px">
          <PlanEditor
            closeModal={() => setModal({ show: false, data: {} })}
            data={modal.data}
            productId={productId}       
            fetchPlans={fetchPlans}
          />
        </ModalBody>
      </Modal>
      <Modal open={planDetailModal.show} center={false}>
        <ModalBody width="600px">
          <PlanDetail

            data={planDetailModal.data}
            closeModal={() =>
              setPlanDetailModal({ ...planDetailModal, show: false })
            }
          />
        </ModalBody>
      </Modal>
      <Modal open={delModal.show} center={false}>
        <ModalBody width="600px">
          <Box pad="32px">
            <Text {...fontSize.h4} block bold>
              Delete the plan “Default”
            </Text>
            <Spacer space="16px" />
            <Text>
              Deleting the plan is not reversible and all linked subscriptions
              will be removed from the system.
            </Text>
          </Box>
          <Grid default="auto max-content" reponsize={false} className="bt">
            <Box pad="24px">
              <Button
                onClick={() => setDelModal(false)}
                pale
                fontColor={Theme.PrimaryGrey}
                color={Theme.PrimaryGrey}
              >
                <Text bold>No</Text>, Keep Plan
              </Button>
            </Box>
            <Box pad="24px">
              <Button
                onClick={() => {
                  onDeletePlan();
                }}
                progress={isDeleting}
                color={Theme.PrimaryRed}
              >
                <Text bold>Yes</Text>, Delete Pan
              </Button>
            </Box>
          </Grid>
        </ModalBody>
      </Modal>
    </Box>
  );
};

export default ProductPlans;
