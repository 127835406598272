import { Box, Grid } from '@flexisaf/flexibull2/build/layout'
import { StatusIndicator } from '@flexisaf/flexibull2/build/statusindicator'
import { Text } from '@flexisaf/flexibull2/build/typo'
import React from 'react'
import fontSize from '../../../utils/typography'
import Theme from "../../../utils/theme";

export default function Header({ step, isEdit }) {
  return (
    <Grid default="auto max-content" responsive={false} className="bb">
      <Box pad="24px 32px">
        <Text {...fontSize.h3} uppercase>
          {isEdit ? 'Edit': 'Create'} Customer
        </Text>
      </Box>
      <Box pad="24px" className="spaced">
        {
          [1, 2, 3].map(key => (
            <StatusIndicator
              pulse={step === key}
              color={step >= key ? Theme.PrimaryColor : Theme.PrimaryLight}
            />
          ))
        }
      </Box>
    </Grid>
  )
}
