import { createSelector } from "@reduxjs/toolkit";

const selectPlans = (state) => state.plans


export const  selectIsLoadingFeatures= createSelector(
    [selectPlans],
    (plans) => plans.isLoadingFeatures
  );

  export const selectFeaturesData = createSelector(
    [selectPlans],
    (plans) => plans.features
  );
  export const selectLatestPlan = createSelector(
    [selectPlans],
    (plans) => plans.latestPlan
  );
  export const selectIsCreatePlan = createSelector(
    [selectPlans],
    (plans) => plans.isCreatePlan
  );
  export const selectIsDeletePlan = createSelector(
    [selectPlans],
    (plans) => plans.isDeletingPlan
  )
  export const selectIsUpdatePlan = createSelector(
    [selectPlans],
    (plans) => plans.isUpdatingPlan
  )