import request from "../utils/request";
import urls from "../utils/config";

const { API_BASE_URL } = urls || {};

export const httpGetStaff = () => {
  const url = `${API_BASE_URL}/api/staffs`;

  return request({
    url,
    method: "get",
  });
};

export const httpPostStaff = (staff) => {
  const url = `${API_BASE_URL}/api/staffs`;

  return request({
    url,
    method: "post",
    data: staff,
  });
};

export const httpPutStaff = (data) => {
  const { id, staff } = data;

  const url = `${API_BASE_URL}/api/staffs/${id}`;

  return request({
    url,
    method: "put",
    data: staff,
  });
};

export const httpDeleteStaff = (id) => {
  const url = `${API_BASE_URL}/api/staffs/${id}`;

  return request({
    url,
    method: "delete",
  });
};
