import { Box, Grid, Spacer, Input, Select, Button,Switch } from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import styled from "styled-components";
import { useEffect } from "react";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import { TextArea } from "@flexisaf/flexibull2/build/textarea";
import { useForm } from "react-hook-form";
import { validEmail, validURL, validHexColor } from "../../../utils/regex";
import useStaffGet from "../../../hooks/staff/useStaffGet";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const getDefaultDetails = () => ({
  name: "",
  description: "",
  notificationEmail: "",
  integrationUrl: "",
  logoUrl: "",
  type: "GENERAL",
  productUrl: "",
  status: "",
  brandColor: "",
  isDemo:false,
  productManager: null,
});

const ProductEditor = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      ...getDefaultDetails(),
    },
    mode: "onBlur",
  });

  const { staff, loadingStaff } = useStaffGet();

  const closeModal = () => {
    reset({ ...getDefaultDetails() });
    props.closeModal();
  };

  const onSaveProduct = (v) => {
    props.onSaveProduct(
      {
        ...v,
        productManager: v.productManager?.label,
        productManagerEmail: v.productManager?.value,
      },
      () => closeModal()
    );
  };

  useEffect(() => {
    if (props.data?.id) {
      reset({
        name: props.data.name,
        description: props.data.description,
        notificationEmail: props.data.notificationEmail,
        integrationUrl: props.data.integrationUrl,
        logoUrl: props.data.logoUrl,
        type: props.data.type,
        productUrl: props.data.productUrl,
        status: props.data.status,
        brandColor: props.data.brandColor,
        productManager: props.data.productManager
          ? {
              label: props.data.productManager,
              value: props.data.productManagerEmail,
            }
          : null,
          isDemo:props.data.isDemo
      });
    }
    // eslint-disable-next-line
  }, [props.data]);

  return (
    <Box>
      <Swiper active={true}>
        <Grid default="auto max-content" responsive={false} className="bb">
          <Box pad="24px 32px">
            <Text {...fontSize.h3} uppercase>
              {props.data?.id ? "Edit" : "Add"} Product
            </Text>
          </Box>
        </Grid>
        <Box pad="32px">
          <Text {...fontSize.h4}>Product Details</Text>
          <Spacer space="24px" />

          <Grid default="1fr 1fr" gap="24px">
            <Input
              spaceTop
              label="Name"
              required
              placeholder="Product Name"
              value={watch("name")}
              {...register("name", { required: "name is required" })}
              error={errors.name?.message}
              onChange={({ target: { value } }) => {
                setValue("name", value);
              }}
              onBlur={({ target: { value } }) => {
                setValue("name", value);
              }}
            />
          </Grid>

          <Spacer space="24px" />
          <TextArea
            spaceTop
            label="Description"
            placeholder="Product Description"
            block
            value={watch("description")}
            {...register("description", {
              required: "description is required",
            })}
            error={errors.description?.message}
            onChange={({ target: { value } }) => setValue("description", value)}
            onBlur={({ target: { value } }) => setValue("description", value)}
          />
          <Spacer space="24px" />

          <Grid default="1fr 1fr" gap="24px">
            <Input
              spaceTop
              label="Notification Email"
              required
              placeholder="Notification email"
              value={watch("notificationEmail")}
              {...register("notificationEmail", {
                required: "Notification email is required",
                pattern: {
                  value: validEmail,
                  message: "invalid email address",
                },
              })}
              error={errors.notificationEmail?.message}
              onChange={({ target: { value } }) =>
                setValue("notificationEmail", value)
              }
              onBlur={({ target: { value } }) =>
                setValue("notificationEmail", value)
              }
            />
            <Select
              spaceTop
              label="Contact Person"
              required
              placeholder="Pick a person"
              block
              isLoading={loadingStaff}
              options={staff.map((s) => ({ label: s.name, value: s.email }))}
              value={watch("productManager")}
              {...register("productManager", {
                required: "contact person is required",
              })}
              error={errors.productManager?.message}
              onChange={(value) => setValue("productManager", value)}
              
            />

            <Input
              spaceTop
              label="Logo"
              required
              placeholder="Image URL"
              value={watch("logoUrl")}
              {...register("logoUrl", {
                required: "logo url is required",
                pattern: {
                  value: validURL,
                  message: "invalid url",
                },
              })}
              error={errors.logoUrl?.message}
              onChange={({ target: { value } }) => setValue("logoUrl", value)}
              onBlur={({ target: { value } }) => setValue("logoUrl", value)}
            />
            <Input
              spaceTop
              label="Brand Color"
              placeholder="color #HEX Value"
              value={watch("brandColor")}
              {...register("brandColor", {
                pattern: {
                  value: validHexColor,
                  message: "invalid hex color",
                },
              })}
              error={errors.brandColor?.message}
              onChange={({ target: { value } }) =>
                setValue("brandColor", value)
              }
              onBlur={({ target: { value } }) => setValue("brandColor", value)}
            />
          </Grid>
          <Spacer space="24px" />
          <Grid default="1fr 1fr" gap="24px">
          <Input
            spaceTop
            label="Integration URL"
            placeholder="Product Integration URL"
            block
            value={watch("integrationUrl")}
            {...register("integrationUrl", {
              required: false,
              pattern: {
                value: validURL,
                message: "invalid url",
              },
            })}
            error={errors.integrationUrl?.message}
            onChange={({ target: { value } }) =>
              setValue("integrationUrl", value)
            }
            onBlur={({ target: { value } }) =>
              setValue("integrationUrl", value)
            }
          />
           <Box>
                <Spacer space="15px" />

                <Text bold block>
                Is this a demo product ?
                </Text>
                  <Switch
                label={watch("isDemo")? "Yes" : "No"}
                  checked={watch("isDemo")}
                  onChange={({ target: { checked } }) => setValue("isDemo", checked)}
                />
              </Box>
         
                   </Grid>
          <Spacer space="24px" />
        </Box>

        <Grid default="auto max-content" responsive={false} className="bt">
          <Box pad="24px">
            <Button
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              progress={props.saving}
              onClick={handleSubmit(onSaveProduct)}
            >
              Save Product
            </Button>
          </Box>
        </Grid>
      </Swiper>
    </Box>
  );
};
export default ProductEditor;
