import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
  isLoadingUsers: false,
  isCreateUser:false,
  isUpdateUser:false,
  isDeleteUser:false,
  isToggleUserStatus:false,
  users:null,
  error: null,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    fetchUserDataStart: (state, action) => {
      state.isLoadingUsers = true;
      state.error = null;
    },
    fetchUserDataSuccess: (state, action) => {
      state.isLoadingUsers = false;
      state.users = action.payload;
      state.error = null;
    },
    fetchUserDataFailure: (state, action) => {
      state.isLoadingUsers = false;
      state.error = action.payload;
      state.users = null
    },
    createUserStart: (state, action) => {
      state.isCreateUser = true;
      state.error = null;
    },
    createUserSuccess: (state, action) => {
      state.isCreateUser = false;
      state.error = null;
    }, 
    createUserFailure: (state, action) => {
      state.isCreateUser = false;
      state.error = action.payload;
    },
    updateUserStart: (state, action) => {
      state.isUpdateUser = true;
      state.error = null;
    },
    updateUserSuccess: (state, action) => {
      state.isUpdateUser = false;
      state.error = null;
    },
    updateUserFailure: (state, action) => {
      state.isUpdateUser = false;
      state.error = action.payload;
    },
    deleteUserStart: (state, action) => {
      state.isDeleteUser = true;
      state.error = null;
    },
    deleteUserSuccess: (state, action) => {
      state.isDeleteUser = false;
      state.error = null;
    },
    deleteUserFailure: (state, action) => {
      state.isDeleteUser = false;
      state.error = action.payload;
    },
    toggleUserStatusStart: (state, action) => {
      state.isToggleUserStatus = true;
      state.error = null;
    },
    toggleUserStatusSuccess: (state, action) => {
      state.isToggleUserStatus = false;
      state.error = null;
    },
    toggleUserStatusFailure: (state, action) => {
      state.isToggleUserStatus = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchUserDataFailure,
  fetchUserDataStart,
  fetchUserDataSuccess,
  createUserFailure,
  createUserSuccess,
  createUserStart,
  updateUserFailure,
  updateUserSuccess,
  updateUserStart,
  deleteUserFailure,
  deleteUserSuccess,
  deleteUserStart,
  toggleUserStatusStart,
  toggleUserStatusSuccess,
  toggleUserStatusFailure
} = userSlice.actions;

const userReducers = userSlice.reducer;

export default userReducers;
