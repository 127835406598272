import request from "../utils/request";
import urls from "../utils/config";

const { API_BASE_URL } = urls || {};

export const httpGetRoles = (options) => {
  const url = `${API_BASE_URL}/api/roles`;

  return request({
    url,
    method: "get",
    data: options,
  });
};

export const httpPostRole = (role) => {
  const url = `${API_BASE_URL}/api/roles`;

  return request({
    url,
    method: "post",
    data: role,
  });
};

export const httpPutRole = (data) => {
  const { id, role } = data;

  const url = `${API_BASE_URL}/api/roles/${id}`;

  return request({
    url,
    method: "put",
    data: role,
  });
};

export const httpDeleteRole = (id) => {
  const url = `${API_BASE_URL}/api/roles/${id}`;

  return request({
    url,
    method: "delete",
  });
};

export const httpGetPrivileges = () => {
  const url = `${API_BASE_URL}/api/roles/privileges`;

  return request({
    url,
    method: "get",
  });
};
