import { useState, useEffect } from "react";
import {
  Box,
  Text,
  Spacer,
  Input,
  Checkbox,
  Button,
} from "@flexisaf/flexibull2";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { passwordResetStart } from "../../redux/auth/reducer";
import { selectIsLoadingPasswordReset } from "../../redux/auth/selectors";

import SplitLayoutContainer from "../../components/auth/splitLayoutContainer";
import fontSize from "../../utils/typography";
import PasswordValidation from "../../components/custom/password-validation";
import { validate } from "../subscriptions/utils";
import { successNotifier } from "../../utils/helpers";

const getDefaultPasswordResetInformation = () => ({
  password: "",
  confirmPassword: "",
});

const PasswordReset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isResetting = useSelector(selectIsLoadingPasswordReset);
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("user");
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      ...getDefaultPasswordResetInformation(),
    },
  });

  useEffect(() => {
    if (!userId || !token) {
      navigate("/login");
    }
  }, [token, userId, navigate]);

  return (
    <SplitLayoutContainer>
      <Box pad="32px" maxWidth="400px">
        <Box pad="24px" align="center">
          <Text {...fontSize.h2} block>
            Choose New Passwords
          </Text>
          <Spacer space="16px" />
          <Text>Go ahead and choose new passwords that you can remember.</Text>
        </Box>

        <PasswordValidation
          watch={watch}
          register={register}
          getValues={getValues}
          setValue={setValue}
          fieldErrors={errors}
          showPassword={showPassword}
          validate={validate}
          field="password"
          type="password"
          label="New Password"
          placeholder="Enter New Password"
        />

        <Spacer space="20px" />

        <Input
          type={showPassword ? "text" : "password"}
          required
          spaceTop
          style={{ backgroundColor: "#F7F7FF" }}
          block
          label="Confirm New Password"
          placeholder="Enter Confirm Password"
          value={watch("confirmPassword")}
          {...register("confirmPassword", {
            required: "Confirm Password is required",
            validate: (val) => {
              if (watch("password") !== val) {
                return "Passwords do not match";
              }
            },
          })}
          error={errors.confirmPassword?.message}
          onChange={({ target: { value } }) =>
            setValue("confirmPassword", value)
          }
        />
        <Spacer space="10px" />
        <Box>
          <Checkbox
            label="Show Password"
            onChange={(e) => setShowPassword(e.target.checked)}
            checked={showPassword}
          />
        </Box>
        <Spacer space="16px" />
        <Button
          block
          onClick={handleSubmit((v) => {
            console.log(v);
            dispatch(
              passwordResetStart({
                data: {
                  password: v.password,
                  token: token,
                  userId: userId,
                },
                onSuccess: () => {
                  navigate("/login");
                  successNotifier("Password changed successfully");
                },
                onFailure: () => {
                  reset();
                },
              })
            );
          })}
          progress={isResetting}
        >
          Reset Password
        </Button>
      </Box>
    </SplitLayoutContainer>
  );
};

export default PasswordReset;
