import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchRolesFailure,
  fetchRolesStart,
  fetchRolesSuccess,
} from "../reducer";

import { httpGetRoles } from "../../../services/roles";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getRoles({ payload }) {
  const response = yield call(httpGetRoles, payload);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchRolesSuccess(raw));
  } else {
    yield put(fetchRolesFailure(raw));
    networkErrorHandler(
      statusCode,
      message || "Unable to fetch roles. Please try again later."
    );
  }
}

export default function* onGetRoles() {
  yield takeLatest(fetchRolesStart.type, getRoles);
}
