import { call, put, takeLatest } from "redux-saga/effects";
import {
  createRoleStart,
  createRoleFailure,
  createRoleSuccess,
} from "../reducer";

import { httpPostRole } from "../../../services/roles";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* createRole({ payload }) {
  const { role, onSuccess, onFailure } = payload;
  const response = yield call(httpPostRole, role);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(createRoleSuccess());
    if (onSuccess) {
      onSuccess();
    }
    successNotifier(`${role?.name.toUpperCase()} role created successfully`);
  } else {
    yield put(createRoleFailure(raw));
    networkErrorHandler(
      statusCode,
      message ||
        `Unable to create ${role?.name.toUpperCase()} role. Please try again later.`
    );

    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onCreateRole() {
  yield takeLatest(createRoleStart.type, createRole);
}
