import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_DASHBOARD_DATA = {
  products: {
    total: 0,
  },
  subscriptions: {
    total: 0,
    active: 0,
    future: 0,
    expired: 0,
  },
  customers: {
    total: 0,
    organization: 0,
    individual: 0,
    public: 0,
    NGO: 0,
  },
  productSubscription: [],
};

export const initialState = {
  isLoading: false,
  dashboardData: DEFAULT_DASHBOARD_DATA,
  error: null,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    fetchDashboardDataStart: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchDashboardDataSuccess: (state, action) => {
      state.isLoading = false;
      state.dashboardData = action.payload;
      state.error = null;
    },
    fetchDashboardDataFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.dashboardData = DEFAULT_DASHBOARD_DATA;
    },
  },
});

export const {
  fetchDashboardDataFailure,
  fetchDashboardDataStart,
  fetchDashboardDataSuccess,
} = dashboardSlice.actions;

const dashboardReducer = dashboardSlice.reducer;

export default dashboardReducer;
