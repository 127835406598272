import React from 'react';
import { Text, Accordion } from '@flexisaf/flexibull2';
import fontSize from '../../../utils/typography';
import { Spacer } from '@flexisaf/flexibull2/build/layout';
import { ComingSoon } from '../../../components/custom/comingSoon';

const TemplatePreview = () => {
  return (
    <>
      <Text {...fontSize.h3}>Template Preview</Text>
      <Spacer space="16px" />
      <ComingSoon>
        <Accordion style={{ borderRadius: '5px 5px 0px 0px' }}>
          <div label={<Text {...fontSize.accordionLabel}>Admin levels</Text>} />
        </Accordion>
        <Accordion style={{ borderRadius: '0px', borderTop: 'none' }}>
          <div label={<Text {...fontSize.accordionLabel}>Workflows</Text>} />
        </Accordion>
        <Accordion style={{ borderRadius: '0px', borderTop: 'none' }}>
          <div label={<Text {...fontSize.accordionLabel}>Forms</Text>} />
        </Accordion>
        <Accordion style={{ borderRadius: '0px', borderTop: 'none' }}>
          <div label={<Text {...fontSize.accordionLabel}>Courses</Text>} />
        </Accordion>
        <Accordion style={{ borderRadius: '0px', borderTop: 'none' }}>
          <div
            label={<Text {...fontSize.accordionLabel}>Email Templates</Text>}
          />
        </Accordion>
        <Accordion
          style={{ borderRadius: '0px 0px 5px 5px', borderTop: 'none' }}
        >
          <div
            label={<Text {...fontSize.accordionLabel}>Document Templates</Text>}
          />
        </Accordion>
      </ComingSoon>
    </>
  );
};

export default TemplatePreview;
