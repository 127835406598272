import request from "../utils/request";
import urls from "../utils/config";

const { API_BASE_URL } = urls || {};

export const httpToggleIsDemo = (options) => {
    const {id , isDemo} = options
    const url = `${API_BASE_URL}/api/customers/${id}/demo-status`;
  
    return request({
      url,
      method: "put",
      data: {isDemo},
    });
  };
  