import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLoadingExpiringSoon: false,
  isLoadingSubscriptions:false,
  subscriptionsExpiringSoon: [],
  toggleDemo:false,
  subscriptions:null,
  error: null,
};

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    fetchSubscriptionExpiringStart: (state, action) => {
      state.isLoadingExpiringSoon = true;
      state.error = null;
    },
    fetchSubscriptionExpiringSuccess: (state, action) => {
      state.isLoadingExpiringSoon = false;
      state.subscriptionsExpiringSoon = action.payload;
      state.error = null;
    },
    fetchSubscriptionExpiringFailure: (state, action) => {
      state.isLoadingExpiringSoon = false;
      state.error = action.payload;
      state.subscriptions = null;
    },
    fetchSubscriptionsStart: (state, action) => {
      if (action.payload.options.reloadPage) {
        state.isLoadingSubscriptions = true;
      }
      state.error = null;
    },
    fetchSubscriptionsSuccess: (state, action) => {
      state.isLoadingSubscriptions = false;
      state.subscriptions = action.payload;
      state.error = null;
    },
    fetchSubscriptionsFailure: (state, action) => {
      state.isLoadingSubscriptions = false;
      state.error = action.payload;
      state.subscriptions = null;
    },
    toggleSubscriptionDemo: (state) => {
      state.error = null;
    },
    toggleSubscrionDemoSuccess: (state) => {
      state.error = null;
    },
    toggleSubscriptionDemoFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  fetchSubscriptionExpiringFailure,
  fetchSubscriptionExpiringStart,
  fetchSubscriptionExpiringSuccess,
  fetchSubscriptionsFailure,
  fetchSubscriptionsStart,
  fetchSubscriptionsSuccess,
  toggleSubscrionDemoSuccess,
  toggleSubscriptionDemo,
  toggleSubscriptionDemoFailure,
  toggleDemo
} = subscriptionsSlice.actions;

const subscriptionsReducer = subscriptionsSlice.reducer;

export default subscriptionsReducer;
