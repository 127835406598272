import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { passwordResetRequestStart } from "../../../redux/auth/reducer";
import { Box, Grid, Input, Text, Spacer, Button } from "@flexisaf/flexibull2";
import { useForm } from "react-hook-form";
import Theme from "../../../utils/theme";
import { isValidEmail } from "../../../utils/helpers";
import { selectIsLoadingPasswordResetRequest } from "../../../redux/auth/selectors";

const getDefaultValues = () => ({
  username: "",
});

const PasswordResetModal = ({ handleClose, data }) => {
  const dispatch = useDispatch();
  const isRequestingPasswordReset = useSelector(
    selectIsLoadingPasswordResetRequest
  );

  const [resetRequestSuccess, setResetRequestSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      ...getDefaultValues(),
      username: data || "",
    },
  });

  useEffect(() => {
    if (data) {
      setValue("username", data);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      <Grid default="auto max-content" reponsize={false} className="bb">
        <Box pad="24px 32px">
          <Text size="16px" bold block>
            Reset Password
          </Text>

          <Spacer space="10px" />

          {!resetRequestSuccess && (
            <Text>
              You are about to reset your password, please enter your email
              address on your account to continue or cancel to go back.
            </Text>
          )}
        </Box>
      </Grid>

      <Box pad="32px">
        {resetRequestSuccess ? (
          <Box>
            <Text bold block>
              Check your email
            </Text>
            <Spacer space="10px" />
            <Text>
              You have successfully requested to reset your password. If the
              email exists, a reset link has been sent to the email.
            </Text>
          </Box>
        ) : (
          <Grid default="1fr" gap="24px">
            <Input
              spaceTop
              label="Email Address"
              required
              type="email"
              placeholder="Ex: jane@example.com"
              value={watch("username")}
              {...register("username", {
                required: "Username is required",
                validate: (val) => {
                  if (!isValidEmail(val)) {
                    return "Please enter a valid email";
                  }
                },
              })}
              error={errors.email?.message}
              onChange={({ target: { value } }) => setValue("username", value)}
            />
          </Grid>
        )}
      </Box>

      <Box pad="32px">
        <Grid default="auto max-content" responsive={false} className="bt">
          <Box>
            <Button
              pale
              onClick={() => {
                setResetRequestSuccess(false);
                handleClose();
              }}
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
            >
              Close
            </Button>
          </Box>
          <Box>
            <Button
              progress={isRequestingPasswordReset}
              disabled={resetRequestSuccess === true}
              onClick={handleSubmit((v) => {
                dispatch(
                  passwordResetRequestStart({
                    data: {
                      username: v.username,
                    },
                    onSuccess: () => {
                      setResetRequestSuccess(true);
                    },
                    onFailure: () => {
                      setValue("email", "");
                    },
                  })
                );
              })}
            >
              Reset Password
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default PasswordResetModal;
