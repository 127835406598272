import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchStaffStart,
  fetchStaffFailure,
  fetchStaffSuccess,
} from "../reducer";

import { httpGetStaff } from "../../../services/staff";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getStaff() {
  const response = yield call(httpGetStaff);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchStaffSuccess(raw));
  } else {
    yield put(fetchStaffFailure(raw));
    networkErrorHandler(
      statusCode,
      message || "Unabled to fetch staff. Please try again later."
    );
  }
}

export default function* onGetStaff() {
  yield takeLatest(fetchStaffStart.type, getStaff);
}
