import { useState } from "react";
import {
  Grid,
  Box,
  Table,
  Text,
  DropDown,
  Select,
  Input,
  Button,
  Modal,
  ModalBody,
  Spacer,
} from "@flexisaf/flexibull2";
import { FlexiPagination } from "@flexisaf/flexibull2/build/table";

import DiscountEditor from "./discountEditor";

import { StatusIndicator } from "@flexisaf/flexibull2/build/statusindicator";
import fontSize from "../../../utils/typography";
import Theme from "../../../utils/theme";
import urls from '../../../utils/config';
import { statusColors } from "../../../utils/colors";
import { PAGE_SIZES } from "../../../utils/constants";

import { EmptyState } from "@flexisaf/flexibull2/build/emptystates";
import request from "../../../utils/request";
import useDebounce from "../../../hooks/useDebounce";
import { errorNotifier, successNotifier } from "../../../utils/helpers";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

const ProductDiscounts = (props) => {
  const params = useParams();
  const [discountModal, setDiscountModal] = useState({
    show: false,
    data: {},
  });
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(null);
  const [discountType, setDiscountType] = useState(null);
  const [delModal, setDelModal] = useState({
    show: false,
    data: null
  });
  const [statusChangeModal, setStatusChangeModal] = useState({
    show: false,
    data: null
  });
  const debouncedTerm = useDebounce(searchTerm, 1000);
  const [loadingData, setLoadingData] = useState(true);

  const [discountList, setDiscountList] = useState({
    count: 0,
    data: [],
  });
  const [page, setPage] = useState(1);

  const column = [
    {
      title: "Name",
      key: "name",
      searchable: "searchable",
    },
    {
      title: "Code",
      key: "code",
    },
    {
      title: "Discount",
      key: "discount",
      custom: (row) => {
        return (
          <Box>
            <Text block bold>
              {row?.typeValue}
            </Text>
            <Text {...fontSize.caption} style={{ textTransform: "capitalize" }}>
              {row.type?.toLowerCase()}
            </Text>
          </Box>
        );
      },
    },
    {
      title: "Redemption Info",
      key: "redemption",
      custom: (row) => {
        return (
          <Box>
            <Text block bold style={{ textTransform: "capitalize" }}>
              {row?.redemptionType?.toLowerCase()}
            </Text>
            <Text {...fontSize.caption} style={{ textTransform: "capitalize" }}>
              Max. Redemptions: {row.maximumRedemptions ? row.maximumRedemptions : 'Unlimited'}
            </Text>
          </Box>
        );
      },
    },
    {
      title: "Valid up to",
      key: "expiryDate",
      custom: (row) => (
        <Text>{moment(row.expiryDate).format('DD MMMM, YYYY')}</Text>
      ),
    },
    {
      title: "Date Created",
      key: 'createdAt',
      custom: (row) => (
        <Text>{moment(row.createdAt).format('DD MMMM, YYYY')}</Text>
      ),
    },
    {
      title: "Status",
      key: "status",
      custom: (row) => {
        return (
          <StatusIndicator
            compound
            label={row.status}
            {...statusColors[row?.status?.toLowerCase()]}
          />
        );
      },
    },
    {
      title: "Action",
      key: "action",
      custom: (row) => {
        return (
          <Box align="center">
            <DropDown
              trigger="click"
              style={{ textAlign: "left" }}
              menuAlign="bottom right"
              label={
                <Box pad="8px" className="cursor">
                  <Text {...fontSize.h3}>...</Text>
                </Box>
              }
              menuList={[
                {
                  onClick: () => setDiscountModal({ show: true, data: row }),
                  label: "Edit Discount",
                  iconLeft: "icon-pencil",
                },
                {
                  onClick: () => setStatusChangeModal({ show: true, data: row }),
                  label:  `${row?.status === 'ACTIVE' ? 'Deactivate' : 'Activate'} Discount`,
                  iconLeft: "icon-pencil",
                },
                {
                  onClick: () => setDelModal({ show: true, data: row }),
                  label: "Remove Discount",
                  color: Theme.PrimaryRed,
                  iconLeft: "icon-cancel-outline",
                },
              ]}
            />
          </Box>
        );
      },
    },
  ];


  const fetchDiscounts = async () => {
    const options = {
      productId: params.id,
      search: debouncedTerm,
      offset: (page - 1) * pageSize,
      limit: pageSize,
    }
    if (discountType?.value){
      options.type = discountType.value;
    }
    if (status?.value){
      options.status = status.value;
    }

    try {
      setLoadingData(true);
      let url = `${urls.API_BASE_URL}/api/discounts`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join('&');
      if (unifiedOptions) url = `${url}?${unifiedOptions}`;

      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setDiscountList(res.raw);
    } catch (error) {
      errorNotifier('Unable to fetch templates');
    } finally {
      if (loadingData) setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchDiscounts();
  // eslint-disable-next-line
  }, [debouncedTerm, page, pageSize, discountType, status]);

  const closeDiscountModal = () => {
    setDiscountModal({ show: false, data: {} });
  };


  const saveDiscount = async (discountData, cb = () => null) => {
    setSaving(true);
    try {
      const currentDiscountId = discountModal?.data?.id;
      const res = await request({
        url: currentDiscountId ? `${urls.API_BASE_URL}/api/discounts/${currentDiscountId}` : `${urls.API_BASE_URL}/api/discounts`,
        method: currentDiscountId ? 'put': 'post',
        data: { ...discountData, productId: params.id },
      });
      if (!res.success) {
        throw res.message;
      }
      successNotifier("The plan was saved successfully");
      setDiscountModal({ show: false, data: {} });
      setLoadingData(true);
      fetchDiscounts();
    } catch (error) {
      errorNotifier(
        Object.values(error?.errors || {})[0] || "Unable to save plan"
      );
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/discounts/${delModal?.data?.id}`,
        method: 'delete',
      });
      if (!res.success) {
        throw res.message;
      }
      successNotifier("The discount was deleted successfully");
      setDelModal({ show: false, data: null });
      setLoadingData(true);
      fetchDiscounts();
    } catch (error) {
      errorNotifier(
        Object.values(error?.errors || {})[0] || "Unable to delete discount"
      );
    } finally {
      setDeleting(false);
    }
  };
  const handleChangeStatus = async () => {
    setChangingStatus(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/discounts/${statusChangeModal?.data?.id}`,
        method: 'put',
        data: {
          ...statusChangeModal?.data,
          expiryDate: moment(statusChangeModal?.data?.expiryDate).format('YYYY-MM-DD'),
          status: statusChangeModal?.data?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        }
      });
      if (!res.success) {
        throw res.message;
      }
      successNotifier("The discount was updated successfully");
      setStatusChangeModal({ show: false, data: null });
      setLoadingData(true);
      fetchDiscounts();
    } catch (error) {
      errorNotifier(
        Object.values(error?.errors || {})[0] || "Unable to update discount"
      );
    } finally {
      setChangingStatus(false);
    }
  };

  return (
    <Box pad="32px">
    <Grid default="minmax(100px, 350px) minmax(100px, 130px) minmax(100px, 130px) auto">
      <Box>
        <Input
          block
          type="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search discount name"
          iconLeft="flexibull-spin6"
        />
      </Box>
      <Box>
        <Select
          block
          placeholder="Type"
          value={discountType}
          isClearable
          onChange={(val) => setDiscountType(val)}
          options={[
            { label: "FLAT", value: "FLAT" },
            { label: "Percentage", value: "PERCENTAGE" },
          ]}

        />
      </Box>
      <Box>
        <Select
          block
          placeholder="Status"
          value={status}
          onChange={(val) => setStatus(val)}
          isClearable
          options={[
            { label: "Active", value: "ACTIVE" },
            { label: "Inactive", value: "INACTIVE" },
          ]}
        />
      </Box>
      <Box align="right">
        <Button
          onClick={() => setDiscountModal({ show: true, data: {} })}
        >
          Add Discount
        </Button>
      </Box>
    </Grid>
      {discountList?.data?.length === 0 ? (
        <EmptyState
          type="customers"
          title="No Discounts available"
          action={
            <Button onClick={() => setDiscountModal({ show: true, data: {} })}>
              Add Discount
            </Button>
          }
        />
      ) : (
        <>
          <Table responsive={false} report>
            <table>
              <thead>
                <tr>
                  {column &&
                    column.map((elem, index) => {
                      return (
                        <th scope="row" key={elem.key ? elem.key : index}>
                          {elem.title}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {
                  discountList?.data.map((elem, index) => {
                    return (
                      <tr key={index}>
                        {column.map((entry, index) => {
                          return (
                            <td key={index} discountsData-label={entry.key}>
                              {entry.custom
                                ? entry.custom(elem)
                                : elem[entry.key]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Table>
          <Box>
            <FlexiPagination
              pageCounts={PAGE_SIZES}
              total={discountList?.count}
              pageSize={pageSize}
              itemsDisplayed
              onChange={(s) => setPage(s)}
              changePageSize={(s) => setPageSize(s?.value)}
              current={page}
            />
          </Box>
        </>
      )}

      <Modal open={discountModal.show} center={false}>
        <ModalBody width="700px">
          {
            discountModal.show && (
              <DiscountEditor
                data={discountModal.data}
                saving={saving}
                onSave={saveDiscount}
                closeModal={closeDiscountModal}
              />
            )
          }
        </ModalBody>
      </Modal>
      <Modal open={delModal.show} center={false}>
        <ModalBody width="600px">
          <Box pad="32px">
            <Text {...fontSize.h4} block bold>
              Delete the discount "{delModal?.data?.name}"
            </Text>
            <Spacer space="16px" />
            <Text>
              Deleting this discount is a permanent action. It will remove the
              discount and disable its use on any active subscriptions.
            </Text>
          </Box>
          <Grid default="auto max-content" reponsize={false} className="bt">
            <Box pad="24px">
              <Button
                onClick={() => setDelModal({ show: false, data: null })}
                pale
                fontColor={Theme.PrimaryGrey}
                color={Theme.PrimaryGrey}
                disabled={deleting}
              >
                <Text bold>No</Text>, Keep Discount
              </Button>
            </Box>
            <Box pad="24px">
              <Button
                onClick={handleDelete}
                color={Theme.PrimaryRed}
                progress={deleting}
                disabled={deleting}
              >
                <Text bold>Yes</Text>, Delete Discount
              </Button>
            </Box>
          </Grid>
        </ModalBody>
      </Modal>
      <Modal open={statusChangeModal.show} center={false}>
        <ModalBody width="600px">
          <Box pad="32px">
            <Text {...fontSize.h4} block bold>
              Change "{statusChangeModal?.data?.name}" status
            </Text>
            <Spacer space="16px" />
            <Text>
              Are you sure you want to change the status of this discount?
            </Text>
          </Box>
          <Grid default="auto max-content" reponsize={false} className="bt">
            <Box pad="24px">
              <Button
                onClick={() => setStatusChangeModal({ show: false, data: null })}
                pale
                fontColor={Theme.PrimaryGrey}
                color={Theme.PrimaryGrey}
                disabled={changingStatus}
              >
                <Text bold>No</Text>, Don't Change
              </Button>
            </Box>
            <Box pad="24px">
              <Button
                onClick={handleChangeStatus}
                color={Theme.PrimaryRed}
                progress={changingStatus}
                disabled={changingStatus}
              >
                <Text bold>Yes</Text>, Change Status
              </Button>
            </Box>
          </Grid>
        </ModalBody>
      </Modal>
    </Box>
  );
};

export default ProductDiscounts;
