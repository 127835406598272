import { Outlet ,useNavigate} from "react-router-dom";
import { useSelector, useDispatch} from "react-redux";
import {
  selectCurrentUser,
  selectCurrentUserRole,
} from "../redux/auth/selectors";
import { Layout, SideLink, SideBarToogle, Box } from "@flexisaf/flexibull2";
import { ProfileHolder } from "../components/custom/profileHolder";
import Theme from "../utils/theme"; 
import { useIdleTimer } from 'react-idle-timer' 
import {
  canViewCustomers,
  canViewProducts,
  canViewUsers,
  canViewSubscriptions,
  canViewTemplates,
  CanFilter,
  canViewTags,
  canViewStaff,
} from "../components/custom/canView";
import React, { useState,useEffect, useCallback} from 'react';
import Logo from "../assets/images/logo-line.svg";
import IdleModal from "../components/custom/IdleModal";
import { logOutUser } from ".././redux/auth/reducer";

const menuItems = [
  {
    icon: "icon-private",
    name: "customers",
    label: "Customers",
    navlink: "/customers",
    can: canViewCustomers,
  },
  {
    icon: "icon-popup",
    name: "Products",
    label: "Products",
    navlink: "/products",
    can: canViewProducts,
  },
  {
    icon: "flexibull-clock",
    name: "Subscriptions",
    label: "Subscriptions",
    navlink: "/subscriptions",
    can: canViewSubscriptions,
  },
  {
    icon: "icon-templates",
    name: "Templates",
    label: "Templates",
    navlink: "/templates",
    can: canViewTemplates,
  },
  {
    icon: "icon-people",
    name: "Users",
    label: "Users",
    navlink: "/users",
    can: canViewUsers,
  },
  {
    icon: "icon-wrench-outline",
    name: "Settings",
    label: "Settings",
    navlink: "/settings",
    can: [...canViewTags, ...canViewStaff],
  },
];

const Application = (props) => {
  const { theme, onlineStatus } = props;
  const user = useSelector(selectCurrentUser);
  const userRole = useSelector(selectCurrentUserRole);
  console.log("these are menu items", CanFilter(menuItems));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [idleModal, setIdleModal] = useState(false);
  const handleLogout = () => {
    dispatch(logOutUser());
    navigate("/login");
  };
   const handleOnIdle = useCallback(() => {
    setIdleModal(true);
  }, []);

  useEffect(() => {
    if (idleModal) {
      const sessionTimeOut = setTimeout(() => handleLogout(), 1000 * 60 * 3);
      return () => {
        clearTimeout(sessionTimeOut);
      };
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idleModal, handleOnIdle]);
  
 
  const resume = () => {
    setIdleModal(false);
  };
  
useIdleTimer({
    onIdle:handleOnIdle, 
    timeout: 1000 * 60 * 10,
  })
  return (
    <>
    <Layout
      theme={theme}
      withSideNav
      collapseIcon="flexibull-cancel-1"
      expandIcon="flexibull-menu-1"
      sideNavProps={{
        float: false,
        background: Theme.PrimarySurface,
        anchorFooter: true,
        brand: (
          <SideBarToogle>
            <Box pad="10px">
              <img alt="logo" src={Logo} style={{ height: "40px" }} />
            </Box>
          </SideBarToogle>
        ),
        menuList: [
          {
            icon: "icon-landing",
            name: "dashboard",
            label: "Home",
            navlink: "/dashboard",
          },
          ...CanFilter(menuItems),
        ],
      }}
      sideNavCloseWidth="60px"
      sideNavOpenWidth="270px"
      sideNavContent={
        <>
          <SideLink>
            <ProfileHolder
              name={`${user?.user?.firstName} ${user?.user?.lastName}`}
              email={user?.user?.primaryEmail}
              status={onlineStatus}
              onLogout={props.onLogout}
              userRole={userRole}
            />
          </SideLink>
        </>
      }
    >
      <Box>
        <Outlet />
      </Box>
       
 
      <IdleModal open={idleModal} resume={resume} logout={() => handleLogout()}  />
    
    </Layout>
 
    </>
  ); 
};

export default Application;
