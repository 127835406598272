import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSubscriptionsStart,
  fetchSubscriptionsFailure,
  fetchSubscriptionsSuccess,
} from "../reducer";

import { httpGetSubscriptions } from "../../../services/subscriptions";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getSubscriptions({ payload }) {

  const { data, onSuccess, onFailure } = payload;
  const response = yield call(httpGetSubscriptions, data);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchSubscriptionsSuccess(raw));
    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield put(fetchSubscriptionsFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to fetch subscription");

    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onGetSubscriptions() {
  yield takeLatest(
    fetchSubscriptionsStart.type,
    getSubscriptions
  );
}
