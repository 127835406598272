import {
  Modal,
  Box,
  Button,
  ModalFooter,
  ModalBody,
  ModalClose,
} from "@flexisaf/flexibull2";
import React, { useState, useEffect } from 'react';
import Theme from "../../utils/theme";

const IdleModal = ({ open, resume, logout }) => {
  const [timer, setTimer] = useState('03:00');
  const [countDown, setCountDown] = useState(60 * 3);

  useEffect(() => {
    if (open) {
      let time = 60 * 3;
      let countDown = 60 * 3;
      const interval = setInterval(() => {
        // if the time is 0 then end the counter
        if (time <= 0) {
          return;
        }
        let minutes = Math.floor(time / 60);
        if (minutes < 10) minutes = '0' + minutes;
        let seconds = time % 60;
        if (seconds < 10) seconds = '0' + seconds;
        const text = minutes + ':' + seconds;
        time--;
        countDown = countDown - 1;
        setTimer(text);
        setCountDown(countDown);
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
   <Modal onClose={resume} open={open}>
      <ModalBody width="35vw">
        <ModalClose onClick={resume}>&times;</ModalClose>
        <Box pad="20px 30px 10px 30px">
          <h2>Are you there?</h2>
        </Box>
        <Box pad="10px 30px 20px 30px">
          <p>
            Looks like you are not doing anything at the moment, The system is
            designed to automatically log you out if idle for up to{' '}
            <strong>3mins</strong>. Do you want to resume or logout?
          </p>

          <Box pad="5px" style={{ textAlign: 'center' }}>
            <p>
              You will be logged out in <br />
              <strong
                style={{
                  fontSize: '20px',
                  color:
                    countDown < 60 ? Theme.PrimaryRed : Theme.PrimaryGreen,
                }}
              >
                {timer}
              </strong>
            </p>
          </Box>
        </Box>
        <ModalFooter>
          <Box pad="20px" align="right">
            <Button
              pale
              onClick={() => logout()}
              color="rgba(0,0,0,0)"
              fontColor={Theme.PrimaryGrey}
              iconLeft={<i className="saf-logout" />}
            >
              Log me out
            </Button>
            <Button onClick={() => {
              setTimer('03:00');
              resume();
            }}>Resume</Button>
          </Box>
        </ModalFooter>
      </ModalBody> 
    </Modal> 
    </>
  );
};
;

export default IdleModal;
