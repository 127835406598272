import { call, put, takeLatest } from "redux-saga/effects";
import {
  createTagStart,
  createTagsFailure,
  createTagsSuccess,
} from "../reducer";

import { httpPostTag } from "../../../services/tags";
import { successNotifier } from "../../../utils/helpers";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* createTag({ payload }) {
  const { data, onSuccess, onFailure } = payload;
  const response = yield call(httpPostTag, data);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(createTagsSuccess());
    if (onSuccess) {
      onSuccess();
    }
    successNotifier("tag was successfully created");
  } else {
    yield put(createTagsFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to update this tag.");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onCreateTag() {
  yield takeLatest(createTagStart.type, createTag);
}
