import { call, put, takeLatest } from "redux-saga/effects";
import {
  updateRoleFailure,
  updateRoleStart,
  updateRoleSuccess,
} from "../reducer";

import { httpPutRole } from "../../../services/roles";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* updateRole({ payload }) {
  const { data, onSuccess, onFailure } = payload;
  const response = yield call(httpPutRole, data);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(updateRoleSuccess(raw));
    if (onSuccess) {
      onSuccess();
    }
    successNotifier(
      `${data?.role?.name?.toUpperCase()} role updated successfully.`
    );
  } else {
    yield put(updateRoleFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to edit this staff.");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onUpdateRole() {
  yield takeLatest(updateRoleStart.type, updateRole);
}
