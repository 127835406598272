import Application from "./app";
import Login from "./login";
import GoogleAuthVerify from "./login/GoogleAuthVerify";
import PasswordReset from "./auth/passwordReset";
import ConfirmEmail from "./auth/confirmEmail";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import PublicRoute from "../components/routeHelpers/PublicRoute";
import NotFound from "../components/custom/notFound";
import PrivateRoute from "../components/routeHelpers/PrivateRoute";
import Dashboard from "./dashboard/dashboard";
import Customer from "./customer/customer";
import Products from "./products/products";
import Product from "./product/product";
import CustomerDetails from "./customer/support/customerDetails";
import Subscriptions from "./subscriptions/subscriptions";
import Subscription from "./subscriptions/subscription";
import Templates from "./templates/templates";
import Template from "./templates/template";
import Users from "./users/users";
import SubscriptionsExpiringSoon from "./subscriptions/subscriptionsExpiringSoon";
import Settings from "../pages/settings";
import {
  canViewCustomers,
  canViewProducts,
  canViewStaff,
  canViewSubscriptions,
  canViewTags,
  canViewTemplates,
  canViewUsers,
  hasAccess,
} from "../components/custom/canView";
import { Page403 } from "./Page403";

const routeList = [
  {
    path: "/customers",
    component: <Customer />,
    can: canViewCustomers,
    exact: true,
  },
  {
    path: "/customers/:id",
    component: <CustomerDetails />,
    can: canViewCustomers,
    exact: true,
  },
  {
    path: "/products",
    component: <Products />,
    can: canViewProducts,
    exact: true,
  },
  {
    path: "/products/:id",
    component: <Product />,
    can: canViewProducts,
    exact: true,
  },
  {
    path: "/subscriptions",
    component: <Subscriptions />,
    can: canViewSubscriptions,
    exact: true,
  },
  {
    path: "/subscriptions/:id",
    component: <Subscription />,
    can: canViewSubscriptions,
    exact: true,
  },
  {
    path: "/settings",
    component: <Settings />,
    can: [...canViewTags, ...canViewStaff],
    exact: true,
  },
  {
    path: "/templates",
    component: <Templates />,
    can: canViewTemplates,
    exact: true,
  },
  {
    path: "/templates/:id",
    component: <Template />,
    can: canViewTemplates,
    exact: true,
  },
  {
    path: "/users",
    component: <Users />,
    can: canViewUsers,
    exact: true,
  },
];

const Pages = (props) => {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/login"
            exact
            element={
              <PublicRoute>
                <Login
                  theme={props.theme}
                  onLoginSuccess={props.onLoginSuccess}
                />
              </PublicRoute>
            }
          />
          <Route path="/reset-request" element={<PasswordReset />} />
          <Route path="/confirm" element={<ConfirmEmail />} />
          <Route path="/" exact element={<Navigate to="/login" />} />
          <Route
            path="/auth-verify"
            exact
            element={
              <PublicRoute>
                <GoogleAuthVerify />
              </PublicRoute>
            }
          />
          <Route
            element={
              <Application
                theme={props.theme}
                onlineStatus={props.onlineStatus}
                onLogout={props.onLogout}
              />
            }
          >
            <Route
              exact
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            {routeList.map((route, index) => (
              <Route
                path={route.path}
                exact={route?.exact}
                element={
                  <PrivateRoute key={index}>
                    {hasAccess(route?.can) ? route.component : <Page403 />}
                  </PrivateRoute>
                }
              />
            ))}
            <Route
              exact
              path="/subscriptions/expiring-soon"
              element={
                <PrivateRoute>
                  <SubscriptionsExpiringSoon />
                </PrivateRoute>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default Pages;
