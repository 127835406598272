import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchDashboardDataStart } from "../../redux/dashboard/reducer";
import {
  selectDashboardData,
  selectIsLoading,
} from "../../redux/dashboard/selectors";

const useDashboardDataGet = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const dashboardData = useSelector(selectDashboardData);

  useEffect(() => {
    dispatch(fetchDashboardDataStart());
  }, [dispatch]);

  return {
    loadingData: isLoading,
    dashboardData,
  };
};

export default useDashboardDataGet;
