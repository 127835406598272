import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectUsersData,
    selectIsLoadingUsers,
} from "../../redux/users/selectors";
import { fetchUserDataStart } from "../../redux/users/reducer";
import usePaginationWrapper from "../usePaginationWrapper";

import useDebounce from "../useDebounce";

const useUsersGet = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsersData);
  const isLoadingUsers = useSelector(selectIsLoadingUsers);
  const [searchTerm, setSearchTerm] = useState("");
  const [forceFetch, setForceFetch] = useState(0);
  const debouncedTerm = useDebounce(searchTerm, 1000);

  const { page, pageSize, setPage, setPageSize } = usePaginationWrapper();

  const fetchUsers = useCallback(() => {
    const options = {
      search: debouncedTerm || "",
      offset: page - 1,
      limit: pageSize,
    };
    dispatch(fetchUserDataStart(options));
  }, [dispatch, debouncedTerm, page, pageSize]);

  const refetch = useCallback(() => setForceFetch((prev) => prev + 1), []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, forceFetch, debouncedTerm, page, pageSize]);

  return {
    users,
    loadingUser: isLoadingUsers,
    setSearchTerm,
    searchTerm,
    setPage,
    setPageSize,
    page,
    pageSize,
    refetch,
  };
};

export default useUsersGet;