import {
  Grid,
  Box,
  Tooltip,
  Text,
  Avatar,
  Tooltiptext,
} from '@flexisaf/flexibull2';
import { Spacer } from '@flexisaf/flexibull2/build/layout';
import { StatusIndicator } from '@flexisaf/flexibull2/build/statusindicator';
import fontSize from '../../../utils/typography';
import Theme from '../../../utils/theme';
import {
  DashWrapper,
  Liner,
  AlertBars,
} from '../../../components/custom/wrappers';
import { LineGenerator } from '../../../components/barGenerator';
import { PageHeader } from '../../../components/custom/header';
import { statusColors } from '../../../utils/colors';
import { useParams } from 'react-router-dom';
import request from '../../../utils/request';
import { errorNotifier } from '../../../utils/helpers';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import urls from '../../../utils/config';
import moment from 'moment';
import NotFound from '../../../components/custom/notFound';
import { EMPTY_VALUE } from '../../../utils/constants';
import SectionLoader from '../../../components/custom/sectionLoader';




const CustomerDetails = (props) => {
  const params = useParams();
  const [loadingData, setLoadingData] = useState(true);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);


  const fetchCustomer = async (id) => {
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/customers/${id}?include-contacts=true`,
      });
      if (!res.success) {
        throw res.message;
      }
      setCustomerDetails(res.raw);
    } catch (error) {
      errorNotifier('Unable to customer product');
      setCustomerDetails(null);
    } finally {
      setLoadingData(false);
    }
  };
  const fetchSubscriptions = async (id) => {
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/subscriptions?customerId=${id}`,
      });
      if (!res.success) {
        throw res.message;
      }
      setSubscriptions(res.raw);
    } catch (error) {
      errorNotifier('Unable to customer subscription');
    } finally {
      setLoadingData(false);
    }
  };
  useEffect(() => {
    if (params.id) {
      setLoadingData(true);
      fetchCustomer(params.id);
      fetchSubscriptions(params.id);
    }
  }, [params.id]);

  function calculatePercentage(startDate, endDate) {
    const startAt = moment(startDate);
    const endAt = moment(endDate);
    const currentDate = moment();
    const validity = endAt.diff(startAt, 'days');
    const daysFromStartDate = currentDate.diff(startAt, 'days');
    return (daysFromStartDate / validity) * 100;
  }

  function checkDaysUntilExpiryDate(endDate) {
    const endAt = moment(endDate);
    const currentDate = moment();
    const daysUntilExpiryDate = endAt.diff(currentDate, 'days');
    return daysUntilExpiryDate;
  }

  if (loadingData) return <SectionLoader />;
  if (!loadingData && !customerDetails)
    return (
      <NotFound
        title="Customer not found"
        info="Seems you are trying to access a customer that does not exist"
        link="customers"
      />
    );

const getSubscriptionStatus = subscription => {
  const percentage = calculatePercentage(
    subscription?.startDate,
    subscription?.endDate
  )
  if (percentage > 100) return 'Expired'
  if (percentage < 0) return 'Future'
  return 'Active'
}
  return (
    <>
      <PageHeader>
        <Text {...fontSize.h3}>
          <i className="icon-private" /> Customer: {customerDetails?.name}
        </Text>
      </PageHeader>
      <DashWrapper className="customers">
        <Box className="bb">
          <Box>
            <Grid default="minmax(300px, 800px) auto" gap="0">
              <Box pad="32px">
                <Text {...fontSize.h4}>Customer Details</Text>
                <Spacer space="24px" />
                <Grid default="auto max-content" gap="24px">
              
                  <Box>
                    <Text {...fontSize.caption} block>
                      Name
                    </Text>
                    <Text {...fontSize.h3} bold>
                      {customerDetails?.name}
                    </Text>
                  </Box>
                  <Box>
                    <Text {...fontSize.caption} block>
                      Business Type
                    </Text>
                    <Text {...fontSize.h3} bold>
                      {customerDetails?.customerType}
                    </Text>
                  </Box>
                  <Box>
                    <Text {...fontSize.caption} block>
                      Account type
                    </Text>
                    <Text {...fontSize.h3} bold>
                      {customerDetails?.isDemo ? "Demo account" :"Live account"}
                    </Text>
                  </Box>
                  <Box>
                    <Text {...fontSize.caption} block>
                      Email
                    </Text>
                    <Text bold>{customerDetails?.email}</Text>
                  </Box>
                  <Box>
                    <Text {...fontSize.caption} block>
                      Phone Number
                    </Text>
                    <Text bold>{customerDetails?.phone}</Text>
                  </Box>
                  <Box>
                    <Text {...fontSize.caption} block>
                      Customer Code
                    </Text>
                    <Text bold>{customerDetails?.code || EMPTY_VALUE}</Text>
                  </Box>
                  <Box>
                    <Text {...fontSize.caption} block>
                      Customer Short Name
                    </Text>
                    <Text bold>{customerDetails?.shortName || EMPTY_VALUE}</Text>
                  </Box>
                  <Box>
                    <Text {...fontSize.caption} block>
                      Website
                    </Text>
                    <Text bold>{customerDetails?.website || EMPTY_VALUE}</Text>
                  </Box>
                </Grid>
                <Spacer space="24px" />
                <Box background={Theme.PrimarySurface} pad="24px" round>
                  <Box>
                    <Text {...fontSize.caption} block>
                      Address
                    </Text>
                    <Text bold>{customerDetails?.address}</Text>
                  </Box>
                  <Spacer space="24px" />
                  <Grid default="1fr 1fr 1fr" gap="24px">
                    <Box>
                      <Text {...fontSize.caption} block>
                        Country
                      </Text>
                      <Text bold>
                        {customerDetails?.country?.toUpperCase()}
                      </Text>
                    </Box>
                    <Box>
                      <Text {...fontSize.caption} block>
                        State
                      </Text>
                      <Text bold>{customerDetails?.state}</Text>
                    </Box>
                    <Box>
                      <Text {...fontSize.caption} block>
                        City
                      </Text>
                      <Text bold>{customerDetails?.city}</Text>
                    </Box>
                
                  </Grid>
                </Box>
                <Spacer space="24px" />
                { Array.isArray(customerDetails?.contacts) && customerDetails.contacts.length > 0 && (
                  <>
                    <Text {...fontSize.h4}>Contact Persons</Text>
                    <Spacer space="24px" />
                    {customerDetails?.contacts?.map((contact, i) => (
                      <Box key={contact.id}>
                        {i > 0 && (
                          <Liner margin="16px 0" borderstyle="dashed" />
                        )}
                        <Grid default="auto max-content" gap="24px">
                          <Box>
                            <Text {...fontSize.caption} block>
                              {contact.isPrimary && 'Primary'} Contact
                            </Text>
                            <Text bold>{contact?.name}</Text>
                          </Box>
                          <Box align="right">
                            <Text block>{contact.email}</Text>
                            <Text bold>{contact.phone}</Text>
                          </Box>
                        </Grid>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
              <Box className="bl" pad="32px" background="white">
                <Text {...fontSize.h4}>Subscriptions</Text>
                <Spacer space="24px" />
                {subscriptions?.count === 0 ? (
                  <Text> No subscriptiontion</Text>
                ) : (
                  subscriptions?.data.map((subscription, index) => {
                    return (
                      <Box
                        round
                        className="bordered"
                        margin="0 0 16px 0"
                        maxWidth="800px"
                        key={index}
                      >
                        <Box pad="16px" className="bb">
                          <Grid default="auto max-content" responsive={false}>
                            <Box>
                              <Link to={`/subscriptions/${subscription?.id}`}>
                                <Text {...fontSize.h3}>
                                  #SUB-
                                  {String(
                                    subscription.subscriptionNumber
                                  ).padStart(5, "0")}
                                </Text>
                              </Link>
                            </Box>
                            <Box>
                              <StatusIndicator
                                label={getSubscriptionStatus(subscription)}
                                {...statusColors[
                                  getSubscriptionStatus(subscription)?.toLowerCase()
                                ]}
                                compound
                              />
                            </Box>
                          </Grid>
                        </Box>

                        {calculatePercentage(
                          subscription?.startDate,
                          subscription?.endDate
                        ) > 70 &&
                          calculatePercentage(
                            subscription?.startDate,
                            subscription?.endDate
                          ) < 80 && (
                            <AlertBars color={Theme.PrimaryOrange}>
                              <Box>
                                <i className="icon-block-outline" />
                              </Box>
                              <Text color={Theme.PrimaryFontColor}>
                                This subscription is expiring soon, on{" "}
                                <strong>
                                  {moment(subscription?.endDate).format(
                                    "DD MMMM YYYY"
                                  )}
                                </strong>
                              </Text>
                              <Box>
                                <>
                                  <a
                                    href={`/subscriptions/${subscription?.id}`}
                                  >
                                    <i className="flexibull-spin4" /> Change
                                    Schedule
                                  </a>
                                </>
                              </Box>
                            </AlertBars>
                          )}
                        {calculatePercentage(
                          subscription?.startDate,
                          subscription?.endDate
                        ) > 80 && (
                          <AlertBars color={Theme.PrimaryRed}>
                            <Box>
                              <i className="icon-block-outline" />
                            </Box>
                            {
                              checkDaysUntilExpiryDate(subscription?.endDate) > 0 ? <Text color={Theme.PrimaryFontColor}>
                              This subscription expires in{" "}
                              <strong>
                                {checkDaysUntilExpiryDate(subscription?.endDate)}
                                &nbsp; Days
                              </strong>
                              </Text>: 
                              <Text>
                                This subscription has expired
                                <strong>
                                  {
                                    checkDaysUntilExpiryDate(subscription?.endDate) === 0 ? 'Today' : checkDaysUntilExpiryDate(subscription?.endDate) === 1 ? 'Yesterday' : (
                                      <>
                                        &nbsp; {Math.abs(checkDaysUntilExpiryDate(subscription?.endDate))}
                                        &nbsp; Day(s) ago
                                      </>
                                    )
                                  }
                              </strong>
                              </Text>
                            }
                            <Box>
                              <>
                                <a href={`/subscriptions/${subscription?.id}`}>
                                  <i className=" icon-eject-outline" /> Renew
                                  Subscription
                                </a>
                              </>
                            </Box>
                          </AlertBars>
                        )}
                        <Spacer space="12px" />
                        <Box pad="24px 16px">
                          <Box vAlign="center" margin="0 0 16px">
                            <Avatar
                              name={subscription?.plan?.product?.name}
                              src={
                                subscription?.plan?.product?.logoUrl
                                  ? subscription?.plan?.product?.logoUrl
                                  : ""
                              }
                              margin="0 8px 0 0"
                              color="none"
                              size="24px"
                            />{" "}
                            <Link
                              to={`/products/${subscription?.plan?.product?.id}`}
                            >
                              <Text bold color={Theme.PrimaryDark}>
                                {subscription?.plan?.product?.name}
                              </Text>
                            </Link>
                          </Box>
                          <Grid default="auto max-content">
                            <Box>
                              <Text {...fontSize.caption} block>
                                Subscription Plan
                              </Text>
                              <Text>{subscription?.plan?.name} / {subscription?.plan?.unitCost}</Text>
                            </Box>
                            <Box>
                              <Text {...fontSize.caption} block>
                                Billing Cycle
                              </Text>
                              {subscription?.plan?.billingCycle > 1 
                               ?  <Text>{subscription?.plan?.billingCycle} {subscription?.plan?.billingCycleType} </Text>
                               :  <Text>{subscription?.plan?.billingCycle} {" "}  {subscription?.plan?.billingCycleType?.slice(0, -1)} </Text>}
                            </Box>
                            <Box>
                              <Text {...fontSize.caption} block>
                                Subscription Started
                              </Text>
                              <Text>
                                {moment(subscription?.startDate).format(
                                  "DD MMMM, YYYY"
                                )}
                              </Text>
                            </Box>
                            <Box>
                              <Text {...fontSize.caption} block>
                                {checkDaysUntilExpiryDate(
                                  subscription?.startDate,
                                  subscription?.endDate,
                                ) < 0
                                  ? "Expired on"
                                  : "Expires on"}
                              </Text>
                              <Text>
                                {moment(subscription?.endDate).format(
                                  "DD MMMM, YYYY"
                                )}
                              </Text>
                            </Box>
                          </Grid>
                          {checkDaysUntilExpiryDate(
                            subscription?.startDate,
                            subscription?.endDate,
                          ) >= 0 &&
                            moment().isBefore(
                              moment(subscription?.startDate),
                              "D"
                            ) && (
                              <LineGenerator
                                percentage={calculatePercentage(
                                  subscription?.startDate,
                                  subscription?.endDate
                                )}
                              />
                            )}
                        </Box>
                        <Box className="bt" pad="8px 16px">
                          <Grid default="auto max-content" responsive={false}>
                            <Box vAlign>
                              <Text {...fontSize.caption} block>
                                Subscription created:{" "}
                                {moment(subscription?.createdAt).format(
                                  "DD MMMM, YYYY"
                                )}
                              </Text>
                            </Box>
                            <Box vAlign>
                              Sales person{" "}
                              <Spacer direction="horizontal" space={10} />
                              {subscription.salesPerson ? 
                              <Tooltip>
                                <Avatar
                                  name={subscription?.salesPerson }
                                  round
                                  margin="0 8px 0 0"
                                />
                                <Tooltiptext top>
                                  {subscription?.salesPerson}
                                </Tooltiptext>
                              </Tooltip>
                  :""}
                            </Box>
                          </Grid>
                        </Box>
                      </Box>
                    );
                  })
                )}
              </Box>
            </Grid>
          </Box>
        </Box>
      </DashWrapper>
    </>
  );
};

export default CustomerDetails;
