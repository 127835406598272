import { call, put, takeLatest } from "redux-saga/effects";
import {
  createUserStart,
  createUserFailure,
  createUserSuccess,
} from "../reducer";

import { httpPostUser } from "../../../services/users";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* createUser({ payload }) {
  const { user, onSuccess, onFailure } = payload;
  const response = yield call(httpPostUser, user);

  const { raw, statusCode, success } = response;

  if (success) {
    yield put(createUserSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield put(createUserFailure(raw));
    networkErrorHandler(
      statusCode,
      "Unable to create this User, a user with this email might already exist or an error occured."
    );

    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onCreateUser() {
  yield takeLatest(createUserStart.type, createUser);
}
