import {
  Box,
  Grid,
  Spacer,
  Input,
  Select,
  Button,
  TextArea,
  Switch,
  RadioButton,
} from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Liner } from "../../custom/wrappers";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import { useForm } from "react-hook-form";
import request from "../../../utils/request";
import urls from '../../../utils/config';
import { useParams } from "react-router-dom";
import { errorNotifier } from "../../../utils/helpers";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const DiscountStep1 = ({
  step,
  setStep,
  discountInformation,
  handleClose,
  bulkUpdateDiscountInformation,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      ...discountInformation,
    },
  });
  const params = useParams()

  const [plans, setPlans] = useState({ data: [] });
  const [indexedPlans, setIndexedPlans] = useState({});
  const [loadingPlans, setLoadingPlans] = useState(true);

  useEffect(() => {
    reset({
      ...discountInformation,
      plans: discountInformation?.plans?.map((plan) => ({ label:indexedPlans?.[plan.value]?.name, value: plan.value }))
    });
  // eslint-disable-next-line
  }, [step, discountInformation, indexedPlans]);

  const fetchPlans = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/plans/lite/`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join('&');
      if (unifiedOptions) {
        url = `${url}?${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setPlans(res.raw);
      setIndexedPlans(res.raw.data.reduce((acc, plan) => {
        acc[plan.id] = plan;
        return acc;
      }, {}));

    } catch (error) {
      errorNotifier('Unable to fetch plans');
    } finally {
      setLoadingPlans(false);
    }
  };

  useEffect(() => {
    setLoadingPlans(true);
    fetchPlans({
      productId: params.id,
      limit: 200,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Swiper active={step === 1}>
      <Box pad="32px">
        <Text {...fontSize.h4}>Discount Information</Text>
        <Spacer space="24px" />
        <Grid default="1fr 1fr" gap="24px">
          <Input
            spaceTop
            type="text"
            label={"Name"}
            required={true}
            placeholder={`Ex: 30% discount forever`}
            value={watch("name")}
            {...register("name", {
              required: `Name is required`,
            })}
            error={errors.name?.message}
            onChange={({ target: { value } }) => setValue("name", value)}
          />
          <Input
            spaceTop
            type="text"
            label={"Code"}
            required={true}
            placeholder={`Unique discount code`}
            value={watch("code")}
            {...register("code", {
              required: `code is required`,
            })}
            error={errors.code?.message}
            onChange={({ target: { value } }) => setValue("code", value)}
          />
        </Grid>
        <Spacer space="24px" />
        <Grid default="1fr" gap="24px">
          <TextArea
            spaceTop
            type="text"
            label={"Description"}
            width="auto"
            required={true}
            placeholder={`Type in a short description for this discount`}
            value={watch("description")}
            {...register("description", {
              required: `Description is required`,
            })}
            error={errors.description?.message}
            onChange={({ target: { value } }) => setValue("description", value)}
          />
        </Grid>

        <Spacer space="24px" />
        <Liner borderstyle="dashed" />
        <Spacer space="24px" />

        <Grid default="1fr 1fr" gap="24px">
          <Grid default="120px auto">
            <Select
              spaceTop
              label="Discount"
              required
              placeholder="Type"
              options={["FLAT", "PERCENTAGE"].map((v) => ({
                label: v,
                value: v,
              }))}
              block
              value={watch("type") ? { label: watch("type"), value : watch("type") } : null}
              {...register("type", {
                required: "Required",
              })}
              onChange={(value) => setValue("type", value?.value)}
            />
            {watch("type") === "FLAT" ? (
              
              <Input
                spaceTop
                required
                label=''
                type="currency"
                placeholder="Value"
                block
                value={watch("typeValue")}
                {...register("typeValue", {
                  required: "Value is required",
                  ...(watch("type") === "PERCENTAGE"
                    ? {
                        max: {
                          value: 100,
                          message: "Can't be more than 100%",
                        },
                      }
                    : {})

                })}
                error={errors.discount?.message}
                onValueChange={(value) => setValue("typeValue", value)}
              />

            ) : (
              <Input
                spaceTop
                required
                label=''
                type="number"
                placeholder="Value"
                block
                value={watch("typeValue")}
                {...register("typeValue", {
                  required: "Value is required",
                  ...(watch("type") === "PERCENTAGE"
                    ? {
                        max: {
                          value: 100,
                          message: "Can't be more than 100%",
                        },
                      }
                    : {}),
                })}
                error={errors.typeValue?.message}
                onChange={({ target: { value } }) =>
                  setValue("typeValue", value)
                }
              />
            )}
          </Grid>

          <Input
            type="date"
            spaceTop
            label="Valid up to"
            required
            {...register("expiryDate", {
              required: "Valid up to is required",
            })}
            error={
              errors.expiryDate && "Date is required and must be today upwards"
            }
            onChange={({ target: { value } }) => setValue("expiryDate", value)}
            value={watch("expiryDate")}
          />
        </Grid>

        <Spacer space="24px" />
        <Liner borderstyle="dashed" />
        <Spacer space="24px" />

        <Grid default="1fr 1fr" gap="24px">
          <Box>
            <Text bold>Assign to plans</Text>
            <Grid default="1fr" gap="10px">
              <Spacer space="10px" />
              <div
                onClick={() => setValue("assignedPlans", "ALL")}
                style={{ cursor: "pointer" }}
              >
                <RadioButton
                  style={{ padding: "0px", margin: "0px" }}
                  name="radio"
                  label="All Plans"
                  checked={watch("assignedPlans") === "ALL"}
                  block
                  size="16px"
                  height="20px"
                />
              </div>

              <div
                onClick={() => setValue("assignedPlans", "NONE")}
                style={{ cursor: "pointer" }}
              >
                <RadioButton
                  style={{ padding: "0px", margin: "0px" }}
                  name="radio"
                  label="No Plan"
                  checked={watch("assignedPlans") === "NONE"}
                  block
                  size="16px"
                  height="20px"
                />
              </div>

              <div
                onClick={() => setValue("assignedPlans", "SELECTED")}
                style={{ cursor: "pointer" }}
              >
                <RadioButton
                  style={{ padding: "0px", margin: "0px" }}
                  name="radio"
                  label="Selected Plans"
                  checked={watch("assignedPlans") === "SELECTED"}
                  block
                  size="16px"
                  height="20px"
                />
              </div>
            </Grid>
          </Box>
          <Text color={Theme.SecondaryFontColor}>
            Discounts are assign to plans. You can either assign discounts to
            all plans, selected plans or no plan.
          </Text>
        </Grid>
        <Spacer space="10px" />

        {watch("assignedPlans") === "SELECTED" && (
          <Grid default="1fr 1fr" gap="24px">
            <Select
              spaceTop
              isMulti
              required
              placeholder="Select plan(s)"
              block
              disabled={loadingPlans}
              options={plans.data.map((d) => ({
                label: d.name,
                value: d.id,
              }))}
              value={watch("plans")}
              {...register("plans", {
                required: "Select at least one plan",
              })}
              error={errors.plans?.message}
              onChange={(value) => setValue("plans", value)}
              isLoading={loadingPlans}
            />

            <Text
              style={{ marginTop: "auto" }}
              color={Theme.SecondaryFontColor}
            >
              These will be the product plans this discount will be assigned to.
            </Text>
          </Grid>
        )}

        <Spacer space="24px" />
        <Liner borderstyle="dashed" />
        <Spacer space="24px" />

        <Text bold>Discount Status</Text>
        <Grid default="1fr 1fr" gap="24px">
          <Switch
            label={watch("status") ? "Active" : "Inactive"}
            checked={watch("status")}
            onChange={({ target: { checked } }) => setValue("status", checked)}
          />
        </Grid>
      </Box>

      <Grid default="auto max-content" className="bt">
        <Box pad="24px">
          <Button
            onClick={handleClose}
            pale
            color="rgba(0,0,0,0.0)"
            fontColor={Theme.PrimaryGrey}
          >
            Cancel
          </Button>
        </Box>
        <Box pad="24px">
          <Button
            onClick={handleSubmit((v) => {
              bulkUpdateDiscountInformation(v);
              setStep(2);
            })}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Swiper>
  );
};

export default DiscountStep1;
