import request from "../utils/request";
import urls from "../utils/config";
const { API_BASE_URL } = urls || {};

export const httpGetUserData = (options) => {
  const url = `${API_BASE_URL}/api/auth/users`;

  return request({
    url,
    method: "get",
    data: options,
  });
};
export const httpPostUser = (user) => {
  const url = `${API_BASE_URL}/api/auth/users`;

  return request({
    url,
    method: "post",
    data: user,
  });
};

export const httpPutUser = (data) => {
  const { id, user } = data;
  const url = `${API_BASE_URL}/api/auth/users/${id}`;

  return request({
    url,
    method: "put",
    data: user,
  });
};

export const httpDeleteUser = (id) => {
  const url = `${API_BASE_URL}/api/auth/users/${id}`;

  return request({
    url,
    method: "delete",
    data: id
  });
};

export const httpPutUserStatus = (data) => {
  const { id, roleId , status} = data;
  const url = `${API_BASE_URL}/api/auth/users/${id}`;

  return request({
    url,
    method: "put",
    data: { roleId , status },
  });
};
