import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Box,
  Text,
  Avatar,
  Tag,
  Table,
  StatusIndicator,
  DropDown,
  Input,
  Select,
  Spacer,
  Button,
} from '@flexisaf/flexibull2';
import { statusColors } from '../../../utils/colors';
import moment from 'moment';
import useDebounce from '../../../hooks/useDebounce';
import fontSize from '../../../utils/typography';
import urls from '../../../utils/config';
import request from '../../../utils/request';
import { errorNotifier } from '../../../utils/helpers';

import Theme from '../../../utils/theme';

import { DashWrapper } from '../../../components/custom/wrappers';
import { FlexiPagination } from '@flexisaf/flexibull2/build/table';
import { EmptyState } from '@flexisaf/flexibull2/build/emptystates';
import { SUBSCRIPTION_STATUS, PAGE_SIZES } from '../../../utils/constants';

const getStatus = (row) => {
  if (moment().isBefore(moment(row.startDate), 'D')) return 'future';
  if (moment().isAfter(moment(row.endDate), 'D')) return 'expired';
  return 'active';
};

const TemplateUsage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedTerm = useDebounce(searchTerm, 1000);
  const [loadingData, setLoadingData] = useState(true);
  const [templateUsageList, setTemplateUsageList] = useState({
    count: 0,
    data: [],
  });

  const [status, setStatus] = useState(null);
  const column = [
    {
      title: 'Customer',
      key: 'customer',
      searchable: 'searchable',
      custom: (row) => {
        return (
          <Box>
            <Avatar name={row.customer?.name} round margin="0 8px 0 0" />
            <Link to={`/customers/${row.customer?.id}`}>
              <Text bold color={Theme.PrimaryColor}>
                {row.customer?.name}
              </Text>
            </Link>
          </Box>
        );
      },
    },
    {
      title: 'Subscription No.',
      key: 'subscriptionNumber',
      searchable: 'searchable',
      custom: (row) => {
        return (
          <Box>
            <Link to={`/subscriptions/${row.id}`}>
              <Text bold color={Theme.PrimaryColor}>
                #SUB-{String(row.subscriptionNumber).padStart(5, '0')}
              </Text>
            </Link>
          </Box>
        );
      },
    },
    {
      title: 'Date Created',
      key: 'createdAt',
      custom: (row) => (
        <Text>{moment(row.createdAt).format('DD MMMM, YYYY')}</Text>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      custom: (row) => {
        const rowStatus = getStatus(row);
        return (
          <StatusIndicator
            compound
            label={rowStatus}
            {...statusColors[rowStatus]}
          />
        );
      },
    },

    {
      title: 'Action',
      key: 'action',
      custom: (row) => {
        return (
          <Box align="auto">
            <DropDown
              trigger="click"
              style={{ textAlign: 'left' }}
              menuAlign="bottom right"
              label={
                <Box pad="8px" className="cursor">
                  <Text {...fontSize.h3}>...</Text>
                </Box>
              }
              menuList={[
                {
                  onClick: () => navigate(`/customers/${row.customer?.id}`),
                  label: 'View Customer',
                  iconLeft: 'icon-private',
                },
                {
                  onClick: () => navigate(`/subscriptions/${row.id}`),
                  label: 'Subscription Details',
                  iconLeft: 'flexibull-clock',
                },
              ]}
            />
          </Box>
        );
      },
    },
  ];

  const fetchTemplateUsage = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/subscriptions?templateId=${params.id}&include-customer=true&include-plan=true`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join('&');
      if (unifiedOptions) {
        url = `${url}&${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setTemplateUsageList(res?.raw);
    } catch (error) {
      errorNotifier('Unable to fetch subscription');
      setTemplateUsageList({
        count: 0,
        data: [],
      });
    } finally {
      if (loadingData) setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchTemplateUsage({
      search: debouncedTerm,
      offset: (page - 1) * pageSize,
      limit: pageSize,
      status: status?.value,
    });
    // eslint-disable-next-line
  }, [debouncedTerm, pageSize, page, status]);

  return (
    <Box className="bb">
      <DashWrapper className="products">
        <Box pad="32px">
          <Grid default="minmax(100px, 350px) minmax(100px, 130px) auto">
            <Box>
              <Input
                block
                type="search"
                placeholder="Search Customer Name or Subscription"
                iconLeft="flexibull-spin6"
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
              />
            </Box>
            <Box>
              <Select
                block
                placeholder="Status"
                options={SUBSCRIPTION_STATUS}
                onChange={(e) => setStatus(e)}
                value={status}
                isClearable
              />
            </Box>
            <Box align="right">
              <Tag
                pad="20px"
                color={Theme.PrimaryColor}
                style={{ borderRadius: '5px' }}
                font="13px"
              >
                <Text color={Theme.PrimaryDark}>
                  <Text bold>{templateUsageList?.count}</Text> subsciptions have
                  been created from this template.
                </Text>
              </Tag>
            </Box>
          </Grid>
          <Spacer space="24px" />
          {templateUsageList?.data?.length === 0 ? (
            <EmptyState
              type="customers"
              title="No subscriptions created from this template"
              action={
                <Button onClick={() => navigate('/subscriptions')}>
                  Create subscriptions
                </Button>
              }
            />
          ) : (
            <>
              <Table responsive={false} report>
                <table>
                  <thead>
                    <tr>
                      {column &&
                        column.map((elem, index) => {
                          return (
                            <th scope="row" key={elem.key ? elem.key : index}>
                              {elem.title}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {templateUsageList?.data &&
                      templateUsageList.data.map((elem, index) => {
                        return (
                          <tr key={index}>
                            {column.map((entry, index) => {
                              return (
                                <td key={index} data-label={entry.key}>
                                  {entry.custom
                                    ? entry.custom(elem)
                                    : elem[entry.key]}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Table>
              <Box pad="20px 0px">
                <FlexiPagination
                  pageCounts={PAGE_SIZES}
                  total={templateUsageList?.count}
                  pageSize={pageSize}
                  itemsDisplayed
                  onChange={(s) => setPage(s)}
                  changePageSize={(s) => setPageSize(s?.value)}
                  current={page}
                />
              </Box>
            </>
          )}
        </Box>
      </DashWrapper>
    </Box>
  );
};

export default TemplateUsage;
