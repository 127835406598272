import React, { useState } from 'react';
import { Input, Grid, Text } from '@flexisaf/flexibull2';
import styled from 'styled-components';
import Theme from '../../utils/theme';

const PasswordInputContainer = styled(Grid)`
  position: relative;
  height: fit-content;
`;

const ValidationInfo = styled(Grid)`
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background-color: ${Theme.PrimarySurface};
  z-index: 100;
  margin-top: 5px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid ${Theme.PrimaryBorderColor};
`;

const ValidationItem = styled.div`
  color: ${(props) => (props.validate ? 'red' : `${Theme.PrimaryColor}`)};
  font-size: 11px;
`;

const PasswordCheck = ({ criteria, message, validate, getValues }) => {
  return (
    <ValidationItem validate={validate(getValues().password, `${criteria}`)}>
      <i
        className={
          validate(getValues().password, `${criteria}`)
            ? 'icon-cancel'
            : 'icon-ok'
        }
      />
      <Text style={{ marginLeft: '5px' }} color={Theme.PrimaryGrey}>
        {message}
      </Text>
    </ValidationItem>
  );
};

const PasswordValidation = ({
  watch,
  register,
  validate,
  getValues,
  setValue,
  fieldErrors,
  field,
  label,
  placeholder,
  type,
  showPassword,
}) => {
  const [showValidationInfo, setShowValidationInfo] = useState(false);
  const hideValidation = () => setShowValidationInfo(false);
  const showValidation = () => setShowValidationInfo(true);

  return (
    <PasswordInputContainer onBlur={hideValidation} default="1fr" gap="0">
      <Input
        spaceTop
        type={showPassword ? "text" : type}
        label={label || field}
        onFocus={showValidation}
        tabIndex={0}
        required
        placeholder={placeholder || `Enter ${field}`}
        value={watch(field)}
        {...register(field, {
          required: `${field} is required`,
          ...(type === "password"
            ? {
                minLength: {
                  value: 8,
                  message: `${field} must have at least 8 characters`,
                },
                maxLength: {
                  value: 30,
                  message: `${field} must not be more than 30 characters`,
                },
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}$/,
                  message: `${field} must contain at least one number, special character, capital case and small case letter`,
                },
              }
            : {}),
        })}
        error={fieldErrors[field]?.message}
        onChange={({ target: { value } }) => {
          setValue(field, value.trim());
        }}
      />
      {showValidationInfo && (
        <ValidationInfo default="1fr" gap="5px">
          <PasswordCheck
            validate={validate}
            getValues={getValues}
            criteria="length"
            message="must be 8 characters or more"
          />
          <PasswordCheck
            validate={validate}
            getValues={getValues}
            criteria="hasNumeric"
            message="must include at least one number"
          />
          <PasswordCheck
            validate={validate}
            getValues={getValues}
            criteria="hasLowerCase"
            message="must include at least one lowercase letter"
          />
          <PasswordCheck
            validate={validate}
            getValues={getValues}
            criteria="hasUpperCase"
            message="must include at least one uppercase letter"
          />
          <PasswordCheck
            validate={validate}
            getValues={getValues}
            criteria="hasSpecialCharacter"
            message="must include at least one special character"
          />
        </ValidationInfo>
      )}
    </PasswordInputContainer>
  );
};

export default PasswordValidation;
