import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchSubscriptionExpiringStart } from "../../redux/subscriptions/reducer";
import {
  selectSubscriptionsExpiringSoon,
  selectIsLoadingExpiringSoon,
} from "../../redux/subscriptions/selectors";
import { filterExpiredSubscriptions } from "../../utils/helpers";

const useSubscriptionsExpiringSoonGet = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoadingExpiringSoon);
  const subscriptionsExpiringSoon = useSelector(
    selectSubscriptionsExpiringSoon
  );

  useEffect(() => {
    dispatch(fetchSubscriptionExpiringStart());
  }, [dispatch]);

  return {
    subscriptionsExpiringSoon: filterExpiredSubscriptions(
      subscriptionsExpiringSoon
    ),
    loadingSubscriptionsExpiringSoon: isLoading,
  };
};

export default useSubscriptionsExpiringSoonGet;
