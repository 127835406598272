import { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  Text,
  Avatar,
  Tag,
  StatusIndicator,
} from "@flexisaf/flexibull2";
import { Spacer } from "@flexisaf/flexibull2/build/layout";
import fontSize from "../../../utils/typography";
import Theme from "../../../utils/theme";
import { statusColors } from "../../../utils/colors";
import moment from "moment";
import { Tooltip, Tooltiptext } from "@flexisaf/flexibull2/build/tooltip";
import TemplatePreview from "./TemplatePreview";
import { ReadMore } from "../../../components/custom/readMoreText";
import { EMPTY_VALUE } from "../../../utils/constants";
import { getLocalUser } from "../../../utils/helpers";
import useTagsGet from "../../../hooks/tags/useTagsGet";
import useStaffGet from "../../../hooks/staff/useStaffGet";

const TemplateDetails = ({ data }) => {
  const { tags } = useTagsGet();
  const urls = JSON.parse(data?.urls || "[]");
  const [createdBy, setCreatedBy] = useState(null);
  const [templateTags, setTemplateTags] = useState(null);
  const { staff } = useStaffGet();

  useEffect(() => {
    if (!tags || !data?.tags) {
      if (templateTags !== null) setTemplateTags(null);
      return;
    }
    const _templateTags = tags?.data?.filter((tag) => data?.tags.includes(tag.id));
    setTemplateTags(_templateTags);

    // eslint-disable-next-line
  }, [tags, data?.tags]);

  const getCreatedBy = useCallback(
    async (id) => {
      try {
        // first check if it is the local user
        const localUser = getLocalUser();
        if (localUser?.id === id) {
          setCreatedBy({
            name: `${localUser?.firstName} ${localUser?.lastName}`,
            email: localUser?.primaryEmail,
          });
          return;
        }

        // else check the list of staff
        // TODO: This would be check list of users instead after user management is implemented
        const createdBy = staff?.find((staff) => staff.id === id);
        if (createdBy) {
          return setCreatedBy({
            name: createdBy?.name,
            email: createdBy?.email,
          });
        }
        return setCreatedBy(null);
      } catch {
        setCreatedBy(null);
      }
    },
    [staff]
  );

  useEffect(() => {
    if (!data?.createdByUserId) return;
    getCreatedBy(data?.createdByUserId);
  }, [data?.createdByUserId, getCreatedBy]);

  return (
    <Box className="bb">
      <Box>
        <Grid default="minmax(330px, 600px) 2fr">
          <Box pad="32px">
            <Text {...fontSize.h3}>Template Information</Text>
            <Spacer space="20px" />
            <Grid default="1fr 1fr" gap="16px">
              <Box>
                <Text {...fontSize.caption}>Template name</Text>
                <Text bold block>
                  {data?.title}
                </Text>
              </Box>
              <Box>
                <Box vAlign>
                  <Avatar
                    name="name"
                    src={
                      data?.product?.logoUrl ||
                      data?.product?.name ||
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRut_K6b-UbqsQHfcvJrADvZVCMTh86_1yJ2Q&usqp=CAU"
                    }
                    margin="0 8px 0 0"
                    color="none"
                    size="32px"
                  />{" "}
                  <Text bold block>
                    {data?.product?.name}
                  </Text>
                </Box>
              </Box>
            </Grid>
            <Spacer space="20px" />
            <Grid default="1fr 1fr" gap="16px">
              <Box>
                <Text {...fontSize.caption}>Created By</Text>
                {createdBy ? (
                  <Box vAlign>
                    <Tooltip>
                      <Avatar
                        name={createdBy?.name || ""}
                        margin="0 8px 0 0"
                        round
                        color={Theme.AccentRed}
                        size="32px"
                      />
                      <Tooltiptext top>{createdBy?.email}</Tooltiptext>
                    </Tooltip>
                    <Text bold block style={{ textTransform: "capitalize" }}>
                      {createdBy?.name} <br />
                      <a href={`mailto:${createdBy?.email}`}>
                        {createdBy.email}
                      </a>
                    </Text>
                  </Box>
                ) : (
                  <Text bold block>
                    {EMPTY_VALUE}
                  </Text>
                )}
              </Box>
              <Box>
                <Text {...fontSize.caption}>Last Edited By</Text>
                {data?.updatedBy ? (
                  <Box vAlign>
                    <Tooltip>
                      <Avatar
                        name={data?.salesPerson || ""}
                        margin="0 8px 0 0"
                        round
                        size="32px"
                      />
                      <Tooltiptext top>{data?.salesPerson}</Tooltiptext>
                    </Tooltip>
                    <Text bold block>
                      {data?.salesPerson} <br />
                      <a href={`mailto:${data?.salesPersonEmail}`}>
                        {data?.salesPersonEmail}
                      </a>
                    </Text>
                  </Box>
                ) : (
                  <Text bold block>
                    {EMPTY_VALUE}
                  </Text>
                )}
              </Box>
            </Grid>
            <Spacer space="20px" />
            <Grid default="1fr 1fr" gap="16px">
              <Box>
                <Text {...fontSize.caption}>Date Created</Text>
                <Text bold block>
                  {data &&
                    moment(data?.createdAt).format("HH:mm DD MMMM, YYYY")}{" "}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Date Last Edited</Text>
                <Text bold block>
                  {data &&
                    moment(data?.updatedAt).format("HH:mm DD MMMM, YYYY")}
                </Text>
              </Box>
            </Grid>
            <Spacer space="20px" />
            <Grid default="1fr 1fr">
              <Box>
                <Text {...fontSize.caption}>Tags</Text>

                {templateTags ? (
                  <Box gap="5px">
                    {templateTags?.map((tag) => (
                      <Tag
                        key={tag?.id}
                        pad="10px 15px"
                        color={Theme.PrimaryColor}
                        font="10px"
                        spaceRight
                        style={{ marginBottom: "5px" }}
                      >
                        {tag?.name}
                      </Tag>
                    ))}
                  </Box>
                ) : (
                  <Text bold block>
                    No tags selected
                  </Text>
                )}
              </Box>
              <Box>
                <Text block {...fontSize.caption}>
                  Server Status
                </Text>
                <StatusIndicator
                  compound
                  label="Provisioned"
                  color={statusColors["active"].color}
                />
              </Box>
            </Grid>
            <Box>
              <Text {...fontSize.caption} block>
                Publish Status
              </Text>
              <Box>
                <StatusIndicator
                  compound
                  label={data?.isPublished ?"published" : "not published" }
                  {...statusColors[ data?.isPublished ?"active" : "inactive"]}
                />
              </Box>
            </Box>
            <Spacer space="20px" />
            <Box round="high" background={Theme.PrimarySurface} pad="24px">
              <Text block {...fontSize.caption}>
                Description
              </Text>
              <ReadMore
                text={data?.description || ""}
                bold={true}
                maxLength={200}
              />
            </Box>
            <Spacer space="40px" />
            <Text {...fontSize.h3}>Admin Information</Text>
            <Spacer space="20px" />
            <Grid default="1fr 1fr 1fr">
              <Box>
                <Text {...fontSize.caption}>Admin name</Text>
                <Text bold block>
                  {`${data?.adminInfo?.first_name} ${data?.adminInfo?.middle_name} ${data?.adminInfo?.last_name}`.trim() ||
                    EMPTY_VALUE}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Admin phone number</Text>
                <Text bold block>
                  {data?.adminInfo?.phone || EMPTY_VALUE}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Admin email address</Text>
                <Text bold block>
                  {data?.adminInfo?.email || EMPTY_VALUE}
                </Text>
              </Box>
            </Grid>
            <Spacer space="40px" />
            {urls?.length > 0 && (
              <>
                <Text {...fontSize.h3}>Deployment Information</Text>
                <Spacer space="20px" />
                <Box>
                  <Text {...fontSize.caption}>Urls</Text>
                  <Grid default="1fr 1fr" gap="16px">
                    {urls.map((l, i) => (
                      <Box key={i} vAlign>
                        <Text bold block>
                          <a rel="noreferrer" target="_blank" href={l}>
                            {l}
                          </a>
                        </Text>
                      </Box>
                    ))}
                  </Grid>
                </Box>
              </>
            )}
          </Box>

          <Box className="bl" pad="32px" maxWidth="600px">
            <TemplatePreview />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default TemplateDetails;
