import { call, all } from "redux-saga/effects";

import onCreateStaff from "./createStaff";
import onDeleteStaff from "./deleteStaff";
import onUpdateStaff from "./updateStaff";
import onGetStaff from "./getStaff";

export default function* staffSaga() {
  yield all([
    call(onCreateStaff),
    call(onDeleteStaff),
    call(onUpdateStaff),
    call(onGetStaff),
  ]);
}
