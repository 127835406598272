import React from 'react';
import { Loader, Box } from '@flexisaf/flexibull2';
import Theme from "../../utils/theme";
const SectionLoader = ({ width = '100%', height = '100vh' }) => {
  return (
    <Box width={width} height={height}>
      <Loader section={true} color={Theme.PrimaryColor} />
    </Box>
  );
};
export default SectionLoader;
