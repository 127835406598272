import {
  Box,
  Grid,
  Spacer,
  Input,
  Button,
  Checkbox,
} from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import { useForm } from "react-hook-form";
import { validate } from "../../../pages/subscriptions/utils";
import PasswordValidation from "../../custom/password-validation";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

function TemplateStep2({
  step,
  setStep,
  handleClose,
  templateInformation,
  integrationExtras,
  getDefaultExtraFields,
  adminFields,
  bulkUpdateAdminInformation,
  adminInformation,
}) {
  const [showPasswords, setShowPasswords] = useState(false);
  const {
    register: registerExtraFields,
    handleSubmit: handleSubmitExtraFields,
    watch: watchExtraFields,
    formState: { errors: extraFieldsErrors },
    reset: resetExtraFields,
    setValue: setExtraFieldValue,
    getValues,
  } = useForm({
    defaultValues: {
      ...getDefaultExtraFields(),
    },
  });

  useEffect(() => {
    resetExtraFields(adminInformation);
    // eslint-disable-next-line
  }, [step, adminInformation]);
  return (
    <Swiper active={step === 2}>
      <Box pad="32px">
        <Text {...fontSize.h4}>Admin Information</Text>
        <Spacer space="24px" />
        <Grid default="1fr 1fr" gap="24px">
          {adminFields?.map((field, i) =>
            field.type === "password" && field.field === "password" ? (
              <PasswordValidation
                watch={watchExtraFields}
                register={registerExtraFields}
                validate={validate}
                getValues={getValues}
                setValue={setExtraFieldValue}
                fieldErrors={extraFieldsErrors}
                field={field.field}
                type={showPasswords ? "text" : field.type}
              />
            ) : (
              <Input
                key={i}
                spaceTop
                type={
                  field.type === "password" && showPasswords
                    ? "text"
                    : field.type
                }
                label={field.title}
                iconRight={
                  field.type === "password"
                    ? showPasswords
                      ? "icon-lock-open"
                      : "icon-lock"
                    : undefined
                }
                required={field.required}
                placeholder={`Enter ${field.title}`}
                value={watchExtraFields(field.field)}
                {...registerExtraFields(field.field, {
                  ...(field.required
                    ? {
                        required: `${field.title} is required`,
                      }
                    : {}),

                  ...(field.field === "confirm_password"
                    ? {
                        validate: (val) => {
                          if (watchExtraFields("password") !== val) {
                            return "Passwords do not match";
                          }
                        },
                      }
                    : {}),
                })}
                error={extraFieldsErrors[field.field]?.message}
                onChange={({ target: { value } }) =>
                  setExtraFieldValue(field.field, value)
                }
              />
            )
          )}
        </Grid>
      </Box>
      <Box pad="32px">
        <Text {...fontSize.h4}>Extra Information</Text>
        <Spacer space="12px" />
        <Grid default="1fr" gap="0px">
          <Spacer space="16px" />

          {integrationExtras?.[templateInformation?.product?.product?.id]
            ?.map((field, i) => {
              if (field.field === "url_path") {
                return (
                  <Box margin="20px 0">
                    <Grid default="1fr 2fr" gap="0px">
                      <Input
                        style={{ borderRadius: "5px 0px 0px 5px" }}
                        label={`${templateInformation?.product?.product?.name} url path`}
                        disabled
                        iconRight={null}
                        value="safapply.com/"
                      />

                      <Input
                        key={i}
                        style={{ borderRadius: "0px 5px 5px 0px" }}
                        type={field.type}
                        required
                        placeholder={`Enter ${field.field}`}
                        value={watchExtraFields(field.field)}
                        {...registerExtraFields(field.field, {
                          required: `${field.field} is required`,
                          ...(field.type === "password"
                            ? {
                                minLength: {
                                  value: 8,
                                  message: `${field.field} must have at least 8 characters`,
                                },
                                maxLength: {
                                  value: 30,
                                  message: `${field.field} must not be more than 30 characters`,
                                },
                                pattern: {
                                  value:
                                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}$/,
                                  message: `${field.field} must contain at least one number, special character, capital case and small case letter`,
                                },
                              }
                            : {}),
                        })}
                        error={extraFieldsErrors[field.field]?.message}
                        onChange={({ target: { value } }) =>
                          setExtraFieldValue(field.field, value)
                        }
                      />
                    </Grid>
                  </Box>
                );
              }
              if (field.type === "password") {
                return (
                  <Box>
                    <Grid default="1fr 1fr">
                      <PasswordValidation
                        watch={watchExtraFields}
                        register={registerExtraFields}
                        validate={validate}
                        getValues={getValues}
                        setValue={setExtraFieldValue}
                        fieldErrors={extraFieldsErrors}
                        field={field.field}
                        type={showPasswords ? "text" : field.type}
                      />
                      <Input
                        key={i}
                        spaceTop
                        type={showPasswords ? "text" : "password"}
                        label="Confirm Password"
                        iconRight={
                          field.type === "password"
                            ? showPasswords
                              ? "icon-lock-open"
                              : "icon-lock"
                            : undefined
                        }
                        required
                        placeholder="Enter password"
                        value={watchExtraFields('confirm_password')}
                        {...registerExtraFields('confirm_password', {
                          required: 'confirm password is required',
                          ...(field.field === "password"
                            ? {
                                validate: (val) => {
                                  if (watchExtraFields("password") !== val) {
                                    return "Passwords do not match";
                                  }
                                },
                              }
                            : {}),
                        })}
                        error={extraFieldsErrors['confirm_password']?.message}
                        onChange={({ target: { value } }) =>
                          setExtraFieldValue('confirm_password', value?.trim())
                        }
                      />
                    </Grid>
                    <Checkbox
                      block
                      style={{ marginRight: "0px" }}
                      label={<Text size="12px">show passwords</Text>}
                      onChange={(e) => setShowPasswords(e.target.checked)}
                      checked={showPasswords}
                      size="16px"
                    />
                  </Box>
                );
              }
              return (
                <Box margin="20px 0">
                  <Grid default="1fr">
                    <Input
                      key={i}
                      type={field.type}
                      label={`Organization ${field.field}`}
                      required
                      placeholder={`Enter ${field.field}`}
                      value={watchExtraFields(field.field)}
                      {...registerExtraFields(field.field, {
                        required: `${field.field} is required`,
                        ...(field.type === "password"
                          ? {
                              minLength: {
                                value: 8,
                                message: `${field.field} must have at least 8 characters`,
                              },
                              maxLength: {
                                value: 30,
                                message: `${field.field} must not be more than 30 characters`,
                              },
                              pattern: {
                                value:
                                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}$/,
                                message: `${field.field} must contain at least one number, special character, capital case and small case letter`,
                              },
                            }
                          : {}),
                      })}
                      error={extraFieldsErrors[field.field]?.message}
                      onChange={({ target: { value } }) =>
                        setExtraFieldValue(field.field, value)
                      }
                    />
                  </Grid>
                </Box>
              );
            })}
        </Grid>
      </Box>
      <Grid
        default="auto max-content max-content"
        responsive={false}
        className="bt"
      >
        <Box pad="24px">
          <Button
            onClick={handleClose}
            pale
            color="rgba(0,0,0,0.0)"
            fontColor={Theme.PrimaryGrey}
          >
            Cancel
          </Button>
        </Box>
        <Box pad="24px 0">
          <Button
            pale
            color="rgba(0,0,0,0.0)"
            iconLeft={<i className="flexibull-left" />}
            onClick={() => {
              const formValues = getValues();
              bulkUpdateAdminInformation(formValues);
              setStep(1);
            }}
          >
            Previous
          </Button>
        </Box>
        <Box pad="24px">
          <Button
            onClick={handleSubmitExtraFields((v) => {
              bulkUpdateAdminInformation(v);
              setStep(3);
            })}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Swiper>
  );
}

export default TemplateStep2;
