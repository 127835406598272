import { takeLatest, call } from "redux-saga/effects";
import { logOutUser } from "../reducer";
import { logout } from "../../../utils/helpers";

export function* logOut() {
  yield call(logout);
  // window.location.reload();
}

export default function* onLogout() {
  yield takeLatest(logOutUser.type, logOut);
}
