import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Spacer,
  Button,
  CardSelector,
} from "@flexisaf/flexibull2";
import { useForm } from "react-hook-form";
import { Text } from "@flexisaf/flexibull2/build/typo";
import styled from "styled-components";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import useTagsGet from "../../../hooks/tags/useTagsGet";
import Select from 'react-select';
const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const TemplateStep4 = ({
  step,
  setStep,
  updateAdditionalInformation,
  getDefaultTemplateAdditionalInformation,
  icons,
  templateAdditionalInformation,
  templateInformation,
  handleClose,
  templateOption,
  data,
  saveTemplate,
  saving,
}) => {
  const { register, setValue, watch, reset, getValues, handleSubmit } = useForm(
    {
      defaultValues: {
        ...getDefaultTemplateAdditionalInformation(),
      },
    }
  );

  const { tags, loadingTags } = useTagsGet();

  function getTagsOptions() {
    let options = [];
    tags?.data?.forEach((t) => {
      options.push({ label: t.name, value: t.id });
    });
    return options;
  }

  const handleUpdateTemplate = () => {
    saveTemplate(
      {
        templateData: templateInformation,
        extra: {
          templateTags: getValues("templateTags"),
          templateIcon: getValues("templateIcon"),
        },
        sourceTemplateId: templateOption,
      },
      handleClose
    );
  console.log(templateOption)
  };
console.log(templateOption)
  useEffect(() => {
    reset(templateAdditionalInformation);

    // eslint-disable-next-line
  }, [step, templateAdditionalInformation]);
  useEffect(() => {
    if (data?.tags) {
      setValue(
        "templateTags",
        tags?.data.filter((t) => data?.tags?.includes(t.id))
          ?.map((t) => ({ label: t.name, value: t.id }))
      );
    }
    // eslint-disable-next-line
  }, [data, tags]);

  useEffect(() => {
    if (data?.icon) {
      setValue("templateIcon", { icon: data.icon });
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Swiper active={step === 4}>
      <Box pad="32px">
        <Grid default="1fr" gap="24px">
          <Select
            placeholder="Tags (optional)"
            closeMenuOnSelect={false}
            value={watch("templateTags")}
            isMulti
            isLoading={loadingTags}
            options={getTagsOptions()}
            {...register("templateTags")}
            onChange={(value) => setValue("templateTags", value)}
          />
          <Box>
            <Text block {...fontSize.h4}>
              Select an icon for this template
            </Text>
            <Spacer space="24px" />
            <Grid default="1fr 1fr 1fr 1fr">
              {icons.map((icon) => {
                return (
                  <CardSelector
                    {...register("templateIcon")}
                    key={icon?.icon}
                    required
                    name="blank"
                    size="120px"
                    value={watch("templateIcon")?.icon}
                    view="icon"
                    checked={watch("templateIcon")?.icon === icon?.icon}
                    icon={
                      <i
                        className={`${icon?.icon}`}
                        style={{ fontSize: "30px" }}
                      />
                    }
                    onChange={() => setValue("templateIcon", icon)}
                  />
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Box>

      <Grid
        default="auto max-content max-content"
        responsive={false}
        className="bt"
      >
        <Box pad="24px">
          <Button
            onClick={handleClose}
            pale
            color="rgba(0,0,0,0.0)"
            fontColor={Theme.PrimaryGrey}
          >
            Cancel
          </Button>
        </Box>
        <Box pad="24px 0">
          <Button
            pale
            color="rgba(0,0,0,0.0)"
            iconLeft={<i className="flexibull-left" />}
            onClick={() => {
              if (data?.id) {
                setStep(1);
                return;
              }
              const values = getValues();
              updateAdditionalInformation(values);
              setStep(3);
            }}
          >
            Previous
          </Button>
        </Box>
        <Box pad="24px">
          {data?.id ? (
            <Button progress={saving} onClick={handleUpdateTemplate}>
              Save
            </Button>
          ) : (
            <Button
              onClick={handleSubmit((v) => {
                updateAdditionalInformation(v);
                setStep(5);
              })}
            >
              Preview
            </Button>
          )}
        </Box>
      </Grid>
    </Swiper>
  );
};

export default TemplateStep4;
