import { Tabs, Text } from "@flexisaf/flexibull2";
import { PageHeader } from "../../components/custom/header";

import { DashWrapper } from "../../components/custom/wrappers";
import fontSize from "../../utils/typography";

import UserManagement from "./userManagement";
import RolesManagement from "./rolesManagement";
import {
  CanViewTabs,
  canViewRoles,
  canViewUsers,
} from "../../components/custom/canView";

const Users = (props) => {
  return (
    <>
      <PageHeader>
        <Text {...fontSize.h3}>
          <i className="icon-people" /> User Management
        </Text>
      </PageHeader>
      <DashWrapper className="products">
        <Tabs bgColor="none" tabelColor="none" padContent="0" persist={false}>
          <div label="Users" disabled={CanViewTabs(canViewUsers)}>
            <UserManagement />
          </div>

          <div label="Roles" disabled={CanViewTabs(canViewRoles)}>
            <RolesManagement />
          </div>
        </Tabs>
      </DashWrapper>
    </>
  );
};

export default Users;
