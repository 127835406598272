import { call, put, takeLatest } from "redux-saga/effects";
import {
    togglePLanDemo,
  togglePlanDemoFailure,
  togglePlanDemoSuccess
} from "../reducer";

import { httpToggleIsDemo } from "../../../services/plans";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* toggleIsDemo({ payload }) {
  const { data, onSuccess, onFailure } = payload;
  const response = yield call(httpToggleIsDemo, data);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(togglePlanDemoSuccess(raw));
    if (onSuccess) {
      onSuccess();
    }
    successNotifier("The demo status is updated successfully");
  } else {
    yield put(togglePlanDemoFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to edit this Plan.");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onToggleDemo() {
  yield takeLatest(togglePLanDemo.type, toggleIsDemo);
}
