import {
    Box,
    Text,
    Grid,
    Button,
    Spacer,
    Modal,
    ModalBody,
  } from "@flexisaf/flexibull2";
  import Theme from "../../utils/theme";
  import fontSize from "../../utils/typography";
  
  const DeleteStaffModal = function ({ open, onClose, data, onDelete, deleting }) {
    return (
      <Modal open={open} center={false}>
        <ModalBody width="600px">
          <Box pad="32px">
            <Text {...fontSize.h4} block bold>
              Delete Staff
            </Text>
            <Spacer space="16px" />
            <Text>
              Are you sure you want to delete <strong>{data?.name}</strong> (staff)
            </Text>
          </Box>
          <Grid default="auto max-content" responsive={false} className="bt">
            <Box pad="24px">
              <Button
                onClick={onClose}
                pale
                fontColor={Theme.PrimaryGrey}
                color={Theme.PrimaryGrey}
              >
                <Text bold>No</Text>, Keep Staff
              </Button>
            </Box>
            <Box pad="24px">
              <Button
                progress={deleting}
                onClick={() => onDelete(data?.id, onClose)}
                color={Theme.PrimaryRed}
              >
                <Text bold>Yes</Text>, Delete Staff
              </Button>
            </Box>
          </Grid>
        </ModalBody>
      </Modal>
    );
  };
  export default DeleteStaffModal;
  