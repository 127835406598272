import {
  Box,
  Grid,
  CardSelector,
  Spacer,
  Input,
  Button,
  Switch,
  Select,
  StatusIndicator
} from "@flexisaf/flexibull2";
import { useForm, useFieldArray } from "react-hook-form";
import { getCountries, getStates } from "country-state-picker";
import { Text } from "@flexisaf/flexibull2/build/typo";
import React, { useEffect } from "react";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import { Liner } from "../../../components/custom/wrappers";
import { Swiper } from "../../../components/customer/create-customer/Swiper";
import { validEmail, validPhone, validURL } from "../../../utils/regex";
import { EMPTY_VALUE } from "../../../utils/constants";
import useTagsGet from "../../../hooks/tags/useTagsGet";
import styled from "styled-components";



const StatusIndicatores = styled(StatusIndicator)`
  & .indicator {
    background-color: ${(props) => (props.isPublished ? "red" : "#A1DFCA")};
  }
`;


const countryOptions = getCountries().map((c) => ({
  label: c.name,
  value: c.code,
}));

export function Step1({
  step,
  setStep,
  bulkUpdateCustomerDetails,
  closeModal,
  customerDetails,
  isEdit,
}) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      customerType: "ORGANIZATION",
      name: "",
      email: "",
      isDemo:false,
      phone: "",
      tags: [],
      country: {},
      state: {},
      city: "",
      code: "",
      shortName: "",
    },
  });

  const { tags, loadingTags } = useTagsGet();

  function getTagsOptions() {
    let options = [];
    tags?.data?.forEach((t) => {
      options.push({ label: t.name, value: t.id });
    });
    return options;
  }

  useEffect(() => {
    reset(customerDetails);
    // eslint-disable-next-line
  }, [step, customerDetails]);
console.log(customerDetails)
  return (
    <Swiper active={step === 1}>
      <Box pad="32px">
        <Text {...fontSize.h4}>Customer Type</Text>
        <Spacer space="24px" />
        <Box>
          <Grid
            pad="10px 0"
            default="1fr 1fr 1fr"
            responsive={false}
            gap="10px"
          >
            <CardSelector
              {...register("customerType")}
              name="customerType"
              size="130px"
              value="INDIVIDUAL"
              view="icon"
              space="0"
              label="Individual"
              checked={watch("customerType") === "INDIVIDUAL"}
              icon={<i className="icon-user" />}
              onChange={() => setValue("customerType", "INDIVIDUAL")}
            />
            <CardSelector
              {...register("customerType")}
              name="customerType"
              size="130px"
              value="Private"
              view="icon"
              label="Private"
              space="0"
              checked={watch("customerType") === "ORGANIZATION"}
              icon={<i className="icon-private" />}
              onChange={() => setValue("customerType", "ORGANIZATION")}
            />
            <CardSelector
              {...register("customerType")}
              name="customerType"
              size="130px"
              value="PUBLIC"
              view="icon"
              space="0"
              label="Public"
              checked={watch("customerType") === "PUBLIC"}
              icon={<i className="icon-people" />}
              onChange={() => setValue("customerType", "PUBLIC")}
            />
            <CardSelector
              {...register("customerType")}
              name="customerType"
              size="130px"
              value="NGO"
              view="icon"
              space="0"
              label="NGO"
              checked={watch("customerType") === "NGO"}
              icon={<i className="icon-heart-circle" />}
              onChange={() => setValue("customerType", "NGO")}
            />
            
          </Grid>
        </Box>
      </Box>
      <Box pad="32px" background={Theme.PrimarySurface}>
        <Text {...fontSize.h4}>Customer Details</Text>
        <Spacer space="24px" />
        <Grid default="1fr 1fr" gap="24px">
          <Input
            spaceTop
            label="Customer Name"
            required
            placeholder="Organization/Customer Name"
            value={watch("name")}
            {...register("name", { required: "customer name is required" })}
            error={errors.name?.message}
            onChange={({ target: { value } }) => setValue("name", value)}
          />
          <Input
            spaceTop
            label="Customer Email"
            required
            type="email"
            placeholder="Organization/Customer Email"
            value={watch("email")}
            {...register("email", {
              required: "customer email is required",
              pattern: {
                value: validEmail,
                message: "invalid email address",
              },
            })}
            error={errors.email?.message}
            onChange={({ target: { value } }) => setValue("email", value)}
          />

          <Input
            spaceTop
            label="Phone Number"
            required
            type="tel"
            placeholder="Organization number"
            value={watch("phone")}
            {...register("phone", {
              required: "customer phone is required",
              pattern: {
                value: validPhone,
                message: "invalid phone number",
              },
            })}
            error={errors.phone?.message}
            onChange={({ target: { value } }) => setValue("phone", value)}
          />
          <Input
            spaceTop
            label="Website"
            placeholder="Organization/Customer Website"
            value={watch("website")}
            {...register("website", {
              pattern: {
                value: validURL,
                message: "invalid url",
              },
            })}
            error={errors.website?.message}
            onChange={({ target: { value } }) => setValue("website", value)}
          />
          <Input
            spaceTop
            label="Customer Code"
            placeholder="Customer Code"
            value={watch("code")}
            {...register("code")}
            onChange={({ target: { value } }) => setValue("code", value)}
          />
          <Input
            spaceTop
            label="Customer Shortname"
            placeholder="Customer Shortname"
            value={watch("shortName")}
            {...register("shortName")}
            onChange={({ target: { value } }) => setValue("shortName", value)}
          />
        </Grid>
        <Spacer space="24px" />
        <Select
          spaceTop
          label="Tags"
          width={255}
          isMulti
          value={watch("tags")}
          options={getTagsOptions()}
          isLoading={loadingTags}
          {...register("tags")}
          onChange={(value) => setValue("tags", value)}
        />
      </Box>
      <Box pad="32px">
        <Text {...fontSize.h4}>Organization Location</Text>
        <Spacer space="24px" />
        <Input
          spaceTop
          block
          label="Organization Address"
          required
          value={watch("address")}
          placeholder="Address"
          {...register("address", { required: "Address is required" })}
          error={errors.address?.message}
          onChange={({ target: { value } }) => setValue("address", value)}
        />
        <Spacer space="32px" />
        <Grid default="1fr 1fr" gap="24px">
          <Select
            spaceTop
            label="Country"
            placeholder="Country"
            block
            value={watch("country")}
            options={countryOptions}
            {...register("country")}
            error={errors.country?.message}
            onChange={(value) => setValue("country", value)}
          />
          <Select
            spaceTop
            label="State"
            placeholder="State"
            block
            options={[
              ...(getStates(watch("country")?.value)?.map((s) => ({
                label: s,
                value: s,
              })) || []),
            ]}
            value={watch("state")}
            {...register("state")}
            error={errors.state?.message}
            onChange={(value) => setValue("state", value)}
          />
          <Input
            spaceTop
            label="City"
            value={watch("city")}
            placeholder="City"
            {...register("city")}
            error={errors.city?.message}
            onChange={({ target: { value } }) => setValue("city", value)}
          />
             <Box>
                <Text bold block>
                Is this a demo customer ?
                </Text>
                <Switch
                  label={ watch("isDemo")? "Yes" : "No"}
                  checked={watch("isDemo")}
                  onChange={({ target: { checked } }) => setValue("isDemo", checked)}
                />
              </Box>
               
      
        </Grid>
  
      </Box>
      <Grid default="auto max-content" responsive={false} className="bt">
        <Box pad="24px">
          <Button
            pale
            color="rgba(0,0,0,0.0)"
            fontColor={Theme.PrimaryGrey}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
        </Box>
        <Box pad="24px">
          <Button
            iconRight={<i className="flexibull-right" />}
            onClick={handleSubmit((v) => {
              bulkUpdateCustomerDetails(v);
              setStep(2);
            })}
          >
            {isEdit ? "Edit" : "Add"} Contact Persons
          </Button>
        </Box>
      </Grid>
    </Swiper>
  );
}

export function Step2({
  step,
  setStep,
  updateCustomerContactDetails,
  closeModal,
  customerContacts,
}) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });

  const addRow = () => {
    append({
      salutation: null,
      name: "",
      email: "",
      phone: "",
    });
  };

  useEffect(() => {
    if (watch('contacts')?.length === 0) {
      addRow();
    }
    // eslint-disable-next-line
  }, [fields]);

  useEffect(() => {
    reset({ contacts: customerContacts })
    // eslint-disable-next-line
  }, [step, customerContacts])

  return (
    <Swiper active={step === 2}>
      {fields.map((_, index) => {
        return (
          <Box key={index} pad="32px" background={Theme.PrimarySurface}>
            {index === 0 ? (
              <>
                <Text {...fontSize.h4}>Primary Contact Person</Text>
                <Spacer space="24px" />
              </>
            ) : (
              <>
                <Grid default="3fr 1fr" gap="24px">
                  <Text {...fontSize.h4}>Additional Contact ({index})</Text>
                  <Box align="right">
                    <Button
                      style={{ marginTop: "2px" }}
                      onClick={() => remove(index)}
                      color={Theme.PrimaryRed}
                    >
                      <i className="icon-trash" />
                    </Button>
                  </Box>
                </Grid>

                <Spacer space="24px" />
              </>
            )}
            <Box>
              <Grid default="1fr 3fr" gap="24px">
                <Select
                  spaceTop
                  label="Salutation"
                  required
                  value={watch(`contacts.${index}.salutation`)}
                  block
                  {...register(`contacts.${index}.salutation`, {
                    validate(v) {
                      return !!v?.value
                    }
                  })}
                  error={errors.contacts?.[index]?.['salutation'] && 'Salutation is required'}
                  onChange={(value) =>
                    setValue(`contacts.${index}.salutation`, value)
                  }
                  options={[
                    { label: "Mr.", value: "Mr." },
                    { label: "Mrs.", value: "Mrs." },
                    { label: "Miss", value: "Miss" },
                  ]}
                />
                <Input
                  spaceTop
                  label="Full Name"
                  required
                  value={watch(`contacts.${index}.name`)}
                  {...register(`contacts.${index}.name`, { required: 'name is required' })}
                  error={errors.contacts?.[index]?.['name']?.message}
                  onChange={({ target: { value } }) =>
                    setValue(`contacts.${index}.name`, value)
                  }
                  placeholder="Point of contact"
                />
              </Grid>
              <Spacer space="24px" />
              <Grid default="1fr 1fr" gap="24px">
                <Input
                  spaceTop
                  label="Phone Number"
                  required
                  value={watch(`contacts.${index}.phone`)}
                  {...register(`contacts.${index}.phone`, {
                    required: 'phone is required',
                    pattern: {
                      value: validPhone,
                      message: "invalid phone number",
                    },
                  })}
                  error={errors.contacts?.[index]?.['phone']?.message}
                  onChange={({ target: { value } }) =>
                    setValue(`contacts.${index}.phone`, value)
                  }
                  placeholder="Contact's phone"
                />
                <Input
                  spaceTop
                  label="Email"
                  required
                  value={watch(`contacts.${index}.email`)}
                  {...register(`contacts.${index}.email`, {
                    required: 'email is required',
                    pattern: {
                      value: validEmail,
                      message: "invalid email address",
                    },
                  })}
                  error={errors.contacts?.[index]?.['email']?.message}
                  onChange={({ target: { value } }) =>
                    setValue(`contacts.${index}.email`, value)
                  }
                  placeholder="Contact's email"
                />
              </Grid>
            </Box>
          </Box>
        );
      })}
      <Box pad="32px">
        <Spacer space="32px" />
        <Box align="right">
          <Button onClick={addRow}>Add more contacts</Button>
        </Box>
      </Box>
      <Grid
        default="auto max-content max-content"
        responsive={false}
        className="bt"
        gap="0"
      >
        <Box pad="24px">
          <Button
            pale
            color="rgba(0,0,0,0.0)"
            fontColor={Theme.PrimaryGrey}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
        </Box>
        <Box pad="24px 0">
          <Button
            pale
            iconLeft={<i className="flexibull-left" />}
            color="rgba(0,0,0,0.0)"
            onClick={() => setStep(1)}
          >
            Organization Details
          </Button>
        </Box>
        <Box pad="24px">
          <Button
            iconRight={<i className="flexibull-right" />}
            onClick={handleSubmit((v) => {
              updateCustomerContactDetails(v.contacts);
              setStep(3);
            })}
          >
            Review Customer Details
          </Button>
        </Box>
      </Grid>
    </Swiper>
  );
}

export function Step3({
  step,
  setStep,
  customerDetails,
  customerContacts,
  saving,
  onSaveCustomer,
  closeModal,
}) {
  console.log(customerDetails)
  return (
    <Swiper active={step === 3}>
      <Box pad="32px">
        <Box
          round="high"
          background={Theme.PrimarySurface}
          className="bordered"
          pad="24px"
        >
          <Text {...fontSize.h4}>Customer Details</Text>
          <Spacer space="24px" />
          <Grid default="auto max-content" gap="24px">
            <Box>
              <Text {...fontSize.caption} block>
                Name
              </Text>
              <Text bold>{customerDetails.name}</Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Customer Type
              </Text>
              <Text bold>

          {customerDetails.customerType === 'ORGANIZATION'
            ? 'PRIVATE'
            : customerDetails.customerType || 'NIL'}
              </Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Email
              </Text>
              <Text bold>{customerDetails.email}</Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Phone Number
              </Text>
              <Text bold>{customerDetails.phone}</Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Customer Short Name
              </Text>
              <Text bold>{customerDetails.shortName || EMPTY_VALUE}</Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Customer Code
              </Text>
              <Text bold>{customerDetails.code || EMPTY_VALUE}</Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Website
              </Text>
              <Text bold>{customerDetails.website || EMPTY_VALUE}</Text>
            </Box>
          </Grid>
          <Liner margin="24px 0" borderstyle="dashed" />
          <Box>
            <Text {...fontSize.caption} block>
             Address
            </Text>
            <Text bold>{customerDetails.address}</Text>
          </Box>
          <Spacer space="24px" />
          <Grid default="1fr 1fr 1fr" gap="24px">
            <Box>
              <Text {...fontSize.caption} block>
                Country
              </Text>
              <Text bold>{customerDetails.country?.value}</Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                State
              </Text>
              <Text bold>{customerDetails.state?.value}</Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                City
              </Text>
              <Text bold>{customerDetails.city}</Text>
            </Box>
            <StatusIndicatores
                  compound
                  label={customerDetails?.isDemo ? "Real customer" : "Demo customer"}
                />
          </Grid>
        </Box>
        <Box round="high" pad="24px" className="bordered">
          <Text {...fontSize.h4}>Contact Persons</Text>
          <Spacer space="24px" />
          {customerContacts.map((contact, i) => (
            <>
              {i !== 0 && <Liner margin="16px 0" borderstyle="dashed" />}
              <Grid default="auto max-content" gap="24px">
                <Box>
                  <Text {...fontSize.caption} block>
                    Contact
                  </Text>
                  <Text bold>{contact.name}</Text>
                </Box>
                <Box align="right">
                  <Text block>{contact.email}</Text>
                  <Text bold>{contact.phone}</Text>
                </Box>
              </Grid>
            </>
          ))}
        </Box>
      </Box>
      <Grid
        default="auto max-content max-content"
        responsive={false}
        className="bt"
        gap="0"
      >
        <Box pad="24px">
          <Button
            pale
            color="rgba(0,0,0,0.0)"
            fontColor={Theme.PrimaryGrey}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </Box>
        <Box pad="24px 0">
          <Button
            pale
            iconLeft={<i className="flexibull-left" />}
            color="rgba(0,0,0,0.0)"
            onClick={() => setStep(2)}
          >
            Contact Persons
          </Button>
        </Box>
        <Box pad="24px">
          <Button
            iconRight={<i className="flexibull-right" />}
            onClick={onSaveCustomer}
            progress={saving}
          >
            Save Customer
          </Button>
        </Box>
      </Grid>
    </Swiper>
  );
}
