import { call, all } from "redux-saga/effects";

import onCreateRole from "./createRole";
import onDeleteRole from "./deleteRole";
import onUpdateRole from "./updateRole";
import onGetRoles from "./getRoles";
import onGetPrivilegeModules from "./getPrivilegeModules";

export default function* rolesSaga() {
  yield all([
    call(onCreateRole),
    call(onDeleteRole),
    call(onUpdateRole),
    call(onGetRoles),
    call(onGetPrivilegeModules),
  ]);
}
