import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid, Spacer, Button, CardSelector } from '@flexisaf/flexibull2';
import { Text } from '@flexisaf/flexibull2/build/typo';
import styled from 'styled-components';
import Theme from '../../../utils/theme';
import fontSize from '../../../utils/typography';

const Swiper = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

const TemplateStep3 = ({
  step,
  setStep,
  getDefaultTemplateOption,
  templateOption,
  updateTemplateOption,
  updateAdditionalInformation,
  allTemplates,
  handleClose,
}) => {

  
  const { register, setValue, watch, reset, getValues, handleSubmit } = useForm(
    {
      defaultValues: {
        ...getDefaultTemplateOption(),
      },
    }
  );

  useEffect(() => {
    reset(templateOption);
    // eslint-disable-next-line
  }, [step, templateOption]);

  return (
    <Swiper active={step === 3}>
      <Box pad="32px">
        <Text {...fontSize.h4}>
          Start afresh or copy over configurations from an already existing
          template.
        </Text>

        <Spacer space="24px" />
        <Grid default="1fr 1fr 1fr">
          <CardSelector
            {...register('templateOption')}
            name="blank"
            value={null}
            view="icon"
            space="0"
            checked={watch('templateOption') === null}
            icon={
              <Box>
                <Text style={{ textAlign: 'center' }} block>
                  Blank Template
                </Text>
                <Text
                  {...fontSize.caption}
                  style={{ textAlign: 'center' }}
                  block
                >
                  preview{' '}
                  <i
                    className="icon-external-link"
                    style={{ fontSize: '10px' }}
                  />
                </Text>
              </Box>
            }
            onChange={() => setValue('templateOption', null)}
          />
          {allTemplates?.map((template) => {
            return (
              <CardSelector
                {...register("templateOption")}
                key={template.id}
                name={template.title}
                value={template.id}
                view="icon"
                label={
                  <Box>
                    <Text style={{ textAlign: "center" }} block>
                      {template.title}
                    </Text>
                    <Text
                      {...fontSize.caption}
                      style={{ textAlign: "center" }}
                      block
                    >
                      preview <i className="icon-external-link" />
                    </Text>
                  </Box>
                }
                checked={watch("templateOption")?.id === template.id}
                icon={
                  <i
                    className={`${template?.icon}`}
                    style={{ fontSize: fontSize.templateIconLarge.size }}
                  />
                }
                onChange={() =>
                  setValue("templateOption", {
                    id: template.id,
                    icon: template.icon,
                  })
                }
              />
            );
          })}
        </Grid>
      </Box>

      <Grid
        default="auto max-content max-content"
        responsive={false}
        className="bt"
      >
        <Box pad="24px">
          <Button
            onClick={handleClose}
            pale
            color="rgba(0,0,0,0.0)"
            fontColor={Theme.PrimaryGrey}
          >
            Cancel
          </Button>
        </Box>
        <Box pad="24px 0">
          <Button
            pale
            color="rgba(0,0,0,0.0)"
            iconLeft={<i className="flexibull-left" />}
            onClick={() => {
              const option = getValues();
              updateTemplateOption(option);
              setStep(2);
            }}
          >
            Previous
          </Button>
        </Box>
        <Box pad="24px">
          <Button
            onClick={handleSubmit((v) => {
              updateTemplateOption(v);
              updateAdditionalInformation({
                templateIcon: v?.templateOption,
              });
              setStep(4);
            })}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Swiper>
  );
};

export default TemplateStep3;
