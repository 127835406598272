import React, { useState } from 'react';
import { Text } from '@flexisaf/flexibull2';
import { MAX_DESC_LENGTH } from '../../utils/constants';
import Theme from '../../utils/theme';

export const ReadMore = ({
  text,
  maxLength = MAX_DESC_LENGTH,
  bold = false,
}) => {
  const [readMore, setReadMore] = useState(false);
  return (
    <>
      <Text bold={bold}>{readMore ? text : text?.substring(0, maxLength)}</Text>
      {text?.length >= maxLength && (
        <Text
          bold
          color={Theme.PrimaryColor}
          style={{ cursor: 'pointer' }}
          onClick={() => setReadMore((prev) => !prev)}
        >
          {readMore ? ' show less' : '...show more'}
        </Text>
      )}
    </>
  );
};
