 import { Box, Text, Grid, Button } from '@flexisaf/flexibull2';
import { useEffect, useState } from 'react';
import { PageHeader } from '../../components/custom/header';
import { DashWrapper } from '../../components/custom/wrappers';
import Theme from '../../utils/theme';
import fontSize from '../../utils/typography';
import { Tabs } from '@flexisaf/flexibull2/build/tabs';
import { Link, useParams } from 'react-router-dom';
import SubscriptionDetails from './support/subscriptionDetails';
// import SubscriptionInvoices from "./support/SubscriptionInvoices";
import SubscriptionPaymentSettings from "./support/SubscriptionPaymentSettings";
import urls from '../../utils/config';
import request from '../../utils/request';
import { errorNotifier } from '../../utils/helpers';
import { DropDown } from '@flexisaf/flexibull2/build/dropdown';
import { darken } from 'polished';

import NotFound from '../../components/custom/notFound';
import SectionLoader from '../../components/custom/sectionLoader';

const Subscription = () => {
  const params = useParams();
  const [loadingData, setLoadingData] = useState(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  const fetchSubscription = async (id) => {
    try {       
      const res = await request({
        url: `${urls.API_BASE_URL}/api/subscriptions/${id}?include-customer=true&include-plan=true`,
      });
      if (!res.success) {
        throw res.message;
      }
      setSubscriptionDetails(res.raw);
    } catch (error) {
      errorNotifier('Unable to fetch subscription');
      setSubscriptionDetails(null);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      setLoadingData(true);
      fetchSubscription(params.id);
    }
  }, [params.id]);

  if (loadingData) return <SectionLoader />;
  if (!subscriptionDetails && !loadingData) {
    return (
      <NotFound
        title="Subscription not found"
        info="Seems you are trying to access a subscription that does not exit"
        link="subscriptions"
      />
    );
  }

  return (
    <>
      <PageHeader>
        <Text {...fontSize.h3}>
          <i className="flexibull-clock" /> Subscription
        </Text>
      </PageHeader>
      <DashWrapper className="products">
        <Box>
          <Box pad="32px" className="bb">
            <Grid default="auto max-content">
              <Box>
                <Text {...fontSize.caption} block>
                  <Link to="/subscriptions">
                    <i className="icon-left" /> back to subscription list
                  </Link>
                </Text>
                <Box margin="8px 0 0 0">
                  <Text {...fontSize.h3} color={Theme.PrimaryDark} block>
                    #SUB-
                    {String(subscriptionDetails?.subscriptionNumber).padStart(
                      5,
                      "0"
                    )}
                  </Text>
                  <Text bold>{subscriptionDetails?.customer?.name}</Text> -{" "}
                  <Text>{subscriptionDetails?.plan?.product?.name}</Text>
                </Box>
              </Box>
              <Box>
                {/* <Button
                  pale
                  iconLeft={<i className="icon-block-outline" />}
                  spaceRight
                  onClick={() => setDelModal(true)}
                >
                  Retire Product
                </Button> */}
                <Button
                  style={{
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                  }}
                >
                  Edit Subscription
                </Button>
                <DropDown
                  label={
                    <Button
                      color={darken(0.1, Theme.PrimaryColor)}
                      pad="0 10px"
                      style={{
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                      }}
                      icon={<i className="flexibull-angle-down" />}
                    />
                  }
                  menuAlign="bottom right"
                  menuList={[
                    {
                      onClick: () => console.log("even me sef see am"),
                      label: "Edit Information",
                      iconLeft: "icon-pencil",
                    },
                    {
                      onClick: () => console.log("even me sef see am"),
                      label: "Change plan",
                      iconLeft: "icon-reply-outline",
                    },
                    {
                      onClick: () => console.log("even me sef see am"),
                      label: "Extend Plan",
                      iconLeft: "icon-eject-outline",
                    },
                  ]}
                />
              </Box>
            </Grid>
          </Box>
          <Box className="product-wrapper">
            <Tabs
              bgColor="none"
              labelColor="none"
              padContent="0"soure 
              persist={false}
            >
              <div label="Details">
                <SubscriptionDetails data={subscriptionDetails} />
              </div>
              {/* <div label="Invoices">
                <SubscriptionInvoices />
              </div> */}
              <div label="Payment Settings">
                <SubscriptionPaymentSettings />
              </div>
            </Tabs>
          </Box>
        </Box>
      </DashWrapper>
    </>
  );
};

export default Subscription;
