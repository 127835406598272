import request from "../utils/request";
import urls from "../utils/config";

const { API_BASE_URL } = urls || {};


export const httpGetInvoices = (id) => {
  let url = `${API_BASE_URL}/api/subscriptions/invoices?subscriptionId=${id}`;
  return request({
    url,
    method: "get",
  });
};
