import { Grid, Box, Text } from "@flexisaf/flexibull2";
import { Spacer } from "@flexisaf/flexibull2/build/layout";
import { StatusIndicator } from "@flexisaf/flexibull2/build/statusindicator";
import fontSize from "../../../utils/typography";
import Theme from "../../../utils/theme";
import { DashCardee, DashCell } from "../../../components/custom/wrappers";
import { LabelStatus } from "../../../components/barGenerator";
import moment from "moment";



const ProductDetails = ({ data, subscriptionSummary, expiringSoon }) => {
  return (
    <Box className="bb">
      <Box maxWidth="1000px">
        <Grid default="3fr 2fr">
          <Box pad="32px">
            <Grid default="1fr 1fr">
              <Box>
                <Text {...fontSize.caption}>Project Manager</Text>
                <Text bold block>
                  {data?.productManager}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Notification Email</Text>
                <a href="mailto: info@safsims.com">
                  <Text bold block>
                    {data?.notificationEmail}
                  </Text>
                </a>
              </Box>
            </Grid>
            <Spacer space="16px" />
            <p>
              <Text>{data?.description}</Text>
            </p>
            <Spacer space="16px" />
            <Grid default="1fr 1fr 1fr">
              <Box>
                <Text {...fontSize.caption} block>
                  Brand Color
                </Text>
                <Text bold block>
                  <StatusIndicator
                    label={data?.brandColor?.toUpperCase() || "#0066f5"}
                    color={data?.brandColor || "#0066f5"}
                    compound
                  />
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Integration URL</Text>
                <Text bold block>
                  {data?.integrationUrl}
                </Text>
              </Box>
              <Box>
              <Box>
                <Text {...fontSize.caption}>Product type</Text>
                <Text bold block>
                {data?.isDemo ? "Demo product" :"Live product"}
                </Text>
              </Box>
                </Box>
            </Grid>
          </Box>
          <Box className="bl" pad="32px">
            <DashCardee elevate="none" className="dashcards">
              <DashCell col={1} colSpan={1} row={1} rowSpan={2}>
                <Box pad="16px">
                  <Text block>Subscriptions</Text>
                  <Spacer size="8px" />
                  <Text size="3em">
                    {subscriptionSummary?.allSubscriptions || 0}
                  </Text>
                </Box>
              </DashCell>
              <DashCell
                className="bb bl"
                col={3}
                colSpan={1}
                row={1}
                rowSpan={1}
              >
                <Box pad="16px" align="right">
                  <Text {...fontSize.h4} color={Theme.PrimaryColor}>
                    {subscriptionSummary?.activeSubscriptions || 0}
                  </Text>{" "}
                  <Text {...fontSize.caption}>Active</Text>
                </Box>
              </DashCell>
              <DashCell
                className="bb bl"
                col={3}
                colSpan={1}
                row={2}
                rowSpan={1}
              >
                <Box pad="16px" align="right">
                  <Text {...fontSize.h4} color={Theme.PrimaryRed}>
                    {subscriptionSummary?.expiredSubscriptions || 0}
                  </Text>{" "}
                  <Text {...fontSize.caption}>Expired</Text>
                </Box>
              </DashCell>
              <DashCell className="bl" col={3} colSpan={1} row={3} rowSpan={1}>
                <Box pad="16px" align="right">
                  <Text {...fontSize.h4} color={Theme.PrimaryGreen}>
                    {subscriptionSummary?.futureSubscriptions || 0}
                  </Text>{" "}
                  <Text {...fontSize.caption}>Future</Text>
                </Box>
              </DashCell>
            </DashCardee>
            <Spacer space="24px" />
            {expiringSoon?.length > 0 && (
              <DashCardee pale>
                <DashCell col={1} colSpan={3} row={1} rowSpan={2} notCentered>
                  <Box pad="16px">
                    <Text {...fontSize.caption}>Expiring</Text>
                    <Spacer space="4px" />
                    <Text {...fontSize.h4} block>
                      Within 30 Days
                    </Text>
                    <Spacer space="24px" />
                    <Box>
                      <Grid
                        responsive={false}
                        default="auto max-content"
                        gap="16px 4px"
                      >
                        {expiringSoon?.map((s) => (
                          <>
                            <LabelStatus
                              tag={Theme.PrimaryBlue}
                              tagSize="8px"
                              truncate
                            >
                              {s?.customer?.name}
                            </LabelStatus>
                            <Box align="right">
                              <Text {...fontSize.caption}>
                                {moment(s.endDate).format("D MMM YYYY")}
                              </Text>
                            </Box>
                          </>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </DashCell>
              </DashCardee>
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProductDetails;
