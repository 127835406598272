import React from "react";
import { Box, Text, Spacer } from "@flexisaf/flexibull2";
import NotFound from "../../assets/images/403.svg";

export const Page403 = () => (
  <>
    <Box
      width="100%"
      height="70vh"
      display="grid"
      style={{ placeItems: "center" }}
    >
      <Box align="center" margin="auto">
        <img src={NotFound} alt="404 not found" />
        <Box margin="20px auto 0" align="center" maxWidth="300px">
          <Text bold block size="18px">
            Access Denied
          </Text>
          <Spacer space="15px" />
          <Text block>You don't have permission to view this page.</Text>
        </Box>
      </Box>
    </Box>
  </>
);
