import { call, put, takeLatest } from "redux-saga/effects";
import {
  deleteRoleStart,
  deleteRoleFailure,
  deleteRoleSuccess,
} from "../reducer";

import { httpDeleteRole } from "../../../services/roles";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* deleteRole({ payload }) {
  const { id, onSuccess, onFailure } = payload;
  const response = yield call(httpDeleteRole, id);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(deleteRoleSuccess());

    if (onSuccess) {
      onSuccess();
    }
    successNotifier("Role deleted successfully");
  } else {
    yield put(deleteRoleFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to delete this role");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onDeleteRole() {
  yield takeLatest(deleteRoleStart.type, deleteRole);
}
