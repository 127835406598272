import "./components/fonts/fontello/css/safbilling.css";
import Theme from "./utils/theme";
import Pages from "./pages";

function App() {
  return (
    <Pages
      theme={Theme}
      onLogout={() => null}
      onlineStatus="online"
    />
  );
}

export default App;
