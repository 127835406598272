import { call, put, takeLatest } from "redux-saga/effects";
import {
  updatePlanStart,
  updatePlanFailure,
  updatePlanSuccess,
} from "../reducer";

import { httpPutPlan } from "../../../services/plans";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* updatePlan({ payload }) {
  const { data, onSuccess, onFailure } = payload;
  const response = yield call(httpPutPlan, data);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(updatePlanSuccess(raw));
    if (onSuccess) {
      onSuccess();
    }
    successNotifier("Plan updated successfully");
  } else {
    yield put(updatePlanFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to edit this Plan.");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onUpdatePlan() {
  yield takeLatest(updatePlanStart.type, updatePlan);
}
