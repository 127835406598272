import { useDispatch } from "react-redux";
import { toggleProductDemo } from "../../redux/products/reducer";

const useToggleDemo = () => {
  const dispatch = useDispatch();  


  const toggleProdDemo =({data, onSuccess, onFailure }) => {
    dispatch(toggleProductDemo({ data, onSuccess, onFailure }));
  }
   
  return {
 toggleProdDemo,
  };
};

export default useToggleDemo;
