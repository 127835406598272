import { lighten } from "polished";
import Theme from "./theme";

export const statusColors = {
    active: {color: Theme.PrimaryGreen},
    inactive: {color: Theme.PrimaryRed},
    canceled: {color: Theme.PrimaryRed},
    retired: {color: Theme.PrimaryRed},
    wip: {color: lighten(0.2, Theme.PrimaryGrey)},
    future: {color: lighten(0.2, Theme.PrimaryBlue)},
    expired: {color: lighten(0.2, Theme.PrimaryGrey)},
    onboarding: {color: Theme.PrimaryBlue, pulse: true},
    warning: {color: Theme.PrimaryOrange},
    issue: {color: Theme.PrimaryRed},
    error: {color: Theme.PrimaryRed},
    inprogress: {color: Theme.PrimaryBlue, pulse: true},
}
