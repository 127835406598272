import { useDispatch, useSelector } from "react-redux";

import {
  updateStaffStart,
  createStaffStart,
  deleteStaffStart,
} from "../../redux/staff/reducer";
import {
  selectIsCreatingStaff,
  selectIsUpdatingStaff,
  selectIsDeletingStaff,
} from "../../redux/staff/selectors";

const useStaffEditor = () => {
  const dispatch = useDispatch();
  const isCreating = useSelector(selectIsCreatingStaff);
  const isUpdating = useSelector(selectIsUpdatingStaff);
  const isDeleting = useSelector(selectIsDeletingStaff);

  const createStaff = ({ staff, onSuccess, onFailure }) => {
    dispatch(createStaffStart({ staff, onSuccess, onFailure }));
  };

  const updateStaff = ({ data, onSuccess, onFailure }) => {
    dispatch(updateStaffStart({ data, onSuccess, onFailure }));
  };

  const deleteStaff = ({ id, onSuccess, onFailure }) => {
    dispatch(deleteStaffStart({ id, onSuccess, onFailure }));
  };

  return {
    isCreating,
    isUpdating,
    isDeleting,
    createStaff,
    updateStaff,
    deleteStaff,
  };
};

export default useStaffEditor;
