import { Box, Grid, StatusIndicator } from '@flexisaf/flexibull2';
import { Text } from '@flexisaf/flexibull2/build/typo';
import { useEffect, useState, useCallback } from "react";
import fontSize from '../../../utils/typography';
import useDebounce from '../../../hooks/useDebounce';
import request from '../../../utils/request';
import urls from '../../../utils/config';
import { errorNotifier } from '../../../utils/helpers';
import Theme from "../../../utils/theme";

import TemplateStep1 from '../../../components/template/steps/TemplateStep1';
import TemplateStep2 from '../../../components/template/steps/TemplateStep2';
import TemplateStep3 from '../../../components/template/steps/TemplateStep3';
import TemplateStep4 from '../../../components/template/steps/TemplateStep4';
import TemplateStep5 from '../../../components/template/steps/TemplateStep5';
import useTagsGet from "../../../hooks/tags/useTagsGet";

const adminFields = [
  {
    field: "first_name",
    type: "text",
    required: true,
    title: "First Name",
  },
  {
    field: "last_name",
    type: "text",
    required: true,
    title: "Last Name",
  },
  {
    field: "middle_name",
    type: "text",
    title: "Middle Name",
  },
  {
    field: "phone",
    type: "text",
    required: true,
    title: "Phone Number",
  },
  {
    field: "email",
    type: "email",
    required: true,
    title: "Email Address",
  },
];

const icons = [
  {
    id: 1,
    icon: "icon-school",
  },
  {
    id: 2,
    icon: "icon-social-network",
  },
  {
    id: 3,
    icon: "icon-ngo",
  },
  {
    id: 4,
    icon: "icon-government",
  },
];

const getDefaultExtraFields = () => {
  const defaultData = {};
  adminFields.forEach((f) => {
    defaultData[f.field] = "";
  });
  return defaultData;
};

const getDefaultTemplateInformation = () => ({
  templateTitle: "",
  product: null,
  templateDescription: "",
});

const getDefaultTemplateOption = () => ({
  templateOption: null,
});

const getDefaultTemplateAdditionalInformation = () => ({
  templateTags: null,
  templateIcon: null,
});

const TemplateEditor = (props) => {
  const [step, setStep] = useState(1);

  const [integrationExtras, setIntegrationExtras] = useState({});
  const [loadingIntegrationExtras, setLoadingIntegrationExtras] =
    useState(false);
  const [errorLoadingIntegrationExtras, setErrorLoadingIntegrationExtras] =
    useState(false);

  const [searchProduct, setSearchProduct] = useState("");
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [products, setProducts] = useState({ data: [] });
  const debouncedProductTerm = useDebounce(searchProduct, 1000);

  const [templateInformation, setTemplateInformation] = useState({
    ...getDefaultTemplateInformation(),
  });
  const [adminInformation, setAdminInformation] = useState({
    ...getDefaultExtraFields(),
  });
  const [templateOption, setTemplateOption] = useState({
    ...getDefaultTemplateOption(),
  });

  const [templateAdditionalInformation, setTemplateAdditionalInformation] =
    useState({
      ...getDefaultTemplateAdditionalInformation(),
    });
    const [isPublished,setIsPublished] = useState(false)

  const { tags } = useTagsGet();

  const fetchProducts = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/products?`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}&${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setProducts(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch customers");
    } finally {
      setLoadingProducts(false);
    }
  };

  const fetchIntegrationExtra = async ({
    id: productId,
    integrationUrl: url,
  } = {}) => {
    setLoadingIntegrationExtras(true);
    try {
      if (!url || integrationExtras[productId]) return;
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setIntegrationExtras((c) => ({
        ...c,
        [productId]: res.raw?.extraSchema || [],
      }));
      setAdminInformation({ ...getDefaultExtraFields() });
    } catch (error) {
      errorNotifier("Unable to fetch integration details");
      setErrorLoadingIntegrationExtras(true);
    } finally {
      setLoadingIntegrationExtras(false);
    }
  };

  const bulkUpdateTemplateInformation = (details) => {
    return setTemplateInformation({ ...templateInformation, ...details });
  };

  const bulkUpdateAdminInformation = (details) => {
    return setAdminInformation({ ...adminInformation, ...details });
  };

  const updateTemplateOption = (templateDetail) => {
    return setTemplateOption({ ...templateOption, ...templateDetail });
  };

  const updateAdditionalInformation = (info) => {
    return setTemplateAdditionalInformation({
      ...templateAdditionalInformation,
      ...info,
    });
  };

  const handleClose = () => {
    setAdminInformation({
      ...getDefaultExtraFields(),
    });
    setTemplateOption({
      ...getDefaultTemplateOption(),
    });
    setTemplateAdditionalInformation({
      ...getDefaultTemplateAdditionalInformation(),
    });
    setStep(1);
    props.closeModal();
    setTemplateInformation({
      ...getDefaultTemplateInformation(),
    });
  };

  const getTagslabels = useCallback(
    (tagIds) => {
      let tagslabels = [];
      tags?.data.forEach((tag) => {
        tagIds.forEach((id) => {
          if (id === tag.id) {
            tagslabels = [...tagslabels, { label: tag.name, value: tag.id }];
          }
        });
      });
      return tagslabels;
    },
    [tags]
  );

  useEffect(() => {
    setLoadingProducts(true);
    fetchProducts({ search: debouncedProductTerm });
    // eslint-disable-next-line
  }, [debouncedProductTerm]);

  useEffect(() => {
    if (props?.data?.id) {
      setTemplateInformation({
        ...getDefaultTemplateInformation(),
        templateTitle: props?.data?.title,
        templateDescription: props?.data?.description,
        product: {
          label: props.data?.product?.name,
          value: props.data?.product?.id,
          product: props.data?.product,
        },
      });
setIsPublished(props.data.isPublished)
      setTemplateAdditionalInformation({
        ...getDefaultTemplateAdditionalInformation(),
        templateTags: getTagslabels(props?.data?.tags),
        templateIcon: icons[0],
      });
    }
  }, [getTagslabels, props.data]);

  return (
    <Box>
      <Grid default="auto max-content" responsive={false} className="bb">
        <Box pad="24px 32px">
          <Text {...fontSize.h3} uppercase>
            {props?.data?.id ? "Edit" : "Create a New"} Template
          </Text>
        </Box>
        <Box pad="24px" className="spaced">
          {props?.data?.id ? (
            <>
              {[1, 2].map((key) => (
                <StatusIndicator
                  pulse={step === key}
                  color={step >= key ? Theme.PrimaryColor : Theme.PrimaryLight}
                />
              ))}
            </>
          ) : (
            <>
              {[1, 2, 3, 4, 5].map((key) => (
                <StatusIndicator
                  pulse={step === key}
                  color={step >= key ? Theme.PrimaryColor : Theme.PrimaryLight}
                />
              ))}
            </>
          )}
        </Box>
      </Grid>
      {step === 1 && (
        <TemplateStep1
        setIsPublished={setIsPublished}
        isPublished={isPublished}
          step={step}
          setStep={setStep}
          handleClose={handleClose}
          bulkUpdateTemplateInformation={bulkUpdateTemplateInformation}
          getDefaultTemplateInformation={getDefaultTemplateInformation}
          templateInformation={templateInformation}
          integrationExtras={integrationExtras}
          fetchIntegrationExtra={fetchIntegrationExtra}
          products={products}
          setSearchProduct={setSearchProduct}
          loadingProducts={loadingProducts}
          templateData={props?.data}
          loadingIntegrationExtras={loadingIntegrationExtras}
          errorLoadingIntegrationExtras={errorLoadingIntegrationExtras}
          data={props?.data}
        />
      )}
      {step === 2 && (
        <TemplateStep2
          step={step}
          setStep={setStep}
          data={props?.data}
          bulkUpdateAdminInformation={bulkUpdateAdminInformation}
          adminInformation={adminInformation}
          handleClose={handleClose}
          templateInformation={templateInformation}
          integrationExtras={integrationExtras}
          getDefaultExtraFields={getDefaultExtraFields}
          adminFields={adminFields}
        />
      )}

      {step === 3 && (
        <TemplateStep3
          step={step}
          setStep={setStep}
          getDefaultTemplateOption={getDefaultTemplateOption}
          updateTemplateOption={updateTemplateOption}
          templateOption={templateOption}
          updateAdditionalInformation={updateAdditionalInformation}
          allTemplates={props.allTemplates.data}
          handleClose={handleClose}
        />
      )}
      {step === 4 && (
        <TemplateStep4
          step={step}
          setStep={setStep}
          data={props?.data}
          templateInformation={templateInformation}
          templateOption={templateOption}
          updateAdditionalInformation={updateAdditionalInformation}
          getDefaultTemplateAdditionalInformation={
            getDefaultTemplateAdditionalInformation
          }
          templateAdditionalInformation={templateAdditionalInformation}
          icons={icons}
          handleClose={handleClose}
          saveTemplate={props.onSubmit}
          saving={props.saving}
        />
      )}
      {step === 5 && (
        <TemplateStep5
          step={step}
          setStep={setStep}
          templateInformation={templateInformation}
          templateOption={templateOption}
          adminInformation={adminInformation}
          templateAdditionalInformation={templateAdditionalInformation}
          handleClose={handleClose}
          isPublished={isPublished}
          saveTemplate={props.onSubmit}
          saving={props.saving}
        />
      )}
    </Box>
  );
};
export default TemplateEditor;
