import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLoadingInvoices:false,
  invoices:null,
  error: null,
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    fetchInvoicesStart: (state, action) => {
   
        state.isLoadingInvoices = true;

      state.error = null;
    },
    fetchInvoicesSuccess: (state, action) => {
      state.isLoadingInvoices = false;
      state.invoices = action.payload;
      state.error = null;
    },
    fetchInvoicesFailure: (state, action) => {
      state.isLoadingInvoices = false;
      state.error = action.payload;
      state.invoices = null;
    },
  },
});

export const {
  fetchInvoicesFailure,
  fetchInvoicesStart,
  fetchInvoicesSuccess,
} = invoicesSlice.actions;

const invoicesReducer = invoicesSlice.reducer;

export default invoicesReducer;
