const PrimaryTheme = '#3a61e9';

const Theme = {
  PrimaryColor: PrimaryTheme,
  PrimaryDark: "#222f3e",
  PrimaryGrey: "#576574",
  PrimaryInputOutline: "#C6CADE",
  PrimaryLight: "#C8D6E5",
  PrimaryBorderColor: "#CBD5E4",
  SecondaryBorderColor: "#ECECEC",
  PrimaryRadius: "5px",
  PrimaryRed: "#fb5e9a",
  PrimaryOrange: "#E98B3A",
  PrimaryBlue: "#437DC1",
  PrimaryGreen: "#8FE2C9",
  noLabelUpperCase: true,
  PrimaryFont: "'Nunito', sans-serif",

  PrimaryFontSize: "13px",
  PrimaryFontColor: "#262E3D",
  SecondaryFontColor: "#8893A6",

  PrimarySurface: "#F2F4F6",
  SecondarySurface: "#f2f7fd",
  PrimaryFade: "#FAFAFA",

  AccentRed: "#EDA8F0",
};

export default Theme;
