import { call, all } from "redux-saga/effects";

import onGetTags from "./getTags";
import onUpdateTag from "./updateTag";
import onCreateTag from "./createTag";
import onDeleteTag from "./deleteTag";

export default function* tagsSaga() {
  yield all([
    call(onGetTags),
    call(onUpdateTag),
    call(onDeleteTag),
    call(onCreateTag),
  ]);
}
