import { createSelector } from "@reduxjs/toolkit";

const selectStaff = (state) => state.staff;

export const selectIsLoadingStaff = createSelector(
  [selectStaff],
  (staff) => staff.isLoadingStaff
);

export const selectIsCreatingStaff = createSelector(
  [selectStaff],
  (staff) => staff.isCreatingStaff
);

export const selectIsUpdatingStaff = createSelector(
  [selectStaff],
  (staff) => staff.isUpdatingStaff
);

export const selectIsDeletingStaff = createSelector(
  [selectStaff],
  (staff) => staff.isDeletingStaff
);

export const selectStaffData = createSelector(
  [selectStaff],
  (staff) => staff.staff
);
