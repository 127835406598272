import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectIsLoadingSubscriptions,
    selectSubscriptionsData ,
} from "../../redux/subscriptions/selectors";
import { fetchSubscriptionsStart } from "../../redux/subscriptions/reducer";
import usePaginationWrapper from "../usePaginationWrapper";

import useDebounce from "../useDebounce";

const useSubscriptionsGet = (additionalOptions) => {
  const dispatch = useDispatch();
  const subscriptions = useSelector(selectSubscriptionsData);
  const isLoadingSubscriptions = useSelector(selectIsLoadingSubscriptions);
  const [searchTerm, setSearchTerm] = useState("");
  const [forceFetch, setForceFetch] = useState(0);
  const [reloadPage, setReloadPage] = useState(true);
  const debouncedTerm = useDebounce(searchTerm, 1000);

  const { page, pageSize, setPage, setPageSize } = usePaginationWrapper();
console.log(additionalOptions)

  const fetchSubscriptions = useCallback(() => {
    const options = {
      search: debouncedTerm || "",
      offset: (page - 1) * pageSize,
      limit: pageSize,
      isDemo:additionalOptions.isDemo,
      PROVISIONED:additionalOptions.PROVISIONED,
      productId:additionalOptions.productID,
      planId:additionalOptions.planID,
      status:additionalOptions.status
      
    };
    dispatch(
      fetchSubscriptionsStart({
        data: options,
        options: {
          reloadPage,
        },
      })
    );
  }, [dispatch, debouncedTerm, page, pageSize, reloadPage,additionalOptions.status, additionalOptions.isDemo,additionalOptions.PROVISIONED,additionalOptions.productID,additionalOptions.planID],);

  const refetch = useCallback(() => setForceFetch((prev) => prev + 1), []);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions, forceFetch, debouncedTerm, page, pageSize]);
  return {
    subscriptions,
    loadingSubscriptions: isLoadingSubscriptions,
    setSearchTerm,
    searchTerm,
    setPage,
    setPageSize,
    page,
    pageSize,
    refetch,
    setReloadPage,
  };
};

export default useSubscriptionsGet;