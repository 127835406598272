import { all, call } from "redux-saga/effects";
import authSagas from "./auth/sagas";
import dashboardSagas from "./dashboard/sagas";
import subscriptionsSagas from "./subscriptions/sagas";
import tagsSaga from "./tags/sagas";
import staffSaga from "./staff/sagas";
import rolesSaga from "./roles/sagas";
import usersSaga from "./users/sagas";
import productsSaga from "./products/sagas";
import planSaga from "./plans/sagas";
import invoicesSagas from "./invoice";
import customersSagas from "./customers/sagas";

export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(dashboardSagas),
    call(subscriptionsSagas),
    call(tagsSaga),
    call(staffSaga),
    call(rolesSaga),
    call(planSaga),
    call(usersSaga),
    call(productsSaga),
    call(invoicesSagas),
    call(customersSagas)
  ]);
}
