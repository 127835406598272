import request from "../utils/request";
import urls from "../utils/config";

const { API_BASE_URL } = urls || {};

export const httpGetSubscriptionsExpiringSoon = () => {
  const url = `${API_BASE_URL}/api/subscriptions/expiring-soon`;

  return request({
    url,
    method: "get",
  });
};
export const httpToggleIsDemo = (options) => {
  const {id , isDemo} = options
  const url = `${API_BASE_URL}/api/subscriptions/${id}/demo-status`;

  return request({
    url,
    method: "put",
    data: {isDemo},
  });
};

export const httpGetSubscriptions = (options) => {
  let url = `${urls.API_BASE_URL}/api/subscriptions?include-customer=true&include-plan=true`;
  return request({
    url,
    method: "get",
    data: options,
  });
};
