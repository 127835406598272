import React, { useEffect } from "react";
import { Liner } from "../../components/custom/wrappers";
import { TextArea } from "@flexisaf/flexibull2/build/textarea";
import {
  Box,
  Grid,
  Spacer,
  Input,
  Select,
  Button,
  RadioButton,
  Text,
} from "@flexisaf/flexibull2";
import Theme from "../../utils/theme";
import fontSize from "../../utils/typography";
import { useForm } from "react-hook-form";
import styled from "styled-components";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const PlanStep1 = ({
  step,
  setStep,
  closeModal,
  getDefaultPlan,
  bulkUpdatePlanInformation,
  planInformation,
  autoRenew,
  integrationUrl,
  setAutoRenew,
  unitCost,
  setUnitCost
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      ...getDefaultPlan(),
    },
  });



  useEffect(() => {
    reset(planInformation);
    // eslint-disable-next-line
  }, [step, planInformation]);



  return (
    <>
      <Swiper active={step === 1}>
        <Box pad="32px">
          <Text {...fontSize.h4}>Plan Details</Text>
          <Spacer space="24px" />

          <Grid default="1fr 1fr" gap="24px">
            <Input
              spaceTop
              label="Name"
              required
              placeholder="Plan Name"
              value={watch("name")}
              {...register("name", { required: "name is required" })}
              error={errors.name?.message}
              onChange={({ target: { value } }) => setValue("name", value)}
            />

            <Select
              spaceTop
              label="Plan Type"
              required
              placeholder="Select Billing Type"
              options={["FLAT", "PER_HEAD"].map((v) => ({
                label: v,
                value: v,
              }))}
              block
              value={watch("planType")}
              {...register("planType", {
                required: "plan type is required",
              })}
              error={errors.planType?.message}
              onChange={(value) => setValue("planType", value)}
            />
          </Grid>
          <Spacer space="24px" />

          <Spacer space="24px" />
          <TextArea
            spaceTop
            label="Description"
            placeholder="Plan Description"
            block
            value={watch("description")}
            {...register("description")}
            onChange={({ target: { value } }) => setValue("description", value)}
          />

          <Spacer space="17px" />
          <Liner borderstyle="dashed" />
          <Box></Box>
        </Box>
        <Box pad=" 0 32px">
          <Spacer space="10px" />

          <Grid default="1fr 1fr">
            <Select
              label="Bill Every"
              spaceTop
              required
              placeholder="Duration"
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((v) => ({
                label: v,
                value: v,
              }))}
              block
              value={watch("duration")}
              {...register("duration", {
                required: "duration  is required",
              })}
              error={errors.duration?.message}
              onChange={(value) => setValue("duration", value)}
            />

            <Select
              spaceTop
              required
              placeholder="Duration Type"
              options={[
                { label: "DAYS", value: "DAYS" },
                { label: "WEEKS", value: "WEEKS" },
                { label: "MONTHS", value: "MONTHS" },
                { label: "YEARS", value: "YEARS" },
              ]}
              block
              value={watch("durationType")}
              {...register("durationType", {
                required: "duration type is required",
              })}
              error={errors.durationType?.message}
              onChange={(value) => setValue("durationType", value)}
            />
          </Grid>
          <Spacer space="10px" />

          <RadioButton
            label="Auto renew"
            block
            checked={autoRenew}
            onChange={() => setAutoRenew(!autoRenew)}
          />
          <Grid default="1fr 1fr" style={{ width: "100%" }}>
            <RadioButton
              label="Expires after a specified no of billing cycle"
              checked={!autoRenew}
              onChange={() => setAutoRenew(!autoRenew)}
            />
            {!autoRenew && (
              <Input
                style={{ width: "100%" }}
                spaceTop
                label="Billing Cycle"
                required={!autoRenew}
                placeholder="Count"
                type="number"
                width="100%"
                block
                value={autoRenew ? "0" : watch("billingCycleFrequency")}
                {...register("billingCycleFrequency")}
                error={errors.billingCycleFrequency?.message}
                onChange={({ target: { value } }) =>
                  setValue("billingCycleFrequency", value)
                }
              />
            )}
          </Grid>
          <Spacer space="24px" />

          <Grid default="1fr 1fr" gap="24px">
            <Select
              spaceTop
              label="Billing Currency"
              required
              placeholder="Billing Currency "
              options={[
                { label: "NGN", value: "NGN" },
                { label: "USD", value: "USD" },
              ]}
              block
              value={watch("billingCycleCurrrency")}
              {...register("billingCycleCurrrency", {
                required: "billing cycle  currency  is required",
              })}
              error={errors.billingCycleCurrrency?.message}
              onChange={(value) => setValue("billingCycleCurrrency", value)}
            />

            <Input
              spaceTop
              label="Unit Cost"
              type="number"
              required
              placeholder="Plan unit cost"
              value={watch("unitCost")}
              {...register("unitCost", {
                required: "Unit cost is required",
              })}
           
              error={errors.unitCost?.message}
              onChange={({ target: { value } }) => {
                setValue("unitCost", value);
              }}
            />
          </Grid>

          <Spacer space="20px" />
          { planInformation.unitCost  > 0?
          <Input
              spaceTop
              label="Set the Free Trial Period (in days)"
              type="text"
            width="100%"
              placeholder="0"
              value={watch("freeTrial")}
              {...register("freeTrial")}
              error={errors.name?.message}
              onChange={({ target: { value } }) => setValue("freeTrial", value)}
         
            /> :""}
             <Spacer space="24px" />
        </Box>

        <Grid default="auto max-content" reponsize={false} className="bt">
          <Box pad="24px">
            <Button
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              onClick={handleSubmit((v) => {
                bulkUpdatePlanInformation(v);
                !integrationUrl?.integrationUrl ? setStep(3) : setStep(2);
              })}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Swiper>
    </>
  );
};

export default PlanStep1;
