import { Text, Box } from '@flexisaf/flexibull2';
import { transparentize } from 'polished';
import styled from 'styled-components';
import Theme from '../utils/theme';


const GeneratorWrapper = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.9em;

    & li{
        display: grid;
        grid-template-columns: 30% auto 20%;
        grid-gap: 16px;
        height: 42px;
        border-bottom: 1px solid ${props => props.theme.PrimaryBorderColor};
        align-items: center;
        &:last-child{
            border-bottom: none;
        }
    }
`;

const BarIndicator = styled.div`
    height: 8px;
    background: none;
    position: relative;
    
    &:before{
        content: '';
        position:absolute;
        border-radius: 2px;
        top:0;
        left:0;
        display: block;
        height: inherit;
        width: ${props => props.percentage}%;
        background-color: ${props => props.theme.PrimaryColor};
    }
`;

export const LabelStatus = styled(Text)`
    &:before{
        display: inline-block;
        width: ${props => props.tagSize ? props.tagSize : '6px'};
        height: ${props => props.tagSize ? props.tagSize : '6px'};
        content: '';
        border-radius: 50%;
        background: ${props => props.tag};
        margin-right: 8px;
    }
`;

export const BarGenerator = props => {
    const max = Math.max.apply(Math, props.data.map(function (o) { return o.totalsubscriptions; }));
    return <GeneratorWrapper>
        {props.data.map((elem, index) => {
            return <li key={`bar-sub-${index}`}><LabelStatus truncate tag="#3A61E9">{elem.name}</LabelStatus><BarIndicator percentage={100 * elem.totalsubscriptions / max} /><Box align="right"><Text>{elem.totalsubscriptions}</Text></Box></li>
        })}
    </GeneratorWrapper>
}

export const GenericBarGenerator = props => {
    const max = Math.max.apply(Math, props?.data?.map(function (o) { return o.value; }));
    return <GeneratorWrapper>
        {props?.data?.map((elem, index) => {
            return <li key={`bar-sub-${index}`}><LabelStatus truncate tag="#3A61E9">{elem.label}</LabelStatus><BarIndicator percentage={100 * elem.value / max} /><Box align="right"><Text>{elem.value}</Text></Box></li>
        })}
    </GeneratorWrapper>
}

const SimpleWrapper = styled.div`
    margin: 10px 5px;
    border-radius: 20px;
    padding: 16px;
    background: ${props => transparentize(0.2, props.theme.SecondarySurface) };
    box-shadow: inset 0 0 8px rgba(0,0,0,0.1);
`;
const LineWrapper = styled.div`
    height: 2px;
    background: #ccc;
    position: relative;

    &:before{
        display: block;
        height: 8px;
        width: 2px;
        background:  ${props => props.color};
        top: -3px;
        left: 0;
        content: '';
        position: absolute;
    }
    &:after{
        display: block;
        height: 8px;
        width: 2px;
        background:  #ccc;
        top: -3px;
        right: 0;
        content: '';
        position: absolute;
    }

    & .lining{
        height: 2px; 
        width: ${props => props.percentage}%;
        background: ${props => props.color};
        position: relative;
        &:before{
        display: block;
        height: 8px;
        width: 2px;
        background: ${props => props.color};
        top: -3px;
        right: 0;
        content: '';
        position: absolute;
        }
    }
`;


export const LineGenerator = props => {
    let myColor = Theme.PrimaryColor;

    switch (true) {
        case (props.percentage > 90):
            myColor = Theme.PrimaryRed;
            break;
        case (props.percentage > 75):
            myColor = Theme.PrimaryOrange;
            break;
        default:
            myColor = Theme.PrimaryColor;
    }
    return <SimpleWrapper><LineWrapper {...props} color={myColor}>
        <div className='lining' />
    </LineWrapper></SimpleWrapper>
}