import { createSelector } from "@reduxjs/toolkit";

const selectProducts = (state) => state.products;

export const  selectIsLoadingProducts= createSelector(
    [selectProducts],
    (products) => products.isLoadingProducts
  );

  export const selectProductsData = createSelector(
    [selectProducts],
    (products) => products.products
  );
