import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingStaff: true,
  isCreatingStaff: false,
  isUpdatingStaff: false,
  isDeletingStaff: false,
  isLoadingSingleStaff: false,
  staff: [],
  error: null,
};

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    fetchStaffStart: (state, action) => {
      state.isLoadingStaff = true;
      state.error = null;
    },
    fetchStaffSuccess: (state, action) => {
      state.isLoadingStaff = false;
      state.staff = action.payload;
    },
    fetchStaffFailure: (state, action) => {
      state.isLoadingStaff = false;
      state.staff = [];
      state.error = action.payload;
    },
    createStaffStart: (state, action) => {
      state.isCreatingStaff = true;
      state.error = null;
    },
    createStaffSuccess: (state) => {
      state.isCreatingStaff = false;
    },
    createStaffFailure: (state, action) => {
      state.isCreatingStaff = false;
      state.error = action.payload;
    },
    updateStaffStart: (state, action) => {
      state.isUpdatingStaff = true;
    },
    updateStaffSuccess: (state) => {
      state.isUpdatingStaff = false;
    },
    updateStaffFailure: (state, action) => {
      state.isUpdatingStaff = false;
      state.error = action.payload;
    },
    deleteStaffStart: (state, action) => {
      state.isDeletingStaff = true;
    },
    deleteStaffSuccess: (state) => {
      state.isDeletingStaff = false;
    },
    deleteStaffFailure: (state, action) => {
      state.isDeletingStaff = false;
      state.error = action.payload;
    },
  },
});

export const {
  createStaffFailure,
  createStaffStart,
  createStaffSuccess,
  deleteStaffFailure,
  deleteStaffStart,
  deleteStaffSuccess,
  fetchStaffFailure,
  fetchStaffStart,
  fetchStaffSuccess,
  updateStaffFailure,
  updateStaffStart,
  updateStaffSuccess,
} = staffSlice.actions;

const staffReducer = staffSlice.reducer;

export default staffReducer;
