import { call, put, takeLatest } from "redux-saga/effects";
import {
  deletePlanStart,
  deletePlanFailure,
  deletePlanSuccess,
} from "../reducer";

import { httpDeletePlan } from "../../../services/plans";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* deletePlan({ payload }) {
  const { id, onSuccess, onFailure } = payload;
  const response = yield call(httpDeletePlan, id);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(deletePlanSuccess());

    if (onSuccess) {
      onSuccess();
    }
    successNotifier("Plan deleted successfully");
  } else {
    yield put(deletePlanFailure(raw));
    networkErrorHandler(statusCode, message.message || "Unable to delete this Plan");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onDeletePlan() {
  yield takeLatest(deletePlanStart.type, deletePlan);
}
