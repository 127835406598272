import { call, put, takeLatest } from "redux-saga/effects";
import {
  updateStaffStart,
  updateStaffFailure,
  updateStaffSuccess,
} from "../reducer";

import { httpPutStaff } from "../../../services/staff";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* updateStaff({ payload }) {
  const { data, onSuccess, onFailure } = payload;
  const response = yield call(httpPutStaff, data);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(updateStaffSuccess(raw));
    if (onSuccess) {
      onSuccess();
    }
    successNotifier("Staff updated successfully");
  } else {
    yield put(updateStaffFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to edit this staff.");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onUpdateStaff() {
  yield takeLatest(updateStaffStart.type, updateStaff);
}
