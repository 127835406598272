import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchDashboardDataFailure,
  fetchDashboardDataStart,
  fetchDashboardDataSuccess,
} from "../reducer";

import { httpGetDashboardData } from "../../../services/dashboard";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getDashboardData() {
  const response = yield call(httpGetDashboardData);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchDashboardDataSuccess(raw));
  } else {
    yield put(fetchDashboardDataFailure(raw));
    networkErrorHandler(
      statusCode,
      message ||
        "Unable to fetch dashboard summary. Please check your internet connection or try again later."
    );
  }
}

export default function* onGetDashboardData() {
  yield takeLatest(fetchDashboardDataStart.type, getDashboardData);
}
