import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingRoles: false,
  isCreatingRole: false,
  isUpdatingRole: false,
  isDeletingRole: false,
  isLoadingSingleRole: false,
  isLoadingPrivileges: false,
  privilegeModules: [],
  roles: null,
  error: null,
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    fetchRolesStart: (state, action) => {
      state.isLoadingRoles = true;
      state.error = null;
    },
    fetchRolesSuccess: (state, action) => {
      state.isLoadingRoles = false;
      state.roles = action.payload;
      state.error = null;
    },
    fetchRolesFailure: (state, action) => {
      state.isLoadingRoles = false;
      state.error = action.payload;
      state.roles = null;
    },
    createRoleStart: (state, action) => {
      state.isCreatingRole = true;
      state.error = null;
    },
    createRoleSuccess: (state, action) => {
      state.isCreatingRole = false;
    },
    createRoleFailure: (state, action) => {
      state.isCreatingRole = false;
      state.error = action.payload;
    },
    updateRoleStart: (state, action) => {
      state.isUpdatingRole = true;
    },
    updateRoleSuccess: (state) => {
      state.isUpdatingRole = false;
    },
    updateRoleFailure: (state, action) => {
      state.isUpdatingRole = false;
      state.error = action.payload;
    },
    deleteRoleStart: (state, action) => {
      state.isDeletingRole = true;
    },
    deleteRoleSuccess: (state) => {
      state.isDeletingRole = false;
    },
    deleteRoleFailure: (state, action) => {
      state.isDeletingRole = false;
      state.error = action.payload;
    },
    fetchPrivilegesStart: (state) => {
      state.isLoadingPrivileges = true;
    },
    fetchPrivilegesSucess: (state, action) => {
      state.isLoadingPrivileges = false;
      state.privilegeModules = action.payload;
    },
    fetchPrivilegesFailure: (state, action) => {
      state.isLoadingPrivileges = false;
      state.privilegeModules = null;
      state.error = action.payload;
    },
  },
});

export const {
  createRoleFailure,
  createRoleStart,
  createRoleSuccess,
  deleteRoleFailure,
  deleteRoleStart,
  deleteRoleSuccess,
  fetchRolesFailure,
  fetchRolesStart,
  fetchRolesSuccess,
  updateRoleFailure,
  updateRoleStart,
  updateRoleSuccess,
  fetchPrivilegesFailure,
  fetchPrivilegesStart,
  fetchPrivilegesSucess,
} = rolesSlice.actions;

const rolesReducer = rolesSlice.reducer;

export default rolesReducer;
