import { Notify } from '@flexisaf/flexibull2/build/notify';
import moment from "moment";
import { DEFAULT_ERROR_MESSAGE, ERROR, SUCCESS, TOP_RIGHT, WARNING } from './constants';

const getLocalItem = (item) => localStorage.getItem(item);

const setLocalItem = (item, value) => localStorage.setItem(item, value);

const clearLocalItem = (item) => localStorage.clear(item);

export const getLocalAccessToken = () => getLocalItem('sb_access_token');
export const getLocalRefreshToken = () => getLocalItem('sb_refresh_token');
export const getLocalUser = () => JSON.parse(getLocalItem("sb_user") || "{}");

export const setLocalAccessToken = (value) => setLocalItem('sb_access_token', value);
export const setLocalRefreshToken = (value) => setLocalItem('sb_refresh_token', value);
export const setLocalUser = (value) => setLocalItem('sb_user', value);

export const clearLocalAccessToken = () => clearLocalItem('sb_access_token');
export const clearLocalRefreshToken = () => clearLocalItem('sb_refresh_token');
export const clearLocalUser = () => clearLocalItem('sb_user');

export const getParentId = (adminLevels, currentOrder) => {
  const t = adminLevels.filter((level) => {
    if (currentOrder === 1) {
      return true;
    }
    return level.levelOrder === currentOrder - 1;
  })[0];

  return t?.id;
};

export const logout = () => {
  clearLocalAccessToken();
  clearLocalRefreshToken();
  clearLocalUser();

  // window.location.reload();
};

export const stringToHslColor = (text = '', s = 50, l = 80) => {
  const str = text.replace(' ', '');
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = Math.abs(hash) % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export const calculateOffset = (page, pageSize) => {
  const offSet = (page - 1) * pageSize;
  return offSet;
}

const STORE_PREFIX = 'SAFBILLING_STORE';

export const typeGenerator = (typeName) => ({
  request: `${STORE_PREFIX}_${typeName}`,
  success: `${STORE_PREFIX}_${typeName}_SUCCESS`,
  failure: `${STORE_PREFIX}_${typeName}_FAILED`,
});

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    initialValue
  );
};

export const successNotifier = (message) => {
  Notify(message || DEFAULT_ERROR_MESSAGE, {
    position: TOP_RIGHT,
    status: SUCCESS,
  });
}

export const errorNotifier = (message) => {
  Notify(message || DEFAULT_ERROR_MESSAGE, {
    position: TOP_RIGHT,
    status: ERROR,
  });
}

export const warningNotifier = (message) => {
  Notify(message || DEFAULT_ERROR_MESSAGE, {
    position: TOP_RIGHT,
    status: WARNING,
  });
}

export const thousandFormatter = (num) => new Intl.NumberFormat().format(num);
export const dollarFormatter = (num) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
}).format(num);


export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

// Subscriptions
export const filterExpiredSubscriptions = (subscriptions) => {
  let filteredSubscriptions = [];
  let totalNumberOfSubscriptions = subscriptions.length;
  const currentDate = moment();
  for (let index = 0; index < totalNumberOfSubscriptions; index++) {
    const endAt = moment(subscriptions[index]?.endDate);
    const daysLeft = endAt.diff(currentDate, "days");
    if (daysLeft > 0 && filteredSubscriptions.length < 7) {
      filteredSubscriptions = [...filteredSubscriptions, subscriptions[index]];
    }
  }
  return filteredSubscriptions;
}

export const isValidEmail = (email) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
