import {
    Box,
    Grid,
    Select,
    Button,
  } from "@flexisaf/flexibull2";
  import { Text } from "@flexisaf/flexibull2/build/typo";
  import styled from "styled-components";
  import { useEffect, useState } from "react";
  import Theme from "../../../utils/theme";
  import fontSize from "../../../utils/typography";
  import { useForm } from "react-hook-form";
  import useDebounce from "../../../hooks/useDebounce";
  import request from "../../../utils/request";
  import urls from "../../../utils/config";
  import { errorNotifier } from "../../../utils/helpers";
  
  const Swiper = styled.div`
    display: ${(props) => (props.active ? "block" : "none")};
  `;
  
  
  const ChangePlan = (props) => {
    const [searchPlan, setSearchPlan] = useState('');
    const [loadingPlans, setLoadingPlans] = useState(true);
    const [plans, setPlans] = useState({ data: [] });
    const debouncedPlanTerm = useDebounce(searchPlan, 1000);
  
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
      reset,
      getValues,
      setValue,
    } = useForm({
      defaultValues: {
        plan: null
      },
    });
  
    const fetchPlans = async (options = {}) => {
      try {
        let url = `${urls.API_BASE_URL}/api/plans?`;
        const unifiedOptions = Object.keys(options)
          .filter(op => options[op])
          .map(op => `${op}=${options[op]}`)
          .join('&');
        if (unifiedOptions) {
          url = `${url}&${unifiedOptions}`
        }
        const res = await request({ url });
        if (!res.success) {
          throw res.message;
        }
        setPlans(res.raw);
      } catch (error) {
        errorNotifier("Unable to fetch customers");
      } finally {
        setLoadingPlans(false);
      }
    };

    useEffect(() => {
      setLoadingPlans(true)
      fetchPlans({
        search: debouncedPlanTerm,
        productId: props.data?.plan?.product?.id
      })
    // eslint-disable-next-line
    }, [debouncedPlanTerm]);
  
    const handleClose = () => {
      reset({ plan: null });
      props.closeModal();
    }
  
    const handleSubmitSubscription = async () => {
      const formValues = getValues();
      const submissionData = {
        "planId": formValues.plan.value,
      }
      props.onSubmit(submissionData, handleClose)
    }

    useEffect(() => {
        if (props?.data?.id) {
          reset({
            plan: { label: props.data?.plan?.name, value: props.data?.plan?.id },
          })
        }
      // eslint-disable-next-line
      }, [props.data]);
  
    return (
      <Box>
        <Grid default="auto max-content" responsive={false} className="bb">
          <Box pad="24px 32px">
            <Text {...fontSize.h3} uppercase>
              Change Plan
            </Text>
          </Box>
        </Grid>
        <Swiper active>
          <Box pad="32px">
              <Select
                spaceTop
                label="Service Plan"
                required
                placeholder="Select a plan"
                block
                isClearable
                options={plans.data.map(d => ({ label: d.name, value: d.id, plan: d }))}
                value={watch("plan")}
                {...register("plan", {
                  required: "plan is required",
                })}
                error={errors.plan?.message}
                onChange={(value) => setValue("plan", value)}
                onInputChange={(e) => setSearchPlan(e)}
                isLoading={loadingPlans}
              />
          </Box>
  
          <Grid default="auto max-content" responsive={false} className="bt">
            <Box pad="24px">
              <Button onClick={handleClose} pale color="rgba(0,0,0,0.0)" fontColor={Theme.PrimaryGrey}>
                Cancel
              </Button>
            </Box>
            <Box pad="24px">
              <Button progress={props.saving} onClick={handleSubmit(handleSubmitSubscription)}>Change Plan</Button>
            </Box>
          </Grid>
        </Swiper>

      </Box>
    );
  };
  export default ChangePlan;
  