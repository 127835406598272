import { Box, Grid, StatusIndicator } from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { useEffect, useState } from "react";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import DiscountStep1 from "../../../components/discount/steps/discountStep1";
import DiscountStep2 from "../../../components/discount/steps/discountStep2";

const getDefaultDiscountInformation = () => ({
  name: "",
  code: "",
  description: "",
  typeValue: null,
  type: "FLAT",
  expiryDate: null,
  assignedPlans: "ALL",
  plans: null,
  status: true,
});

const getDefaultDiscountRedemptionSettings = () => ({
  redemptionType: "ONE_TIME",
  numberOfRedemptions: null,
  maximumRedemptions: null,
});

const DiscountEditor = (props) => {
  const [step, setStep] = useState(1);
  const [discountInformation, setDiscountInformation] = useState({
    ...getDefaultDiscountInformation(),
  });
  const [discountRedemptionSettings, setDiscountRedemptionSettings] = useState({
    ...getDefaultDiscountRedemptionSettings(),
  });

  const bulkUpdateDiscountInformation = (details) => {
    return setDiscountInformation({ ...discountInformation, ...details });
  };

  const bulkUpdateDiscountRedemptionSettings = (details) => {
    return setDiscountRedemptionSettings({
      ...discountRedemptionSettings,
      ...details,
    });
  };

  const handleClose = () => {
    setDiscountInformation({
      ...getDefaultDiscountInformation(),
    });
    setDiscountRedemptionSettings({
      ...getDefaultDiscountRedemptionSettings(),
    });
    setStep(1);
    props.closeModal();
  };

  const onSubmit = (v) => {
    const payload = {...discountInformation, ...v };
    payload.numberOfRedemptions = +payload.numberOfRedemptions;
    payload.maximumRedemptions = +payload.maximumRedemptions;
    payload.plans = payload.plans?.map((plan) => plan.value);
    payload.status = payload.status ? 'ACTIVE' : 'INACTIVE';
    props.onSave(payload)
  }
  useEffect(() => {
    if (props?.data?.id){
      const editData = {...props.data};
      setDiscountInformation({
        name: editData?.name || "",
        code: editData?.code || "",
        description: editData?.description || "",
        typeValue: editData?.typeValue || null,
        type: editData?.type || "FLAT",
        expiryDate: editData?.expiryDate ? editData?.expiryDate?.split('T')?.[0] : null,
        assignedPlans: editData?.assignedPlans || "ALL",
        plans: editData?.plans?.map((plan) => ({ value: plan })) || null,
        status: editData?.status === 'ACTIVE' ? true : false,

      })
      setDiscountRedemptionSettings({
        redemptionType: props.data?.redemptionType || "ONE_TIME",
        numberOfRedemptions: props.data?.numberOfRedemptions || null,
        maximumRedemptions: props.data?.maximumRedemptions || null,
      })

    }
  }, [props.data])
  return (
    <Box>
      <Grid default="auto max-content" responsive={false} className="bb">
        <Box pad="24px 32px">
          <Text {...fontSize.h3} uppercase>
            {props?.data?.id ? "Edit" : "Create a New"} Discount
          </Text>
        </Box>
        <Box pad="24px" className="spaced">
          {[1, 2].map((key) => (
            <StatusIndicator
              pulse={step === key}
              color={step >= key ? Theme.PrimaryColor : Theme.PrimaryLight}
            />
          ))}
        </Box>
      </Grid>

      {step === 1 && (
        <DiscountStep1
          step={step}
          setStep={setStep}
          discountInformation={discountInformation}
          getDefaultDiscountInformation={getDefaultDiscountInformation}
          handleClose={handleClose}
          bulkUpdateDiscountInformation={bulkUpdateDiscountInformation}
        />
      )}

      {step === 2 && (
        <DiscountStep2
          step={step}
          setStep={setStep}
          discountRedemptionSettings={discountRedemptionSettings}
          getDefaultDiscountRedemptionSettings={
            getDefaultDiscountRedemptionSettings
          }
          onSubmit={onSubmit}
          handleClose={handleClose}
          bulkUpdateDiscountRedemptionSettings={
            bulkUpdateDiscountRedemptionSettings
          }
          saving={props.saving}
        />
      )}
    </Box>
  );
};

export default DiscountEditor;
