import React from "react";
import {
  Box,
  Button,
  Table,
  Modal,
  ModalBody,
  Text,
} from "@flexisaf/flexibull2";
import { useState } from "react";
import TagEditor from "./TagEditor";
import DeleteTag from "./DeleteTag";
import { DropDown } from "@flexisaf/flexibull2/build/dropdown";
import fontSize from "../../utils/typography";
import { EmptyState } from "@flexisaf/flexibull2/build/emptystates";
import SectionLoader from "../../components/custom/sectionLoader";
import useTagsGet from "../../hooks/tags/useTagsGet";
import useTagsEditor from "../../hooks/tags/useTagsEditor";
import CanView, {
  CanFilter,
  Permissions,
} from "../../components/custom/canView";

export default function Tags() {
  const { loadingTags, tags, refetch } = useTagsGet();
  const { createTag, deleteTag, isCreating, isDeleting, isEditing, updateTag } =
    useTagsEditor();
  const [showModal, setShowModal] = useState({ show: false, data: {} });
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    data: {},
  });


  const handleSaveTag = async (name,  productId, cb, setProduct, setName) => {
    const isEdit = !!showModal?.data?.id;

    if (isEdit) {
      updateTag({
        data: { id: showModal?.data?.id, name, productId},
        onSuccess: () => {
          refetch();
          cb();
          setName("")
         setProduct(null)
        },
      });
    } else {
      createTag({
        data: { name ,productId },
        onSuccess: () => {
          refetch();
          setName("")
          setProduct(null)
          cb();
        },
      });
    }
  };

  const handleDeleteTag = async (id, cb) => {
    deleteTag({
      id,
      onSuccess: () => {
        refetch();
        cb();
      },
    });
  };

  if (loadingTags) return <SectionLoader />;

  return (
    <Box>
      {tags.length === 0 ? (
        <EmptyState
          type="customers"
          title="No Tag available"
          action={
            <CanView can={[Permissions.CREATE_TAGS]}>
              <Button onClick={() => setShowModal({ show: true, data: {} })}>
                Add Tag
              </Button>
            </CanView>
          }
        />
      ) : (
        <>
          <Box align="right">
            <CanView can={[Permissions.CREATE_TAGS]}>
              <Button onClick={() => setShowModal({ show: true, data: {} })}>
                Create Tag
              </Button>
            </CanView>
          </Box>
          <Table style={{ overflowX: "visible" }}>
            <table>
              <thead pad="20px">
                <tr>
                  <th width="50px">s/n</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tags && tags?.data.map((t, index) => (
                  <tr key={t.id}>
                    <td>{index + 1}</td>
                    <td>{t.name}</td>
                    <td>
                      <Box align="left">
                        <DropDown
                          trigger="click"
                          style={{ textAlign: "left" }}
                          menuAlign="bottom right"
                          label={
                            <Box pad="8px" className="cursor">
                              <Text {...fontSize.h3}>...</Text>
                            </Box>
                          }
                          menuList={CanFilter([
                            {
                              onClick: () =>
                                setShowModal({ show: true, data: t }),
                              label: "Edit Tag",
                              iconLeft: "icon-pencil",
                              can: [Permissions.UPDATE_TAGS],
                            },
                            {
                              onClick: () =>
                                setShowDeleteModal({ show: true, data: t }),
                              label: "Delete Tag",
                              iconLeft: "icon-trash",
                              can: [Permissions.DELETE_TAGS],
                            },
                          ])}
                        />
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Table>
        </>
      )}

      <Modal open={showModal.show} center={false}>
        <ModalBody width="600px">
          <TagEditor
            open={showModal.show}
            tags={tags}
            data={showModal.data}
            onSaveProduct={handleSaveTag}
            closeModal={() => setShowModal({ show: false, data: {} })}
            saving={isCreating || isEditing}
          />
        </ModalBody>
      </Modal>
      <DeleteTag
        open={showDeleteModal.show}
        onClose={() => setShowDeleteModal({ show: false, data: {} })}
        data={showDeleteModal.data}
        onDelete={handleDeleteTag}
        deleting={isDeleting}
      />
    </Box>
  );
}
