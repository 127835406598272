import { Box, Grid, Spacer, Input, Button ,Select} from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Theme from "../../utils/theme";
import useProductsGet from "../../hooks/products/useProductsGet";
import fontSize from "../../utils/typography";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const TagEditor = (props) => {
  const [name, setName] = useState('');
  const { products } = useProductsGet();
  const [product ,setProduct]=useState(null)
  const [error, setError] = useState(null);

  const closeModal = () => {
    props.closeModal();
  };

  const handleSubmit = () => {
    if (!name) return setError('Name is Required');
    if (props?.tags?.data.some(t => t.name.toLowerCase() === name.toLowerCase() && t.id !== props.data?.id)) {
      return setError('Tag name already exist');
    }
    if(!product) return setError("Product is required")
    setError('');
    const productId = product?.value
    props.onSaveProduct(name , productId, closeModal, setProduct, setName);
  }
  

  useEffect(() => {
    if (!props.open) {
      setName('');
    }
  }, [props.open])

  useEffect(() => {
    if (props.data?.id) {
      setName(props.data.name)
      const newProduct = products?.data.find((item) => item.id === props.data.productId);
      setProduct( {label:newProduct?.name ,value:newProduct?.id})
    }
    // eslint-disable-next-line
  }, [props.data])
  console.log(product)

  return (
    <Box>
      <Swiper active={true}>
        <Grid default="auto max-content" responsive={false} className="bb">
          <Box pad="24px 32px">
            <Text {...fontSize.h3} uppercase>
              {props.data?.id ? 'Edit' : 'Add'} Tag
            </Text>
          </Box>
        </Grid>
        <Box pad="32px">
          <Input
            spaceTop
            label="Tag"
            placeholder="Tag Name"
            block
            required
            value={name}
            error={error}
            onChange={({ target: { value } }) => setName(value)}
          />
          <Spacer space="24px" />
          <Select
              spaceTop
              label="Product"
              required
              placeholder="Select product"
              options={products?.data.map((v) => ({
                label: v?.name,
                value: v?.id,
              }))}
              block
              value={product}
            error={error}
              onChange={(value) => setProduct(value)}
            />
        </Box>
      

        <Grid default="auto max-content" responsive={false} className="bt">
          <Box pad="24px">
            <Button
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              progress={props.saving}
              onClick={handleSubmit}
            >
              {props.data?.id ? 'Update' : 'Save'} Tag
            </Button>
          </Box>
        </Grid>
      </Swiper>
    </Box>
  );
};
export default TagEditor;
