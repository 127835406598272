import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoadingPrivileges,
  selectPrivilegeModules,
  selectPermissionsGroup,
} from "../../redux/roles/selectors";

import { fetchPrivilegesStart } from "../../redux/roles/reducer";

const usePrivilegeModulesGet = () => {
  const dispatch = useDispatch();
  const privilegeModules = useSelector(selectPrivilegeModules);
  const permissions = useSelector(selectPermissionsGroup);
  const isLoading = useSelector(selectIsLoadingPrivileges);

  useEffect(() => {
    dispatch(fetchPrivilegesStart());
  }, [dispatch]);

  return {
    privilegeModules,
    loadingModules: isLoading,
    permissions,
  };
};

export default usePrivilegeModulesGet;
