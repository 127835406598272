import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
  isLoadingProducts: false,
  products:null,
  error: null,
};


export const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
      fetchProductDataStart: (state, action) => {
        state.isLoadingProducts = true;
        state.error = null;
      },
      fetchProductDataSuccess: (state, action) => {
        state.isLoadingProducts = false;
        state.products = action.payload;
        state.error = null;
      },
      fetchProductDataFailure: (state, action) => {
        state.isLoadingProducts = false;
        state.error = action.payload;
        state.products = null
      },
      toggleProductDemo: (state) => {
        state.error = null;
      },
      toggleProductDemoSuccess: (state) => {
        state.error = null;
      },
      toggleProductDemoFailure: (state, action) => {
        state.error = action.payload;
      },
    }
    })

    export const {
        fetchProductDataFailure,
        fetchProductDataStart,      
        fetchProductDataSuccess,
        toggleProductDemo,
        toggleProductDemoSuccess,
        toggleProductDemoFailure
      } = productsSlice.actions;
      
      const productsReducer = productsSlice.reducer;
      
      export default productsReducer;