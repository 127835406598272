import { useEffect, useState } from 'react';
import { Box } from '@flexisaf/flexibull2';
import Header from '../../../components/customer/create-customer/Header';
import {
  Step1,
  Step2,
  Step3,
} from '../../../components/customer/create-customer/Steps';

const getDefaultDetails = () => ({
  name: '',
  customerType: 'ORGANIZATION',
  email: '',
  address: '',
  state: null,
  phone: '',
  country: null,
  city: '',
});

const CustomerEditor = (props) => {
  const [step, setStep] = useState(1);
  const [customerDetails, setCustomerDetail] = useState({
    ...getDefaultDetails(),
  });
  const [customerContacts, setCustomerContacts] = useState([
    {
      salutation: null,
      name: '',
      email: '',
      phone: '',
    },
  ]);

  const bulkUpdateCustomerDetails = (details) => {
    setCustomerDetail((currentValue) => ({ ...currentValue, ...details }));
  };

  const updateCustomerContactDetails = (details) => {
    setCustomerContacts(details);
  };

  const closeModal = () => {
    setCustomerDetail({ ...getDefaultDetails() });
    setCustomerContacts([]);
    setStep(1);
    props.closeModal();
  };

  const onSaveCustomer = () => {
    const updatedCustomerDetails = {
      ...customerDetails,
      country: customerDetails?.country?.value,
      state: customerDetails?.state?.value,
      contacts: customerContacts.map((cc, i) => ({
        ...cc,
        isPrimary: i === 0,
        salutation: cc?.salutation?.value,
      })),
    };
  
    if (customerDetails.code === "") {
      delete updatedCustomerDetails.code;
    }
    props.onSaveCustomer(updatedCustomerDetails, closeModal);

  };
  

  useEffect(() => {
    if (props.data?.id) {
      setCustomerDetail({
        name: props.data.name,
        customerType: props.data.customerType,
        email: props.data.email,
        address: props.data.address,
        state: props.data.state
          ? { label: props.data.state, value: props.data.state }
          : null,
        phone: props.data.phone,
        country: props.data.country
          ? { label: props.data.country, value: props.data.country }
          : null,
        city: props.data.city,
        website: props.data.website,
        code: props.data.code,
        shortName: props.data.shortName,
        isDemo:props.data.isDemo
      });
      setCustomerContacts(
        props.data.contacts?.map((c) => ({
          salutation: c.salutation
            ? { label: c.salutation, value: c.salutation }
            : null,
          name: c.name,
          email: c.email,
          phone: c.phone,
          id: c.id,
        })) || []
      );
    }
  }, [props.data]);

  return (
    <Box>
      <Header isEdit={props.data?.id} step={step} />
      {step === 1 && (
        <Step1
          customerDetails={customerDetails}
          getDefaultDetails={getDefaultDetails}
          step={step}
          setStep={setStep}
          bulkUpdateCustomerDetails={bulkUpdateCustomerDetails}
          closeModal={closeModal}
          isEdit={props.data?.id}
        />
      )}
      {step === 2 && (
        <Step2
          step={step}
          customerContacts={customerContacts}
          setStep={setStep}
          updateCustomerContactDetails={updateCustomerContactDetails}
          closeModal={closeModal}
        />
      )}
      {step === 3 && (
        <Step3
          step={step}
          setStep={setStep}
          customerDetails={customerDetails}
          customerContacts={customerContacts}
          saving={props.saving}
          onSaveCustomer={onSaveCustomer}
          closeModal={closeModal}
        />
      )}
    </Box>
  );
};
export default CustomerEditor;
