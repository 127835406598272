import { call, put, takeLatest } from "redux-saga/effects";
import { httpGetLatestPlan } from "../../../services/plans";
import {
    fetchLatestPlanFailure,
    fetchLatestPlanStart,
    fetchLatestPlanSuccess,
  } from "../reducer";



export function* getLatestPLan({payload}) {
    const { id } = payload;


  const response = yield call(httpGetLatestPlan,id);

  const { raw,  success } = response;

  if (success) {
    yield put(fetchLatestPlanSuccess(raw));
  } else {
    yield put(fetchLatestPlanFailure(raw));
    // networkErrorHandler(
    //   statusCode,
    //   message ||
    //     "Unable to fetch plan summary. Please check your internet connection or try again later."
    // );
  }
}

export default function* onGetLatestPlan() {
  yield takeLatest(fetchLatestPlanStart.type, getLatestPLan);
}