import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Text, Grid, FlexiPie } from "@flexisaf/flexibull2";
import { Spacer } from "@flexisaf/flexibull2/build/layout";
import {
  BarGenerator,
  GenericBarGenerator,
  LabelStatus,
} from "../../components/barGenerator";
import { PageHeader } from "../../components/custom/header";
import {
  DashWrapper,
  DashCardee,
  DashCell,
  ChartWrap,
} from "../../components/custom/wrappers";
import Theme from "../../utils/theme";
import fontSize from "../../utils/typography";
import moment from "moment";
import { Link } from "react-router-dom";
import useDashboardDataGet from "../../hooks/dashboard/useDashboardDataGet";
import useSubscriptionsExpiringSoonGet from "../../hooks/subscriptions/useSubscriptionsExpiringSoonGet";
import DashCardEmptyState from "../../components/custom/dashCardEmptyState";
import SectionLoader from "../../components/custom/sectionLoader";

const Dashboard = () => {
  const navigate = useNavigate();
  const { dashboardData, loadingData } = useDashboardDataGet();
  const { subscriptionsExpiringSoon } = useSubscriptionsExpiringSoonGet();


  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (subscriptionsExpiringSoon.length > 7) setShowMore(true);
  }, [subscriptionsExpiringSoon]);

  if (loadingData) return <SectionLoader />;

  return (
    <>
      <PageHeader>
        <Text {...fontSize.h3}>
          <i className="icon-landing" /> Dashboard
        </Text>
      </PageHeader>
      <DashWrapper>
        <Box pad="32px" maxWidth="1500px">
          <Grid default="1fr 1fr 1fr" gap="24px">
            <DashCardee elevate="none" className="dashcards">
              <DashCell col={1} colSpan={1} row={1} rowSpan={2}>
                <Box pad="16px">
                  <Text block>Customers</Text>
                  <Spacer size="8px" />
                  <Text size="3em">{dashboardData?.customers?.total.allCustomersNotDemo}</Text>
                </Box>
              </DashCell>
             
            </DashCardee>
            <DashCardee elevate="none" className="dashcards">
              <DashCell col={1} colSpan={1} row={1} rowSpan={2}>
                <Box pad="16px">
                  <Text block>Products</Text>
                  <Spacer size="8px" />
                  <Text size="3em">{dashboardData?.products.total.allProductsNotDemo}</Text>
                </Box>
              </DashCell>
            </DashCardee>
            <DashCardee elevate="none" className="dashcards">
              <DashCell col={1} colSpan={1} row={1} rowSpan={2}>
                <Box pad="16px">
                  <Text block>Subscriptions</Text>
                  <Spacer size="8px" />
                  <Text size="2em">{dashboardData?.subscriptions.total.allSubscriptions}</Text>
                </Box>
              </DashCell>
              <DashCell className="br" col={2} colSpan={1} row={1} rowSpan={2}>
                <ChartWrap pad="16px 0">
                  <FlexiPie
                     xData={["Active", "expired", "Future"]}
                     yData={[
                       { uv: dashboardData.subscriptions.active },
                       { uv: dashboardData.subscriptions.expired },
                       { uv: dashboardData.subscriptions.future },
                     ]}
                    tooltip
                    radius={40}
                    thickness={5}
                    doughnut
                    colors={[
                      Theme.PrimaryColor,
                      Theme.PrimaryRed,
                      Theme.PrimaryGreen,
                    ]}
                  />
                </ChartWrap>
              </DashCell>
              <DashCell className="bb" col={3} colSpan={1} row={1} rowSpan={1}>
                <Box pad="16px" align="center">
                  <Text {...fontSize.h4} color={Theme.PrimaryColor}>
                    {dashboardData?.subscriptions.active?.activeSubscriptionsNotDemo}
                  </Text>{" "}
                  <Text {...fontSize.caption}>Active</Text>
                </Box>
              </DashCell>
              <DashCell className="bb" col={3} colSpan={1} row={2} rowSpan={1}>
                <Box pad="16px" align="center">
                  <Text {...fontSize.h4} color={Theme.PrimaryRed}>
                    {dashboardData?.subscriptions.expired?.expiredSubscriptionsNotDemo}
                  </Text>{" "}
                  <Text {...fontSize.caption}>Expired</Text>
                </Box>
              </DashCell>
              <DashCell className="bl" col={3} colSpan={1} row={3} rowSpan={1}>
                <Box pad="16px" align="center">
                  <Text {...fontSize.h4} color={Theme.PrimaryGreen}>
                    {dashboardData.subscriptions.future?.futureSubscriptionsNotDemo}
                  </Text>{" "}
                  <Text {...fontSize.caption}>Future</Text>
                </Box>
              </DashCell>
            </DashCardee>
          </Grid>
          <Spacer space="24px" />
          <Grid default="1fr 1fr 1fr" gap="24px">
            <DashCardee>
              <DashCell col={1} colSpan={3} row={1} rowSpan={2} notCentered>
                <Box pad="16px">
                  <Text {...fontSize.caption}>Subscription by</Text>
                  <Spacer space="4px" />
                  <Text {...fontSize.h4} block>
                    Products
                  </Text>
                  <Spacer space="24px"></Spacer>
                  {!dashboardData?.productSubscription?.productSubscriptionNotDemo?.length ? (
                    <DashCardEmptyState
                      title="No products are added yet"
                      icon="icon-popup"
                      btnText="Create a product"
                      btnAction={() => navigate("/products")}
                    />
                  ) : (
                    <BarGenerator data={dashboardData?.productSubscription.productSubscriptionNotDemo
                    } />
                  )}
                </Box>
              </DashCell>
            </DashCardee>
            <DashCardee>
              <DashCell col={1} colSpan={3} row={1} rowSpan={2} notCentered>
                <Box pad="16px">
                  <Text {...fontSize.caption}>Subscription by</Text>
                  <Spacer space="4px" />
                  <Text {...fontSize.h4} block>
                    Organisation Type
                  </Text>
                  <Spacer space="24px" />
                  {!dashboardData?.customers.organization.organizationCustomersNotDemo &&
                  !dashboardData?.customers.public.publicCustomersNotDemo &&
                  !dashboardData?.customers.individual.individualCustomersNotDemo &&
                  !dashboardData?.customers.NGO.NGOCustomersNotDemo ? (
                    <DashCardEmptyState
                      title="No subscription by organisation type"
                      icon="icon-popup"
                      btnText="Create a subscription"
                      btnAction={() => navigate("/subscriptions")}
                    />
                  ) : (
                    <FlexiPie
                      xData={["Private", "Public", "Individual", "NGO"]}
                      yData={[
                        { uv: dashboardData?.customers.private.privateCustomersNotDemo },
                        { uv: dashboardData?.customers.public.publicCustomersNotDemo },
                        { uv: dashboardData?.customers.individual.individualCustomersNotDemo },
                        { uv: dashboardData?.customers.NGO.NGOCustomersNotDemo },
                      ]}
                      colors={[
                        Theme.PrimaryBlue,
                        Theme.PrimaryGreen,
                        Theme.PrimaryRed,
                        Theme.PrimaryOrange,
                      ]}
                      tooltip
                      radius={90}
                      thickness={10}
                      doughnut
                      legend
                    />
                  )}
                </Box>
              </DashCell>
            </DashCardee>
            <DashCardee>
              <DashCell col={1} colSpan={3} row={1} rowSpan={2} notCentered>
                <Box pad="16px">
                  <Text {...fontSize.caption}>Expiring</Text>
                  <Spacer space="4px" />
                  <Text {...fontSize.h4} block>
                    Within 30 Days
                  </Text>
                  <Spacer space="24px" />
                  {!subscriptionsExpiringSoon?.length ? (
                    <DashCardEmptyState
                      title="No subscriptions expiring within a month"
                      icon="flexibull-clock"
                      btnText="Create a new subscription"
                      btnAction={() => navigate("/subscriptions")}
                    />
                  ) : (
                    <Box>
                      <Grid
                        responsive={false}
                        default="auto max-content"
                        gap="16px 4px"
                      >
                        {subscriptionsExpiringSoon.map((s) => (
                          <>
                            <LabelStatus
                              tag={Theme.PrimaryBlue}
                              tagSize="8px"
                              truncate
                            >
                              {s.customer?.name}
                            </LabelStatus>
                            <Box align="right">
                              <Text {...fontSize.caption}>
                                {moment(s.endDate).format("D MMM YYYY")}
                              </Text>
                            </Box>
                          </>
                        ))}
                        {showMore && (
                          <Box margin="16px 0 0">
                            <Link to={`/subscriptions/expiring-soon`}>
                              <Text>Show More...</Text>
                            </Link>
                          </Box>
                        )}
                      </Grid>
                    </Box>
                  )}
                </Box>
              </DashCell>
            </DashCardee>

            <DashCardee>
              <DashCell col={1} colSpan={3} row={1} rowSpan={2} notCentered>
                <Box pad="16px">
                  <Text {...fontSize.h4} block>
                    Customer Category Summary
                  </Text>
                  <Spacer space="24px"></Spacer>
                  {!dashboardData?.customerTagSummary?.customerTagSummaryNotDemo ? (
                    <DashCardEmptyState
                      title="No customer category created"
                      icon="icon-wrench-outline"
                      btnText="Create a category"
                      btnAction={() => navigate("/settings")}
                    />
                  ) : (
                    <GenericBarGenerator
                      data={dashboardData?.customerTagSummary?.customerTagSummaryNotDemo.map((c) => ({
                        ...c,
                        label: c.name,
                        value: c.totalcustomers,
                      }))}
                    />
                  )}
                </Box>
              </DashCell>
            </DashCardee>
          </Grid>
        </Box>
      </DashWrapper>
    </>
  );
};

export default Dashboard;
