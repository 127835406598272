import { call, put, takeLatest } from "redux-saga/effects";
import { httpGetProducts} from "../../../services/products";
import {
    fetchProductDataFailure,
    fetchProductDataStart,
    fetchProductDataSuccess,
  } from "../reducer";

  import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getProducts() {
  const response = yield call(httpGetProducts);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchProductDataSuccess(raw));
  } else {
    yield put(fetchProductDataFailure(raw));
    networkErrorHandler(
      statusCode,
      message ||
        "Unable to fetch User summary. Please check your internet connection or try again later."
    );
  }
}

export default function* onGetProductData() {
  yield takeLatest(fetchProductDataStart.type, getProducts);
}