import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingTags: true,
  isCreatingTags: false,
  isUpdatingTag: false,
  isDeletingTag: false,
  isLoadingSingleTag: false,
  tags: [],
  error: null,
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    fetchTagsStart: (state, action) => {
      state.isLoadingTags = true;
      state.error = null;
    },
    fetchTagsSuccess: (state, action) => {
      state.isLoadingTags = false;
      state.tags = action.payload;
    },
    fetchTagsFailure: (state, action) => {
      state.isLoadingTags = false;
      state.tags = [];
      state.error = action.payload;
    },
    createTagStart: (state, action) => {
      state.isCreatingTags = true;
      state.error = null;
    },
    createTagsSuccess: (state) => {
      state.isCreatingTags = false;
    },
    createTagsFailure: (state, action) => {
      state.isCreatingTags = false;
      state.error = action.payload;
    },
    updateTagStart: (state, action) => {
      state.isUpdatingTag = true;
    },
    updateTagSuccess: (state) => {
      state.isUpdatingTag = false;
    },
    updateTagFailure: (state, action) => {
      state.isUpdatingTag = false;
      state.error = action.payload;
    },
    deleteTagStart: (state, action) => {
      state.isDeletingTag = true;
    },
    deleteTagSuccess: (state) => {
      state.isDeletingTag = false;
    },
    deleteTagFailure: (state, action) => {
      state.isDeletingTag = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchTagsFailure,
  fetchTagsStart,
  fetchTagsSuccess,
  createTagStart,
  createTagsFailure,
  createTagsSuccess,
  updateTagFailure,
  updateTagSuccess,
  updateTagStart,
  deleteTagFailure,
  deleteTagStart,
  deleteTagSuccess,
} = tagsSlice.actions;

const tagsReducer = tagsSlice.reducer;

export default tagsReducer;
