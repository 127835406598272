import { Box, Grid, StatusIndicator , Modal, ModalBody, ModalClose,ModalFooter,Button} from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import { useState, useEffect, useMemo } from "react";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import PlanStep1 from "../../../components/plan/PlanStep1";
import PlanStep2 from "../../../components/plan/PlanStep2";
import PlanStep3 from "../../../components/plan/PlanStep3";
import PlanStep4 from "../../../components/plan/PlanStep4";
import usePlanEditor from "../../../hooks/plan/usePlanEditor";
import useFeatureGet from "../../../hooks/plan/useFeatureGet";
import useLatestPlanGet from "../../../hooks/plan/useLatestPlanGet";
import useProductsGet from "../../../hooks/products/useProductsGet"
import urls from "../../../utils/config";

const { SELF_SERVICE } = urls || {};
const getDefaultPlan = () => ({
  name: "",
  description: "",
  planType: "",
  product: null,
  billingCycleFrequency: "0",
  duration: null,
  durationType: "",
  billingCycleCurrrency: "",
  unitCost:"0",
  freeTrial:"0"
});
const getDefaultAliases = () => ({
  segment: null,
  planAlias: "",
  callToActionText:"",
  embedCode:"",
  billingAlias: "",
});


const PlanEditor = (props) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [step, setStep] = useState(1);
  const [unitCost, setUnitCost] = useState("0")
  const [aliasValue, setAliasValue] = useState([]);
  const [addFeature, setAddFeature] = useState("");
  const [status, setStatus] = useState(false);
  const [featureLogic, setFeatureLogic] = useState([]);
  const [isPublished, setIsPublished] = useState(false);
  const [popular, setPopular] = useState(false)
  const [isDemo, setIsDemo] = useState(false)
  const { features } = useFeatureGet();
  const [unlimitedCheck, setUnlimitedCheck] = useState([]);
  const [autoRenew, setAutoRenew] = useState(false);
  const { products } = useProductsGet();
  const [addMoreInfo , setAddMoreInfo] = useState([])
  const [incrementValue, setIncrementValue] = useState([])

  const { createPlan, isCreating, updatePlan, isUpdating } = usePlanEditor();
  
  const { latestPlan } = useLatestPlanGet(props.productId);

  const featureplanAlias = useMemo(() => {
    if (latestPlan) {
      let aliases;
      try {
        const jsonArray = JSON.parse(latestPlan?.planConfiguration);
        aliases = jsonArray;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }

      if (aliases) {
        const alias = aliases.map((feature) => ({
          ...feature,
          value:
            typeof feature.value === "boolean"
              ? (feature.value = false)
              : (feature.value = 0),
        }));
        return alias;
      }
    }

    return null;
  }, [latestPlan]);



  const transformFeature = useMemo(() => {
    return  features?.features?.map((feature) => {
      let updatedFeature = { ...feature,  };
      updatedFeature.value = feature.type
     
      if (updatedFeature.value === "boolean") {
        updatedFeature.value = false;
      } else {
        updatedFeature.value = 0;
      }
      delete updatedFeature.type
      return updatedFeature;
    });
  }, [  features]);

  
  const [cachedTransformFeature, setCachedTransformFeature] = useState(null);
  const findProductName= products?.data.find((product) => props?.productId === product?.id)

  useEffect(() => {
    if (!cachedTransformFeature) {
      if(!findProductName?.integrationUrl){
        setCachedTransformFeature(null)
       
      }else{ 
      setCachedTransformFeature(transformFeature);
    
      }
    }
  
  }, [transformFeature, cachedTransformFeature, findProductName ]);


  useEffect(() => {
 
    if (props.data.id) {
     
      setFeatureLogic((prevFeatures) => {
        let updatedFeature = prevFeatures;

        if (props?.data?.planConfiguration) {
          try {
            const jsonArray = JSON.parse(props.data.planConfiguration)
            updatedFeature = jsonArray;
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        }

        return updatedFeature;
      });
    } 
    else {
      setFeatureLogic(cachedTransformFeature);
  
    }
  }, [ props.data, featureplanAlias, latestPlan,   cachedTransformFeature]);


  const handleAlias = (value, index) => {
    setAliasValue((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = value;
      return updatedValues;
    });
    setFeatureLogic((prevFeatures) => {
      const updatedFeature = prevFeatures.map((feature, i) => {
        if (i === index) {
          return { ...feature, alias: value };
        }
        return feature;
      });
      return updatedFeature;
    });
  };
const handleIncrementValueChange = (index,value)=>{
  setFeatureLogic((prevFeatures) => {
    const updatedFeatures = prevFeatures.map((feature, i) => {
      if (
        i === index &&
        typeof feature.value === "number" &&
        !Number.isNaN(feature.value)
      ) {
        const newValue = value.trim() === '' ? 0 : parseInt(value);
        return { ...feature, value: parseInt(newValue)};
      }
      return feature;
    });
    return updatedFeatures;
  });
}

  const handleCheckBox = (checked, index) => {
    setFeatureLogic((prevFeatures) => {
      const updatedFeature = prevFeatures.map((feature, i) => {
        if (i === index && typeof feature.value === "boolean") {
          return { ...feature, value: checked ? (feature.value = true) : false };
        }
        return feature;
      });
      return updatedFeature;
    });
  };

  const handleDecrementValue = (index) => {
    setFeatureLogic((prevFeatures) => {
      const updatedFeatures = prevFeatures.map((feature, i) => {
        if (
          i === index &&
          typeof feature.value === "number" &&
          !Number.isNaN(feature.value)
        ) {
          return { ...feature, value: feature.value - 1 };
        }
        return feature;
      });
      return updatedFeatures;
    });
  };
  const handleUnlimitedCheck = (index, checked) => {
    setFeatureLogic((prevFeatures) => {
      const updatedFeatures = prevFeatures.map((feature, i) => {
        if (
          i === index &&
          typeof feature.value === "number" &&
          !Number.isNaN(feature.value)
        ) {
          return {
            ...feature,
            value: checked ? -1 : 0, 
          };
        }
  
        return feature;
      });
      setUnlimitedCheck((prevCheckboxStates) => {
        const updatedCheckboxStates = [...prevCheckboxStates];
        updatedCheckboxStates[index] = checked;
        return updatedCheckboxStates;
      });
    
      return updatedFeatures;
    });
  };

  const handleDeleteInfo =(index) =>{
    setAddMoreInfo((prevInfo) =>{
      const updatedFeature = prevInfo.filter((moreinfo, i) =>{
        if(i=== index){
         return false 
        }
        else{
          return true
        }  
      })
      return updatedFeature
    })
  }

  const handleIncrementValue = (index) => {
    setFeatureLogic((prevFeatures) => {
      const updatedFeatures = prevFeatures.map((feature, i) => {
        
        if (
          i === index &&
          typeof feature.value === "number" &&
          !Number.isNaN(feature.value)
        ) {
          if (feature.value === -1) {
            return feature;
          } else {
            return { ...feature, value: feature.value + 1 };
          }
        }
        return feature;
      });
      return updatedFeatures;
    });
  };

  const handleAddFeature = () => {
    if (addFeature === "") return;
    setAddMoreInfo((prevInfo) => {
      const  newInfo = { name: addFeature };
      const  updatedInfo = [...prevInfo, newInfo];

      return updatedInfo;
    });
    setAddFeature("");
  };
 

  const [planInformation, setPlanInformation] = useState({
    ...getDefaultPlan(),
  });


  const [planAliases, setPlanAliases] = useState({
    ...getDefaultAliases(),
  });

  const bulkUpdatePlanAliases = (details) => {
    return setPlanAliases({ ...planAliases, ...details });
  };
  const bulkUpdatePlanInformation = (details) => {
    return setPlanInformation({ ...planInformation, ...details });
  };
  const handleCloseModal = () => {
    setPlanInformation({ ...getDefaultPlan() });
    setPlanAliases({ ...getDefaultAliases() });
    props.closeModal();
    setUnitCost("0")
    setIncrementValue([])
    setStep(1);
    setUnlimitedCheck([])
    setOpenSuccessModal(false);
    setIsPublished(isPublished)
    setIsDemo(isDemo)
    setStatus(status)

  };

  
  const data = {
    name: planInformation.name,
    type: planInformation.planType.value,
    description: planInformation.description,
    billingCycle: planInformation?.duration?.value,
    status: status ? "ACTIVE" : "INACTIVE",
   unitCost:parseInt(planInformation.unitCost),
    billingCycleType: planInformation?.durationType?.value,
    planNameAlias: planAliases.planAlias,
    marketSegmentId: planAliases.segment?.value,
    billingCycleAlias: planAliases?.billingAlias,
    frequency: planInformation?.billingCycleFrequency?.value,
    currency: planInformation?.billingCycleCurrrency?.value,
    planConfiguration: featureLogic,
    productId: props.productId,
    isPublished: isPublished,
    moreFeatures:addMoreInfo,
    isPopular:popular,
    autoRenew: autoRenew,
    freeTrial:planInformation.freeTrial,
    isDemo:isDemo,
    embedCode:planAliases.embedCode,
    callToActionText:planAliases.callToActionText ?planAliases.callToActionText :""
  };



  const onSavePlan = () => {
    if (props?.data?.id) {
      const id = props?.data?.id;

      updatePlan({
        data: { id, plan: data },
        onSuccess: () => {
          setOpenSuccessModal(true); 
          setTimeout(() => {
            props.fetchPlans();
          
          }, 1000); 
        },
      });
    } else {
      createPlan({
        plan: data,
        onSuccess: () => {
          setOpenSuccessModal(true); 
          setTimeout(() => {
            props.fetchPlans();
          
          }, 1000); 
        },
      });
    }
  };

  useEffect(() => {
    if (props.data?.id) {
      setPlanAliases({
        segment: props.data.marketSegment
          ? {
              label: props.data.marketSegment.name,
              value: props.data.marketSegmentId,
            }
          : null,
        planAlias: props?.data?.planNameAlias,
        billingAlias: props?.data?.billingCycleAlias,
        embedCode:props?.data?.embedCode,
        callToActionText:props?.data?.callToActionText
      });
   

        
            const jsonArray = JSON.parse(props?.data?.planConfiguration);
            const initialUnlimitedCheck = jsonArray.map((value) => {
              return typeof value.value === 'number' && value.value === -1;
            });
            setUnlimitedCheck(initialUnlimitedCheck);

      setPlanInformation({
        name: props?.data?.name,
        description: props?.data?.description,
        planType: props.data.type
          ? { label: props.data.type, value: props.data.type }
          : null,
        product: props.data.product
          ? { label: props.data.product.name, value: props.data.product.name }
          : null,
        billingCycleFrequency: props.data.frequency
          ? { label: `${props.data.frequency}x`, value: props.data.frequency }
          : null,
        duration: props.data.billingCycle
          ? { label: props.data.billingCycle, value: props.data.billingCycle }
          : null,
        durationType: props.data.billingCycleType
          ? {
              label: props.data.billingCycleType,
              value: props.data.billingCycleType,
            }
          : null,
        billingCycleCurrrency: props.data.currency
          ? { label: props.data.currency, value: props.data.currency }
          : null,
        unitCost: props?.data?.unitCost,
      freeTrial:props?.data?.freeTrial
      });
      const  moreFeatures = JSON.parse(props?.data?.moreFeatures);
setAddMoreInfo(moreFeatures)
setUnitCost(props.data.unitCost)
setIsDemo(props.data.isDemo)

      setIsPublished(props?.data.isPublished);
      setPopular(props.data.isPopular)
      setStatus((prev) => (props.data.status === "ACTIVE" ? true : false));
      setAutoRenew(props?.data.autoRenew);
      // setFeatureEdit(props?.data?.planConfiguration);
    }
  }, [props.data, props.data.id]);
 

 
 
  return (
    <Box>
      <Grid default="auto max-content" reponsize={false} className="bb">
        <Box pad="24px 32px">
          <Text {...fontSize.h3} uppercase>
            {props.data.id ? "EDIT" : "CREATE NEW"} PLAN FOR {findProductName?.name.toUpperCase()}
          </Text>
        </Box>
        <Box pad="24px" className="spaced">
          <>
            {[1, 2, 3, 4].map((key) => (
              <StatusIndicator
                pulse={step === key}
                color={step >= key ? Theme.PrimaryColor : Theme.PrimaryLight}
              />
            ))}
          </>
        </Box>
        <Modal onClose={handleCloseModal} open={openSuccessModal}>
      <ModalBody width="35vw">
        <ModalClose onClick={handleCloseModal}>&times;</ModalClose>
        <Box pad="20px 30px 10px 30px">
          <h2>Your  plan has been successfully {props?.data?.id ?"edited" : "created"}</h2>
        </Box>
        <Box pad="10px 30px 20px 30px" margin="-2rem 0 0 0 ">
          <p >
           Click the link down below to  preview your created plan on self service page.
          </p>

         <a
                    href={SELF_SERVICE}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                  >
                     {SELF_SERVICE}
                  </a>
        </Box>
        <ModalFooter>
          <Box pad="20px" align="right">
         
            <Button onClick={() => handleCloseModal()}>Close</Button>
          </Box>
        </ModalFooter>
      </ModalBody> 
    </Modal> 
      </Grid>

      {step === 1 && (
        <PlanStep1
          step={step}
          getDefaultPlan={getDefaultPlan}
          setStep={setStep}
          integrationUrl={findProductName}
          planInformation={planInformation}
          closeModal={handleCloseModal}
          bulkUpdatePlanInformation={bulkUpdatePlanInformation}
          autoRenew={autoRenew}
          setAutoRenew={setAutoRenew}
          unitCost={unitCost}
          setUnitCost={setUnitCost}
          
        />
      )}
      {step === 2 && (
        <PlanStep2
          step={step}
          setStep={setStep}
          closeModal={handleCloseModal}
          handleIncrementValueChange={handleIncrementValueChange}
          incrementValue={incrementValue}
          handleAddFeature={handleAddFeature}
          handleCheckBox={handleCheckBox}
          handleIncrementValue={handleIncrementValue}
          handleUnlimitedCheck={handleUnlimitedCheck}
          handleDecrementValue={handleDecrementValue}
          addFeature={addFeature}
          addMoreInfo={addMoreInfo}
          featureLogic={featureLogic}
          unlimitedCheck={unlimitedCheck}
          handleDeleteInfo={handleDeleteInfo}
          setAddFeature={setAddFeature}
        />
      )}
      {step === 3 && (
        <PlanStep3
          step={step}
          setStep={setStep}
          planInformation={planInformation}
          closeModal={handleCloseModal}
          featureLogic={featureLogic}
          isPublished={isPublished}
          integrationUrl={findProductName}
          getDefaultAliases={getDefaultAliases}
          bulkUpdatePlanAliases={bulkUpdatePlanAliases}
          planAliases={planAliases}
          aliasValue={aliasValue}
          setIsPublished={setIsPublished}
          setFeatureLogic={setFeatureLogic}
          isCreating={isCreating}
          autoRenew={autoRenew}
          getDefaultPlan={getDefaultPlan}
          productId={findProductName}
          handleAlias={handleAlias}
          status={status}
          setStatus={setStatus}
          popular={popular}
          isDemo={isDemo}
          setIsDemo={setIsDemo}
          setPopular={setPopular}
          onSavePlan={onSavePlan}
        />
      )}

      {step === 4 && (
        <PlanStep4
          step={step}
          setStep={setStep}
          planInformation={planInformation}
          closeModal={handleCloseModal}
          featureLogic={featureLogic}
          status={status}
          isPublished={isPublished}
          setIsPublished={setIsPublished}
          isCreating={isCreating}
          autoRenew={autoRenew}
          planAlias={planAliases}
          onSavePlan={onSavePlan}
          isUpdating={isUpdating}
          id={props.data.id}
          isDemo={isDemo}
          name={findProductName}
        />
      )}
    
    </Box>
  );
};
export default PlanEditor;
