import { call, all } from "redux-saga/effects";

import onGetSubscriptionsExpiringSoon from "./getSubscriptionsExpiringSoon";
import onGetSubscriptions from "./getSubscriptions";
import onToggleDemo from "./toggleSubscriptsDemo";
export default function* subscriptionsSagas() {
  yield all([
    call(onGetSubscriptionsExpiringSoon),
    call(onGetSubscriptions),
    call(onToggleDemo)
  ]);
}
