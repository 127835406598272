import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchInvoicesStart,
  fetchInvoicesFailure,
  fetchInvoicesSuccess,
} from "../reducer";

import { httpGetInvoices } from "../../../services/invoice";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getInvoices({ payload }) {
 
  const { id, onSuccess, onFailure } = payload;
  const response = yield call(httpGetInvoices, id);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchInvoicesSuccess(raw));
    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield put(fetchInvoicesFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to fetch subscription");

    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onGetInvoices() {
  yield takeLatest(
    fetchInvoicesStart.type,
    getInvoices
  );
}
