import { Box, Tabs, Text } from '@flexisaf/flexibull2'
import React from 'react'
import { PageHeader } from '../../components/custom/header'
import fontSize from "../../utils/typography";
import Tags from "./Tags";
import Staff from "./Staff";
import {
  CanViewTabs,
  canViewStaff,
  canViewTags,
} from "../../components/custom/canView";

export default function index() {
  return (
    <Box>
      <PageHeader>
        <Text {...fontSize.h3}>
          <i className="icon-wrench-outline" /> Settings
        </Text>
      </PageHeader>
      <Tabs>
        <div label="Tags" disabled={CanViewTabs(canViewTags)}>
          <Tags />
        </div>
        <div label="Staff" disabled={CanViewTabs(canViewStaff)}>
          <Staff />
        </div>
      </Tabs>
    </Box>
  );
}
