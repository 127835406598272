import React from "react";
import { Text } from "@flexisaf/flexibull2";

import Theme from "../../utils/theme";
import fontSize from "../../utils/typography";

import { DashCellEmptyState } from "./wrappers";
import { Spacer } from "@flexisaf/flexibull2/build/layout";

const DashCardEmptyState = ({ icon, title, btnText, btnAction }) => {
  return (
    <DashCellEmptyState>
      <i className={icon || "icon-cloud"} style={{ fontSize: "18px" }} />
      <Spacer space="5px" />
      <Text {...fontSize.caption} block size="12px">
        {title || "No data"}
      </Text>
      <Spacer space="5px" />

      {btnText && (
        <Text
          style={{ cursor: "pointer" }}
          onClick={btnAction}
          color={Theme.PrimaryColor}
          block
          size="11px"
        >
          {btnText}
        </Text>
      )}
    </DashCellEmptyState>
  );
};

export default DashCardEmptyState;
