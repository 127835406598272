import request from "../utils/request";
import urls from "../utils/config";

const { API_BASE_URL,API_SAFAPPLY_URL } = urls || {};

export const httpGetSafapplyFeatures = () => {
  const url = `${API_SAFAPPLY_URL}/safbilling/integration/features`;

  return request({
    url,
    method: "get",
  });
};

export const httpGetLatestPlan = (id) => {
  const url = `${API_BASE_URL}/api/plans/latest-plan/${id}`;

  return request({
    url,
    method: "get",
  });
};

export const httpPostPlan = (plan) => {
  const url = `${API_BASE_URL}/api/plans`;

  return request({
    url,
    method: "post",
    data: plan,
  });
};
export const httpDeletePlan = (id) => {
  const url = `${API_BASE_URL}/api/plans/${id}`;

  return request({
    url,
    method: "delete",
  });
};
export const httpPutPlan = (data) => {
  const { id, plan } = data;

  const url = `${API_BASE_URL}/api/plans/${id}`;

  return request({
    url,
    method: "put",
    data: plan,
  });
};

export const httpToggleIsDemo = (options) => {
  const {id , isDemo} = options
  const url = `${API_BASE_URL}/api/plans/${id}/demo-status`;

  return request({
    url,
    method: "put",
    data: {isDemo},
  });
};