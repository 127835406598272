import { Grid, Box, Text, Avatar, Button } from "@flexisaf/flexibull2";
import { Spacer } from "@flexisaf/flexibull2/build/layout";
import { StatusIndicator } from "@flexisaf/flexibull2/build/statusindicator";
import fontSize from "../../../utils/typography";
import Theme from "../../../utils/theme";
import { AlertBars } from "../../../components/custom/wrappers";
import { LineGenerator } from "../../../components/barGenerator";
import { statusColors } from "../../../utils/colors";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  MultiTooltip,
  MultiTooltiptext,
  Tooltip,
  Tooltiptext,
} from "@flexisaf/flexibull2/build/tooltip";
import { Table } from "@flexisaf/flexibull2/build/table";
import { thousandFormatter } from "../../../utils/helpers";
import { EMPTY_VALUE } from "../../../utils/constants";


const SubscriptionDetails = ({ data }) => {
  const warningStatus = {
    message: (
      <>
        This subscription is expiring soon, on{" "}
        <strong>{moment(data?.endDate).format("DD MMM, YYYY")}</strong>
      </>
    ),
    color: statusColors["warning"].color,
    action: (
      <>
        <Button>
          <i className=" icon-cw-outline" /> Renew Subscription
        </Button>
      </>
    ),
  };

  const urls =
    typeof data?.urls === "string" ? JSON.parse(data?.urls) : data?.urls;
  const taxInfo =
    typeof data?.plan?.tax === "string"
      ? JSON.parse(data?.plan?.tax || "[]")
      : [];
  const adminInfromation =
   typeof data?.extra === "string" ? JSON.parse(data?.extra || "")  : data?.extra
   
  return (
    <Box className="bb">
      <Box>
        <Grid default="minmax(330px, 600px) 2fr">
          <Box pad="32px">
            <Text {...fontSize.h3}>Subscription Details</Text>
            <Spacer space="16px" />
            <Grid default="1fr 1fr" gap="16px">
              <Box>
                <Text {...fontSize.caption}>Customer</Text>
                <Link to={`/customers/${data?.customerId}`}>
                  <Box vAlign>
                    <Avatar
                      name={data?.customer?.name || ""}
                      margin="0 8px 0 0"
                      round
                      size="32px"
                    />{" "}
                    <Text bold block>
                      {data?.customer?.name}
                    </Text>
                  </Box>
                </Link>
              </Box>
              <Box>
              <Box>
                <Text {...fontSize.caption}>Subscription type</Text>
                <Text bold block>
                {data?.isDemo ? "Demo subscription" :"Live subscription"}
                </Text>
              </Box>
                </Box>
              <Box>
                <Text {...fontSize.caption}>Product</Text>
                <Box vAlign>
                  <Avatar
                    name="name"
                    src={
                      data?.plan?.product?.logo_url ||
                      data?.plan?.product?.name ||
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRut_K6b-UbqsQHfcvJrADvZVCMTh86_1yJ2Q&usqp=CAU"
                    }
                    margin="0 8px 0 0"
                    color="none"
                    size="32px"
                  />{" "}
                  <Text bold block>
                    {data?.plan?.product?.name}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Product plan</Text>
                <Text bold block>
                  {data?.plan?.name}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Billing Cycle</Text>
                <Text bold block>
                  {data?.plan?.billingCycle} {data?.plan?.billingCycleType}
                </Text>
              </Box>
            </Grid>
            <Spacer space="16px" />
            <Grid default="1fr 1fr" gap="16px">
              <Box>
                <Text {...fontSize.caption}>Start Date (Activation date)</Text>
                <Text bold block>
                  {data && moment(data?.startDate).format("DD MMMM, YYYY")}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>End Date (Expires on)</Text>
                <Text bold block>
                  {data && moment(data?.endDate).format("DD MMMM, YYYY")}
                </Text>
              </Box>
            </Grid>
            <Spacer space="16px" />
            <p>
              <Text>{data?.description}</Text>
            </p>
            <Spacer space="16px" />
            <Grid default="1fr 1fr" gap="16px">
              <Box>
                <Text {...fontSize.caption} block>
                  Brand Color
                </Text>
                <Text bold block>
                  <StatusIndicator
                    label={data?.status?.toUpperCase()}
                    color={statusColors[data?.status?.toLowerCase()]?.color}
                    compound
                  />
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption} block>
                  Server Status
                </Text>
                <Text bold block>
                  {data?.isLive ? (
                    <StatusIndicator
                      label="Provisioned"
                      compound
                      color={statusColors["active"].color}
                    />
                  ) : (
                    <Button height={28}>Start Provisioning</Button>
                  )}
                  {data?.isLive === "provisioning" && (
                    <>
                      <Text {...fontSize.caption} color={Theme.PrimaryOrange}>
                        Oops! Something went wrong with setting up your new
                        server.
                      </Text>
                      <MultiTooltip>
                        <Text {...fontSize.caption} color={Theme.PrimaryColor}>
                          click to see more details
                        </Text>
                        <MultiTooltiptext bottomStart>
                          Error goes inhere
                        </MultiTooltiptext>
                      </MultiTooltip>
                    </>
                  )}

                  {data?.isLive === "provisioning" && (
                    <Button progress height={28} disabled round>
                      In Progress
                    </Button>
                  )}

                  {data?.isLive === "false" && (
                    <Button height={28}>Start Provisioning</Button>
                  )}
                  {data?.isLive === "failed" && (
                    <Button
                      color={Theme.PrimaryOrange}
                      iconLeft={<i className="icon-attention-1" />}
                      height={28}
                    >
                      Restart Provisioning
                    </Button>
                  )}
                  {/* <StatusIndicator label={data?.isLive ? data.isLive : <>In progress</>} color={statusColors[data?.isLive ? "active" : "inprogress"]?.color} compound /> */}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Subscription type</Text>
                <Text bold block>
                  {data?.isDemo ? "Demo subscription" :" Real subscription"}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Sales Person</Text>
                <Box vAlign>
                  <Tooltip>
                    <Avatar
                      name={data?.salesPerson || ""}
                      margin="0 8px 0 0"
                      round
                      size="32px"
                    />
                    <Tooltiptext top>{data?.salesPerson}</Tooltiptext>
                  </Tooltip>
                  <Text bold block>
                    {data?.salesPerson} <br />
                    <a href={`mailto:${data?.salesPersonEmail}`}>
                      {data?.salesPersonEmail}
                    </a>
                  </Text>
                </Box>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Created at</Text>
                <Text bold block>
                  {data &&
                    moment(data?.createdAt).format("HH:mm DD MMMM, YYYY")}{" "}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Updated at</Text>
                <Text block>
                  {data &&
                    moment(data?.updatedAt).format("HH:mm DD MMMM, YYYY")}
                </Text>
              </Box>
            </Grid>

            <Spacer space="32px" />

            <Text {...fontSize.h3}>Admin Information</Text>
            <Spacer space="16px" />
            <Grid default="1fr 1fr 1fr" gap="16px">
              <Box>
                <Text {...fontSize.caption}>Admin Name</Text>
                <Text bold block style={{ textTransform: "capitalize" }}>
                  { adminInfromation
                    ? `${adminInfromation?.product_____first_name} ${adminInfromation?.product_____last_name}`
                    : EMPTY_VALUE}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Admin Phone</Text>
                <Text bold block>
                  {adminInfromation?.product_____phone || EMPTY_VALUE}
                </Text>
              </Box>
              <Box>
                <Text {...fontSize.caption}>Admin Email</Text>
                <Text bold block>
                  {adminInfromation?.product_____email || EMPTY_VALUE}
                </Text>
              </Box>
            </Grid>
            {urls?.length > 0 && (
              <>
                <Spacer space="32px" />
                <Text {...fontSize.h3}>Deployment Information</Text>
                <Spacer space="16px" />
                <Box>
                  <Text {...fontSize.caption}>Urls</Text>
                  <Grid default="1fr 1fr" gap="16px">
                    {urls.map((l, i) => (
                      <Box key={i} vAlign>
                        <Text bold block>
                          <a rel="noreferrer" target="_blank" href={l}>
                            {l}
                          </a>
                        </Text>
                      </Box>
                    ))}
                  </Grid>
                </Box>
              </>
            )}
          </Box>
          <Box className="bl" pad="32px" maxWidth="600px">
            <Text {...fontSize.h3}>Subscription Information</Text>
            <Spacer space="16px" />
            <Grid default="1fr 1fr" responsive={false}>
              <Box>{data && moment(data?.startDate).format("DD-MM-YY")}</Box>
              <Box align="right">
                {data && moment(data?.endDate).format("DD-MM-YY")}
              </Box>
            </Grid>
            <LineGenerator percentage={60} />
            <Spacer space="16px" />
            {warningStatus && (
              <AlertBars color={warningStatus.color}>
                <Box>
                  <i className="icon-block-outline" />
                </Box>
                <Text color={Theme.PrimaryFontColor}>
                  {warningStatus.message}
                </Text>
                {warningStatus.action && <Box>{warningStatus.action}</Box>}
              </AlertBars>
            )}
            <Spacer space="32px" />
            {data?.plan?.name && (
              <>
                <Text {...fontSize.h5}>Subscription breakdown</Text>
                <Table cellPad="13px 20px">
                  <table>
                    <thead>
                      <th>Plan Details</th>
                      <th className="ar">Amount</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data?.plan?.name}</td>
                        <td className="ar">
                          <Text>
                            ₦{thousandFormatter(+data.plan?.unitCost)}
                          </Text>
                        </td>
                      </tr>
                      {!taxInfo  ? taxInfo?.map((t, i) => (
                        <tr key={i}>
                          <td>{t?.name.toUpperCase()}</td>
                          <td className="ar">
                            <Text>
                              {" "}
                              ₦
                              {thousandFormatter(
                                (+data.plan?.unitCost * t.rate) / 100
                              )}
                            </Text>
                          </td>
                        </tr>
                      )) : ""}
                      <tr>
                        <td>
                          <Text>Discount: {data?.discount?.name}</Text>
                        </td>
                        <td className="ar">
                          <Text color="red">
                            {" "}
                            ₦ 5000
                          </Text>
                        </td>
                      </tr>
                      <tr className="bbt">
                        <td>
                          <Text bold>Total</Text>
                        </td>
                        <td className="ar">
                          <Text bold>
                            ₦ 5000
                          </Text>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Table>
              </>
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default SubscriptionDetails;
