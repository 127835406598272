import {
  Box,
  Text,
  Grid,
  Button,
  Input,
  Select,
  Spacer,
  Table,
  StatusIndicator,
  Modal,
  ModalBody,
  Switch,
  Notify,
} from '@flexisaf/flexibull2';
import { useState, useEffect } from 'react';
import useToggleDemo from '../../hooks/products/useToggleDemo';
import { Avatar } from '@flexisaf/flexibull2/build/avatar';
import { DropDown } from '@flexisaf/flexibull2/build/dropdown';
import { PageHeader } from '../../components/custom/header';
import { DashWrapper } from '../../components/custom/wrappers';
import { statusColors } from '../../utils/colors';
import Theme from '../../utils/theme';
import fontSize from '../../utils/typography';
import ProductEditor from './support/createProduct';
import request from '../../utils/request';
import urls from '../../utils/config';
import { errorNotifier, successNotifier } from '../../utils/helpers';
import moment from 'moment';
import { EmptyState } from '@flexisaf/flexibull2/build/emptystates';
import { Link, useNavigate } from 'react-router-dom';
import { FlexiPagination } from '@flexisaf/flexibull2/build/table';
import { PAGE_SIZES , IS_DEMO_PRODUCTS} from '../../utils/constants';
import useDebounce from '../../hooks/useDebounce';
import DeleteProductModal from './support/DeleteProduct';
import SectionLoader from '../../components/custom/sectionLoader';
import CanView, {
  Permissions,
  CanFilter,
} from "../../components/custom/canView";
import styled from "styled-components"

const Gridstyle = styled(Grid)`
grid-template-columns: 1fr 1fr 1fr 1fr ;
`

const Products = (props) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    show: false,
    data: null,
  });
  const [delModal, setDelModal] = useState({
    show: false,
    data: null,
  });
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [productStatus, setProductStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedTerm = useDebounce(searchTerm, 1000);
  const [saving, setSaving] = useState(false);
  const [deletingProduct, setDeletingProduct] = useState(false);
  const [productType, setProductType] = useState(null)
  const [productList, setProductList] = useState({ data: [] });
  const [loadingData, setLoadingData] = useState(false);
  const [togglingProduct, setTogglingStatus] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    data: null,
  });
const {toggleProdDemo} = useToggleDemo()
  const fetchProducts = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/products?include-contacts=true`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}&${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setProductList(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch products");
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    setLoadingData(true);
    fetchProducts();
  }, []);

  useEffect(() => {
    fetchProducts({
      status: productStatus?.value,
      search: debouncedTerm,
      offset: (page - 1) * pageSize,
      limit: pageSize,
      isDemo:productType?.value
    });
  }, [debouncedTerm, productStatus, pageSize, page, productType]);

  const toggleProductStatus = async () => {
    setTogglingStatus(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/products/${delModal.data?.id}`,
        method: "put",
        data: {
          ...delModal.data,
          status: delModal.data.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        },
      });
      if (!res.success) {
        throw res.message;
      }

      setDelModal({
        show: false,
        data: null,
      });
      Notify("The Product has been retired", {
        status: "warning",
      });
      fetchProducts();
    } catch (error) {
      errorNotifier(error.message || "Unable to update product");
    } finally {
      setTogglingStatus(false);
    }
  };

  const saveProduct = async (productData, cb = () => null) => {
    setSaving(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/products${
          modal.data?.id ? `/${modal.data?.id}` : ""
        }`,
        method: modal.data?.id ? "put" : "post",
        data: productData,
      });
      if (!res.success) {
        throw res.message;
      }
      cb();
      successNotifier("The Product was saved successfully");
      fetchProducts();
    } catch (error) {
      errorNotifier(error.message || "Unable to save product");
    } finally {
      setSaving(false);
    }
  };

  const deleteProduct = async (id) => {
    setDeletingProduct(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/products/${id}`,
        method: "delete",
      });
      if (!res.success) {
        throw res;
      }
      successNotifier("The Product was deleted successfully");
      setDeleteModal({ show: false, data: deleteModal.data });
      fetchProducts();
    } catch (error) {
      const errorMessage =
        error.statusCode === 422
          ? "product have an active plan"
          : error.message;
      errorNotifier(errorMessage || "Unable to delete product");
    } finally {
      setDeletingProduct(false);
    }
  };
  const column = [
    {
      title: "Product",
      key: "name",
      searchable: "searchable",
      custom: (row) => {
        return (
          <Box vAlign="center">
            <Avatar
              name={row.name}
              src={row.logoUrl ? row.logoUrl : ""}
              margin="0 8px 0 0"
              color="none"
              size="24px"
            />
            <Link to={`/products/${row.id}`}>
              <Text bold color={Theme.PrimaryColor}>
                {row.name}
              </Text>
            </Link>
          </Box>
        );
      },
    },
    {
      title: "Product Manager",
      key: "productManager",
      searchable: "searchable",
    },
    {
      title: "Product type",
      key: "producttype",
      custom: (row) => (
        
        <div
        onClick={() =>
          toggleProdDemo({
            data: { id: row.id, isDemo: !row.isDemo },
            onSuccess: () => {
            fetchProducts()
            },
        
          })
        }
        >
          <Switch checked={!row.isDemo} label= {row.isDemo ? "Demo" : "Live"}/>
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      custom: (row) => {
        return (
          <StatusIndicator
            compound
            label={row.status}
            {...statusColors[row.status?.toLowerCase()]}
          />
        );
      },
    },
    {
      title: "Created",
      key: "created",
      custom: (row) => (
        <Text>{moment(row.createdAt).format("DD MMMM, YYYY")}</Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      custom: (row) => {
        return (
          <Box align="left">
            <DropDown
              trigger="click"
              style={{ textAlign: "left" }}
              menuAlign="bottom right"
              label={
                <Box pad="8px" className="cursor">
                  <Text {...fontSize.h3}>...</Text>
                </Box>
              }
              menuList={CanFilter([
                {
                  onClick: () => navigate(`/products/${row.id}`),
                  label: "View Details",
                  iconLeft: "icon-popup",
                  can: [Permissions.READ_PRODUCTS],
                },
                {
                  onClick: () => setModal({ show: true, data: row }),
                  label: "Edit Product",
                  iconLeft: "icon-pencil",
                  can: [Permissions.UPDATE_PRODUCTS],
                },
                {
                  onClick: () => setDeleteModal({ show: true, data: row }),
                  label: "Delete Product",
                  color: Theme.PrimaryRed,
                  iconLeft: "icon-trash",
                  can: [Permissions.DELETE_PRODUCTS],
                },
                {
                  onClick: () =>
                    setDelModal({
                      show: true,
                      data: row,
                    }),
                  label: `${
                    row.status === "ACTIVE" ? "Deactivate" : "Activate"
                  } Product`,
                  color:
                    row.status === "ACTIVE"
                      ? Theme.PrimaryRed
                      : Theme.PrimaryGreen,
                  iconLeft: "icon-block-outline",
                  can: [Permissions.UPDATE_PRODUCTS],
                },
              ])}
            />
          </Box>
        );
      },
    },
  ];

  if (loadingData) return <SectionLoader />;

  return (
    <>
      <PageHeader>
        <Text {...fontSize.h3}>
          <i className="flexibull-clock" /> Products
        </Text>
      </PageHeader>
      <DashWrapper className="products">
        <Box pad="32px">
          <Gridstyle>
            <Box>
              <Input
                block
                type="search"
                placeholder="Search product name, email or phone number"
                iconLeft="flexibull-spin6"
                value={searchTerm}
                onChange={({ target: { value } }) => setSearchTerm(value)}
              />
            </Box>
            <Box>
              <Select
                block
                placeholder="Status"
                value={productStatus}
                options={[
                  { label: "All Status", value: "" },
                  { label: "ACTIVE", value: "ACTIVE" },
                  { label: "INACTIVE", value: "INACTIVE" },
                ]}
                onChange={(value) => setProductStatus(value)}
              />
            </Box>
            <Box>
                <Select
                block
                placeholder="Product type"
                options={ IS_DEMO_PRODUCTS.map((t) => ({ label: t.name, value: t.value }))}
                onChange={(e) => setProductType(e)}
                value={productType}
                isClearable
              />
              </Box>
            <Box align="right">
              <CanView can={[Permissions.CREATE_PRODUCTS]}>
                <Button onClick={() => setModal({ show: true, data: {} })}>
                  Add Product
                </Button>
              </CanView>
            </Box>
          </Gridstyle>
          <Spacer space="24px" />
          {productList.data.length === 0 ? (
            <EmptyState
              type="customers"
              title="No Product available"
              action={
                <CanView can={[Permissions.CREATE_PRODUCTS]}>
                  <Button onClick={() => setModal({ show: true, data: {} })}>
                    Add Product
                  </Button>
                </CanView>
              }
            />
          ) : (
            <>
              <Table responsive={false} report>
                <table>
                  <thead>
                    <tr>
                      {column &&
                        column.map((elem, index) => {
                          return (
                            <th scope="row" key={elem.key ? elem.key : index}>
                              {elem.title}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {productList.data &&
                      productList.data.map((elem, index) => {
                        return (
                          <tr key={index}>
                            {column.map((entry, index) => {
                              return (
                                <td key={index} data-label={entry.key}>
                                  {entry.custom
                                    ? entry.custom(elem)
                                    : elem[entry.key]}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Table>
              <Box pad="20px">
                <FlexiPagination
                  pageCounts={PAGE_SIZES}
                  total={productList?.count}
                  pageSize={pageSize}
                  itemsDisplayed
                  onChange={(s) => setPage(s)}
                  changePageSize={(s) => setPageSize(s?.value)}
                  current={page}
                />
              </Box>
            </>
          )}
        </Box>

        <Modal open={modal.show} center={false}>
          <ModalBody width="600px">
            <ProductEditor
              data={modal.data}
              closeModal={() => setModal({ show: false, data: {} })}
              onSaveProduct={saveProduct}
              saving={saving}
            />
          </ModalBody>
        </Modal>
        <Modal open={delModal.show} center={false}>
          <ModalBody width="600px">
            <Box pad="32px">
              <Text {...fontSize.h4} block bold>
                {delModal.data?.status === "ACTIVE" ? "Deactivate" : "Activate"}{" "}
                the product “{delModal.data?.name}”
              </Text>
              <Spacer space="16px" />
              <Text>
                {delModal.data?.status === "ACTIVE"
                  ? `
                  Deactivating the product will make it unavailable to all customers
                  and linked activities. Also the product will be achieved and it
                  can be restored at a later time.
                  `
                  : `Are you sure you want to activate this product`}
              </Text>
            </Box>
            <Grid default="auto max-content" responsive={false} className="bt">
              <Box pad="24px">
                <Button
                  onClick={() =>
                    setDelModal({
                      show: false,
                      data: null,
                    })
                  }
                  pale
                  fontColor={Theme.PrimaryGrey}
                  color={Theme.PrimaryGrey}
                >
                  <Text bold>No</Text>, Keep Product
                </Button>
              </Box>
              <Box pad="24px">
                <Button
                  onClick={() => {
                    toggleProductStatus();
                  }}
                  color={Theme.PrimaryRed}
                  progress={togglingProduct}
                  disabled={togglingProduct}
                >
                  <Text bold>Yes</Text>, Retire Product
                </Button>
              </Box>
            </Grid>
          </ModalBody>
        </Modal>
        <DeleteProductModal
          data={deleteModal.data}
          open={deleteModal.show}
          openModal={setDeleteModal}
          onDelete={deleteProduct}
          deleting={deletingProduct}
        />
      </DashWrapper>
    </>
  );
};

export default Products;
