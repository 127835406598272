import { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/auth/selectors";
import { getLocalAccessToken } from "../../utils/helpers";

const PrivateRoute = ({ children }) => {
  const accessToken = getLocalAccessToken();
  const user = useSelector(selectCurrentUser);

  const auth = !!(accessToken && user);

  return <Fragment>{auth ? children : <Navigate to="/login" />}</Fragment>;
};

export default PrivateRoute;
