import { createSelector } from "@reduxjs/toolkit";

const selectSubscriptions = (state) => state.subscriptions;

export const selectIsLoadingExpiringSoon = createSelector(
  [selectSubscriptions],
  (subscriptions) => subscriptions.isLoadingExpiringSoon
);

export const selectSubscriptionsExpiringSoon = createSelector(
  [selectSubscriptions],
  (subscriptions) => subscriptions.subscriptionsExpiringSoon
);

export const selectIsLoadingSubscriptions= createSelector(
  [selectSubscriptions],
  (subscriptions) => subscriptions.isLoadingSubscriptions
);

export const selectSubscriptionsData = createSelector(
  [selectSubscriptions],
  (subscriptions) => subscriptions.subscriptions
);


