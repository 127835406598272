import {
  Box,
  Text,
  Grid,
  Button,
  Input,
  Select,
  Spacer,
  Table,
  Switch,
  Modal,
  ModalBody,
} from '@flexisaf/flexibull2';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@flexisaf/flexibull2/build/avatar';
import { DropDown } from '@flexisaf/flexibull2/build/dropdown';
import { PageHeader } from '../../components/custom/header';
import { DashWrapper } from "../../components/custom/wrappers";
import Theme from '../../utils/theme';
import fontSize from '../../utils/typography';
import DeleteTemplate from './support/DeleteTemplate';
import request from '../../utils/request';
import urls from '../../utils/config';
import { errorNotifier } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FlexiPagination } from '@flexisaf/flexibull2/build/table';
import {
  PAGE_SIZES,
  EMPTY_VALUE,
  TEMPLATE_STATUS,
} from '../../utils/constants';
import useDebounce from '../../hooks/useDebounce';
import { EmptyState } from '@flexisaf/flexibull2/build/emptystates';
import TemplateEditor from './support/TemplateEditor';
import { successNotifier } from '../../utils/helpers';
import SectionLoader from '../../components/custom/sectionLoader';
import CanView, {
  Permissions,
  CanFilter,
} from "../../components/custom/canView";
import useTagsGet from "../../hooks/tags/useTagsGet";

const Templates = (props) => {
  const { tags, loadingTags } = useTagsGet();
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    show: false,
    data: null,
  });
  const [delModal, setDelModal] = useState({
    show: false,
    data: {},
  });
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedTerm = useDebounce(searchTerm, 1000);
  const [deleting, setDeleting] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [templateList, setTemplateList] = useState({
    count: 0,
    data: [],
  });

  const [status, setStatus] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState({ data: [] });
  const debouncedProductTerm = useDebounce(searchProduct, 1000);
  const [saving, setSaving] = useState(false);

  const changeTemplateStatus = async (id, value) => {
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/templates/${id}/status`,
        method: "PUT",
        data: { status: value ? "ACTIVE" : "ONBOARDING" },
      });
      if (!res.success) {
        throw res.message;
      }
      successNotifier("The Template status is updated successfully");
      fetchTemplates();
    } catch (error) {
      errorNotifier(error);
    }
  };

  const column = [
    {
      title: "Template Title",
      key: "subscriptionNumber",
      searchable: "searchable",
      custom: (row) => {
        return (
          <Box>
            <Link to={`/templates/${row.id}`}>
              <Text bold color={Theme.PrimaryColor}>
                {row.title}
              </Text>
            </Link>
          </Box>
        );
      },
    },
    {
      title: "Product",
      key: "product",
      searchable: "searchable",
      custom: (row) => {
        return (
          <Box vAlign="start">
            {row?.product?.name && (
              <Avatar
                name={row.product.name}
                src={row.product.logoUrl ? row.product.logoUrl : ""}
                margin="0 8px 0 0"
                color="none"
                size="18px"
              />
            )}
            <Box>
              <Text bold block>
                {row?.product?.name}
              </Text>
            </Box>
          </Box>
        );
      },
    },
    {
      title: "Created By",
      key: "salesPerson",
      searchable: "searchable",
      custom: (row) => <Text>{row?.createdBy || EMPTY_VALUE}</Text>,
    },
    {
      title: "Status",
      key: "isLive",
      custom: (row) => {
        return (
          <div
            onClick={() =>
              changeTemplateStatus(row.id, !(row.status === "ACTIVE"))
            }
          >
            <Switch checked={row.status === "ACTIVE"} />
          </div>
        );
      },
    },
    {
      title: "Date Created",
      key: "createdAt",
      custom: (row) => (
        <Text>{moment(row.createdAt).format("DD MMMM, YYYY")}</Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      custom: (row) => {
        return (
          <Box align="auto">
            <DropDown
              trigger="click"
              style={{ textAlign: "left" }}
              menuAlign="bottom right"
              label={
                <Box pad="8px" className="cursor">
                  <Text {...fontSize.h3}>...</Text>
                </Box>
              }
              menuList={CanFilter([
                {
                  onClick: () => navigate(`/templates/${row?.id}`),
                  label: "View Template",
                  iconLeft: "icon-popup",
                  can: [Permissions.READ_TEMPLATES],
                },
                {
                  onClick: () => setModal({ show: true, data: row }),
                  label: "Edit Template",
                  iconLeft: "icon-pencil",
                  can: [Permissions.UPDATE_TEMPLATES],
                },
                {
                  onClick: () => setDelModal({ show: true, data: row }),
                  label: "Delete Template",
                  color: Theme.PrimaryRed,
                  iconLeft: "icon-trash",
                  can: [Permissions.DELETE_TEMPLATES],
                },
              ])}
            />
          </Box>
        );
      },
    },
  ];

  const fetchTemplates = async () => {
    const options = {
      productId: product?.value,
      search: debouncedTerm,
      offset: (page - 1) * pageSize,
      limit: pageSize,
      status: status?.value,
    };
    selectedTags?.forEach((tag, i) => {
      options[`tags[${i}]`] = tag.value;
    });
    try {
      let url = `${urls.API_BASE_URL}/api/templates`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) url = `${url}?${unifiedOptions}`;

      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setTemplateList(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch templates");
    } finally {
      if (loadingData) setLoadingData(false);
    }
  };

  const fetchProducts = async (options = {}) => {
    try {
      let url = `${urls.API_BASE_URL}/api/products?`;
      const unifiedOptions = Object.keys(options)
        .filter((op) => options[op])
        .map((op) => `${op}=${options[op]}`)
        .join("&");
      if (unifiedOptions) {
        url = `${url}?${unifiedOptions}`;
      }
      const res = await request({ url });
      if (!res.success) {
        throw res.message;
      }
      setProducts(res.raw);
    } catch (error) {
      errorNotifier("Unable to fetch products");
    } finally {
      setLoadingProducts(false);
    }
  };

  const saveTemplate = async (data, cb = () => null) => {
    const { templateData, admin, extra, sourceTemplateId, isPublished } = data;
    const payload = {
      adminInfo: admin,
      title: templateData.templateTitle,
      description: templateData.templateDescription,
      productId: templateData.product.value,
      icon: extra?.templateIcon?.icon,
      tags: extra.templateTags?.map((t) => t.value),
      sourceTemplateId: sourceTemplateId,
      extraInfo: admin,
      isPublished
    };

    if (sourceTemplateId === null) {
      delete payload.sourceTemplateId;
    }
    setSaving(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/templates${
          modal.data?.id ? `/${modal.data?.id}` : ""
        }`,
        method: modal?.data?.id ? "put" : "post",
        data: payload,
      });

      if (!res.success) {
        throw res.message;
      }
      cb();
      successNotifier("The Template was saved successfully");
      fetchTemplates();
    } catch (error) {
      errorNotifier(error[0]?.message);
    } finally {
      setSaving(false);
    }
  };

  const deleteTemplate = async (id) => {
    setDeleting(true);
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/templates/${id}`,
        method: "delete",
      });

      if (!res.success) {
        throw res.message;
      }
      setDelModal({ show: false, data: {} });
      successNotifier("The Template was deleted successfully");
      fetchTemplates();
    } catch (error) {
      errorNotifier(error);
    } finally {
      setDeleting(false);
    }
  };

  useEffect(() => {
    setLoadingProducts(true);
    fetchProducts({
      search: debouncedProductTerm,
    });
    // eslint-disable-next-line
  }, [debouncedProductTerm]);

  useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line
  }, [debouncedTerm, pageSize, page, product, status, selectedTags]);

  if (loadingData) return <SectionLoader />;
  return (
    <>
      <PageHeader>
        <Text {...fontSize.h3}>
          <i className="icon-templates" /> Templates
        </Text>
      </PageHeader>
      <DashWrapper className="products">
        <Box pad="32px">
          {templateList?.data?.length === 0 && searchTerm === "" ? (
            <EmptyState
              type="customers"
              title={"You have not created any template"}
              action={
                <CanView can={[Permissions.CREATE_TEMPLATES]}>
                  <Button onClick={() => setModal({ show: true, data: {} })}>
                    Create New Template
                  </Button>
                </CanView>
              }
            />
          ) : (
            <>
              <Grid default="minmax(100px, 350px) minmax(100px, 130px) minmax(100px, 130px) auto auto">
                <Box>
                  <Input
                    block
                    type="search"
                    placeholder="Search Template Title, Tag, Product"
                    iconLeft="flexibull-spin6"
                    value={searchTerm}
                    onChange={({ target: { value } }) => setSearchTerm(value)}
                  />
                </Box>
                <Box>
                  <Select
                    block
                    placeholder="Product"
                    s
                    options={products.data.map((d) => ({
                      label: d.name,
                      value: d.id,
                      plan: d,
                    }))}
                    onInputChange={(e) => setSearchProduct(e)}
                    onChange={(e) => {
                      setProduct(e);
                    }}
                    value={product}
                    isClearable
                    isLoading={loadingProducts}
                  />
                </Box>
                <Box>
                  <Select
                    block
                    placeholder="Status"
                    options={TEMPLATE_STATUS}
                    onChange={(e) => setStatus(e)}
                    value={status}
                    isClearable
                  />
                </Box>
                <Box>
                  <Select
                    block
                    placeholder="Tags"
                    options={tags?.data?.map((t) => ({ label: t.name, value: t.id }))}
                    isLoading={loadingTags}
                    onChange={(e) => setSelectedTags(e)}
                    value={selectedTags}
                    isClearable
                    isMulti
                  />
                </Box>
                <Box align="right">
                  <Button onClick={() => setModal({ show: true, data: null })}>
                    Create New Template
                  </Button>
                </Box>
              </Grid>
              <Spacer space="24px" />
              {templateList?.data?.length === 0 ? (
                <EmptyState
                  type="customers"
                  title={`No ${
                    status?.value.toLowerCase() || ""
                  } templates currently available`}
                  action={
                    <Button onClick={() => setModal({ show: true, data: {} })}>
                      Create New Template
                    </Button>
                  }
                />
              ) : (
                <>
                  <Table responsive={false} report>
                    <table>
                      <thead>
                        <tr>
                          {column &&
                            column.map((elem, index) => {
                              return (
                                <th
                                  scope="row"
                                  key={elem.key ? elem.key : index}
                                >
                                  {elem.title}
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {templateList?.data &&
                          templateList.data.map((elem, index) => {
                            return (
                              <tr key={index}>
                                {column.map((entry, index) => {
                                  return (
                                    <td key={index} data-label={entry.key}>
                                      {entry.custom
                                        ? entry.custom(elem)
                                        : elem[entry.key]}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Table>
                  <Box pad="20px 0px">
                    <FlexiPagination
                      pageCounts={PAGE_SIZES}
                      total={templateList?.count}
                      pageSize={pageSize}
                      itemsDisplayed
                      onChange={(s) => setPage(s)}
                      changePageSize={(s) => setPageSize(s?.value)}
                      current={page}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
        <Modal open={modal.show} center={false}>
          <ModalBody width="600px">
            <TemplateEditor
              closeModal={() => setModal({ show: false, data: null })}
              data={modal.data}
              onSubmit={saveTemplate}
              saving={saving}
              allTemplates={templateList}
            />
          </ModalBody>
        </Modal>
        <DeleteTemplate
          data={delModal.data}
          open={delModal.show}
          openModal={setDelModal}
          onDelete={deleteTemplate}
          deleting={deleting}
        />
      </DashWrapper>
    </>
  );
};

export default Templates;
