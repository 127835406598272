import { call, all } from "redux-saga/effects";
import onGetFeatureData from "./getFeatures";
import onCreatePlan from "./createPlan";
import onDeletePlan from "./deletePlan";
import onUpdatePlan from "./updatePlan";
import onGetLatestPlan from "./getLatestPlan";
import onToggleDemo from "./togglePlanDemo";
export default function* planSaga() {
  yield all([
    call(onGetFeatureData),
    call(onCreatePlan),
    call(onDeletePlan),
    call(onUpdatePlan),
    call(onGetLatestPlan),
    call(onToggleDemo)
  ]);
}
