import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectProductsData, selectIsLoadingProducts } from "../../redux/products/selector";
import { fetchProductDataStart } from "../../redux/products/reducer";
import usePaginationWrapper from "../usePaginationWrapper";

import useDebounce from "../useDebounce";

const useProductsGet = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectProductsData);
  const isLoadingProducts = useSelector(selectIsLoadingProducts);
  const [searchTerm, setSearchTerm] = useState("");
  const [forceFetch, setForceFetch] = useState(0);
  const debouncedTerm = useDebounce(searchTerm, 1000);

  const { page, pageSize, setPage, setPageSize } = usePaginationWrapper();

  const fetchProducts = useCallback(() => {
    const options = {
      search: debouncedTerm || "",
      offset: page - 1,
      limit: pageSize,
    };
    dispatch(fetchProductDataStart(options));
  }, [dispatch, debouncedTerm, page, pageSize]);

  const refetch = useCallback(() => setForceFetch((prev) => prev + 1), []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts, forceFetch, debouncedTerm, page, pageSize]);

  return {
    products,
    isLoadingProducts: isLoadingProducts,
    setSearchTerm,
    searchTerm,
    setPage,
    setPageSize,
    page,
    pageSize,
    refetch,
  };
};

export default useProductsGet;