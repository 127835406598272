import {
  Box,
  Grid,
  Spacer,
  Input,
  Button,
  RadioButton,
} from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import styled from "styled-components";
import { useEffect } from "react";
import { Liner } from "../../custom/wrappers";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import { useForm } from "react-hook-form";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const DiscountStep2 = ({
  step,
  setStep,
  discountRedemptionSettings,
  getDefaultDiscountRedemptionSettings,
  bulkUpdateDiscountRedemptionSettings,
  handleClose,
  onSubmit,
  saving
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      ...getDefaultDiscountRedemptionSettings(),
    },
  });

  useEffect(() => {
    reset(discountRedemptionSettings);
    // eslint-disable-next-line
  }, [step, discountRedemptionSettings]);

  return (
    <Swiper active={step === 2}>
      <Box pad="32px">
        <Text {...fontSize.h4}>Redemption Settings</Text>
        <Spacer space="24px" />
        <Grid default="1fr 1fr" gap="24px">
          <Box>
            <Text bold>Redemption Type</Text>
            <Grid default="1fr" gap="10px">
              <Spacer space="10px" />
              <div
                onClick={() => setValue("redemptionType", "ONE_TIME")}
                style={{ cursor: "pointer" }}
              >
                <RadioButton
                  style={{ padding: "0px", margin: "0px" }}
                  name="radio"
                  label="One time"
                  checked={watch("redemptionType") === "ONE_TIME"}
                  block
                  size="16px"
                  height="20px"
                />
              </div>

              <div
                onClick={() => setValue("redemptionType", "FOREVER")}
                style={{ cursor: "pointer" }}
              >
                <RadioButton
                  style={{ padding: "0px", margin: "0px" }}
                  name="radio"
                  label="Forever"
                  checked={watch("redemptionType") === "FOREVER"}
                  block
                  size="16px"
                  height="20px"
                />
              </div>

              <div
                onClick={() => setValue("redemptionType", "LIMITED")}
                style={{ cursor: "pointer" }}
              >
                <RadioButton
                  style={{ padding: "0px", margin: "0px" }}
                  name="radio"
                  label="Limited Number of Times"
                  checked={watch("redemptionType") === "LIMITED"}
                  block
                  size="16px"
                  height="20px"
                />
              </div>
            </Grid>
          </Box>
          <Text color={Theme.SecondaryFontColor}>
            Redemption type refers to the number of times a discount can be
            applied to a subscription.
          </Text>
        </Grid>
        <Spacer space="10px" />

        {watch("redemptionType") === "LIMITED" && (
          <Grid default="1fr 1fr" gap="24px">
            <Input
              spaceTop
              required
              type="number"
              placeholder="No. of times"
              block
              value={watch("numberOfRedemptions") || ""}
              {...register("numberOfRedemptions", {
                required: "This is required",
              })}
              error={errors.discount?.message}
              onChange={({ target: { value } }) => setValue("numberOfRedemptions", value)}
            />

            <Text
              style={{ marginTop: "auto" }}
              color={Theme.SecondaryFontColor}
            >
              Number of times the discount should be applied to a subscription.
              Discount will be applied when an invoice is raised during renewal.
            </Text>
          </Grid>
        )}

        <Spacer space="24px" />
        <Liner borderstyle="dashed" />
        <Spacer space="24px" />

        <Grid default="1fr 1fr" gap="24px">
          <Input
            spaceTop
            type="number"
            label="Maxiumum Redemption"
            placeholder="Unlimited"
            block
            value={watch("maximumRedemptions") || ""}
            onChange={({ target: { value } }) =>
              setValue("maximumRedemptions", value)
            }
          />

          <Text style={{ marginTop: "auto" }} color={Theme.SecondaryFontColor}>
            Maximum number of subscriptions that can redeem this discount. This
            is unlimited by default.
          </Text>
        </Grid>
      </Box>

      <Grid
        default="auto max-content max-content"
        responsive={false}
        className="bt"
      >
        <Box pad="24px">
          <Button
            onClick={handleClose}
            pale
            color="rgba(0,0,0,0.0)"
            fontColor={Theme.PrimaryGrey}
          >
            Cancel
          </Button>
        </Box>
        <Box pad="24px 0">
          <Button
            pale
            color="rgba(0,0,0,0.0)"
            iconLeft={<i className="flexibull-left" />}
            onClick={() => {
              const values = getValues();
              bulkUpdateDiscountRedemptionSettings(values);
              setStep(1);
            }}
          >
            Previous
          </Button>
        </Box>
        <Box pad="24px">
          <Button
            onClick={handleSubmit((v) => {
              bulkUpdateDiscountRedemptionSettings(v);
              onSubmit(v);
            })}
            progress={saving}
          >
            Create
          </Button>
        </Box>
      </Grid>
    </Swiper>
  );
};

export default DiscountStep2;
