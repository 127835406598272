import { createSelector } from "@reduxjs/toolkit";

const selectDashboard = (state) => state.dashboard;

export const selectIsLoading = createSelector(
  [selectDashboard],
  (dashboard) => dashboard.isLoading
);

export const selectDashboardData = createSelector(
  [selectDashboard],
  (dashboard) => dashboard.dashboardData
);
