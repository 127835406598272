import React from 'react';
import { EmptyState } from '@flexisaf/flexibull2/build/emptystates';
import { useNavigate } from 'react-router-dom';
import { Button } from '@flexisaf/flexibull2';

const NotFound = ({ title, info, link }) => {
  const navigate = useNavigate();
  return (
    <EmptyState
      type="customers"
      title={title || 'Page not found'}
      info={info || 'Uh oh, you might be lost'}
      action={
        link ? (
          <Button onClick={() => navigate(`/${link}`)}>Return to {link}</Button>
        ) : (
          <Button onClick={() => navigate('/')}>Return to home</Button>
        )
      }
    />
  );
};

export default NotFound;
