import {
  Box,
  Text,
  Grid,
  Button,
  Spacer,
  Modal,
  ModalBody,
} from "@flexisaf/flexibull2";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";

const DeleteSubscriptionModal = function ({
  open,
  openModal,
  data,
  onDelete,
  deleting,
}) {
  if (!data?.customer) return null;
  return (
    <Modal open={open} center={false}>
      <ModalBody width="600px">
        <Box pad="32px">
          <Text {...fontSize.h4} block bold>
            Remove the subscription between "{data?.customer?.name}" and "
            {data?.plan?.product?.name}"
          </Text>
          <Spacer space="16px" />
          <Text>
            Deleting the subscription denies {data?.customer?.name} from
            accessing
            {data?.plan?.product?.name}.
          </Text>
        </Box>
        <Grid default="auto max-content" responsive={false} className="bt">
          <Box pad="24px">
            <Button
              onClick={() => openModal({ show: false, data: data })}
              pale
              fontColor={Theme.PrimaryGrey}
              color={Theme.PrimaryGrey}
              disabled={deleting}
            >
              <Text bold>No</Text>, Keep Subscription
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              onClick={() => onDelete(data?.id)}
              color={Theme.PrimaryRed}
              progress={deleting}
            >
              <Text bold>Yes</Text>, Remove Subscription
            </Button>
          </Box>
        </Grid>
      </ModalBody>
    </Modal>
  );
};
export default DeleteSubscriptionModal;
