import React, { useState } from "react";
import { Liner } from "../../components/custom/wrappers";
import {
  Box,
  Grid,
  Spacer,
  Input,
  Button,
  Text,
  Table,
  Switch,
  Checkbox,
} from "@flexisaf/flexibull2";
import Theme from "../../utils/theme";
import fontSize from "../../utils/typography";
import styled from "styled-components";
import useFeatureGet from "../../hooks/plan/useFeatureGet";
import SectionLoader from "../../components/custom/sectionLoader";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;
const Checkboxstyle = styled(Checkbox)`
  & .check-toggle {
    position: relative;
    left: 40px;
  }
  & input {
    width: 23px;
    height: 19px;
  }
`;
const Boxopen = styled(Box)`
  display: ${(props) => (props.open ? "block" : "none")};
`;

const PlanStep2 = ({
  step,
  setStep,
  handleAddFeature,
  handleCheckBox,
  handleIncrementValueChange,
  handleUnlimitedCheck,
  closeModal,
  addFeature,
  featureLogic,
  unlimitedCheck,
  handleDeleteInfo,
  setAddFeature,
  addMoreInfo
}) => {
  const { isLoadingFeature } = useFeatureGet();

  const [openFeature, setOpenFeature] = useState(false);
  const columninfo = [
    {
      title: "Additional features",
      key: "additionalfeatures",
      custom: (row) => <Text>{row?.name}</Text>,
    },
   {
    title:"",
    key:'delete',
  custom: (row , index) => (
    <i
      className="icon-trash"
      style={{
        color: "#ffff",
        background: Theme.PrimaryRed,
        padding: "5px",
     
        borderRadius: "5px",
        cursor: "pointer",
        marginLeft: "2.6rem",
      }}
      onClick={() => handleDeleteInfo(index)}
    />
  )      
   }
    
  ]

  const column = [
    {
      title: "Feature",
      key: "feature",
      custom: (row) => <Text>{row?.name.replace(/_/g, ' ')}</Text>,
    },

    {
      title: "Unlimited",
      key: "limited",
      custom: (row, index) => (
        <>
          {typeof row?.value === "number" && !Number.isNaN(row?.value) ? (
            <Switch
              checked={unlimitedCheck[index]}
              onChange={({ target: { checked } }) => {
                handleUnlimitedCheck(index, checked);
              }}
            />
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Value",
      key: "value",
      custom: (row, index) => {
        if (typeof row?.value === "number" && !Number.isNaN(row?.value)) {
          return (
            <Box style={{display:'flex',justifyContent:"space-between"}}>
              {row?.value === -1 ? (
                <>
                 
                  <Text
                    bold
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      color: "#CBD5E4",
                    }}
                  >
                    0
                  </Text>
               
                </>
              ) : (
                <>
                
      
                  <Text bold style={{ textAlign: "center", fontSize: "16px" }}>
                    <Input
                      type=""
                      placeholder=""
                      width="70%"
                      value={row.value}
                      onChange={({ target: { value } }) => {
                         handleIncrementValueChange(index,value)}}
                    />
                  </Text>
                 
                </>
              )}
            </Box>
          );
        } else if (typeof row?.value === "boolean") {
          return (
            <Checkboxstyle
              block
              style={{ marginRight: "0px" }}
              checked={row?.value}
              onChange={({ target: { checked } }) =>
                handleCheckBox(checked, index)
              }
              size="16px"
            />
          );
        } else if (row.value === "" && row.value !==  Boolean) {
          return (
            <i
              className="icon-trash"
              style={{
                color: "#ffff",
                background: Theme.PrimaryRed,
                padding: "5px",
                borderRadius: "5px",
                cursor: "pointer",
                marginLeft: "2.6rem",
              }}
              onClick={() => handleDeleteInfo(index)}
            />
          );
        } else {
          return null;
        }
      },
      
    },
  ];

  if (isLoadingFeature) return <SectionLoader />;

  return (
    <>
      <Swiper active={step === 2}>
        <Box pad="32px">
          <Text {...fontSize.h4}>Features & Configuration</Text>
          <Spacer space="24px" />

          <Table responsive={false} report>
            <table>
              <thead style={{textAlign:"center"}}>
                <tr>
                  {column &&
                    column.map((elem, index) => {
                      return (
                        <th scope="row" key={elem.key ? elem.key : index}>
                          {elem.title}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {featureLogic?.map((elem, outerindex) => {
                  return (
                    <tr key={outerindex}>
                      {column.map((entry, innerindex) => {
                        return (
                          <td key={innerindex} data-label={entry.key}>
                            {entry.custom
                              ? entry.custom(elem, outerindex)
                              : elem[entry.key]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Table>
          <Spacer space="10px" />
          <Liner borderstyle="dashed" />
          <Spacer space="10px" />
          <Text bold>Add more Information</Text>
          <Spacer space="5px" />
          
          <Table responsive={false} report>
            <table>
              <thead>
                <tr>
                  {columninfo &&
                    columninfo.map((elem, index) => {
                      return (
                        <th scope="row" key={elem.key ? elem.key : index}>
                          {elem.title}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {addMoreInfo?.map((elem, outerindex) => {
                  return (
                    <tr key={outerindex}>
                      {columninfo.map((entry, innerindex) => {
                        return (
                          <td key={innerindex} data-label={entry.key}>
                            {entry.custom
                              ? entry.custom(elem, outerindex)
                              : elem[entry.key]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Table>
          <Boxopen open={openFeature}>
            <Input
              spaceTop
              style={{ width: "100%" }}
              placeholder="Add more features "
              value={addFeature}
              onChange={({ target: { value } }) => setAddFeature(value)}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                placeItems: "flex-end",
              }}
            >
              <Spacer space="15px" />
              <i
                className="icon-trash"
                style={{
                  color: "#ffff",
                  background: Theme.PrimaryRed,
                  padding: "8px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => setOpenFeature(false)}
              />
              <Spacer space="15px" />
              <Button
                pad="24px"
                onClick={() => {
                  handleAddFeature();
                }}
              >
                {" "}
                Add More Info
              </Button>
            </Box>
          </Boxopen>
          <Box>
            {!openFeature && (
              <Button
                pad="24px"
                style={{ position: "relative", left: "469px" }}
                onClick={() => setOpenFeature(true)}
              >
                {" "}
                Add Info
              </Button>
            )}
          </Box>
          <Spacer space="24px" />
          <Liner borderstyle="dashed" />
          <Grid  default="auto max-content max-content"
        responsive={false}
        className="bt">
            <Box pad="24px">
              <Button
                pale
                color="rgba(0,0,0,0.0)"
                fontColor={Theme.PrimaryGrey}
                onClick={() => closeModal()}
              >
                close
              </Button>
            </Box>

            <Box pad="24px">   
            <Button
          pale
          color="rgba(0,0,0,0.0)"
          iconLeft={<i className="flexibull-left" />}
          onClick={() => setStep(1)}
        >
          Previous
        </Button>
        </Box>

            <Box pad="24px">
              <Button onClick={() => setStep(3)}>Next</Button>
            </Box>
          </Grid>
        </Box>
      </Swiper>
    </>
  );
};

export default PlanStep2;
