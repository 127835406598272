import request from "../utils/request";
import urls from "../utils/config";

const { API_BASE_URL } = urls || {};

export const httpGetProducts = () => {
  const url = `${API_BASE_URL}/api/products?include-contacts=true`;

  return request({
    url,
    method: "get",
  });
};
export const httpToggleIsDemo = (options) => {
  const {id , isDemo} = options
  const url = `${API_BASE_URL}/api/products/${id}/demo-status`;

  return request({
    url,
    method: "put",
    data: {isDemo},
  });
};
