import { createSelector } from "@reduxjs/toolkit";

const selectTags = (state) => state.tags;

export const selectIsLoadingTags = createSelector(
  [selectTags],
  (tags) => tags.isLoadingTags
);

export const selectIsCreatingTags = createSelector(
  [selectTags],
  (tags) => tags.isCreatingTags
);
export const selectIsUpdatingTag = createSelector(
  [selectTags],
  (tags) => tags.isUpdatingTag
);

export const selectIsDeletingTag = createSelector(
  [selectTags],
  (tags) => tags.isDeletingTag
);

export const selectTagsData = createSelector([selectTags], (tags) => tags.tags);
