import request from "../utils/request";
import urls from "../utils/config";

const { API_BASE_URL } = urls || {};

export const httpPostLogin = (payload) => {
  const { username, password } = payload;

  const url = `${API_BASE_URL}/api/auth/login`;

  return request({
    url,
    method: "post",
    data: { username, password },
  });
};

export const httpPostPasswordResetRequest = (payload) => {
  const {
    data: { username },
  } = payload;

  const url = `${API_BASE_URL}/api/auth/reset-request`;

  return request({
    url,
    method: "post",
    data: { username },
  });
};

export const httpPostPasswordReset = (payload) => {
  const {
    data: { userId, password, token },
  } = payload;

  const url = `${API_BASE_URL}/api/auth/reset`;

  return request({
    url,
    method: "post",
    data: { userId, password, token },
  });
};

export const httpPostConfirmEmail = (payload) => {
  const {
    data: { userId, token },
  } = payload;

  const url = `${API_BASE_URL}/api/auth/confirm`;

  return request({
    url,
    method: "post",
    data: { userId, token },
  });
};
