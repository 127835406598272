import { useEffect,  useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLatestPlan } from "../../redux/plans/selector";
import { fetchLatestPlanStart } from "../../redux/plans/reducer";




const useLatestPlanGet = (id) => {
  const dispatch = useDispatch();
  const latestPlan = useSelector(selectLatestPlan);
 
  const fetchLatestPlan = useCallback(() => {
 
    dispatch(fetchLatestPlanStart({id}));
  }, [dispatch, id]);

  useEffect(() => {
    fetchLatestPlan();
  }, [fetchLatestPlan]);

  return {
    latestPlan,
  };
};

export default useLatestPlanGet;