import { createSelector } from "@reduxjs/toolkit";

const selectRoles = (state) => state.roles;

export const selectIsLoadingRoles = createSelector(
  [selectRoles],
  (roles) => roles.isLoadingRoles
);

export const selectIsCreatingRole = createSelector(
  [selectRoles],
  (roles) => roles.isCreatingRole
);

export const selectIsUpdatingRole = createSelector(
  [selectRoles],
  (roles) => roles.isUpdatingRole
);

export const selectIsDeletingRole = createSelector(
  [selectRoles],
  (roles) => roles.isDeletingRole
);

export const selectRolesData = createSelector(
  [selectRoles],
  (roles) => roles.roles
);

export const selectIsLoadingPrivileges = createSelector(
  [selectRoles],
  (roles) => roles.isLoadingPrivileges
);

export const selectPrivilegeModules = createSelector(
  [selectRoles],
  (roles) => roles.privilegeModules
);

export const selectPermissionsGroup = createSelector(
  [selectPrivilegeModules],
  (modules) => {
    const permissions = {};

    Object.values(modules)?.forEach((module) => {
      module?.privileges.forEach((privilege) => {
        const str = `${privilege?.name}_${privilege?.id?.split(":")[0]}`;

        permissions[str.toUpperCase()] = privilege?.id;
      });
    });

    // this should return an object in a shape like
    //{READ_USER: user:r, UPDATE_USER: user:u, ...}

    return permissions;
  }
);
