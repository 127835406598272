import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchStaffStart } from "../../redux/staff/reducer";
import {
  selectIsLoadingStaff,
  selectStaffData,
} from "../../redux/staff/selectors";

const useStaffGet = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoadingStaff);
  const staff = useSelector(selectStaffData);
  const [fetch, setFetch] = useState(0);

  const refetch = () => setFetch((prev) => prev + 1);

  useEffect(() => {
    dispatch(fetchStaffStart());
  }, [dispatch, fetch]);

  return {
    loadingStaff: isLoading,
    staff: staff?.data || [],
    refetch,
  };
};

export default useStaffGet;
