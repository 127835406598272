import {
  Box,
  Text,
  Grid,
  Button,
  Spacer,
  Modal,
  ModalBody,
} from '@flexisaf/flexibull2';
import Theme from '../../../utils/theme';
import fontSize from '../../../utils/typography';

const DeleteTemplate = function ({ open, openModal, data, deleting, onDelete }) {
  if (data?.customer) return null;
  return (
    <Modal open={open} center={false}>
      <ModalBody width="600px">
        <Box pad="32px">
          <Text {...fontSize.h4} block bold>
            Delete Template
          </Text>
          <Spacer space="16px" />
          <Text block>
            Deleting <Text bold>{data?.title}</Text> will make it
            unavailable and future subscriptions can’t be created with this
            template.
          </Text>
          <Spacer space="16px" />
          <Text>
            <Text bold>NOTE: </Text>It will not affect subscriptions already
            created using the template.
          </Text>
        </Box>
        <Grid default="auto max-content" responsive={false} className="bt">
          <Box pad="24px">
            <Button
              onClick={() => openModal({ show: false, data: data })}
              pale
              fontColor={Theme.PrimaryGrey}
              color={Theme.PrimaryGrey}
              disabled={deleting}
            >
              <Text bold>No</Text>, Keep Template
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              onClick={() => onDelete(data?.id)}
              color={Theme.PrimaryRed}
              progress={deleting}
            >
              <Text bold>Yes</Text>, Delete Template
            </Button>
          </Box>
        </Grid>
      </ModalBody>
    </Modal>
  );
};
export default DeleteTemplate;
