import React from "react";
import {
  Box,
  Button,
  Table,
  Modal,
  ModalBody,
  Text,
} from "@flexisaf/flexibull2";
import { useState } from "react";
import StaffEditor from "./StaffEditor";
import DeleteStaff from "./DeleteStaff";
import { DropDown } from "@flexisaf/flexibull2/build/dropdown";
import fontSize from "../../utils/typography";
import { EmptyState } from "@flexisaf/flexibull2/build/emptystates";
import SectionLoader from "../../components/custom/sectionLoader";
import useStaffGet from "../../hooks/staff/useStaffGet";
import useStaffEditor from "../../hooks/staff/useStaffEditor";
import CanView, {
  Permissions,
  CanFilter,
} from "../../components/custom/canView";

export default function Staff() {
  const { loadingStaff, staff, refetch } = useStaffGet();
  const {
    deleteStaff,
    isDeleting,
    createStaff,
    updateStaff,
    isCreating,
    isUpdating,
  } = useStaffEditor();
  const [showModal, setShowModal] = useState({ show: false, data: {} });
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    data: {},
  });
  const handleSaveStaff = async (data, cb) => {
    const isEdit = !!showModal?.data?.id;

    if (isEdit) {
      updateStaff({
        data: { id: showModal?.data?.id, staff: data },
        onSuccess: () => {
          refetch();
          cb();
        },
      });
    } else {
      createStaff({
        staff: data,
        onSuccess: () => {
          refetch();
          cb();
        },
      });
    }
  };

  const handleDeleteStaff = async (id, cb) => {
    deleteStaff({
      id,
      onSuccess: () => {
        refetch();
        cb();
      },
    });
  };

  if (loadingStaff) return <SectionLoader />;

  return (
    <Box>
      {staff.length === 0 ? (
        <EmptyState
          type="customers"
          title="No Staff available"
          action={
            <CanView can={[Permissions.CREATE_STAFF]}>
              <Button onClick={() => setShowModal({ show: true, data: {} })}>
                Add Staff
              </Button>
            </CanView>
          }
        />
      ) : (
        <>
          <Box align="right">
            <CanView can={[Permissions.CREATE_STAFF]}>
              <Button onClick={() => setShowModal({ show: true, data: {} })}>
                Create Staff
              </Button>
            </CanView>
          </Box>
          <Table style={{ overflowX: "visible" }}>
            <table>
              <thead pad="20px">
                <tr>
                  <th width="50px">s/n</th>
                  <th>Name</th>
                  <th>email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {staff?.map((s, index) => (
                  <tr key={s.id}>
                    <td>{index + 1}</td>
                    <td>{s.name}</td>
                    <td>{s.email}</td>
                    <td>
                      <Box align="left">
                        <DropDown
                          trigger="click"
                          style={{ textAlign: "left" }}
                          menuAlign="bottom right"
                          label={
                            <Box pad="8px" className="cursor">
                              <Text {...fontSize.h3}>...</Text>
                            </Box>
                          }
                          menuList={CanFilter([
                            {
                              onClick: () =>
                                setShowModal({ show: true, data: s }),
                              label: "Edit Staff",
                              iconLeft: "icon-pencil",
                              can: [Permissions.UPDATE_STAFF],
                            },
                            {
                              onClick: () =>
                                setShowDeleteModal({ show: true, data: s }),
                              label: "Delete Staff",
                              iconLeft: "icon-trash",
                              can: [Permissions.DELETE_STAFF],
                            },
                          ])}
                        />
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Table>
        </>
      )}

      <Modal open={showModal.show} center={false}>
        <ModalBody width="600px">
          <StaffEditor
            open={showModal.show}
            staff={staff}
            data={showModal.data}
            onSaveStaff={handleSaveStaff}
            closeModal={() => setShowModal({ show: false, data: {} })}
            saving={isCreating || isUpdating}
          />
        </ModalBody>
      </Modal>
      <DeleteStaff
        open={showDeleteModal.show}
        onClose={() => setShowDeleteModal({ show: false, data: {} })}
        data={showDeleteModal.data}
        onDelete={handleDeleteStaff}
        deleting={isDeleting}
      />
    </Box>
  );
}
