import { call, put, takeLatest } from "redux-saga/effects";
import { loginFailure, loginStart, loginSuccess } from "../reducer";

import {
  setLocalAccessToken,
  setLocalRefreshToken,
  setLocalUser,
} from "../../../utils/helpers";
import { httpPostLogin } from "../../../services/auth";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* login({ payload }) {
  const { onSuccess, onFailure } = payload;

  const response = yield call(httpPostLogin, payload);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(loginSuccess(raw));
    setLocalAccessToken(response?.raw.access_token);
    setLocalRefreshToken(response?.raw.refresh_token);
    setLocalUser(JSON.stringify(raw.user));

    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield put(loginFailure(raw));
    networkErrorHandler(
      statusCode,
      message?.message || message || "Unable to login"
    );
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onLogin() {
  yield takeLatest(loginStart.type, login);
}
