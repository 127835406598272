import {
  Box,
  Text,
  Grid,
  Button,
  Spacer,
  Modal,
  ModalBody,
} from "@flexisaf/flexibull2";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";

const DeleteRoleModal = ({ open, openModal, data, onDelete, deleting }) => {
  return (
    <Modal open={open} center={false}>
      <ModalBody width="600px">
        <Box pad="32px">
          <Text {...fontSize.h4} block bold>
            Delete Role
          </Text>
          <Spacer space="16px" />
          <Text>
            Deleting{" "}
            <strong style={{ textTransform: "capitalize" }}>
              {data?.name}
            </strong>{" "}
            role will make it unavailable to be assigned to a user. <br />
            Note that if you have users with this role, you will not be able to
            delete it.
            <br />
            <br />
            <strong>
              Are you sure you want to delete this role, this action cannot be
              undone.
            </strong>
          </Text>
        </Box>
        <Grid default="auto max-content" responsive={false} className="bt">
          <Box pad="24px">
            <Button
              onClick={() => openModal({ show: false, data: {} })}
              pale
              fontColor={Theme.PrimaryGrey}
              color={Theme.PrimaryGrey}
            >
              <Text bold>No</Text>, Keep Role
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              progress={deleting}
              onClick={() => {
                onDelete(data?.id);
              }}
              color={Theme.PrimaryRed}
            >
              <Text bold>Yes</Text>, Delete Role
            </Button>
          </Box>
        </Grid>
      </ModalBody>
    </Modal>
  );
};

export default DeleteRoleModal;
