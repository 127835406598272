import request from "../utils/request";
import urls from "../utils/config";

const { API_BASE_URL } = urls || {};

export const httpGetTags = () => {
  const url = `${API_BASE_URL}/api/tags`;

  return request({
    url,
    method: "get",
  });
};

export const httpPostTag = (data) => {
  const { name , productId} = data
  const url = `${API_BASE_URL}/api/tags`;

  return request({
    url,
    method: "post",
    data: {name, productId},
  });
};

export const httpPutTag = (data) => {
  const { id, name, productId } = data;
  const url = `${API_BASE_URL}/api/tags/${id}`;

  return request({
    url,
    method: "put",
    data: { name, productId }, 
  });
};


export const httpDeleteTag = (id) => {
  const url = `${API_BASE_URL}/api/tags/${id}`;

  return request({
    url,
    method: "delete",
  });
};
