import { call, put, takeLatest } from "redux-saga/effects";
import {
  createStaffStart,
  createStaffFailure,
  createStaffSuccess,
} from "../reducer";

import { httpPostStaff } from "../../../services/staff";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* createStaff({ payload }) {
  const { staff, onSuccess, onFailure } = payload;
  const response = yield call(httpPostStaff, staff);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(createStaffSuccess());
    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield put(createStaffFailure(raw));
    networkErrorHandler(
      statusCode,
      message || "Unable to create this staff, please try again later."
    );

    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onCreateStaff() {
  yield takeLatest(createStaffStart.type, createStaff);
}
