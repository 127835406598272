import { useDispatch, useSelector } from "react-redux";

import {
  createRoleStart,
  updateRoleStart,
  deleteRoleStart,
} from "../../redux/roles/reducer";

import {
  selectIsCreatingRole,
  selectIsDeletingRole,
  selectIsUpdatingRole,
} from "../../redux/roles/selectors";

const useRolesEditor = () => {
  const dispatch = useDispatch();
  const isCreating = useSelector(selectIsCreatingRole);
  const isUpdating = useSelector(selectIsUpdatingRole);
  const isDeleting = useSelector(selectIsDeletingRole);

  const createRole = ({ role, onSuccess, onFailure }) => {
    dispatch(createRoleStart({ role, onSuccess, onFailure }));
  };

  const updateRole = ({ data, onSuccess, onFailure }) => {
    dispatch(updateRoleStart({ data, onSuccess, onFailure }));
  };

  const deleteRole = ({ id, onSuccess, onFailure }) => {
    dispatch(deleteRoleStart({ id, onSuccess, onFailure }));
  };

  return {
    createRole,
    isCreating,
    updateRole,
    isUpdating,
    deleteRole,
    isDeleting,
  };
};

export default useRolesEditor;
