import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLoading: false,
  isLoadingPasswordResetRequest: false,
  isConfirmingEmail: true,
  isLoadingPasswordReset: false,
  isAuthenticated: false,
  currentUser: null,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.currentUser = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.currentUser = null;
      state.error = action.payload;
    },
    logOutUser: (state) => {
      state.isAuthenticated = false;
      state.currentUser = null;
      state.error = null;
      state.isLoading = false;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    passwordResetRequestStart: (state, action) => {
      state.isLoadingPasswordResetRequest = true;
    },
    passwordResetRequestFailure: (state, action) => {
      state.isLoadingPasswordResetRequest = false;
      state.error = action.payload;
    },
    passwordResetRequestSuccess: (state) => {
      state.isLoadingPasswordResetRequest = false;
    },
    passwordResetStart: (state, action) => {
      state.isLoadingPasswordReset = true;
    },
    passwordResetFailure: (state, action) => {
      state.isLoadingPasswordReset = false;
      state.error = action.payload;
    },
    passwordResetSuccess: (state) => {
      state.isLoadingPasswordReset = false;
    },
    confirmEmailStart: (state, action) => {
      state.isConfirmingEmail = true;
    },
    confirmEmailSuccess: (state) => {
      state.isConfirmingEmail = false;
    },
    confirmEmailFailure: (state, action) => {
      state.isConfirmingEmail = false;
      state.error = action.payload;
    },
  },
});

export const {
  loginFailure,
  loginStart,
  loginSuccess,
  logOutUser,
  setCurrentUser,
  passwordResetRequestFailure,
  passwordResetRequestStart,
  passwordResetRequestSuccess,
  passwordResetFailure,
  passwordResetStart,
  passwordResetSuccess,
  confirmEmailFailure,
  confirmEmailStart,
  confirmEmailSuccess,
} = authSlice.actions;

const persistConfig = {
  key: "auth",
  storage,
  whitelist: ["currentUser", "isAuthenticated"],
};

const authReducer = authSlice.reducer;
export default persistReducer(persistConfig, authReducer);
