import { useDispatch, useSelector } from "react-redux";

import {
  createUserStart,
  updateUserStart,
  deleteUserStart,
  toggleUserStatusStart,
} from "../../redux/users/reducer";
import {
  selectIsCreateUser,
  selectIsUpdateUser,
  selectIsDeleteUser,
  selectIsToggleUserStatus,
} from "../../redux/users/selectors";
const useUserEditor = () => {
  const dispatch = useDispatch();
  const isCreating = useSelector(selectIsCreateUser);
  const isUpdating = useSelector(selectIsUpdateUser);
  const isDeleting = useSelector(selectIsDeleteUser);
  const isToggleUserStatus = useSelector(selectIsToggleUserStatus);
  const createUser = ({ user, onSuccess, onFailure }) => {
    dispatch(createUserStart({ user, onSuccess, onFailure }));
  };
  const updateUser = ({ data, onSuccess, onFailure }) => {
    dispatch(updateUserStart({ data, onSuccess, onFailure }));
  };
  const deleteUser = ({ id, onSuccess, onFailure }) => {
    dispatch(deleteUserStart({ id, onSuccess, onFailure }));
  };

  const toggleUserStatus = ({ data, onSuccess, onFailure }) => {
    dispatch(toggleUserStatusStart({ data, onSuccess, onFailure }));
  };
  return {
    isCreating,
    createUser,
    isUpdating,
    updateUser,
    isDeleting,
    deleteUser,
    isToggleUserStatus,
    toggleUserStatus,
  };
};

export default useUserEditor;
