import { useDispatch, useSelector } from "react-redux";
import { loginStart } from "../../redux/auth/reducer";
import { selectIsLoading, selectCurrentUser } from "../../redux/auth/selectors";

const useLoginPost = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const user = useSelector(selectCurrentUser);

  const loginUser = (payload) => {
    dispatch(loginStart(payload));
  };

  return {
    loginUser,
    isLoading,
    user,
  };
};

export default useLoginPost;
