import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRolesData,
  selectIsLoadingRoles,
} from "../../redux/roles/selectors";
import { fetchRolesStart } from "../../redux/roles/reducer";
import usePaginationWrapper from "../usePaginationWrapper";

import useDebounce from "../useDebounce";

const useRolesGet = () => {
  const dispatch = useDispatch();
  const roles = useSelector(selectRolesData);
  const isLoadingRoles = useSelector(selectIsLoadingRoles);
  const [searchTerm, setSearchTerm] = useState("");
  const [forceFetch, setForceFetch] = useState(0);
  const debouncedTerm = useDebounce(searchTerm, 1000);

  const { page, pageSize, setPage, setPageSize } = usePaginationWrapper();

  const fetchRoles = useCallback(() => {
    const options = {
      search: debouncedTerm || "",
      offset: page - 1,
      limit: pageSize,
    };
    dispatch(fetchRolesStart(options));
  }, [dispatch, debouncedTerm, page, pageSize]);

  const refetch = useCallback(() => setForceFetch((prev) => prev + 1), []);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles, forceFetch, debouncedTerm, page, pageSize]);

  return {
    roles,
    loadingRoles: isLoadingRoles,
    setSearchTerm,
    searchTerm,
    setPage,
    setPageSize,
    page,
    pageSize,
    refetch,
  };
};

export default useRolesGet;