import { Box, Grid, Spacer, Input, Button } from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Theme from "../../utils/theme";
import fontSize from "../../utils/typography";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;

const StaffEditor = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState({
    name: null,
    email: null,
  });

  const closeModal = () => {
    props.closeModal();
    setEmail('');
    setName('');
    setError({name: null, email: null,});
  };

  const handleSubmit = () => {
    setError({name: null, email: null,});
    if (!name) return setError((prevState)=> ({...prevState, name: 'Name is required'}));
    if (!email) return setError((prevState)=> ({...prevState, email: 'email is required'}));
    if (props.staff.some(s => s.email.toLowerCase() === email.toLowerCase() && s.id !== props.data?.id)) {
      return setError((prevState)=> ({...prevState, email: 'email already exist'}));
    }
    setError({name: null, email: null,});
    props.onSaveStaff({name, email}, closeModal);
  }

  useEffect(() => {
    if (!props.open) {
      setName('');
    }
  }, [props.open])

  useEffect(() => {
    if (props.data?.id) {
      setEmail(props.data.email)
      setName(props.data.name)
    }
    // eslint-disable-next-line
  }, [props.data])

  return (
    <Box>
      <Swiper active={true}>
        <Grid default="auto max-content" responsive={false} className="bb">
          <Box pad="24px 32px">
            <Text {...fontSize.h3} uppercase>
              {props.data?.id ? 'Edit' : 'Add'} Staff
            </Text>
          </Box>
        </Grid>
        <Box pad="32px">
          <Input
            spaceTop
            label="Staff Name"
            placeholder="Staff Name"
            block
            required
            value={name}
            error={error?.name}
            onChange={({ target: { value } }) => setName(value)}
          />
          <Spacer space="32px"/>
          <Input
            spaceTop
            label="Staff Email"
            placeholder="Staff Email"
            block
            required
            disabled={props.data.email}
            value={email}
            error={error?.email}
            onChange={({ target: { value } }) => setEmail(value)}
          />
          <Spacer space="24px" />
        </Box>

        <Grid default="auto max-content" responsive={false} className="bt">
          <Box pad="24px">
            <Button
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              progress={props.saving}
              onClick={handleSubmit}
            >
              {props.data?.id ? 'Update' : 'Save'} Staff
            </Button>
          </Box>
        </Grid>
      </Swiper>
    </Box>
  );
};
export default StaffEditor;
