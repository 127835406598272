import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchSubscriptionExpiringStart,
  fetchSubscriptionExpiringFailure,
  fetchSubscriptionExpiringSuccess,
} from "../reducer";

import { httpGetSubscriptionsExpiringSoon } from "../../../services/subscriptions";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getSubscriptionsExpiring() {
  const response = yield call(httpGetSubscriptionsExpiringSoon);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchSubscriptionExpiringSuccess(raw));
  } else {
    yield put(fetchSubscriptionExpiringFailure(raw));
    networkErrorHandler(
      statusCode,
      message ||
        "Unable to fetch subscriptions expiring soon. Please try again later."
    );
  }
}

export default function* onGetSubscriptionsExpiringSoon() {
  yield takeLatest(
    fetchSubscriptionExpiringStart.type,
    getSubscriptionsExpiring
  );
}
