import {
  Box,
  Text,
  Grid,
  Button,
  Modal,
  ModalBody,
} from '@flexisaf/flexibull2';
import { useEffect, useState } from 'react';
import { PageHeader } from '../../components/custom/header';
import { DashWrapper } from '../../components/custom/wrappers';
import Theme from '../../utils/theme';
import fontSize from '../../utils/typography';
import { Tabs } from '@flexisaf/flexibull2/build/tabs';
import { Link, useParams } from 'react-router-dom';
import TemplateDetails from './support/TemplateDetails';
import TemplateUsage from './support/TemplateUsage';
import urls from '../../utils/config';
import request from '../../utils/request';
import { errorNotifier } from '../../utils/helpers';
import { DropDown } from '@flexisaf/flexibull2/build/dropdown';
import { darken } from 'polished';
import TemplateEditor from './support/TemplateEditor';
import DeleteTemplate from './support/DeleteTemplate';

import NotFound from '../../components/custom/notFound';
import SectionLoader from '../../components/custom/sectionLoader';

const Template = (props) => {
  const params = useParams();
  const [loadingData, setLoadingData] = useState(true);
  const [templateDetails, setTemplateDetails] = useState(null);
  const [modal, setModal] = useState({
    show: false,
    data: null,
  });
  const [delModal, setDelModal] = useState({
    show: false,
    data: {},
  });
  const [saving] = useState(false);
  const [deleting] = useState(false);

  const fetchTemplate = async (id) => {
    try {
      const res = await request({
        url: `${urls.API_BASE_URL}/api/templates/${id}`,
      });
      if (!res.success) {
        throw res.message;
      }
      setTemplateDetails(res.raw);
    } catch (error) {
      errorNotifier('Unable to fetch subscription');
      setTemplateDetails(null);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      setLoadingData(true);
      fetchTemplate(params.id);
    }
  }, [params.id]);


  if (loadingData) return <SectionLoader />;
  if (!loadingData && !templateDetails)
    return (
      <NotFound
        title="Template not found"
        info="Seems you are trying to access a template that does not exist"
        link="templates"
      />
    );
  return (
    <>
      <PageHeader>
        <Text {...fontSize.h3}>
          <i className="icon-templates" /> Template
        </Text>
      </PageHeader>
      <DashWrapper className="products">
        <Box>
          <Box pad="32px" className="bb">
            <Grid default="auto max-content">
              <Box>
                <Text {...fontSize.caption} block>
                  <Link to="/templates">
                    <i className="icon-left" /> back to template list
                  </Link>
                </Text>
                <Box margin="8px 0 0 0">
                  <Text {...fontSize.h3} color={Theme.PrimaryDark} block>
                    #TEMP-
                    {String(templateDetails?.templateNumber).padStart(5, '0')}
                  </Text>
                  <Text bold>{templateDetails?.title}</Text> -{' '}
                  <Text>{templateDetails?.product?.name}</Text>
                </Box>
              </Box>
              <Box>
                <Button
                  style={{
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                  }}
                  onClick={() =>
                    setModal({ show: true, data: templateDetails })
                  }
                >
                  Edit Information
                </Button>
                <DropDown
                  label={
                    <Button
                      color={darken(0.1, Theme.PrimaryColor)}
                      pad="0 10px"
                      style={{
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                      }}
                      icon={<i className="flexibull-angle-down" />}
                    />
                  }
                  menuAlign="bottom right"
                  menuList={[
                    {
                      onClick: () =>
                        setDelModal({ show: true, data: templateDetails }),
                      label: 'Delete Template',
                      color: Theme.PrimaryRed,
                      iconLeft: 'icon-trash',
                    },
                  ]}
                />
              </Box>
            </Grid>
          </Box>
          <Box className="product-wrapper">
            <Tabs
              bgColor="none"
              labelColor="none"
              padContent="0"
              persist={false}
            >
              <div label="Template Details">
                <TemplateDetails data={templateDetails} />
              </div>
              <div label="Template Usage">
                <TemplateUsage />
              </div>
            </Tabs>
          </Box>
        </Box>
        <Modal open={modal.show} center={false}>
          <ModalBody width="600px">
            <TemplateEditor
              closeModal={() => setModal({ show: false, data: null })}
              data={modal.data}
              onSubmit={props.saveTemplate}
              saving={saving}
            />
          </ModalBody>
        </Modal>
        <DeleteTemplate
          data={delModal.data}
          open={delModal.show}
          openModal={setDelModal}
          onDelete={() => {}}
          deleting={deleting}
        />
      </DashWrapper>
    </>
  );
};

export default Template;
