import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Layout } from "@flexisaf/flexibull2";
import Theme from "../../utils/theme";
import { useNavigate } from "react-router-dom";

import { setCurrentUser } from "../../redux/auth/reducer";
import {
  getQueryVariable,
  setLocalAccessToken,
  setLocalRefreshToken,
  setLocalUser
} from "../../utils/helpers";
import request from "../../utils/request";
import urls from "../../utils/config";
import { ORGANIZATION_ID, PRODUCT_ID } from "../../utils/constants";
import NotFound from "../../components/custom/notFound";
import { DashWrapper } from "../../components/custom/wrappers";
import SectionLoader from "../../components/custom/sectionLoader";

const GoogleAuthVerify = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const { UMS_BASE_URL } = urls;

  const getUserDetails = async (code) => {
    const url = `${UMS_BASE_URL}/auth/products/${PRODUCT_ID}/organisations/${ORGANIZATION_ID}/transfer`;
    const res = await request({ method: "post", url, data: { code } });
    if (res.success) {
      setLocalAccessToken(res.raw.access_token);
      setLocalRefreshToken(res.raw.refresh_token);
      setLocalUser(JSON.stringify(res?.raw?.user));

      dispatch(
        setCurrentUser({ ...(res?.raw || {}), ...(res?.raw?.user || {}) })
      );
    
       navigate("/dashboard");
      window.location.reload();
    }
    
    return res;
  };

  useEffect(() => {
    const getToken = async () => {
      const code = getQueryVariable("transfer_code") || "";
      if (code) {
        const res = await getUserDetails(code, ORGANIZATION_ID);
        if (res?.success) {
          navigate(`/dashboard`);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    };

    getToken();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout theme={Theme}>
      <DashWrapper>
        {!error ? (
          <SectionLoader />
        ) : (
          <NotFound
            title="No User Found"
            info="The user with that email address does not exist or is wrong."
            link="login"
          />
        )}
      </DashWrapper>
    </Layout>
  );
};

export default GoogleAuthVerify;
