import { call, put, takeLatest } from "redux-saga/effects";
import {
  passwordResetFailure,
  passwordResetStart,
  passwordResetSuccess,
} from "../reducer";
import { httpPostPasswordReset } from "../../../services/auth";
import { networkErrorHandler } from "../../../utils/errorHandlers";

function* resetPassword({ payload }) {
  const { onSuccess, onFailure } = payload;

  const response = yield call(httpPostPasswordReset, payload);

  const { raw, statusCode, success } = response;

  if (success) {
    yield put(passwordResetSuccess());

    if (onSuccess) {
      onSuccess();
    }
  } else {
    yield put(passwordResetFailure(raw));
    networkErrorHandler(statusCode);

    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onPasswordReset() {
  yield takeLatest(passwordResetStart.type, resetPassword);
}
