import React from "react";
import styled from "styled-components";
import {
  Box,
  Text,
  Grid,
  Button,
  Spacer,
  Table,
  Checkbox,
} from "@flexisaf/flexibull2";
import fontSize from "../../utils/typography";
import Theme from "../../utils/theme";
import { StatusIndicator } from "@flexisaf/flexibull2/build/statusindicator";
import { Link } from "react-router-dom";
import infinity from "../../assets/images/infinity.svg";
import { EMPTY_VALUE } from "../../utils/constants";
import { thousandFormatter } from "../../utils/helpers";

const Swiper = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
`;
const StatusIndicators = styled(StatusIndicator)`
  & .indicator {
    background-color: ${(props) => (props.Status ? "red" : "#A1DFCA")};
  }
`;
const StatusIndicatores = styled(StatusIndicator)`
  & .indicator {
    background-color: ${(props) => (props.isPublished ? "red" : "#A1DFCA")};
  }
`;

const PlanStep4 = ({
  step,
  setStep,
  closeModal,
  planInformation,
  featureLogic,
  status,
  isPublished,
  planAlias,
  onSavePlan,
  name,
  id,
  isUpdating,
  isDemo,
  autoRenew,
  isCreating,
}) => {

  const column = [
    {
      title: "Feature",
      key: "feature",
      custom: (row, index) => <Text>{row?.name.replace(/_/g, ' ')}</Text>,
    },

    {
      title: "Aliases",
      key: "feature",
      custom: (row, index) => <Text>{row?.alias}</Text>,
    },
    {
      title: "Value",
      key: "value",
      custom: (row, index) => (
        <>
          {typeof row?.value === "number" && !Number.isNaN(row?.value) ? (
            <>
              {row?.value === -1 ? (
                <img src={infinity} alt="minus" style={{ width: "15px" }} />
              ) : (
                <Text bold style={{ textAlign: "center", fontSize: "16px" }}>
                  {row?.value}
                </Text>
              )}
            </>
          ) : typeof row?.value === "boolean" ? (
            <Checkbox
              block
              style={{ marginRight: "0px" }}
              checked={row?.value}
              size="16px"
            />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      <Swiper active={step === 4}>
        <Spacer space="24px" />
        <Box pad=" 0 32px">
          <Box
            round="high"
            background={Theme.PrimarySurface}
            className="bordered"
            pad="24px"
          >
            <Grid default="1fr 1fr 1fr" gap="24px">
              <Box>
                <Text {...fontSize.caption} block>
                  Plan Name
                </Text>
                <Text bold>{planInformation.name || EMPTY_VALUE}</Text>
              </Box>
              <Box>
                <Text {...fontSize.caption} block>
                  Alias
                </Text>
                <Link>
                  <Text bold color={Theme.PrimaryColor}>
                    {planAlias?.planAlias || EMPTY_VALUE}
                  </Text>
                </Link>
              </Box>
              <Box>
                <Text {...fontSize.caption} block>
                  Plan type
                </Text>
                <Text bold>
                  {" "}
                  {planInformation?.planType.value || EMPTY_VALUE}
                </Text>
              </Box>
            </Grid>
            <Spacer space="24px" />
            <Grid default="1fr 1fr" style={{ width: "70%" }} responsive={false}>
              <Box>
                <Text {...fontSize.caption} block>
                  Product
                </Text>
                <Text bold>{name?.name.toUpperCase() || EMPTY_VALUE}</Text>
              </Box>
              <Box>
                <Text {...fontSize.caption} block>
                  Segment
                </Text>
                {planAlias?.segment?.label ? (
                  <Box>
                    <Text
                      bold
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {planAlias?.segment?.label}
                    </Text>
                  </Box>
                ) : (
                  <Box
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {EMPTY_VALUE}
                  </Box>
                )}
              </Box>
            </Grid>
          </Box>
        </Box>
        <Spacer space="24px" />
        <Box pad=" 0 32px">
          <Box
            round="high"
            background={Theme.PrimarySurface}
            className="bordered"
            pad="24px"
          >
            <Spacer space="24px" />

            <Grid default="1fr 1fr 1fr" gap="24px">
              <Box>
                <Text {...fontSize.caption} block>
                  Billing Alias
                </Text>
                <Text bold>{planAlias?.billingAlias || EMPTY_VALUE}</Text>
              </Box>
              <Box>
                <Text {...fontSize.caption} block>
                  Billing Cycle
                </Text>
                <Link>
                  {autoRenew ? (
                    <Text bold block>
                      On Auto Renew
                    </Text>
                  ) : (
                    <Box>
                      {planInformation?.billingCycleFrequency ? (
                        <Text
                          bold
                        >{`   every ${planInformation?.billingCycleFrequency}
              
                 ${planInformation?.durationType?.value}
                `}</Text>
                      ) : (
                        ""
                      )}
                    </Box>
                  )}
                </Link>
              </Box>
              <Box>
                <Text {...fontSize.caption} block>
                  Unit Cost
                </Text>
                <Text bold>
                  {planInformation?.billingCycleCurrrency?.value === "NGN"
                    ? `₦${thousandFormatter(planInformation.unitCost)}`
                    : `$${thousandFormatter(planInformation.unitCost)}` ||
                      EMPTY_VALUE}
                </Text>
              </Box>
            </Grid>
            <Spacer space="24px" />
            <div>
              <Grid default="1fr 1fr 1fr">
                <Box>
                  <Text {...fontSize.caption} block>
                    Plan Status
                  </Text>
                  <Box>
                    <StatusIndicators
                      compound
                      label={status ? "Activated" : "Deactivated"}
                    />
                  </Box>
                </Box>

                <Box>
                  <Text {...fontSize.caption} block>
                    Publish Status
                  </Text>
                  <StatusIndicatores
                    compound
                    label={isPublished ? "Published" : "Unpublish"}
                  />
                </Box>
                <Box>
                  <Text {...fontSize.caption} block>
                    Is this a demo Plan ?
                  </Text>
                  <StatusIndicatores compound label={isDemo ? "Yes" : "No"} />
                </Box>
              </Grid>
            </div>
          </Box>
        </Box>
        <Spacer space="24px" />
        <Box pad=" 0 32px">
          <Box
            round="high"
            background={Theme.PrimarySurface}
            className="bordered"
            pad="24px"
          >
            <Table style={{ overflowX: "visible" }}>
              <table style={{ backgroundColor: "#F2F4F6" }}>
                <thead pad="20px">
                  <tr>
                    {column &&
                      column.map((elem, index) => {
                        return (
                          <th scope="row" key={elem.key ? elem.key : index}>
                            {elem.title}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(featureLogic) &&
                    featureLogic.map((elem, outerindex) => (
                      <tr key={outerindex}>
                        {column.map((entry, innerindex) => {
                          return (
                            <td key={innerindex} data-label={entry.key}>
                              {entry.custom
                                ? entry.custom(elem, outerindex)
                                : elem[entry.key]}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                </tbody>
              </table>
            </Table>
          </Box>
        </Box>
        <Spacer space="24px" />

        <Grid
          default="auto max-content max-content"
          responsive={false}
          className="bt"
          gap="0"
        >
          <Box pad="24px">
            <Button
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
          </Box>
          <Box pad="24px 0">
            {/* <Button
              // iconRight={<i className="flexibull-right" />}
              style={{ border: "none" }}
              pale
            >
              Save as draft
            </Button> */}
          </Box>
          <Grid
            default="auto max-content max-content"
            responsive={false}
            className="bt"
            gap="0"
          >
            
            <Box pad="24px">
              <Button
                pale
                color="rgba(0,0,0,0.0)"
                iconLeft={<i className="flexibull-left" />}
                onClick={() => setStep(3)}
              >
                Previous
              </Button>
            </Box>
            <Box pad="24px">
              <Button
                iconRight={<i className="flexibull-right" />}
                onClick={() => onSavePlan()}
                progress={id ? isUpdating : isCreating}
              >
                {id ? "Finish" : "Publish"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Swiper>
    </>
  );
};

export default PlanStep4;
