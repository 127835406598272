import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isLoadingFeatures: false,
  features: null,
  error: null,
  isCreatePlan: false,
  latestPlan:null,
  isDeletingPlan: false,
  isLatestPlan:false,
  isUpdatingPlan: false,
};

export const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    fetchFeatureDataStart: (state, action) => {
      state.isLoadingFeatures = true;
      state.error = null;
    },
    fetchFeatureDataSuccess: (state, action) => {
      state.isLoadingFeatures = false;
      state.features = action.payload;
      state.error = null;
    },
    fetchFeatureDataFailure: (state, action) => {
      state.isLoadingFeatures = false;
      state.error = action.payload;
      state.features = null;
    },
    createPlanStart: (state, action) => {
      state.isCreatePlan = true;
      state.error = null;
    },
    createPlanSuccess: (state, action) => {
      state.isCreatePlan = false;
      state.error = null;
    },
    createPlanFailure: (state, action) => {
      state.isCreatePlan = false;
      state.error = action.payload;
    },
    deletePlanStart: (state, action) => {
      state.isDeletingPlan = true;
    },
    deletePlanSuccess: (state) => {
      state.isDeletingPlan = false;
    },
    deletePlanFailure: (state, action) => {
      state.isDeletingPlan = false;
      state.error = action.payload;
    },
    updatePlanStart: (state, action) => {
      state.isUpdatingPlan = true;
    },
    updatePlanSuccess: (state) => {
      state.isUpdatingPlan = false;
    },
    updatePlanFailure: (state, action) => {
      state.isUpdatingPlan = false;
      state.error = action.payload;
    },
    fetchLatestPlanStart: (state, action) => {
      state.isLatestPlan = true;
      state.error = null;
    },
    fetchLatestPlanSuccess: (state, action) => {
      state.isLatestPlan = false;
      state.latestPlan = action.payload;
      state.error = null;
    },
    fetchLatestPlanFailure: (state, action) => {
      state.isLatestPlan = false;
      state.error = action.payload;
      state.latestPlan = null;
    },
    togglePLanDemo: (state) => {
      state.error = null;
    },
    togglePlanDemoSuccess: (state) => {
      state.error = null;
    },
    togglePlanDemoFailure: (state, action) => {
      state.error = action.payload;
    },
  },
  
});

export const {
  fetchFeatureDataFailure,
  fetchFeatureDataStart,
  fetchFeatureDataSuccess,
  createPlanFailure,
  createPlanSuccess,
  createPlanStart,
  deletePlanFailure,
  deletePlanStart,
  deletePlanSuccess,
  isDeletingPlan,
  updatePlanFailure,
  updatePlanStart,
  updatePlanSuccess,
  isUpdatingPlan,
  fetchLatestPlanStart,
  fetchLatestPlanFailure,
  fetchLatestPlanSuccess,
  latestPlan,
  togglePlanDemoSuccess,
  togglePLanDemo,
  togglePlanDemoFailure
} = planSlice.actions;

const planReducer = planSlice.reducer;

export default planReducer;
