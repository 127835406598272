import { call, put, takeLatest } from "redux-saga/effects";
import {
  createPlanStart,
  createPlanFailure,
  createPlanSuccess,
} from "../reducer";
import { successNotifier } from "../../../utils/helpers";

import { httpPostPlan } from "../../../services/plans";
import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* createPlan({ payload }) {
  const { plan, onSuccess, onFailure } = payload;
  const response = yield call(httpPostPlan, plan);

  const { raw, statusCode, success } = response;

  if (success) {
    yield put(createPlanSuccess());
    if (onSuccess) {
      onSuccess();
    }
    successNotifier("Plan was successfully created");
  } else {
    yield put(createPlanFailure(raw));
    networkErrorHandler(
      statusCode,
      "Unable to create this Plan, a Plan with this email might already exist or an error occured."
    );

    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onCreatePlan() {
  yield takeLatest(createPlanStart.type, createPlan);
}
