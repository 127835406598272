import { useDispatch } from "react-redux";
import { toggleSubscriptionDemo } from "../../redux/subscriptions/reducer";

const useToggleDemo = () => {
  const dispatch = useDispatch();  


  const toggleSubDemo =({data, onSuccess, onFailure }) => {
    dispatch(toggleSubscriptionDemo({ data, onSuccess, onFailure }));
  }
   
  return {
 toggleSubDemo,
  };
};

export default useToggleDemo;
