import React from 'react';
import { Box, Text } from '@flexisaf/flexibull2';
import fontSize from '../../utils/typography';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const ComingSoonBox = styled(Box)`
  filter: blur(1.5px);
  position: relative;
  pointer-events: none;
`;

const ComingSoonText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ComingSoon = ({ children }) => {
  return (
    <Container>
      <ComingSoonBox>{children}</ComingSoonBox>
      <ComingSoonText {...fontSize.comingSoonText}>Coming Soon</ComingSoonText>
    </Container>
  );
};
