import { call, all } from "redux-saga/effects";
import onGetUserData from "./getUsers";
import onCreateUser from "./createUser";
import onUpdateUser from "./updateUser";
import onDeleteUser from "./deleteUser";
import onToggleUserStatus from "./toggleUserStatus"
export default function* usersSaga() {
  yield all([
    call(onGetUserData),
    call(onCreateUser),
    call(onUpdateUser),
    call(onDeleteUser),
    call(onToggleUserStatus)
  ]);
}
