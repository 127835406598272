import { call, put, takeLatest } from "redux-saga/effects";
import {
  deleteUserFailure,
  deleteUserStart,
  deleteUserSuccess,
} from "../reducer";

import { httpDeleteUser } from "../../../services/users";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";
export function* deleteUser({ payload }) {
  const { id, onSuccess, onFailure } = payload;
  const response = yield call(httpDeleteUser, id);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(deleteUserSuccess());

    if (onSuccess) {
      onSuccess();
    }
    successNotifier("The user was successfully deleted");
  } else {
    yield put(deleteUserFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to delete this user");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onDeleteUser() {
  yield takeLatest(deleteUserStart.type, deleteUser);
}
