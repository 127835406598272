import {
  Box,
  Text,
  Grid,
  Button,
  Input,
  Select,
  Spacer,
  Table,
  StatusIndicator,
  Modal,
  ModalBody,
  DropDown,
} from "@flexisaf/flexibull2";
import { useState } from "react";
import { statusColors } from "../../utils/colors";
import Theme from "../../utils/theme";
import fontSize from "../../utils/typography";
import UserEditor from "./support/createUser";
import moment from "moment";
import { EmptyState } from "@flexisaf/flexibull2/build/emptystates";
import { FlexiPagination } from "@flexisaf/flexibull2/build/table";
import { EMPTY_VALUE, PAGE_SIZES } from "../../utils/constants";
import DeleteUserModal from "./support/DeleteUser";
import SectionLoader from "../../components/custom/sectionLoader";
import useUsersGet from "../../hooks/users/useUsersGet";
import useUserEditor from "../../hooks/users/useUserEditor";
import CanView, {
  Permissions,
  CanFilter,
} from "../../components/custom/canView";

const UserManagement = () => {
  const [modal, setModal] = useState({
    show: false,
    data: null,
  });
  const [delModal, setDelModal] = useState({
    show: false,
    data: null,
  });
  const {
    loadingUser,
    users,
    page,
    pageSize,
    setPage,
    setPageSize,
    searchTerm,
    refetch,
    setSearchTerm,
  } = useUsersGet();

  const { isToggleUserStatus, toggleUserStatus } = useUserEditor();
  const [userStatus, setUserStatus] = useState(null);
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    data: null,
  });

  const column = [
    {
      title: "Username",
      key: "username",
      searchable: "searchable",
    },
    {
      title: "First Name",
      key: "first_name",
      searchable: "searchable",
    },
    {
      title: "Last Name",
      key: "last_name",
      searchable: "searchable",
    },
    {
      title: "Other Name",
      key: "other_name",
      searchable: "searchable",
    },
    {
      title: "Status",
      key: "status",
      custom: (row) => {
        return (
          <StatusIndicator
            compound
            label={row?.status ? "ACTIVE" : "INACTIVE"}
            {...(row?.status ? statusColors.active : statusColors.inactive)}
          />
        );
      },
    },
    {
      title: "Created",
      key: "date_created",
      custom: (row) => (
        <Text>{moment(row.createdAt).format("DD MMMM, YYYY")}</Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      custom: (row) => {
        return (
          <Box align="left">
            <DropDown
              trigger="click"
              style={{ textAlign: "left" }}
              menuAlign="bottom right"
              label={
                <Box pad="8px" className="cursor">
                  <Text {...fontSize.h3}>...</Text>
                </Box>
              }
              menuList={CanFilter([
                {
                  onClick: () => setModal({ show: true, data: row }),
                  label: "Edit User",
                  iconLeft: "icon-pencil",
                  can: [Permissions.UPDATE_USERS],
                },
                {
                  onClick: () =>
                    setDelModal({
                      show: true,
                      data: row,
                    }),
                  label: `${row.status ? "Deactivate" : "Activate"} User`,
                  color: row.status ? Theme.PrimaryRed : Theme.PrimaryGreen,
                  iconLeft: "icon-block-outline",
                  can: [Permissions.DELETE_USERS],
                },
                {
                  onClick: () =>
                    setDeleteModal({
                      show: true,
                      data: row,
                    }),
                  label: ` Delete User`,
                  color:  Theme.PrimaryRed ,
                  iconLeft:  "icon-trash",
                  can: [Permissions.DELETE_USERS],
                },
              ])}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box pad="32px">
        <Grid default="minmax(100px, 350px) minmax(100px, 130px) auto">
          <Box>
            <Input
              block
              type="search"
              placeholder="Search user's name, email or phone number"
              iconLeft="flexibull-spin6"
              value={searchTerm}
              onChange={({ target: { value } }) => setSearchTerm(value)}
            />
          </Box>
          <Box>
            <Select
              block
              placeholder="Status"
              value={userStatus}
              options={[
                { label: "All Status", value: "" },
                { label: "ACTIVE", value: "ACTIVE" },
                { label: "INACTIVE", value: "INACTIVE" },
              ]}
              onChange={(value) => {
                setPage(1);
                setUserStatus(value);
              }}
            />
          </Box>
          <Box align="right">
            <CanView can={[Permissions.CREATE_USERS]}>
              <Button onClick={() => setModal({ show: true, data: {} })}>
                Add Users
              </Button>
            </CanView>
          </Box>
        </Grid>
        <Spacer space="24px" />
        {users?.users?.length === 0 ? (
          <EmptyState
            type="customers"
            title="No user available"
            action={
              <CanView can={[Permissions.CREATE_USERS]}>
                <Button onClick={() => setModal({ show: true, data: {} })}>
                  Add Users
                </Button>
              </CanView>
            }
          />
        ) : loadingUser ? (
          <SectionLoader />
        ) : (
          <>
            <Table responsive={false} report>
              <table>
                <thead>
                  <tr>
                    {column &&
                      column.map((elem, index) => {
                        return (
                          <th scope="row" key={elem.key ? elem.key : index}>
                            {elem.title}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {users?.users.map((elem, index) => {
                    return (
                      <tr key={index}>
                        {column.map((entry, index) => {
                          return (
                            <td key={index} data-label={entry.key}>
                              {entry.custom
                                ? entry.custom(elem)
                                : elem[entry.key] || EMPTY_VALUE}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Table>
            <Box>
              <FlexiPagination
                pageCounts={PAGE_SIZES}
                total={users?.total}
                pageSize={pageSize}
                itemsDisplayed
                onChange={(s) => setPage(s)}
                changePageSize={(s) => setPageSize(s?.value)}
                current={page}
              />
            </Box>
          </>
        )}
      </Box>
      <Modal open={modal.show} center={false}>
        <ModalBody width="600px">
          <UserEditor
            data={modal.data}
            closeModal={() => setModal({ show: false, data: {} })}
          />
        </ModalBody>
      </Modal>
      <Modal open={delModal.show} center={false}>
        <ModalBody width="600px">
          <Box pad="32px">
            <Text {...fontSize.h4} block bold>
              {delModal.data?.status ? "Deactivate" : "Activate"} the user “
              {delModal.data?.name}”
            </Text>
            <Spacer space="16px" />
            <Text>
              {delModal.data?.status
                ? `
                    Deactivating the user will make them unable to use SAFBILLING. But their account can be reactivated at any point in time.
                    `
                : `Are you sure you want to activate this user account`}
            </Text>
          </Box>
          <Grid default="auto max-content" responsive={false} className="bt">
            <Box pad="24px">
              <Button
                onClick={() =>
                  setDelModal({
                    show: false,
                    data: null,
                  })
                }
                pale
                fontColor={Theme.PrimaryGrey}
                color={Theme.PrimaryGrey}
              >
                <Text bold>No</Text>, don't{" "}
                {delModal.data?.status ? "deactivate" : "activate"}
              </Button>
            </Box>
            <Box pad="24px">
              <Button
                onClick={() => {
                  toggleUserStatus({
                    data: {
                      id: delModal?.data.id,
                      roleId: delModal?.data.roles[0].role?.id,
                      status: delModal.data.status,
                    },
                    onSuccess: () => {
                      refetch();
                      setDelModal({
                        show: false,
                        data: null,
                      });
                    },
                  });
                }}
                color={Theme.PrimaryRed}
                progress={isToggleUserStatus}
                disabled={isToggleUserStatus}
              >
                <Text bold>Yes</Text>,{" "}
                {delModal.data?.status ? "deactivate" : "activate"} user
              </Button>
            </Box>
          </Grid>
        </ModalBody>
      </Modal>
      <DeleteUserModal
        data={deleteModal.data}
        open={deleteModal.show}
        openModal={setDeleteModal}
      />
    </>
  );
};

export default UserManagement;
