import { errorNotifier } from "./helpers";
import { statusCodes } from "./constants";

export const networkErrorHandler = (statusCode, message) => {
  const {
    INTERNAL_SERVER_ERROR,
    CONFLICT,
    BAD_REQUEST,
    UNAUTHORIZED,
    UNPROCESSABLE,
  } = statusCodes;
  switch (statusCode) {
    case INTERNAL_SERVER_ERROR: {
      errorNotifier(
        `Error ${statusCode}: ${
          message ||
          "An error ocurred, but don't worry the problem is from our end."
        }`
      );
      break;
    }
    case BAD_REQUEST: {
      errorNotifier(
        `Error ${statusCode}: ${
          message ||
          "Please double check your fields, something is not quite right."
        }`
      );
      break;
    }
    case CONFLICT: {
      errorNotifier(
        `Error ${statusCode}: ${
          message || "There seems to be conflicting information."
        }`
      );
      break;
    }
    case UNAUTHORIZED: {
      errorNotifier(
        `Error ${statusCode}: ${
          message ||
          "You are not authorized to view this resource, please ensure you are logged in or you have the necessary permissions."
        }`
      );
      break;
    }
    case UNPROCESSABLE: {
      errorNotifier(
        `Error ${statusCode}: ${
          message ||
          "There seems to be a problem processing your request. Our engineers have been notified of this error. Please try again later."
        }`
      );
      break;
    }
    default: {
      errorNotifier(
        `Error ${INTERNAL_SERVER_ERROR}: ${
          message || "Something went wrong, please try again later."
        }`
      );
      break;
    }
  }
};

export const clientErrorHandler = (message) =>
  errorNotifier(
    message ||
      "Something went wrong, we have notified our engineers to get this fixed."
  );
