import { call, put, takeLatest } from "redux-saga/effects";
import {
  toggleUserStatusStart,
  toggleUserStatusFailure,
  toggleUserStatusSuccess,
} from "../reducer";

import { httpPutUserStatus } from "../../../services/users";
import { networkErrorHandler } from "../../../utils/errorHandlers";
import { successNotifier } from "../../../utils/helpers";

export function* toggleUserStatus({ payload }) {
  const { data, onSuccess, onFailure } = payload;
  const response = yield call(httpPutUserStatus, data);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(toggleUserStatusSuccess(raw));
    if (onSuccess) {
      onSuccess();
    }
    successNotifier("The user status has been successfully");
  } else {
    yield put(toggleUserStatusFailure(raw));
    networkErrorHandler(statusCode, message || "Unable to edit this user.");
    if (onFailure) {
      onFailure();
    }
  }
}

export default function* onToggleUserStatus() {
  yield takeLatest(toggleUserStatusStart.type, toggleUserStatus);
}
