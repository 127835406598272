import axios from 'axios';
import urls from './config';
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  setLocalAccessToken,
  logout,
  getLocalUser,
} from "./helpers";

const fetch = (options) => {
  const { method = "get", data, url, responseType } = options;

  const authToken = getLocalAccessToken();
  // eslint-disable-next-line no-unused-expressions
  authToken &&
    (axios.defaults.headers.common.Authorization = `Bearer ${authToken}`);

  switch (method.toLowerCase()) {
    case "get":
      return axios.get(url, {
        params: data,
        responseType,
      });
    case "delete":
      return axios.delete(url, {
        data,
      });
    case "post":
      switch (options.upload) {
        case true:
          return axios.post(url, options.formData, options.config);
        default:
          return axios.post(url, data);
      }
    case "put":
      return axios.put(url, data);
    case "patch":
      return axios.patch(url, data);
    case "download":
      return axios.get(url, {
        params: data,
        responseType,
      });
    default:
      return axios(options);
  }
};

export default function request(options) {
  return fetch(options)
    .then((response) => {
      const { data, status } = response;
      return {
        success: true,
        raw: data,
        statusCode: status,
      };
    })
    .catch((err) => {
      const { response } = err;
      let msg;
      let statusCode;
      if (response && response instanceof Object) {
        const { data, statusText } = response;
        statusCode = response.status;

        msg = data || data.error || statusText;

        if (statusCode === 401) {
          const refreshToken = getLocalRefreshToken();
          const userDetails = getLocalUser();

          if (!userDetails?.id) {
            return {
              success: false,
              raw: data,
              statusCode,
              message: msg,
            };
          }

          const payload = {
            refreshToken,
          };

          return axios
            .post(`${urls.API_BASE_URL}/api/auth/refresh-token`, payload)
            .then((resp) => {
              const refreshData = resp.data ? resp.data : {};
              setLocalAccessToken(refreshData.access_token);
              return request(options);
            })
            .catch((error) => {
              const errResponse = error.response;
              const errStatus = errResponse.status;
              if (errStatus === 401 || errStatus === 404) {
                return logout();
              }
              return {
                success: false,
                statusCode: errStatus,
                message: errResponse.data
                  ? errResponse.data.message
                  : errResponse.statusText,
              };
            });
        }
      } else {
        statusCode = 600;
        msg = "Poor internet connections.";
      }
      return { success: false, statusCode, message: msg };
    });
}
