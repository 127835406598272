import { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../../redux/auth/selectors";
import { getLocalAccessToken } from "../../utils/helpers";

const PublicRoute = ({ children }) => {
  const accessToken = getLocalAccessToken();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const auth = !!(accessToken && isAuthenticated);

  return <Fragment>{auth ? <Navigate to="/dashboard" /> : children}</Fragment>;
};

export default PublicRoute;
