import { createSlice } from "@reduxjs/toolkit";


export const initialState = {
  error: null,
};


export const customersSlice = createSlice({
    name: "customers",
    initialState,
    reducers: {
      toggleCustomerDemo: (state) => {
        state.error = null;
      },
      toggleCustomerDemoSuccess: (state) => {
        state.error = null;
      },
      toggleCustomerDemoFailure: (state, action) => {
        state.error = action.payload;
      },
    }
    })

    export const {

        toggleCustomerDemo,
        toggleCustomerDemoSuccess,
        toggleCustomerDemoFailure
      } = customersSlice.actions;
      
      const  customersReducer = customersSlice.reducer;
      
      export default customersReducer;