import { createSelector } from "@reduxjs/toolkit";

const selectUsers = (state) => state.users;

export const  selectIsLoadingUsers= createSelector(
    [selectUsers],
    (users) => users.isLoadingUsers
  );

  export const selectUsersData = createSelector(
    [selectUsers],
    (users) => users.users
  );

  export const selectIsCreateUser = createSelector(
    [selectUsers],
    (users) => users.isCreateUser
  );
  export const selectIsUpdateUser = createSelector(
    [selectUsers],
    (users) => users.isUpdateUser
  );
  export const selectIsDeleteUser = createSelector(
    [selectUsers],
    (users) => users.isDeleteUser
  );
  export const selectIsToggleUserStatus = createSelector(
    [selectUsers],
    (users) => users.isToggleUserStatus
  );