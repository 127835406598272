import { useState } from "react";
import {
  Input,
  Box,
  Button,
  Spacer,
  Text,
  Grid,
  Checkbox,
  Modal,
  ModalBody,
} from "@flexisaf/flexibull2";

import fontSize from "../../utils/typography";
import urls from "../../utils/config";
import { ORGANIZATION_ID, PRODUCT_ID } from "../../utils/constants";
import useLoginPost from "../../hooks/auth/useLoginPost";
import PasswordResetModal from "./modals/passwordReset";
import SplitLayoutContainer from "../../components/auth/splitLayoutContainer";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loadingGoogleAuth, setLoadingGoogleAuth] = useState(false);
  const [showPassword, setShowPassword] = useState("");
  const { isLoading, loginUser } = useLoginPost();
  const [forgotPasswordModal, setForgotPasswordModal] = useState({
    show: false,
    data: null,
  });

  const handleLogin = async () => {
    loginUser({
      username,
      password,
      onSuccess: () => {
        // navigate("/dashboard");
        window.location.reload();
      },
    });
  };

  function googleAuth() {
    setLoadingGoogleAuth(true);
    const callback_url = `${window.location.origin}/auth-verify`;
    const url = `${urls.UMS_BASE_URL}/oauth2/google/authorize?callback_url=${callback_url}&organization=${ORGANIZATION_ID}&product=${PRODUCT_ID}`;
    window.location.href = url;
  }

  const handleClose = () => {
    setForgotPasswordModal({
      show: false,
      data: null,
    });
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <SplitLayoutContainer
        modal={
          <Modal open={forgotPasswordModal.show} center={false}>
            <ModalBody style={{ width: "40%" }}>
              <PasswordResetModal
                data={forgotPasswordModal.data}
                handleClose={handleClose}
              />
            </ModalBody>
          </Modal>
        }
      >
        <Box pad="32px" maxWidth="400px">
          <Box pad="24px" align="center">
            <Text {...fontSize.h2} block>
              Hello Again!
            </Text>
            <Spacer space="16px" />
            <Text>
              Welcome to SAFBilling, here you get to manage our clients at
              flexisaf
            </Text>
          </Box>
          <Input
            Placeholder="User Email"
            spaceBottom
            block
            type="email"
            value={username}
            onChange={({ target: { value } }) => setUsername(value)}
          />
          <Input
            Placeholder="Password"
            block
            type={!showPassword ? "password" : "text"}
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
          />
          <Grid responsive={false} default="auto max-content">
            <Box>
              <Checkbox
                label="Show Password"
                onChange={(e) => setShowPassword(e.target.checked)}
                checked={showPassword}
              />
            </Box>

            {/* eslint-disable-next-line */}
            <Box
              pad="8px 0"
              style={{ cursor: "pointer" }}
              onClick={() =>
                setForgotPasswordModal({
                  show: true,
                  data: username || "",
                })
              }
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                <Text>Recover Password</Text>
              </a>
            </Box>
          </Grid>
          <Spacer space="16px" />
          <Button
            disabled={loadingGoogleAuth || isLoading}
            block
            onClick={handleLogin}
            progress={isLoading}
          >
            Login
          </Button>
          <Spacer space="16px" />
          <Button
            pale
            block
            disabled={loadingGoogleAuth || isLoading}
            onClick={googleAuth}
            loading={loadingGoogleAuth}
            iconLeft={
              <img
                alt="google signin"
                src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png"
                style={{ height: "13px" }}
              />
            }
          >
            Sign in with Google
          </Button>
        </Box>
      </SplitLayoutContainer>
    </>
  );
};

export default Login;
