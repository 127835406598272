import { call, put, takeLatest } from "redux-saga/effects";
import { httpGetUserData} from "../../../services/users";
import {
    fetchUserDataFailure,
    fetchUserDataStart,
    fetchUserDataSuccess,
  } from "../reducer";

  import { networkErrorHandler } from "../../../utils/errorHandlers";

export function* getUsers() {
  const response = yield call(httpGetUserData);

  const { raw, statusCode, success, message } = response;

  if (success) {
    yield put(fetchUserDataSuccess(raw));
  } else {
    yield put(fetchUserDataFailure(raw));
    networkErrorHandler(
      statusCode,
      message ||
        "Unable to fetch User summary. Please check your internet connection or try again later."
    );
  }
}

export default function* onGetUserData() {
  yield takeLatest(fetchUserDataStart.type, getUsers);
}