import React from "react";
import PropTypes from "prop-types";
import { getLocalUser } from "../../utils/helpers";

export const getUserPrivileges = () => {
  const user = getLocalUser();

  return user.roles
    ?.map(
      (role) =>
        role?.role?.privileges?.map((privilege) => privilege.name) || []
    )
    .flat()
    .flat();
};

export const hasAccess = (restrictedPermissions = []) => {
  const userPermissions = getUserPrivileges();
  return !!restrictedPermissions?.some((restrictedPermission) =>
    userPermissions?.includes(restrictedPermission)
  );
};

const CanView = (props) =>
  hasAccess(props.can) ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : null;

export const CanViewTabs = (can) => (hasAccess(can) ? false : true);

CanView.propTypes = {
  can: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.any,
};

export const CanFilter = (list) =>
  list.filter((item) => hasAccess(item.can || []));

export default CanView;

export const Permissions = {
  CREATE_USERS: "users:c",
  DELETE_USERS: "users:d",
  READ_USERS: "users:r",
  UPDATE_USERS: "users:u",

  CREATE_CUSTOMERS: "customers:c",
  READ_CUSTOMERS: "customers:r",
  DELETE_CUSTOMERS: "customers:d",
  UPDATE_CUSTOMERS: "customers:u",

  CREATE_PRODUCTS: "products:c",
  DELETE_PRODUCTS: "products:d",
  READ_PRODUCTS: "products:r",
  UPDATE_PRODUCTS: "products:u",

  CREATE_ROLES: "roles:c",
  DELETE_ROLES: "roles:d",
  READ_ROLES: "roles:r",
  UPDATE_ROLES: "roles:u",

  CREATE_STAFF: "staff:c",
  DELETE_STAFF: "staff:d",
  READ_STAFF: "staff:r",
  UPDATE_STAFF: "staff:u",

  CREATE_SUBSCRIPTIONS: "subscriptions:c",
  DELETE_SUBSCRIPTIONS: "subscriptions:d",
  READ_SUBSCRIPTIONS: "subscriptions:r",
  UPDATE_SUBSCRIPTIONS: "subscriptions:u",

  CREATE_TEMPLATES: "templates:c",
  DELETE_TEMPLATES: "templates:d",
  READ_TEMPLATES: "templates:r",
  UPDATE_TEMPLATES: "templates:u",

  CREATE_TAGS: "tags:c",
  DELETE_TAGS: "tags:d",
  UPDATE_TAGS: "tags:u",
  READ_TAGS: "tags:r",
};

export const canViewCustomers = [
  Permissions.CREATE_CUSTOMERS,
  Permissions.READ_CUSTOMERS,
  Permissions.DELETE_CUSTOMERS,
  Permissions.UPDATE_CUSTOMERS,
];

export const canViewProducts = [
  Permissions.CREATE_PRODUCTS,
  Permissions.READ_PRODUCTS,
  Permissions.READ_PRODUCTS,
  Permissions.UPDATE_PRODUCTS,
];

export const canViewUsers = [
  Permissions.CREATE_USERS,
  Permissions.READ_USERS,
  Permissions.READ_USERS,
  Permissions.UPDATE_USERS,
];

export const canViewRoles = [
  Permissions.CREATE_ROLES,
  Permissions.READ_ROLES,
  Permissions.READ_ROLES,
  Permissions.UPDATE_ROLES,
];

export const canViewStaff = [
  Permissions.CREATE_STAFF,
  Permissions.READ_STAFF,
  Permissions.READ_STAFF,
  Permissions.UPDATE_STAFF,
];

export const canViewSubscriptions = [
  Permissions.CREATE_SUBSCRIPTIONS,
  Permissions.READ_SUBSCRIPTIONS,
  Permissions.READ_SUBSCRIPTIONS,
  Permissions.UPDATE_SUBSCRIPTIONS,
];

export const canViewTemplates = [
  Permissions.CREATE_TEMPLATES,
  Permissions.READ_TEMPLATES,
  Permissions.READ_TEMPLATES,
  Permissions.UPDATE_TEMPLATES,
];

export const canViewTags = [
  Permissions.CREATE_TAGS,
  Permissions.READ_TAGS,
  Permissions.READ_TAGS,
  Permissions.UPDATE_TAGS,
];

export const canViewRoutes = [
  ...canViewCustomers,
  ...canViewProducts,
  ...canViewSubscriptions,
  ...canViewTemplates,
  ...canViewRoles,
  ...canViewUsers,
];


