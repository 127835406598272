import {
  Box,
  Text,
  Grid,
  Button,
  Spacer,
  Modal,
  ModalBody,
} from "@flexisaf/flexibull2";
import Theme from "../../../utils/theme";
import fontSize from "../../../utils/typography";
import useUserEditor from "../../../hooks/users/useUserEditor";
import useUsersGet from "../../../hooks/users/useUsersGet";

const DeleteUserModal = function ({
  open,
  openModal,
  data,
}) {
  const {
    isDeleting,
    deleteUser
  } = useUserEditor();
  const {
    refetch
  } = useUsersGet();

  return (
    <Modal open={open} center={false}>
      <ModalBody width="600px">
        <Box pad="32px">
          <Text {...fontSize.h4} block bold>
            Delete User
          </Text>
          <Spacer space="16px"  />
          <Text style={{fontSize:"19px"}}>
            Deleting <strong>{data?.first_name}</strong> will make it unavailable to
            all customers and linked activities. Also, this action cannot be
            undone. Are you sure you want to delete this user.
          </Text>
        </Box>
        <Grid default="auto max-content" responsive={false} className="bt">
          <Box pad="24px">
            <Button
              onClick={() => openModal({ show: false, data: {} })}
              pale
              fontColor={Theme.PrimaryGrey}
              color={Theme.PrimaryGrey}
            >
              <Text bold>No</Text>, Keep User
            </Button>
          </Box>
          <Box pad="24px">
            <Button
              progress={isDeleting}
              onClick={() => {
                deleteUser({id:data?.id,  onSuccess: () => {
                  refetch();
                  openModal({
                    show: false,
                    data: null,
                  });
                }})
             
              }}
              color={Theme.PrimaryRed}
            >
              <Text bold>Yes</Text>, Delete User
            </Button>
          </Box>
        </Grid>
      </ModalBody>
    </Modal>
  );
};
export default DeleteUserModal;
