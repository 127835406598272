import {
  Box,
  Grid,
  StatusIndicator,
  Spacer,
  Checkbox,
  Table,
  Button,
} from "@flexisaf/flexibull2";
import { Text } from "@flexisaf/flexibull2/build/typo";
import Theme from "../../../utils/theme";
import moment from "moment";
import fontSize from "../../../utils/typography";
import infinity from "../../../assets/images/infinity.svg"
import { Link } from "react-router-dom";
import styled from "styled-components";
import { dollarFormatter, thousandFormatter } from "../../../utils/helpers";
import { useEffect, useState } from "react";
const StatusIndicators = styled(StatusIndicator)`
  & .indicator {
    background-color: ${(props) => (props.Status ? "red" : "#A1DFCA")};
  }
`;
const StatusIndicatores = styled(StatusIndicator)`
  & .indicator {
    background-color: ${(props) => (props.isPublished ? "red" : "#A1DFCA")};
  }
`;

const PlanDetail = (props) => {
  const { data } = props;
const [planConfig, setPlanConfig] = useState([])
useEffect(() =>{
  if (data) {
  
    try {
      const jsonArray = JSON.parse(data?.planConfiguration);
    setPlanConfig(jsonArray)
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }
}, [data])

const column = [
  {
    title: "Feature",
    key: "feature",
    custom: (row, index) => <Text>{row?.name}</Text>,
  },

  {
    title: "Aliases",
    key: "feature",
    custom: (row, index) => <Text>{row?.alias}</Text>,
  },
  {
    title: "Value",
    key: "value",
    custom: (row, index) => (
      <>
        {typeof row?.value === "number" && !Number.isNaN(row?.type) ? (
          <>
            {row?.value === -1 ? (
              <img src={infinity} alt="minus" style={{ width: "15px" }} />
            ) : (
              <Text bold style={{ textAlign: "center", fontSize: "16px" }}>
                {row?.value}
              </Text>
            )}
          </>
        ) : typeof row?.value === "boolean" ? (
          <Checkbox
            block
            style={{ marginRight: "0px" }}
            checked={row?.value}
            size="16px"
          />
        ) : null}
      </>
    ),
  },
];
  return (
    <Box>
      <Grid default="auto max-content" reponsize={false} className="bb">
        <Box pad="24px 32px">
          <Text {...fontSize.h3} uppercase>
            Plan Details
          </Text>
        </Box>
      </Grid>
      <Box pad="32px">
        <Box
          round="high"
          background={Theme.PrimarySurface}
          className="bordered"
          pad="24px"
        >
          <Grid default="1fr 1fr 1fr" gap="24px">
            <Box>
              <Text {...fontSize.caption} block>
                Plan Name
              </Text>
              <Text bold>{data?.name}</Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Alias
              </Text>
              <Link>
                <Text bold color={Theme.PrimaryColor}>
                  {data?.planAlias}
                </Text>
              </Link>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Plan type
              </Text>
              <Text bold> {data?.type}</Text>
            </Box>
          </Grid>
          <Spacer space="24px" />
          <Grid default="1fr 1fr">
            <Box>
              <Text {...fontSize.caption} block>
                product
              </Text>
              <Text bold>{data?.product?.name}</Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Customer segment
              </Text>
              <Box
                style={{
                  backgroundColor: "#B8D4FF",
                  borderRadius: "5px",
                  padding: "3px",
                  textAlign: "center",
                }}
              >
                <Text bold>{data?.marketSegment?.name}</Text>
              </Box>
            </Box>
          </Grid>
        </Box>

        <Spacer space="16px" />
        <Box
          round="high"
          background={Theme.PrimarySurface}
          className="bordered"
          pad="24px"
        >
      
          <Grid default="1fr 1fr 1fr" gap="24px">
            <Box>
              <Text {...fontSize.caption} block>
                Billing Alias
              </Text>
              <Text bold>{data?.billingCylceAlias}</Text>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Billing Cycle
              </Text>
              <Link>
                <Text bold color={Theme.PrimaryColor}>
                  {data?.frequency ? (
                    <Text bold>{ ` every   ${data?.frequency} ${data?.billingCycleType}    `}</Text>
                  ) : (
                    ""
                  )}
                </Text>
              </Link>
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
                Unit Cost
              </Text>
              <Text bold>
                {data?.currency === "NGN"
                  ?  ` ₦ ${thousandFormatter(data?.unitCost)}`
                  :`  W${dollarFormatter(data?.unitCost)}`}
              </Text>
            </Box>
          </Grid>
          <Spacer space="24px" />
          <Grid default="1fr 1fr 1fr">
            <Box>
              <Text {...fontSize.caption} block>
                Plan Status
              </Text>
              <Box>
                <StatusIndicators
                  compound
                  label={data?.status ? "Active" : "InActive"}
                />
              </Box>
            </Box>

            <Box>
              <Text {...fontSize.caption} block>
                Publish status
              </Text>
              <StatusIndicatores
                compound
                label={data?.isPublished ? "Published" : "UnPublish"}
              />
            </Box>
            <Box>
              <Text {...fontSize.caption} block>
             Plan type
              </Text>
              <Text {...fontSize.h3} >
                      {data?.isDemo ? "Demo plan" :"Live plan"}
                    </Text>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Spacer space="10px" />
        <Box pad=" 0 32px">
          <Box
            round="high"
            background={Theme.PrimarySurface}
            className="bordered"
            pad="24px"
          >
            <Table style={{ overflowX: "visible" }}>
              <table style={{ backgroundColor: "#F2F4F6" }}>
                <thead pad="20px">
                  <tr>
                  {column &&
                    column.map((elem, index) => {
                      return (
                        <th scope="row" key={elem.key ? elem.key : index}>
                          {elem.title}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {planConfig &&
                    planConfig.map((elem, outerindex) => (
                      <tr key={outerindex}>
                      {column.map((entry, innerindex) => {
                        return (
                          <td key={innerindex} data-label={entry.key}>
                            {entry.custom
                              ? entry.custom(elem, outerindex)
                              : elem[entry.key]}
                          </td>
                        );
                      })}
                    </tr>
                    ))}
                </tbody>
              </table>
            </Table>
            <Box>
              <Text {...fontSize.caption} block>
              created On
              </Text>
              <Text bold>{moment(data?.createdAt).format("DD MMMM, YYYY")}</Text>
            </Box>
          </Box>
        </Box>
        
        <Spacer space="24px" />
      {/* <Grid default="auto max-content" reponsize={false} className="bt"> */}
      <Box pad="24 px">
      <Button
              pale
              color="rgba(0,0,0,0.0)"
              fontColor={Theme.PrimaryGrey}
              onClick={() => props.closeModal()}
            >
              Cancel
            </Button>
        {/* <Box pad="24px" align="right">
          <Button onClick={() => props.openEditor}     iconRight={<i className="icon-pencil" />}>Edit</Button>
        </Box> */}
      {/* </Grid> */}
      </Box>
    </Box>
  );
};
export default PlanDetail;
