import { Box, Text, Avatar, DropDown } from '@flexisaf/flexibull2';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { logOutUser } from "../../redux/auth/reducer";
import styled from "styled-components";

const ProfileWrapper = styled.div`
  padding: 20px 10px;
  display: grid;
  grid-template-columns: max-content auto max-content;
  width: 100%;
  grid-gap: 10px;
  align-items: center;

  & .text-profile {
    max-width: 190px;

    & .role {
      font-size: 9px;
      font-weight: 500;
      background-color: rgba(203, 213, 228, 0.6);
      width: fit-content;
      padding: 5px;
      border-radius: 5px;
    }
  }
  & .popper {
    border-radius: 2px;
    transition: ${(props) => props.theme.PrimaryTransition};
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
`;

const LIST = (handleLogout) => [
  {
    onClick: () => {},
    label: "Manage Profile",
    iconLeft: " icon-user-outline",
  },
  {
    onClick: handleLogout,
    label: "Sign out",
    iconLeft: "icon-eject-outline",
  },
];

export const ProfileHolder = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name, image, email, status, userRole } = props;
  const handleLogout = () => {
    dispatch(logOutUser());
    navigate("/login");
  };

  return (
    <ProfileWrapper>
      <Box className="avatar-box">
        <Avatar size="40px" round name={name} src={image} status={status} />
      </Box>
      <Box className="text-profile">
        <Text block truncate style={{ textTransform: "capitalize" }}>
          {name}
        </Text>

        <Text size="0.8em" truncate>
          {email}
        </Text>
        {userRole && (
          <div className="role">
            <Text>{userRole}</Text>
          </div>
        )}
      </Box>
      <Box>
        <DropDown
          block
          trigger="click"
          label={
            <Box pad="10px" className="popper">
              <i className="flexibull-up-dir" />
            </Box>
          }
          menuAlign="top right"
          menuList={LIST(handleLogout, navigate)}
        />
      </Box>
    </ProfileWrapper>
  );
};