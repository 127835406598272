import { REACT_APP_ENV } from "./constants";

const config = {
    development: {
      API_SAFAPPLY_URL: 'https://api.safapply.flexisafapps-dev.com',
      API_BASE_URL: process.env.REACT_APP_API_BASE_URL || 'https://api.safbilling.flexisafapps-dev.com',
      UMS_BASE_URL:'https://api.ums.flexisafapps-dev.com',
      SELF_SERVICE:  "https://signup.safapply.flexisafapps-dev.com",
    },
    staging: {
      API_SAFAPPLY_URL: 'https://api.safapply.flexisafapps-stage.com',
      API_BASE_URL: process.env.REACT_APP_API_BASE_URL || 'https://api.safbilling.flexisafapps-dev.com',
      UMS_BASE_URL:'https://api.ums.flexisafapps-dev.com',
      SELF_SERVICE:  "https://signup.safapply.flexisafapps-dev.com",
    },
    production: {
      API_SAFAPPLY_URL: 'https://api.safapply.com',
      API_BASE_URL: process.env.REACT_APP_API_BASE_URL || 'https://api.safbilling.flexisaf.com',
      UMS_BASE_URL:'https://ums-api.component.flexisaf.com',
      SELF_SERVICE:  "https://signup.safapply.com/",
    },
  };
  
  export default config[REACT_APP_ENV];